<template>
  <div class="activity-form">
    <v-form>
      <v-row>
        <v-col>
          <v-text-field
            :label="$i18n.locale === 'fr' ? 'Nom Activité' : 'Name Activity'"
            dense
            :persistent-placeholder="true"
            outlined
            hide-details
            color="#5C2DD3"
            v-model="activity.name"
            item-color="#5C2DD3"
          >
          </v-text-field>
        </v-col>
      </v-row>
      <!-- MENU TYPE ACTIVITY -->
      <v-row class="mb-2">
        <v-col>
          <div class="activity-type">
            <div class="activity-types-tabs flex justify-between">
              <div
                class="activity-types-tab"
                :class="{
                  active: currentActivityType == 1
                }"
              >
                <font-awesome-icon class="mr-1 img-h-14" icon="phone-alt" />
                {{ $i18n.locale === 'fr' ? 'Appel' : 'Call' }}
              </div>

              <div
                class="activity-types-tab"
                :class="{
                  active: currentActivityType == 2
                }"
              >
                <font-awesome-icon class="mr-1 img-h-14" icon="briefcase" />{{
                  $i18n.locale === 'fr' ? 'Pré-visite' : 'Pre-visit'
                }}
              </div>
              <div
                class="activity-types-tab"
                :class="{
                  active: currentActivityType == 3
                }"
              >
                <font-awesome-icon class="mr-1 img-h-14" icon="truck" />
                {{ $i18n.locale === 'fr' ? 'Planification' : 'Planning' }}
              </div>
              <div
                class="activity-types-tab"
                :class="{
                  active: currentActivityType == 4
                }"
              >
                <font-awesome-icon
                  class="mr-1 img-h-14"
                  icon="hourglass-start"
                />{{ $i18n.locale === 'fr' ? 'Autre' : 'Other' }}
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
      <!-- PLANIFICATION -->
      <v-row v-if="currentActivityType === 3">
        <v-col>
          <div class="diponibility">
            <div class="flex-center">
              <div class="ml-2 font-sz-13 font-text">
                {{
                  $i18n.locale === 'fr'
                    ? 'Suggestions des rendez-vous :'
                    : 'Appointment suggestions:'
                }}
              </div>
              <div class="loading" v-if="getProjectsSuggestionsProcessing">
                {{ $t('loading') }}
              </div>
            </div>

            <div>
              <template v-if="planificationSuggestions">
                <div class="planning-description italic">
                  <span class="planning-description--label bold">
                    {{
                      $i18n.locale === 'fr'
                        ? 'Estimation de durée de pose :'
                        : 'Estimated installation time :'
                    }}
                  </span>
                  <span class="planning-description--value">
                    {{ planificationSuggestions.pose_time_minutes | duration }}
                  </span>
                </div>
                <div class="suggestions-list mt-2 ml-2">
                  <label
                    class="suggestion"
                    v-if="activity.type == 'Planning'"
                    :class="{
                      active: selectedSuggestion == activity.date_start
                    }"
                    for="radio-suggest-update-selected"
                  >
                    <input
                      type="radio"
                      name="radio-suggest"
                      id="radio-suggest-update-selected"
                      :value="selectedSuggestion | date('YYYY-MM-DD HH:mm:ss')"
                      v-model="activity.date_start"
                    />
                    <div class="suggestion-container">
                      <div class="date-flag">
                        <font-awesome-icon icon="calendar-day" />
                      </div>
                      <div class="date-details">
                        <span class="capitalize">
                          {{ selectedSuggestion | date('ddd DD MMM') }}
                        </span>
                        à
                        {{ selectedSuggestion | time('HH:mm') }}
                      </div>
                    </div>
                  </label>
                </div>
                <Collapse
                  v-for="(week, week_index) in planificationSuggestions.weeks"
                  :key="'planification-suggestions-update-' + week.week_id"
                  :init="week_index == 0 ? true : false"
                  :selected="collapseSuggestions"
                  @toggle="collapseSuggestions = $event"
                >
                  <template #title>
                    {{
                      week_index == 0
                        ? `Semaine en cours (N°${week.week_nbr})`
                        : week_index == 1
                        ? `Semaine prochaine (N°${week.week_nbr})`
                        : `Semaine (N°${week.week_nbr})`
                    }}
                  </template>
                  <template #body>
                    <div class="suggestions-list">
                      <label
                        class="suggestion"
                        v-for="(date,
                        index) in week.available_dates_hours_techs"
                        :key="'available-dates-hours-techs-' + index"
                        :for="`radio-suggest-update-${week_index}-${index}`"
                        :class="{
                          active:
                            activity.date_start == `${date.day} ${date.hour}:00`
                        }"
                      >
                        <input
                          :id="`radio-suggest-update-${week_index}-${index}`"
                          type="radio"
                          name="radio-suggest"
                          :value="`${date.day} ${date.hour}:00`"
                          v-model="activity.date_start"
                        />
                        <div class="suggestion-container">
                          <div class="date-flag">
                            <font-awesome-icon icon="calendar-day" />
                          </div>
                          <div class="date-details">
                            <span class="capitalize">
                              {{ date.day | date('ddd DD MMM') }}
                            </span>
                            à
                            {{ date.hour }}
                          </div>
                        </div>
                      </label>
                    </div>
                  </template>
                </Collapse>
              </template>
            </div>
          </div>
        </v-col>
      </v-row>
      <!--  -->
      <v-row v-else>
        <v-col>
          <v-menu :close-on-content-click="false" offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="activity.date_start"
                :value="activity.date_start"
                @click:clear="
                  handleResetFilter(
                    'date_start',
                    'selectedDateTimeDate',
                    'selectedDateTimeTime'
                  )
                "
                label="Date"
                outlined
                readonly
                clearable
                dense
                hide-details
                v-on="on"
                v-bind="attrs"
                :persistent-placeholder="true"
                :placeholder="$t('msgSelectDate')"
                prepend-inner-icon="mdi-calendar"
                color="#5C2DD3"
              ></v-text-field>
            </template>
            <v-card>
              <v-card-text>
                <v-date-picker
                  value-type="format"
                  format="YYYY-MM-DD HH:mm"
                  no-title
                  v-model="selectedDateTimeDate"
                  :value="selectedDateTimeDate"
                  locale="fr"
                  color="#5C2DD3"
                  item-color="#5C2DD3"
                ></v-date-picker>
                <v-time-picker
                  class="time-dynamique"
                  v-model="selectedDateTimeTime"
                  :value="selectedDateTimeTime"
                  color="#5C2DD3"
                  item-color="#5C2DD3"
                ></v-time-picker>
              </v-card-text>
            </v-card>
          </v-menu>
        </v-col>
        <v-col
          class="activity-me-pevenir"
          v-if="
            !activity.terminer &&
              (activity.type == 'Appel' || activity.type == 'Autre')
          "
        >
          <v-menu :close-on-content-click="false" offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="activity.me_prevenir"
                :value="activity.me_prevenir"
                :label="
                  $i18n.locale === 'fr'
                    ? 'Me prévenir avant'
                    : 'Notify me before'
                "
                outlined
                readonly
                hide-details
                dense
                clearable
                v-on="on"
                v-bind="attrs"
                :persistent-placeholder="true"
                :placeholder="
                  $i18n.locale === 'fr' ? 'Sélectionner heure' : 'Select time'
                "
                prepend-inner-icon="mdi-clock-time-four-outline"
                color="#5C2DD3"
              ></v-text-field>
            </template>

            <v-time-picker
              v-model="activity.me_prevenir"
              :value="activity.me_prevenir"
              color="#5C2DD3"
              item-color="#5C2DD3"
              format="24hr"
            ></v-time-picker>
          </v-menu>
        </v-col>
        <v-col>
          <v-checkbox
            v-if="activity.type == 'Appel' || activity.type == 'Autre'"
            class="input-checkbox msg-error mt-2 label-check"
            :label="
              $i18n.locale === 'fr' ? 'Activité terminée' : 'Activity completed'
            "
            v-model="activity.terminer"
            color="#5C2DD3"
            hide-details
          ></v-checkbox>
        </v-col>
      </v-row>
      <!-- DESCRIPTION -->
      <v-row>
        <v-col>
          <div class="ml-2 font-sz-13 font-text">
            Description :
          </div>
          <e-editor ref="activityEditor" v-model="activity.description" />
        </v-col>
      </v-row>
      <!-- USERS -->
      <v-row>
        <v-col>
          <v-autocomplete
            class="mr-2"
            :clearable="true"
            color="#5C2DD3"
            :placeholder="
              $t('searchMsg', {
                msg: $t('utilisateurs')
              })
            "
            item-color="#5C2DD3"
            v-model="activity.user"
            :value="activity.user"
            :items="activityUsersList"
            :persistent-placeholder="true"
            :label="$i18n.locale === 'fr' ? 'Affectée à' : 'Assigned to'"
            return-object
            item-text="text"
            dense
            item-value="value"
            :loading="getProjectsUsersNotDeletedLoading"
            :no-data-text="
              $t('noDataOptionMsg', {
                option: $t('utilisateurs')
              })
            "
            outlined
            :menu-props="{
              bottom: true,
              offsetY: true
            }"
          >
          </v-autocomplete>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Collapse from '@/components/CrmIla26Component/Projets/Collapse'
import EEditor from '@/components/CrmIla26Component/Projets/EEditor'
import moment from 'moment'
import momentDurationFormatSetup from 'moment-duration-format'
momentDurationFormatSetup(moment)
moment.locale('fr')
export default {
  components: {
    Collapse,
    EEditor
  },
  props: {
    data: {
      default: null
    },
    activity: { required: true },
    project: { required: false }
  },
  data() {
    return {
      currentActivityType: null,
      planificationSuggestions: null,
      preVisitSuggestions: null,
      activityTypes: ['Appel', 'Pré visite', 'Planning', 'Autre'],
      selectedSuggestion: this.activity.date_start,
      collapseSuggestions: null,
      selectedDateTimeDate: null,
      selectedDateTimeTime: null
    }
  },
  methods: {
    ...mapActions([
      'fetchPreVisitDatesSuggestions',
      'fetchPlanningDatesSuggestions',
      'createActivity'
    ]),
    handleResetFilter(ref_1, ref_2, ref_3) {
      if (ref_1) {
        this.activity[ref_1] = null
      }
      if (ref_2) {
        this[ref_2] = null
      }
      if (ref_3) {
        this[ref_3] = null
      }
    },
    updateDateTime() {
      // Combine selectedDate and selectedTime into dateTime
      if (this.selectedDateTimeDate && this.selectedDateTimeTime) {
        this.activity.date_start = `${this.selectedDateTimeDate} ${this.selectedDateTimeTime}`
      } else if (this.selectedDateTimeDate && !this.selectedDateTimeTime) {
        this.activity.date_start = `${this.selectedDateTimeDate} 00:00`
      } else if (!this.selectedDateTimeDate && this.selectedDateTimeTime) {
        this.selectedDateTimeDate = new Date().toJSON().slice(0, 10)
        this.activity.date_start = `${this.selectedDateTimeDate} ${this.selectedDateTimeTime}`
      }
    }
  },
  computed: {
    ...mapGetters([
      'getProjectsSuggestionsProcessing',
      'getProjectsUsersNotDeleted',
      'getProjectsUsersNotDeletedLoading',
      'getProjectsUsersPoseursNotDeleted',
      'getProjectsUsersPrevisiteurNotDeleted'
    ]),
    computedPoseursNotDeleted: function() {
      return this.getProjectsUsersPoseursNotDeleted.map(u => {
        return {
          text: u.full_name,
          value: u.id
        }
      })
    },
    computedPrevisiteurNotDeleted: function() {
      return this.getProjectsUsersPrevisiteurNotDeleted.map(u => {
        return {
          text: u.full_name,
          value: u.id
        }
      })
    },
    activityUsersList: function() {
      if (this.activity.type == 'Pré visite') {
        return this.computedPrevisiteurNotDeleted
      } else if (this.activity.type == 'Planning') {
        return this.computedPoseursNotDeleted
      } else {
        return this.getProjectsUsersNotDeleted.map(u => {
          return {
            text: u.full_name,
            value: u.id
          }
        })
      }
    }
  },
  filters: {
    date: function(value, format = 'DD/MM/YYYY') {
      if (!value) return ''
      return moment(value).format(format)
    },
    time: function(value, format = 'HH:mm:ss') {
      if (!value) return '--:--'
      return moment(value).format(format)
    },
    duration: function(value) {
      if (value === null || value === undefined) return ''
      if (!value) return '00h00'
      if (!Number.isInteger(value)) {
        value = parseInt(value)
      }
      if (value < 60) return '00h' + value
      value = moment.duration(value, 'minutes').format('HH[h]mm')
      return value
    },
    datetime: function(value, format = 'YYYY-DD-MM HH:mm:ss') {
      if (!value) return ''
      return moment(value, 'YYYY-MM-DD H').format(format)
    },
    key: value => {
      switch (value) {
        case 'status':
          return 'Statut'
        case 'cstage_id':
          return 'Stade'
        case 'csubstage_id':
          return 'Sous-stade'
        default:
          return ''
      }
    }
  },
  watch: {
    selectedDateTimeDate() {
      this.updateDateTime()
    },
    selectedDateTimeTime() {
      this.updateDateTime()
    }
  },
  async mounted() {
    if (this.activity.terminer) {
      this.activity.terminer = true
    } else {
      this.activity.terminer = false
    }
    this.activity.user = {
      ...this.activity.user,
      value: this.activity.user.id,
      text: this.activity.user.full_name
    }
    if (this.activity.type == 'Appel') {
      this.currentActivityType = 1
    } else if (this.activity.type == 'Pré visite') {
      this.currentActivityType = 2
    } else if (this.activity.type == 'Planning') {
      this.currentActivityType = 3
      this.planificationSuggestions = await this.fetchPlanningDatesSuggestions({
        zipCode: this.project.zipCode,
        s: this.project.surface_souffle || 0,
        d: this.project.surface_deroule || 0,
        r: this.project.surface_rampant || 0,
        m: this.project.surface_mur || 0,
        p: this.project.surface_plafond || 0,
        vs: this.project.surface_vide_sanitaire || 0
      })
    } else {
      this.currentActivityType = 4
    }
  }
}
</script>

<style lang="scss" scoped>
.activity-form {
  padding: 10px;
  font-size: 13px;
  .activity-type {
    .activity-types-tabs {
      margin-top: 5px;
      .activity-types-tab {
        white-space: nowrap;
        padding: 4px 6px;
        font-size: 14px;
        font-weight: bold;
        width: 34%;
        text-align: center;
        user-select: none;
        cursor: pointer;
        background-color: #5c2dd3;
        border: 1px solid #fff;
        color: #fff;
        &.active {
          background-color: #fff;
          color: #5c2dd3;
          padding: 4px 6px;
          border-color: #5c2dd3;
        }
      }
    }
  }
  .diponibility {
    display: block;
    .planning-description {
      margin-left: 10px;
      font-size: 12px;
    }
    .suggestions-list {
      display: flex;
      flex-wrap: wrap;
      .suggestion {
        display: block;
        background: #e5e5e5;
        border: 1px solid #d5d5d5;
        padding: 2px 5px;
        margin-right: 10px;
        border-radius: 3px;
        cursor: pointer;
        transition: background 0.5s;
        width: 150px;

        .suggestion-container {
          display: flex;
          align-items: center;

          .date-flag {
            position: relative;
            margin-right: 4px;
            font-size: 14px;
          }
          .date-details {
            font-size: 12px;
          }
        }

        &:hover {
          background: #d5d5d5;
        }

        input {
          display: none;
        }

        &.active {
          background: #b0f2b6;
          border: 1px solid #8fca95;
        }
      }
    }
  }
}

.close {
  position: absolute;
  font-size: 20px;
  top: 10px;
  right: 10px;
  cursor: pointer;
}
.error {
  color: #ff6961;
}
.flex {
  display: flex;
}
.bold {
  font-weight: 600;
}
.italic {
  font-style: italic;
}
.text-right {
  text-align: right;
}
.justify-between {
  justify-content: space-between;
}
.capitalize {
  text-transform: capitalize;
}
.activity-date-interval {
  display: flex;
  .checkbox-terminer {
    margin-left: 40px;
  }
}
label {
  margin-bottom: 6px;
}
</style>
