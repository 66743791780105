<template>
  <Card>
    <template #body>
      <v-form @submit.prevent="submitForm" ref="myForm">
        <v-row>
          <v-col cols="6" v-for="(info, i) in form18.informations" :key="i">
            <Card>
              <template #title>
                <div :class="{ link: i != 0 }" class="title">
                  {{
                    $i18n.locale === 'fr'
                      ? `Information Fiscalité (Avis ${i + 1})`
                      : `Tax information (Notice ${i + 1})`
                  }}
                  <font-awesome-icon
                    v-if="i != 0"
                    @click="removeAvis(i)"
                    class="icon"
                    icon="circle-minus"
                    style="color: #704ad1;"
                  />
                </div>
              </template>
              <template #body>
                <v-text-field
                  type="number"
                  v-model="info.nbre_personne"
                  :label="
                    $i18n.locale === 'fr'
                      ? 'Nombre de personne'
                      : 'Number of persons'
                  "
                  :field="
                    $i18n.locale === 'fr'
                      ? 'Nombre de personne'
                      : 'Number of persons'
                  "
                  dense
                  placeholder="-"
                  outlined
                  :persistent-placeholder="true"
                  color="#704ad1"
                ></v-text-field>
                <v-text-field
                  v-model="info.rev_fiscal"
                  :label="
                    $i18n.locale === 'fr' ? 'Revenu Fiscal' : 'Income Tax'
                  "
                  :field="
                    $i18n.locale === 'fr' ? 'Revenu Fiscal' : 'Income Tax'
                  "
                  dense
                  placeholder="-"
                  outlined
                  :persistent-placeholder="true"
                  color="#704ad1"
                ></v-text-field>
                <v-text-field
                  v-model="info.num_fiscal"
                  :label="
                    $i18n.locale === 'fr' ? 'Numéro Fiscal' : 'Tax number'
                  "
                  :field="
                    $i18n.locale === 'fr' ? 'Numéro Fiscal' : 'Tax number'
                  "
                  dense
                  placeholder="-"
                  outlined
                  :persistent-placeholder="true"
                  color="#704ad1"
                ></v-text-field>
                <v-text-field
                  v-model="info.ref_avis"
                  :label="
                    $i18n.locale === 'fr'
                      ? 'Reference Avis'
                      : 'Reference Notice'
                  "
                  :field="
                    $i18n.locale === 'fr'
                      ? 'Reference Avis'
                      : 'Reference Notice'
                  "
                  dense
                  placeholder="-"
                  outlined
                  :persistent-placeholder="true"
                  color="#704ad1"
                ></v-text-field>
                <v-text-field
                  v-model="info.coup_pouce"
                  :label="
                    $i18n.locale === 'fr'
                      ? 'Classification coup de pouce'
                      : 'Coup de pouce classification'
                  "
                  :field="
                    $i18n.locale === 'fr'
                      ? 'Classification coup de pouce'
                      : 'Coup de pouce classification'
                  "
                  dense
                  placeholder="-"
                  outlined
                  :persistent-placeholder="true"
                ></v-text-field>
                <v-text-field
                  v-model="info.mpr"
                  :label="
                    $i18n.locale === 'fr'
                      ? 'Classification MPR'
                      : 'Classification TPM'
                  "
                  :field="
                    $i18n.locale === 'fr'
                      ? 'Classification MPR'
                      : 'Classification TPM'
                  "
                  dense
                  placeholder="-"
                  outlined
                  :persistent-placeholder="true"
                ></v-text-field>
                <v-col cols="12">
                  <v-file-input
                    v-model="info.file1"
                    dense
                    accept="image/*,application/pdf"
                    prepend-icon="mdi-plus"
                    :label="
                      $i18n.locale === 'fr'
                        ? `Ajouter photo avis d'imposition ${i + 1} (page 1)`
                        : `Add photo tax notice ${i + 1} (page 1)`
                    "
                    underlined
                    clearable
                    color="#704ad1"
                  ></v-file-input>
                </v-col>
                <v-col cols="12">
                  <v-file-input
                    v-model="info.file2"
                    dense
                    accept="image/*,application/pdf"
                    prepend-icon="mdi-plus"
                    :label="
                      $i18n.locale === 'fr'
                        ? `Ajouter photo avis d'imposition ${i + 1} (page 2)`
                        : `Add photo tax notice ${i + 1} (page 2)`
                    "
                    underlined
                    clearable
                    color="#704ad1"
                  ></v-file-input>
                </v-col>
                <v-col cols="12">
                  <v-file-input
                    v-model="info.file3"
                    dense
                    accept="image/*,application/pdf"
                    prepend-icon="mdi-plus"
                    :label="
                      $i18n.locale === 'fr'
                        ? `Ajouter photo avis d'imposition ${i + 1} (page 3)`
                        : `Add photo tax notice ${i + 1} (page 3)`
                    "
                    underlined
                    clearable
                    color="#704ad1"
                  ></v-file-input>
                </v-col>
                <v-col cols="12">
                  <v-file-input
                    v-model="info.file4"
                    dense
                    accept="image/*,application/pdf"
                    prepend-icon="mdi-plus"
                    :label="
                      $i18n.locale === 'fr'
                        ? `Ajouter photo avis d'imposition ${i + 1} (page 4)`
                        : `Add photo tax notice ${i + 1} (page 4)`
                    "
                    underlined
                    clearable
                    color="#704ad1"
                  ></v-file-input>
                </v-col>
              </template>
            </Card>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <div class="link" @click="addAvis">
              <font-awesome-icon
                class="icon"
                icon="circle-plus"
                style="color: #704ad1;"
              />
              {{
                $i18n.locale === 'fr'
                  ? "Ajouter un avis d'imposition"
                  : 'Add a tax notice'
              }}
            </div>
          </v-col>
        </v-row>
        <!-- Bouton "Suivant" pour chaque étape -->
        <v-col cols="12" class="bottom">
          <v-btn color="#5C2DD3" outlined @click="prevStep">
            {{ $t('precedant') }}
          </v-btn>
          <v-btn color="#5C2DD3" dark type="submit">
            {{ $t('suivant') }}
          </v-btn>
        </v-col>
      </v-form>
    </template>
  </Card>
</template>

<script>
import Card from '@/components/CrmIla26Component/Projets/Card.vue'

export default {
  components: {
    Card
  },
  data() {
    return {
      form18: {
        informations: [
          {
            nbre_personne: null,
            rev_fiscal: null,
            num_fiscal: null,
            ref_avis: null,
            coup_pouce: null,
            mpr: null,
            file1: null,
            file2: null,
            file3: null,
            file4: null
          }
        ]
      }
    }
  },
  methods: {
    resetComponent() {
      // Réinitialiser les données spécifiques à ce composant (par exemple, formulaire)
      this.form18 = {
        informations: [
          {
            nbre_personne: null,
            rev_fiscal: null,
            num_fiscal: null,
            ref_avis: null,
            coup_pouce: null,
            mpr: null,
            file1: null,
            file2: null,
            file3: null,
            file4: null
          }
        ]
      }
    },
    addAvis() {
      this.form18.informations.push({
        nbre_personne: null,
        rev_fiscal: null,
        num_fiscal: null,
        ref_avis: null,
        coup_pouce: null,
        mpr: null,
        file1: null,
        file2: null,
        file3: null,
        file4: null
      })
    },
    removeAvis(i) {
      this.form18.informations.splice(i, 1)
    },
    submitForm() {
      // Utilisez $refs pour accéder au formulaire
      const valid = this.$refs.myForm.validate()
      if (!valid) {
        return
      }

      this.$emit('nextStep', this.form18)
    },
    prevStep() {
      this.$emit('prevStep')
    }
  }
}
</script>

<style lang="scss" scoped>
.link {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 4px;
  margin-bottom: 12px;
  .icon {
    cursor: pointer;
    width: 18px;
    height: 18px;
  }
}
.bottom {
  display: flex !important;
  align-items: center;
  justify-content: center !important;
  gap: 16px;
}
</style>
