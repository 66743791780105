<template>
  <Card>
    <template #title>
      <div class="title">
        {{
          $i18n.locale === 'fr'
            ? 'Information Equipement - ECS & Ventilation'
            : 'Equipment Information - DHW & Ventilation'
        }}
      </div>
    </template>
    <template #body>
      <v-form @submit.prevent="submitForm" ref="myForm">
        <v-row>
          <v-col cols="6">
            <Card>
              <template #title>
                {{
                  $i18n.locale === 'fr'
                    ? 'Eau Chaude Sanitaire'
                    : 'Domestic hot water'
                }}
              </template>
              <template #body>
                <v-row>
                  <v-col cols="12">
                    <v-select
                      v-model="form16.ecs.type"
                      label="Type"
                      dense
                      clearable
                      :placeholder="$t('choose')"
                      item-text="label"
                      item-value="value"
                      :items="Types"
                      outlined
                      :persistent-placeholder="true"
                      required
                      color="#704ad1"
                      item-color="#704ad1"
                      hide-details
                    ></v-select>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="form16.ecs.volume"
                      label="Volume (L)"
                      placeholder="Volume (L)"
                      :persistent-placeholder="true"
                      type="number"
                      :min="0"
                      dense
                      outlined
                      hide-details
                      color="#704ad1"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-select
                      v-model="form16.ecs.installation"
                      label="Installation"
                      dense
                      clearable
                      :placeholder="$t('choose')"
                      item-text="label"
                      item-value="value"
                      :items="Installations"
                      outlined
                      :persistent-placeholder="true"
                      required
                      color="#704ad1"
                      item-color="#704ad1"
                      hide-details
                    ></v-select>
                  </v-col>
                </v-row>
              </template>
            </Card>
          </v-col>
          <v-col cols="6">
            <Card>
              <template #title>
                <div class="title">
                  Ventilation
                </div></template
              >
              <template #body>
                <v-row>
                  <v-col cols="12">
                    <v-select
                      v-model="form16.ventillation.type"
                      label="Type"
                      dense
                      clearable
                      :placeholder="$t('choose')"
                      item-text="label"
                      item-value="id"
                      :items="Ventilations"
                      outlined
                      :persistent-placeholder="true"
                      required
                      color="#704ad1"
                      item-color="#704ad1"
                      hide-details
                    ></v-select>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="form16.ventillation.nbr_bouche"
                      :label="
                        $i18n.locale === 'fr'
                          ? 'Nombre de bouche'
                          : 'Number of vents'
                      "
                      :placeholder="
                        $i18n.locale === 'fr'
                          ? 'Nombre de bouche'
                          : 'Number of vents'
                      "
                      :persistent-placeholder="true"
                      type="number"
                      :min="0"
                      dense
                      outlined
                      hide-details
                      color="#704ad1"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </template>
            </Card>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-file-input
              v-model="form16.file_ecs"
              dense
              accept="image/*"
              prepend-icon="mdi-plus"
              :label="
                $i18n.locale === 'fr'
                  ? 'Ajouter photo du systeme d\'ECS'
                  : 'Add photo of the DHW system'
              "
              underlined
              clearable
              color="#704ad1"
            ></v-file-input>
          </v-col>
          <v-col cols="6">
            <v-file-input
              v-model="form16.file_ventillation"
              dense
              accept="image/*"
              prepend-icon="mdi-plus"
              :label="
                $i18n.locale === 'fr'
                  ? 'Ajouter photo du systeme systeme de ventilation'
                  : 'Add photo of system ventilation system'
              "
              underlined
              clearable
              color="#704ad1"
            ></v-file-input>
          </v-col>
        </v-row>
        <!-- Bouton "Suivant" pour chaque étape -->
        <v-col cols="12" class="bottom">
          <v-btn color="#5C2DD3" outlined @click="prevStep">
            {{ $t('precedant') }}
          </v-btn>
          <v-btn color="#5C2DD3" dark type="submit">
            {{ $t('suivant') }}
          </v-btn>
        </v-col>
      </v-form>
    </template>
  </Card>
</template>

<script>
import Card from '@/components/CrmIla26Component/Projets/Card.vue'

export default {
  components: {
    Card
  },
  data() {
    return {
      form16: {
        ecs: {
          type: null,
          volume: null,
          installation: null
        },
        ventillation: {
          type: null,
          nbr_bouche: null
        },
        file_ecs: null,
        file_ventillation: null
      },
      Types: [
        {
          label: this.$i18n.locale === 'fr' ? 'Chaudiere' : 'Boiler',
          value: 'Chaudiere'
        },
        {
          label:
            this.$i18n.locale === 'fr'
              ? 'Ballon electrique'
              : 'Electric balloon',
          value: 'Ballon electrique'
        },
        {
          label:
            this.$i18n.locale === 'fr' ? 'Ballon thermo.' : 'Thermo balloon.',
          value: 'Ballon thermo.'
        },
        {
          label:
            this.$i18n.locale === 'fr' ? 'Ballon solaire' : 'Solar balloon',
          value: 'Ballon solaire'
        }
      ],
      Installations: [
        {
          label:
            this.$i18n.locale === 'fr' ? 'Volume chauffé' : 'Heated volume',
          value: 'Volume chauffé'
        },
        {
          label:
            this.$i18n.locale === 'fr'
              ? 'Volume non-chauffé'
              : 'Unheated volume',
          value: 'Volume non-chauffé'
        }
      ],
      Ventilations: [
        {
          label: this.$i18n.locale === 'fr' ? 'Naturelle' : 'Natural',
          value: 'Naturelle'
        },
        {
          label: this.$i18n.locale === 'fr' ? 'Grilles hautes' : 'High grilles',
          value: 'Grilles hautes'
        },
        {
          label: 'VMC SF auto',
          value: 'VMC SF auto'
        },
        {
          label: 'VMC Hygro',
          value: 'VMC Hygro'
        },
        {
          label: 'VMC Double',
          value: 'VMC Double'
        }
      ]
    }
  },
  methods: {
    resetComponent() {
      // Réinitialiser les données spécifiques à ce composant (par exemple, formulaire)
      this.form16 = {
        ecs: {
          type: null,
          volume: null,
          installation: null
        },
        ventillation: {
          type: null,
          nbr_bouche: null
        },
        file_ecs: null,
        file_ventillation: null
      }
    },
    submitForm() {
      // Utilisez $refs pour accéder au formulaire
      const valid = this.$refs.myForm.validate()
      if (!valid) {
        return
      }

      this.$emit('nextStep', this.form16)
    },
    prevStep() {
      this.$emit('prevStep')
    }
  }
}
</script>

<style lang="css" scoped>
.bottom {
  display: flex !important;
  align-items: center;
  justify-content: center !important;
  gap: 16px;
}
</style>
