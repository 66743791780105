<template>
  <ProjectComment
    v-if="entity.object == 'Comment'"
    :entity="entity"
    :project="project"
    :readonly="readonly"
  />
  <ProjectActivity
    v-else-if="entity.object == 'Activity'"
    :entity="entity"
    :project="project"
    :readonly="readonly"
    :page="1"
  />
  <ProjectFile
    v-else-if="entity.object == 'Fichier'"
    :entity="entity"
    :project="project"
    :readonly="readonly"
  />
  <ProjectHistory v-else :entity="entity" />
</template>

<script>
import ProjectComment from './ProjectComment'
import ProjectActivity from './ProjectActivity'
import ProjectFile from './ProjectFile'
import ProjectHistory from './ProjectHistory'
export default {
  components: {
    ProjectComment,
    ProjectActivity,
    ProjectFile,
    ProjectHistory
  },
  props: {
    entity: { required: true },
    project: { required: true },
    readonly: { default: false }
  }
}
</script>

<style></style>
