<template>
  <div>
    <v-tabs
      class="files-tabs"
      color="#5C2DD3"
      item-color="#5C2DD3"
      :hide-slider="true"
      v-model="tabIndex"
    >
      <v-tab
        v-for="categorie in computedCategoriesType"
        :key="categorie"
        color="#5C2DD3"
        item-color="#5C2DD3"
      >
        <span
          v-if="categorie != 'Voir plus...'"
          class="mr-2 font-text font-sz-11"
        >
          {{ categorie }}</span
        >
        <!-- <div v-if="categorie == 'Voir plus...'"  class="mr-2 font-text font-sz-11" @click="addCategorie">
          Voir plus...
        </div> -->
        <span v-if="categorie == 'Tous'" class="counter">
          {{ project.files.length }}
        </span>
        <span class="counter" v-if="categorie == 'CNI du bénéficiaire'">
          {{ getCniBeneficiaire.length }}
        </span>
        <span class="counter" v-if="categorie == 'Avis d’imposition'">
          {{ getAvisImposition.length }}
        </span>
        <span class="counter" v-if="categorie == 'Taxe foncière'">
          {{ getTaxeFonciere.length }}
        </span>
        <span class="counter" v-if="categorie == 'Devis signé'">
          {{ getDevisSigne.length }}
        </span>
        <span
          class="counter"
          v-if="categorie == 'Dernière facture de chauffage'"
        >
          {{ getDerniereFactureChauffage.length }}
        </span>
        <span
          class="counter"
          v-if="categorie == 'Dernière facture d’électricité'"
        >
          {{ getDerniereFactureElectricite.length }}
        </span>
        <span
          class="counter"
          v-if="categorie == 'Photos de la maison prise de loin'"
        >
          {{ getPhotosMaison.length }}
        </span>
        <span
          class="counter"
          v-if="
            categorie ==
              'Photos du système de chauffage existant radiateurs inclus'
          "
        >
          {{ getPhotosSystemeChauffage.length }}
        </span>
        <span
          class="counter"
          v-if="categorie == 'Photo façade nord (portes et fenêtres visibles)'"
        >
          {{ getFaçadeNord.length }}
        </span>
        <span
          class="counter"
          v-if="categorie == 'Photo façade sud (portes et fenêtres visibles)'"
        >
          {{ getFaçadeSub.length }}
        </span>
        <span
          class="counter"
          v-if="categorie == 'Photo façade ouest (portes et fenêtres visibles)'"
        >
          {{ getFaçadeOuest.length }}
        </span>
        <span
          class="counter"
          v-if="categorie == 'Photo façade est (portes et fenêtres visibles)'"
        >
          {{ getFaçadeEst.length }}
        </span>
        <span class="counter" v-if="categorie == 'Photo de la toiture'">
          {{ getPhotoTiture.length }}
        </span>
        <span class="counter" v-if="categorie == 'Divers'">
          {{ getDivers.length }}
        </span>
        <span class="counter" v-if="categorie == 'Titre de pension'">
          {{ getTitrePension.length }}
        </span>
        <span class="counter" v-if="categorie == 'Contrat AMO'">
          {{ getContartAmo.length }}
        </span>
        <span class="counter" v-if="categorie == 'Attestation de TVA'">
          {{ getAttestation.length }}
        </span>
        <span
          class="counter"
          v-if="categorie == 'Photo de la salle de bain (prise de loin)'"
        >
          {{ getPhotoSalleDeBain.length }}
        </span>
        <span
          class="counter"
          v-if="categorie == 'Photo de l’emplacement douche (prise de proche)'"
        >
          {{ getPhotoEmplacementDouche.length }}
        </span>
        <span class="counter" v-if="categorie == 'Rapport d’audit'">
          {{ getRapportAudit.length }}
        </span>
      </v-tab>

      <!-- TOUS -->
      <v-tab-item
        class="content-items-tab padding-tab-file-wa-file mt-3"
        v-for="categorie in computedCategoriesType"
        :key="categorie"
      >
        <span v-if="categorie == 'Tous'">
          <ProjectFile
            v-for="file in project.files"
            :key="'project-file-1-' + file.id"
            :entity="file"
            :project="project"
            :preview="true"
            :readonly="readonly"
        /></span>
        <span v-if="categorie == 'CNI du bénéficiaire'">
          <ProjectFile
            v-for="file in getCniBeneficiaire"
            :key="'project-file-2-' + file.id"
            :entity="file"
            :project="project"
            :preview="true"
            :readonly="readonly"
          />
        </span>
        <span v-if="categorie == 'Avis d’imposition'">
          <ProjectFile
            v-for="file in getAvisImposition"
            :key="'project-file-3-' + file.id"
            :entity="file"
            :project="project"
            :preview="true"
            :readonly="readonly"
          />
        </span>
        <span v-if="categorie == 'Taxe foncière'">
          <ProjectFile
            v-for="file in getTaxeFonciere"
            :key="'project-file-4-' + file.id"
            :entity="file"
            :project="project"
            :preview="true"
            :readonly="readonly"
          />
        </span>
        <span v-if="categorie == 'Devis signé'">
          <ProjectFile
            v-for="file in getDevisSigne"
            :key="'project-file-5-' + file.id"
            :entity="file"
            :project="project"
            :preview="true"
            :readonly="readonly"
          />
        </span>
        <span v-if="categorie == 'Attestation de TVA'">
          <ProjectFile
            v-for="file in getAttestation"
            :key="'project-file-6-' + file.id"
            :entity="file"
            :project="project"
            :preview="true"
            :readonly="readonly"
          />
        </span>
        <span v-if="categorie == 'Dernière facture de chauffage'">
          <ProjectFile
            v-for="file in getDerniereFactureChauffage"
            :key="'project-file-7-' + file.id"
            :entity="file"
            :project="project"
            :preview="true"
            :readonly="readonly"
          />
        </span>
        <span v-if="categorie == 'Dernière facture d’électricité'">
          <ProjectFile
            v-for="file in getDerniereFactureElectricite"
            :key="'project-file-8-' + file.id"
            :entity="file"
            :project="project"
            :preview="true"
            :readonly="readonly"
          />
        </span>
        <span v-if="categorie == 'Photos de la maison prise de loin'">
          <ProjectFile
            v-for="file in getPhotosMaison"
            :key="'project-file-9-' + file.id"
            :entity="file"
            :project="project"
            :preview="true"
            :readonly="readonly"
          />
        </span>
        <span
          v-if="
            categorie ==
              'Photos du système de chauffage existant radiateurs inclus'
          "
        >
          <ProjectFile
            v-for="file in getPhotosSystemeChauffage"
            :key="'project-file-10-' + file.id"
            :entity="file"
            :project="project"
            :preview="true"
            :readonly="readonly"
          />
        </span>
        <span
          v-if="categorie == 'Photo façade nord (portes et fenêtres visibles)'"
        >
          <ProjectFile
            v-for="file in getFaçadeNord"
            :key="'project-file-11-' + file.id"
            :entity="file"
            :project="project"
            :preview="true"
            :readonly="readonly"
          />
        </span>
        <span
          v-if="categorie == 'Photo façade sud (portes et fenêtres visibles)'"
        >
          <ProjectFile
            v-for="file in getFaçadeSub"
            :key="'project-file-12-' + file.id"
            :entity="file"
            :project="project"
            :preview="true"
            :readonly="readonly"
          />
        </span>
        <span
          v-if="categorie == 'Photo façade ouest (portes et fenêtres visibles)'"
        >
          <ProjectFile
            v-for="file in getFaçadeOuest"
            :key="'project-file-13-' + file.id"
            :entity="file"
            :project="project"
            :preview="true"
            :readonly="readonly"
          />
        </span>
        <span
          v-if="categorie == 'Photo façade est (portes et fenêtres visibles)'"
        >
          <ProjectFile
            v-for="file in getFaçadeEst"
            :key="'project-file-14-' + file.id"
            :entity="file"
            :project="project"
            :preview="true"
            :readonly="readonly"
          />
        </span>
        <span v-if="categorie == 'Photo de la toiture'">
          <ProjectFile
            v-for="file in getPhotoTiture"
            :key="'project-file-15-' + file.id"
            :entity="file"
            :project="project"
            :preview="true"
            :readonly="readonly"
          />
        </span>
        <span v-if="categorie == 'Divers'">
          <ProjectFile
            v-for="file in getDivers"
            :key="'project-file-16-' + file.id"
            :entity="file"
            :project="project"
            :preview="true"
            :readonly="readonly"
          />
        </span>
        <span v-if="categorie == 'Titre de pension'">
          <ProjectFile
            v-for="file in getTitrePension"
            :key="'project-file-17-' + file.id"
            :entity="file"
            :project="project"
            :preview="true"
            :readonly="readonly"
          />
        </span>
        <span v-if="categorie == 'Contrat AMO'">
          <ProjectFile
            v-for="file in getContartAmo"
            :key="'project-file-18-' + file.id"
            :entity="file"
            :project="project"
            :preview="true"
            :readonly="readonly"
          />
        </span>
        <span v-if="categorie == 'Photo de la salle de bain (prise de loin)'">
          <ProjectFile
            v-for="file in getPhotoSalleDeBain"
            :key="'project-file-19-' + file.id"
            :entity="file"
            :project="project"
            :preview="true"
            :readonly="readonly"
          />
        </span>
        <span
          v-if="categorie == 'Photo de l’emplacement douche (prise de proche)'"
        >
          <ProjectFile
            v-for="file in getPhotoEmplacementDouche"
            :key="'project-file-20-' + file.id"
            :entity="file"
            :project="project"
            :preview="true"
            :readonly="readonly"
          />
        </span>
        <span v-if="categorie == 'Rapport d’audit'">
          <ProjectFile
            v-for="file in getRapportAudit"
            :key="'project-file-21-' + file.id"
            :entity="file"
            :project="project"
            :preview="true"
            :readonly="readonly"
          />
        </span>
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<script>
import ProjectFile from './ProjectFile'
export default {
  name: 'Files',
  props: {
    project: {
      required: true
    },
    categories: {
      required: true
    },
    readonly: {
      default: false
    }
  },
  components: {
    ProjectFile
  },
  data() {
    return {
      tabIndex: null,
      //   categoriesType: ['Tous', ...this.categories, 'Voir plus...'],
      categoriesType: ['Tous', ...this.categories],
      voirPlus: false
    }
  },
  methods: {
    addCategorie() {
      this.voirPlus = true
    },
    linkClass(catg) {
      if (catg != 'Voir plus...') {
        return ['files-tab-title']
      } else {
        return ['files-tab-title-categ']
      }
    }
  },
  computed: {
    computedCategoriesType: function() {
      return this.categoriesType.filter(catg => {
        // if (!this.voirPlus) {
        //   if (key < 5) {
        //     return catg
        //   }
        //   if (key == this.categoriesType.length - 1) {
        return catg
        //   }
        // } else {
        //   return catg != 'Voir plus...'
        // }
      })
    },
    getCniBeneficiaire: function() {
      return this.project.files.filter(
        item => item.categorie == 'CNI du bénéficiaire'
      )
    },
    getAvisImposition: function() {
      return this.project.files.filter(
        item => item.categorie == 'Avis d’imposition'
      )
    },
    getTaxeFonciere: function() {
      return this.project.files.filter(
        item => item.categorie == 'Taxe foncière'
      )
    },
    getDevisSigne: function() {
      return this.project.files.filter(item => item.categorie == 'Devis signé')
    },
    getAttestation: function() {
      return this.project.files.filter(
        item => item.categorie == 'Attestation de TVA'
      )
    },
    getDerniereFactureChauffage: function() {
      return this.project.files.filter(
        item => item.categorie == 'Dernière facture de chauffage'
      )
    },
    getDerniereFactureElectricite: function() {
      return this.project.files.filter(
        item => item.categorie == 'Dernière facture d’électricité'
      )
    },
    getPhotosMaison: function() {
      return this.project.files.filter(
        item => item.categorie == 'Photos de la maison prise de loin'
      )
    },
    getPhotosSystemeChauffage: function() {
      return this.project.files.filter(
        item =>
          item.categorie ==
          'Photos du système de chauffage existant radiateurs inclus'
      )
    },
    getFaçadeNord: function() {
      return this.project.files.filter(
        item =>
          item.categorie == 'Photo façade nord (portes et fenêtres visibles)'
      )
    },
    getFaçadeSub: function() {
      return this.project.files.filter(
        item =>
          item.categorie == 'Photo façade sud (portes et fenêtres visibles)'
      )
    },
    getFaçadeOuest: function() {
      return this.project.files.filter(
        item =>
          item.categorie == 'Photo façade ouest (portes et fenêtres visibles)'
      )
    },
    getFaçadeEst: function() {
      return this.project.files.filter(
        item =>
          item.categorie == 'Photo façade est (portes et fenêtres visibles)'
      )
    },
    getPhotoTiture: function() {
      return this.project.files.filter(
        item => item.categorie == 'Photo de la toiture'
      )
    },
    getDivers: function() {
      return this.project.files.filter(item => item.categorie == 'Divers')
    },
    getTitrePension: function() {
      return this.project.files.filter(
        item => item.categorie == 'Titre de pension'
      )
    },
    getContartAmo: function() {
      return this.project.files.filter(item => item.categorie == 'Contrat AMO')
    },
    getPhotoSalleDeBain: function() {
      return this.project.files.filter(
        item => item.categorie == 'Photo de la salle de bain (prise de loin)'
      )
    },
    getPhotoEmplacementDouche: function() {
      return this.project.files.filter(
        item =>
          item.categorie == 'Photo de l’emplacement douche (prise de proche)'
      )
    },
    getRapportAudit: function() {
      return this.project.files.filter(
        item => item.categorie == 'Rapport d’audit'
      )
    }
  }
}
</script>

<style lang="scss">
.files-tabs {
  .v-tabs-bar {
    border-radius: inherit;
    height: 38px;
  }
  .v-slide-group__content {
    justify-content: center;

    .v-tab {
      margin: 0px 3px;
      color: #5c2dd3 !important;
      font-size: 13px !important;
      font-weight: 600;
      padding: 10px 10px 10px 10px;
      border: 1px solid #5c2dd3;
      display: flex;
      align-items: center;
      border-radius: 4px;
      .counter {
        text-align: center;
        width: 23px;
        height: 23px;
        padding: 6px;
        border-radius: 4px;
        line-height: 12px;
        background-color: #5c2dd3;
        color: #fff;
      }
    }
    .v-tab--active {
      border: 1px solid #5c2dd3;
      color: #ffffff !important;
      background-color: #5c2dd3;
      .counter {
        width: 23px;
        height: 23px;
        padding: 6px;
        line-height: 12px;
        border-radius: 4px;
        background-color: #fff;
        color: #5c2dd3;
      }
    }
  }
}
.padding-tab-file-wa-file {
  padding: 9px 23px 10px 23px !important;
}
</style>
