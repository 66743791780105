<template>
  <div class="event-projet">
    <div class="header pb-3">
      <div class="message">
        <div
          v-if="getEventLoading && !initLoading && !loading"
          class="loading ml-2"
        >
          {{ $t('loading') }}
        </div>
        <div v-if="hasError" class="error-msg">
          <ul v-if="Array.isArray(errorMessages)">
            <li v-for="(e, index) in errorMessages" :key="index">
              {{ e }}
            </li>
          </ul>
          <span v-else>{{ errorMessages }}</span>
        </div>
      </div>
      <v-btn class="btn-filter" dark color="#5C2DD3" @click="handleAddEvent">
        {{ $i18n.locale === 'fr' ? 'Ajouter événement' : 'Add event' }}
      </v-btn>
    </div>
    <div class="loader-content-event" v-if="initLoading">
      <v-progress-circular
        indeterminate
        class="ml-3"
        color="#5C2DD3"
      ></v-progress-circular>
    </div>
    <div class="content mt-3" v-else>
      <div class="mt-3 mb-2 event-list">
        <v-expansion-panels
          v-if="getEventOfProject && getEventOfProject.length"
        >
          <v-expansion-panel
            v-for="(item, i) in getEventOfProject"
            :key="i"
            class="mb-3"
          >
            <v-expansion-panel-header class="text-capitalize font-text">
              <div class="block-flex-align-space-between">
                <div>
                  <div class="font-sz-14  mt-2">
                    <span class="bold-600 text-capitalize">
                      {{ $t('designation') }} : </span
                    >{{ item.name }}
                  </div>
                  <div class="font-sz-14  mt-2">
                    <span class="bold-600 text-capitalize"> Code : </span
                    >{{ item.code }}
                  </div>
                </div>

                <div class="float-right block-flex-align-space-between">
                  <div class="font-text  font-sz-11  bold-500 flex-center mr-3">
                    <div class="mr-3 color-date">
                      {{ item.start | datetime }}
                    </div>
                    <div class="color-date">{{ item.end | datetime }}</div>
                  </div>
                  <v-btn
                    icon
                    fab
                    small
                    @click.prevent.stop="handleModifierEvent(item)"
                  >
                    <font-awesome-icon class="img-h-16" icon="pencil-alt"
                  /></v-btn>
                  <v-btn
                    icon
                    fab
                    small
                    @click.prevent.stop="handleSupprimerEvent(item)"
                  >
                    <font-awesome-icon class="img-h-16" icon="trash-alt"
                  /></v-btn>
                </div>
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="border-top-solid">
              <div class=" mt-2 ">
                <div v-if="item.detail" class="font-sz-12 font-text mt-2">
                  <div
                    class="text-capitalize font-text bold-700 font-sz-12 mb-2"
                  >
                    Description :
                  </div>
                  {{ item.detail }}
                </div>

                <div v-else class="div-aucun">
                  <div class="titre">
                    {{
                      $i18n.locale === 'fr'
                        ? 'Aucune description'
                        : 'No description'
                    }}
                  </div>
                </div>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <div v-else class="div-aucun">
          <div class="titre">
            {{ $i18n.locale === 'fr' ? 'Aucun événement' : 'No event' }}
          </div>
        </div>
      </div>
      <div class="footer-style-table mt-2 mb-3">
        <div class="text-center pagination-table">
          <v-pagination
            v-model="page"
            :length="totalPages"
            @input="pagination"
            color="#5C2DD3"
            item-color="#5C2DD3"
          ></v-pagination>
        </div>
        <div class="select-input-vuetify">
          <v-select
            class="per-page-element-style"
            v-model="per_page"
            :items="perPageList"
            @change="changePerPage"
            :label="
              $i18n.locale === 'fr' ? 'Eléments par page' : 'Rows per page'
            "
            outlined
            dense
            hide-details
            :no-data-text="
              $i18n.locale === 'fr' ? 'Aucun élément trouvé' : 'No items found'
            "
            :menu-props="{
              left: true,
              offsetX: true
            }"
            color="#5C2DD3"
            item-color="#5C2DD3"
          ></v-select>
        </div>
      </div>
    </div>
    <!-- ADD EVENT -->
    <v-dialog
      v-model="openModalAddEvent"
      max-width="1000"
      persistent
      scrollable
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">
            {{ $i18n.locale === 'fr' ? 'Ajouter événement' : 'Add event' }}
          </v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click="closeDialog('openModalAddEvent')"
            :title="$t('close')"
            color="#5C2DD3"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>

        <v-card-text class="body-card center-text  mt-2">
          <v-form ref="openModalAddEvent" class="mt-2">
            <!-- NOM EVENT -->
            <v-row class="row">
              <v-col>
                <v-text-field
                  :label="$t('name')"
                  dense
                  :persistent-placeholder="true"
                  outlined
                  color="#5C2DD3"
                  v-model="eventToAdd.name"
                  item-color="#5C2DD3"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <!-- DESCRIPTION -->
            <v-row class="row">
              <v-col>
                <v-textarea
                  dense
                  label="Description"
                  v-model="eventToAdd.detail"
                  :persistent-placeholder="true"
                  rows="3"
                  outlined
                  color="#5C2DD3"
                ></v-textarea>
              </v-col>
            </v-row>
            <!-- DATE DEBUT / DATE FIN -->
            <v-row class="mb-2">
              <v-col>
                <vue-ctk-date-time-picker
                  v-model="eventToAdd.start"
                  color="#45148F"
                  :inline="false"
                  :buttonNowTranslation="'Today'"
                  inputSize="sm"
                  buttonColor="#45148F"
                  :locale="$i18n.locale"
                  label="Start Date"
                  class="custom-ctk-component"
                  format="YYYY-MM-DD HH:mm"
                  :no-value-to-custom-elem="true"
                  @input="checkRulesDate(['dateStart', 'dateEnd'])"
                >
                  <v-text-field
                    v-model="eventToAdd.start"
                    :label="$t('date_debut')"
                    outlined
                    readonly
                    dense
                    ref="dateStart"
                    v-on="on"
                    v-bind="attrs"
                    :persistent-placeholder="true"
                    :placeholder="$t('msgSelectDate')"
                    prepend-inner-icon="mdi-calendar"
                    color="#5C2DD3"
                    required
                    validate-on-blur
                    :rules="[
                      v => !!v || $t('date_debut') + ' ' + $t('msgOblg')
                    ]"
                  ></v-text-field>
                </vue-ctk-date-time-picker>
                <!-- <v-menu
                  :close-on-content-click="false"
                  offset-y
                  content-class="input-date-dynamique custom-menu-position"
                  @input="handleOpenDateDebut"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="eventToAdd.start"
                      :value="eventToAdd.start"
                      :label="$t('date_debut')"
                      outlined
                      readonly
                      dense
                      ref="dateStart"
                      v-on="on"
                      v-bind="attrs"
                      :persistent-placeholder="true"
                      :placeholder="$t('msgSelectDate')"
                      prepend-inner-icon="mdi-calendar"
                      color="#5C2DD3"
                      required
                      validate-on-blur
                      :rules="[
                        v => !!v || $t('date_debut') + ' ' + $t('msgOblg')
                      ]"
                    ></v-text-field>
                  </template>
                  <v-card>
                    <v-card-text>
                      <v-date-picker
                        value-type="format"
                        format="YYYY-MM-DD HH:mm"
                        :value="selectedDateTimeDateStart"
                        v-model="selectedDateTimeDateStart"
                        no-title
                        locale="fr"
                        color="#5C2DD3"
                        item-color="#5C2DD3"
                        @input="
                          handleDatePicker(
                            selectedDateTimeDateStart,
                            'dateStart'
                          )
                        "
                      ></v-date-picker>
                      <v-time-picker
                        class="ml-3"
                        v-model="selectedDateTimeTimeStart"
                        :value="selectedDateTimeTimeStart"
                        color="#5C2DD3"
                        item-color="#5C2DD3"
                        format="24hr"
                        @input="
                          handleDatePicker(
                            selectedDateTimeTimeStart,
                            'dateStart'
                          )
                        "
                      ></v-time-picker>
                    </v-card-text>
                  </v-card>
                </v-menu> -->
              </v-col>
              <v-col>
                <vue-ctk-date-time-picker
                  v-model="eventToAdd.end"
                  color="#45148F"
                  :inline="false"
                  :buttonNowTranslation="'Today'"
                  inputSize="sm"
                  buttonColor="#45148F"
                  :locale="$i18n.locale"
                  label="Start Date"
                  class="custom-ctk-component"
                  format="YYYY-MM-DD HH:mm"
                  :no-value-to-custom-elem="true"
                  @input="checkRulesDate('dateEnd')"
                >
                  <v-text-field
                    v-model="eventToAdd.end"
                    :label="$t('date_fin')"
                    outlined
                    readonly
                    ref="dateEnd"
                    dense
                    v-on="on"
                    v-bind="attrs"
                    :persistent-placeholder="true"
                    :placeholder="$t('msgSelectDate')"
                    prepend-inner-icon="mdi-calendar"
                    color="#5C2DD3"
                    required
                    validate-on-blur
                    :rules="[v => !!v || $t('date_fin') + ' ' + $t('msgOblg')]"
                  ></v-text-field>
                </vue-ctk-date-time-picker>
                <!-- <v-menu
                  :close-on-content-click="false"
                  offset-y
                  content-class="input-date-dynamique custom-menu-position"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="eventToAdd.end"
                      :value="eventToAdd.end"
                      :label="$t('date_fin')"
                      outlined
                      readonly
                      ref="dateEnd"
                      dense
                      v-on="on"
                      v-bind="attrs"
                      :persistent-placeholder="true"
                      :placeholder="$t('msgSelectDate')"
                      prepend-inner-icon="mdi-calendar"
                      color="#5C2DD3"
                      required
                      validate-on-blur
                      :rules="[
                        v => !!v || $t('date_fin') + ' ' + $t('msgOblg')
                      ]"
                    ></v-text-field>
                  </template>
                  <v-card>
                    <v-card-text>
                      <v-date-picker
                        value-type="format"
                        format="YYYY-MM-DD HH:mm"
                        :value="selectedDateTimeDateEnd"
                        v-model="selectedDateTimeDateEnd"
                        no-title
                        locale="fr"
                        color="#5C2DD3"
                        item-color="#5C2DD3"
                        @input="
                          handleDatePicker(selectedDateTimeDateEnd, 'dateEnd')
                        "
                      ></v-date-picker>
                      <v-time-picker
                        class="ml-3"
                        v-model="selectedDateTimeTimeEnd"
                        :value="selectedDateTimeTimeEnd"
                        color="#5C2DD3"
                        item-color="#5C2DD3"
                        format="24hr"
                        @input="
                          handleDatePicker(selectedDateTimeTimeEnd, 'dateEnd')
                        "
                      ></v-time-picker>
                    </v-card-text>
                  </v-card>
                </v-menu> -->
              </v-col>
            </v-row>
            <!-- STATUS EVENT -->
            <v-row>
              <v-col>
                <v-autocomplete
                  :clearable="true"
                  color="#5C2DD3"
                  :placeholder="$t('event_status')"
                  item-color="#5C2DD3"
                  v-model="eventToAdd.status"
                  :items="getEventStatus"
                  :persistent-placeholder="true"
                  :label="$t('event_status')"
                  item-text="text"
                  dense
                  item-value="value"
                  outlined
                  :menu-props="{
                    bottom: true,
                    offsetY: true
                  }"
                  required
                  validate-on-blur
                  :rules="[
                    v => !!v || $t('event_status') + ' ' + $t('msgOblg')
                  ]"
                >
                </v-autocomplete>
              </v-col>
            </v-row>
            <!-- ASSIGN USER TO EVENT -->
            <v-row>
              <v-col>
                <v-autocomplete
                  :clearable="true"
                  color="#5C2DD3"
                  :placeholder="$t('assigned_to')"
                  item-color="#5C2DD3"
                  v-model="eventToAdd.assigned_user_id"
                  :items="allUsers"
                  :persistent-placeholder="true"
                  :label="$t('assigned_to')"
                  item-text="full_name"
                  dense
                  item-value="id"
                  outlined
                  :menu-props="{
                    bottom: true,
                    offsetY: true
                  }"
                  required
                  validate-on-blur
                  :rules="[v => !!v || $t('assigned_to') + ' ' + $t('msgOblg')]"
                >
                </v-autocomplete>
              </v-col>
            </v-row>
            <!-- TYPE EVENT -->
            <v-row>
              <v-col>
                <v-autocomplete
                  :clearable="true"
                  color="#5C2DD3"
                  @input="changeFieldsTypeEvent"
                  :loading="getTypeEventLoading"
                  :placeholder="
                    $t('searchMsg', {
                      msg:
                        $i18n.locale === 'fr' ? 'type événement' : 'Event type'
                    })
                  "
                  item-color="#5C2DD3"
                  v-model="eventToAdd.type"
                  :items="getTypeEvent"
                  :persistent-placeholder="true"
                  :label="
                    $i18n.locale === 'fr' ? 'Type événement' : 'Event type'
                  "
                  item-text="designation"
                  dense
                  return-object
                  item-value="id"
                  :no-data-text="
                    $t('noDataOptionMsg', {
                      option:
                        $i18n.locale === 'fr' ? 'type événement' : 'Event type'
                    })
                  "
                  outlined
                  :menu-props="{
                    bottom: true,
                    offsetY: true
                  }"
                  required
                  validate-on-blur
                  :rules="[
                    v =>
                      !!v ||
                      ($i18n.locale === 'fr'
                        ? 'Type événement'
                        : 'Event type') +
                        ' ' +
                        $t('msgOblg')
                  ]"
                >
                </v-autocomplete>
              </v-col>
            </v-row>
            <!-- CHAMP DYNAMIQUE  -->
            <div
              v-if="
                eventToAdd.type &&
                  eventToAdd.type.fields &&
                  eventToAdd.type.fields.length
              "
            >
              <v-row
                v-for="field in eventToAdd.type.fields"
                :key="'field-add-' + field.id + `${eventToAdd.type.id}`"
              >
                <v-col
                  :cols="
                    field.type == 'ressource' && field.value
                      ? 10
                      : field.type == 'ressource' && !field.value
                      ? 11
                      : 12
                  "
                >
                  <div class="value">
                    <EditableCustomFieldForm
                      ref="editableDynamicForm"
                      :readonly="false"
                      :field="field"
                      :value="field.value"
                      :typeRessource="eventToAdd.type.fields"
                      @updateFunction="updateFunction"
                      :fieldKeyEdit="false"
                      :required="field.required"
                      :type_projet_id="project.type_id"
                    />
                  </div>
                </v-col>
                <v-col
                  :cols="field.type == 'ressource' && field.value ? 1 : 1"
                  class="mt-1 mb-1 "
                  v-if="field.type == 'ressource'"
                >
                  <ModalResource
                    @openModalResource="openModalResource"
                    @closeModalResource="closeModalResource"
                    :field="field"
                    :project="project"
                    :event="eventToAdd"
                /></v-col>
              </v-row>
            </div>
          </v-form>
        </v-card-text>
        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <div class="message">
            <div v-if="getEventLoading" class="loading">
              {{ $t('loading') }}
            </div>
            <div v-if="getEventError" class="error-msg">
              <ul v-if="Array.isArray(getEventError)">
                <li v-for="(e, index) in getEventError" :key="index">
                  {{ e }}
                </li>
              </ul>
              <span v-else>{{ getEventError }}</span>
            </div>
          </div>

          <v-spacer></v-spacer>
          <v-btn
            color="#5C2DD3"
            dark
            @click.prevent.stop="ajouterEvent"
            :loading="loading"
          >
            {{ $t('btnValider') }}
          </v-btn>
          <v-btn text @click="closeDialog('openModalAddEvent')">{{
            $t('btnCancel')
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- EDIT EVENT -->
    <v-dialog
      v-model="openModalUpdateEvent"
      max-width="1000"
      persistent
      scrollable
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">
            {{ $i18n.locale === 'fr' ? 'Modifier événement' : 'Edit event' }}
          </v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click="closeDialog('openModalUpdateEvent')"
            :title="$t('close')"
            color="#5C2DD3"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>

        <v-card-text class="body-card center-text  mt-2">
          <v-form ref="openModalUpdateEvent" class="mt-2" v-if="eventToUpdate">
            <!-- NOM EVENT -->
            <v-row class="row">
              <v-col>
                <v-text-field
                  :label="$t('name')"
                  dense
                  :persistent-placeholder="true"
                  outlined
                  color="#5C2DD3"
                  v-model="eventToUpdate.name"
                  item-color="#5C2DD3"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <!-- CODE EVENT -->
            <v-row class="row">
              <v-col>
                <v-text-field
                  label="Code"
                  dense
                  :persistent-placeholder="true"
                  outlined
                  color="#5C2DD3"
                  v-model="eventToUpdate.code"
                  item-color="#5C2DD3"
                  disabled
                >
                </v-text-field>
              </v-col>
            </v-row>
            <!-- DESCRIPTION -->
            <v-row class="row">
              <v-col>
                <v-textarea
                  dense
                  label="Description"
                  v-model="eventToUpdate.detail"
                  :persistent-placeholder="true"
                  rows="3"
                  outlined
                  color="#5C2DD3"
                ></v-textarea>
              </v-col>
            </v-row>
            <!-- DATE DEBUT / DATE FIN -->
            <v-row class="mb-2">
              <v-col>
                <vue-ctk-date-time-picker
                  v-model="eventToUpdate.start"
                  color="#45148F"
                  :inline="false"
                  :buttonNowTranslation="'Today'"
                  inputSize="sm"
                  buttonColor="#45148F"
                  :locale="$i18n.locale"
                  label="Start Date"
                  class="custom-ctk-component"
                  format="YYYY-MM-DD HH:mm"
                  :no-value-to-custom-elem="true"
                  @input="checkRulesDate(['dateStartUpdate', 'dateEndUpdate'])"
                >
                  <v-text-field
                    v-model="eventToUpdate.start"
                    :label="$t('date_debut')"
                    outlined
                    readonly
                    dense
                    ref="dateStartUpdate"
                    v-on="on"
                    v-bind="attrs"
                    :persistent-placeholder="true"
                    :placeholder="$t('msgSelectDate')"
                    prepend-inner-icon="mdi-calendar"
                    color="#5C2DD3"
                    required
                    validate-on-blur
                    :rules="[
                      v => !!v || $t('date_debut') + ' ' + $t('msgOblg')
                    ]"
                  ></v-text-field>
                </vue-ctk-date-time-picker>
                <!-- <v-menu
                  :close-on-content-click="false"
                  offset-y
                  content-class="input-date-dynamique custom-menu-position"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="eventToUpdate.start"
                      :value="eventToUpdate.start"
                      :label="$t('date_debut')"
                      outlined
                      readonly
                      dense
                      ref="dateStartUpdate"
                      v-on="on"
                      v-bind="attrs"
                      :persistent-placeholder="true"
                      :placeholder="$t('msgSelectDate')"
                      prepend-inner-icon="mdi-calendar"
                      color="#5C2DD3"
                      required
                      validate-on-blur
                      :rules="[
                        v => !!v || $t('date_debut') + ' ' + $t('msgOblg')
                      ]"
                    ></v-text-field>
                  </template>
                  <v-card>
                    <v-card-text>
                      <v-date-picker
                        value-type="format"
                        format="YYYY-MM-DD HH:mm"
                        :value="selectedDateTimeDateStart"
                        v-model="selectedDateTimeDateStart"
                        no-title
                        locale="fr"
                        color="#5C2DD3"
                        item-color="#5C2DD3"
                        @input="
                          handleDatePicker(
                            selectedDateTimeDateStart,
                            'dateStartUpdate'
                          )
                        "
                      ></v-date-picker>
                      <v-time-picker
                        v-model="selectedDateTimeTimeStart"
                        :value="selectedDateTimeTimeStart"
                        color="#5C2DD3"
                        format="24hr"
                        item-color="#5C2DD3"
                        @input="
                          handleDatePicker(
                            selectedDateTimeTimeStart,
                            'dateStartUpdate'
                          )
                        "
                      ></v-time-picker>
                    </v-card-text>
                  </v-card>
                </v-menu> -->
              </v-col>
              <v-col>
                <vue-ctk-date-time-picker
                  v-model="eventToUpdate.end"
                  color="#45148F"
                  :inline="false"
                  :buttonNowTranslation="'Today'"
                  inputSize="sm"
                  buttonColor="#45148F"
                  :locale="$i18n.locale"
                  label="Start Date"
                  class="custom-ctk-component"
                  format="YYYY-MM-DD HH:mm"
                  :no-value-to-custom-elem="true"
                  @input="checkRulesDate('dateEndUpdate')"
                >
                  <v-text-field
                    v-model="eventToUpdate.end"
                    :label="$t('date_fin')"
                    outlined
                    readonly
                    ref="dateEndUpdate"
                    dense
                    v-on="on"
                    v-bind="attrs"
                    :persistent-placeholder="true"
                    :placeholder="$t('msgSelectDate')"
                    prepend-inner-icon="mdi-calendar"
                    color="#5C2DD3"
                    required
                    validate-on-blur
                    :rules="[v => !!v || $t('date_fin') + ' ' + $t('msgOblg')]"
                  ></v-text-field>
                </vue-ctk-date-time-picker>
                <!-- <v-menu
                  :close-on-content-click="false"
                  offset-y
                  content-class="input-date-dynamique custom-menu-position"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="eventToUpdate.end"
                      :value="eventToUpdate.end"
                      :label="$t('date_fin')"
                      outlined
                      readonly
                      ref="dateEndUpdate"
                      dense
                      v-on="on"
                      v-bind="attrs"
                      :persistent-placeholder="true"
                      :placeholder="$t('msgSelectDate')"
                      prepend-inner-icon="mdi-calendar"
                      color="#5C2DD3"
                      required
                      validate-on-blur
                      :rules="[
                        v => !!v || $t('date_fin') + ' ' + $t('msgOblg')
                      ]"
                    ></v-text-field>
                  </template>
                  <v-card>
                    <v-card-text>
                      <v-date-picker
                        value-type="format"
                        format="YYYY-MM-DD HH:mm"
                        :value="selectedDateTimeDateEnd"
                        v-model="selectedDateTimeDateEnd"
                        no-title
                        locale="fr"
                        color="#5C2DD3"
                        item-color="#5C2DD3"
                        @input="
                          handleDatePicker(
                            selectedDateTimeDateEnd,
                            'dateEndUpdate'
                          )
                        "
                      ></v-date-picker>
                      <v-time-picker
                        v-model="selectedDateTimeTimeEnd"
                        :value="selectedDateTimeTimeEnd"
                        color="#5C2DD3"
                        format="24hr"
                        item-color="#5C2DD3"
                        @input="
                          handleDatePicker(
                            selectedDateTimeTimeEnd,
                            'dateEndUpdate'
                          )
                        "
                      ></v-time-picker>
                    </v-card-text>
                  </v-card>
                </v-menu> -->
              </v-col>
            </v-row>
            <!-- STATUS EVENT -->
            <v-row>
              <v-col>
                <v-autocomplete
                  :clearable="true"
                  color="#5C2DD3"
                  :placeholder="$t('event_status')"
                  item-color="#5C2DD3"
                  v-model="eventToUpdate.status"
                  :items="getEventStatus"
                  :persistent-placeholder="true"
                  :label="$t('event_status')"
                  item-text="text"
                  dense
                  item-value="value"
                  outlined
                  :menu-props="{
                    bottom: true,
                    offsetY: true
                  }"
                  required
                  validate-on-blur
                  :rules="[
                    v => !!v || $t('event_status') + ' ' + $t('msgOblg')
                  ]"
                >
                </v-autocomplete>
              </v-col>
            </v-row>
            <!-- ASSIGN USER TO EVENT -->
            <v-row>
              <v-col>
                <v-autocomplete
                  :clearable="true"
                  color="#5C2DD3"
                  :placeholder="$t('assigned_to')"
                  item-color="#5C2DD3"
                  v-model="eventToUpdate.assigned_user_id"
                  :items="allUsers"
                  :persistent-placeholder="true"
                  :label="$t('assigned_to')"
                  item-text="full_name"
                  dense
                  item-value="id"
                  outlined
                  :menu-props="{
                    bottom: true,
                    offsetY: true
                  }"
                  required
                  validate-on-blur
                  :rules="[v => !!v || $t('assigned_to') + ' ' + $t('msgOblg')]"
                >
                </v-autocomplete>
              </v-col>
            </v-row>
            <!-- TYPE EVENT -->
            <v-row>
              <v-col>
                <v-autocomplete
                  :clearable="true"
                  color="#5C2DD3"
                  disabled
                  :loading="getTypeEventLoading"
                  :placeholder="
                    $t('searchMsg', {
                      msg:
                        $i18n.locale === 'fr' ? 'type événement' : 'Event type'
                    })
                  "
                  item-color="#5C2DD3"
                  v-model="eventToUpdate.type"
                  :items="getTypeEvent"
                  :persistent-placeholder="true"
                  :label="
                    $i18n.locale === 'fr' ? 'Type événement' : 'Event type'
                  "
                  item-text="designation"
                  dense
                  return-object
                  item-value="id"
                  :no-data-text="
                    $t('noDataOptionMsg', {
                      option:
                        $i18n.locale === 'fr' ? 'type événement' : 'Event type'
                    })
                  "
                  outlined
                  :menu-props="{
                    bottom: true,
                    offsetY: true
                  }"
                >
                </v-autocomplete>
              </v-col>
            </v-row>
            <!-- CHAMP DYNAMIQUE  -->
            <div
              v-if="
                eventToUpdate &&
                  eventToUpdate.type &&
                  eventToUpdate.fields &&
                  eventToUpdate.fields.length
              "
            >
              <v-row
                v-for="field in eventToUpdate.fields"
                :key="'field-update-' + field.id + `${eventToUpdate.type.id}`"
              >
                <v-col
                  :cols="
                    field.type == 'ressource' &&
                    (field.value || field.lastValue)
                      ? 10
                      : field.type == 'ressource'
                      ? 11
                      : 12
                  "
                >
                  <div class="value">
                    <EditableCustomFieldForm
                      ref="editableDynamicFormUpdate"
                      :readonly="false"
                      :field="field"
                      :value="field.value"
                      :typeRessource="eventToUpdate.fields"
                      :fieldKeyEdit="false"
                      @updateFunction="updateFunctionUpdate"
                      :required="field.required"
                      :type_projet_id="project.type_id"
                    />
                  </div>
                </v-col>
                <v-col
                  :cols="
                    field.type == 'ressource' &&
                    (field.value || field.lastValue)
                      ? 1
                      : 1
                  "
                  class="mt-1 mb-1 "
                  v-if="field.type == 'ressource'"
                >
                  <ModalResource
                    @openModalResource="openModalResourceUpdate"
                    @closeModalResource="closeModalResourceUpdate"
                    :field="field"
                    :project="project"
                    :event="eventToUpdate"
                /></v-col>
              </v-row>
            </div>
          </v-form>
        </v-card-text>
        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <div class="message">
            <div v-if="getEventLoading" class="loading">
              {{ $t('loading') }}
            </div>
            <div v-if="getEventError" class="error-msg">
              <ul v-if="Array.isArray(getEventError)">
                <li v-for="(e, index) in getEventError" :key="index">
                  {{ e }}
                </li>
              </ul>
              <span v-else>{{ getEventError }}</span>
            </div>
          </div>

          <v-spacer></v-spacer>
          <v-btn
            color="#5C2DD3"
            dark
            @click.prevent.stop="modifierEvent"
            :loading="loading"
          >
            {{ $t('btnValider') }}
          </v-btn>
          <v-btn text @click="closeDialog('openModalUpdateEvent')">{{
            $t('btnCancel')
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- DELETED EVENT -->
    <v-dialog v-model="ModalDeleteEvent" max-width="700" persistent>
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">
            {{ $i18n.locale === 'fr' ? 'Supprimer événement' : 'Delete event' }}
          </v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click="closeDialog('ModalDeleteEvent')"
            :title="$t('close')"
            color="#5C2DD3"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>

        <v-card-text class="body-card center-text  mt-2">
          <p class="color-black-light">
            {{
              $t('msgdeleted', {
                msg: $i18n.locale === 'fr' ? "l'événement :" : 'the event :'
              })
            }}
            <strong class="text-capitalize">
              {{
                eventToDelete && eventToDelete.name ? eventToDelete.name : ''
              }}</strong
            >
            ?
          </p>
        </v-card-text>

        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <div class="message">
            <div v-if="getEventLoading" class="loading">
              {{ $t('loading') }}
            </div>
            <div v-if="getEventError" class="error-msg">
              <ul v-if="Array.isArray(getEventError)">
                <li v-for="(e, index) in getEventError" :key="index">
                  {{ e }}
                </li>
              </ul>
              <span v-else>{{ getEventError }}</span>
            </div>
          </div>

          <v-spacer></v-spacer>
          <v-btn
            color="#5C2DD3"
            dark
            @click.prevent.stop="supprimerEvent"
            :loading="loading"
          >
            {{ $t('btnValider') }}
          </v-btn>
          <v-btn text @click="closeDialog('ModalDeleteEvent')">{{
            $t('btnCancel')
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import * as moment from 'moment'
moment.locale('fr')
export default {
  components: {
    EditableCustomFieldForm: () =>
      import('@/components/CrmIla26Component/EditableCustomFieldForm'),
    ModalResource: () =>
      import('@/components/CrmIla26Component/Projets/ModalResource')
  },
  props: {
    project: { required: true },
    readonly: { required: false }
  },
  data() {
    return {
      initLoading: false,
      openModalAddEvent: false,
      loading: false,
      eventToAdd: {
        name: null,
        detail: null,
        start: null,
        end: null,
        type: null,
        fields: [],
        status: 'En attente',
        assigned_user_id: null
      },
      ModalDeleteEvent: false,
      eventToDelete: null,
      openModalUpdateEvent: false,
      eventToUpdate: null,
      eventToUpdateOriginal: null,
      selectedDateTimeDateStart: null,
      selectedDateTimeTimeStart: null,
      selectedDateTimeDateEnd: null,
      selectedDateTimeTimeEnd: null,
      page: 1,
      per_page: 10,
      perPageList: [
        { value: 10, text: 10 },
        { value: 20, text: 20 },
        { value: 30, text: 30 },
        { value: 50, text: 50 }
      ],
      errorMessages: null
    }
  },
  methods: {
    ...mapActions([
      'fetchALLTypeEventOfProject',
      'addEvent',
      'updateEvent',
      'deletedEvent',
      'resetErrorEvent',
      'fetchAllTypeEvent',
      'getAllUsers'
    ]),
    checkRulesDate(ref) {
      if (Array.isArray(ref)) {
        ref.forEach((item, index) => {
          setTimeout(() => {
            this.$refs[item].focus()
          }, index * 1)
        })
      } else {
        this.$refs[ref].focus()
      }
    },
    openModalResource() {
      this.openModalAddEvent = false
    },
    closeModalResource() {
      this.refreshComponentAddEvent()
      this.handleAddEvent()
    },
    openModalResourceUpdate() {
      this.openModalUpdateEvent = false
    },
    closeModalResourceUpdate() {
      this.openModalUpdateEvent = true
      this.refreshComponentUpdateEvent()
    },
    pagination(pagination) {
      this.page = pagination
      let project_id =
        this.project && this.project.id
          ? this.project.id
          : this.$route.name == 'project' &&
            this.$route.params &&
            this.$route.params.id
          ? this.$route.params.id
          : null
      if (project_id != null) {
        this.fetchALLTypeEventOfProject({
          project_id: project_id,
          page: this.page,
          per_page: this.per_page
        })
      }
    },
    changePerPage() {
      let project_id =
        this.project && this.project.id
          ? this.project.id
          : this.$route.name == 'project' &&
            this.$route.params &&
            this.$route.params.id
          ? this.$route.params.id
          : null
      if (project_id != null) {
        this.fetchALLTypeEventOfProject({
          project_id: project_id,
          page: this.page,
          per_page: this.per_page
        })
      }
    },
    handleOpenDateDebut(event) {
      if (!event) {
        if (!this.selectedDateTimeDateEnd && !this.selectedDateTimeTimeEnd) {
          this.selectedDateTimeDateEnd = this.selectedDateTimeDateStart
          this.selectedDateTimeTimeEnd = this.selectedDateTimeTimeStart
          this.$refs['dateEnd'].resetValidation()
        }
      }
    },
    updateDateTimeStart() {
      // Combine selectedDate and selectedTime into dateTime
      if (this.selectedDateTimeDateStart && this.selectedDateTimeTimeStart) {
        if (this.openModalAddEvent) {
          this.eventToAdd.start = `${this.selectedDateTimeDateStart} ${this.selectedDateTimeTimeStart}`
        } else {
          this.eventToUpdate.start = `${this.selectedDateTimeDateStart} ${this.selectedDateTimeTimeStart}`
        }
      } else if (
        this.selectedDateTimeDateStart &&
        !this.selectedDateTimeTimeStart
      ) {
        if (this.openModalAddEvent) {
          this.eventToAdd.start = `${this.selectedDateTimeDateStart} 00:00`
        } else {
          this.eventToUpdate.start = `${this.selectedDateTimeDateStart} 00:00`
        }
      } else if (
        !this.selectedDateTimeDateStart &&
        this.selectedDateTimeTimeStart
      ) {
        this.selectedDateTimeDateStart = new Date().toJSON().slice(0, 10)

        if (this.openModalAddEvent) {
          this.eventToAdd.start = `${this.selectedDateTimeDateStart} ${this.selectedDateTimeTimeStart}`
        } else {
          this.eventToUpdate.start = `${this.selectedDateTimeDateStart} ${this.selectedDateTimeTimeStart}`
        }
      }
    },
    updateDateTimeEnd() {
      // Combine selectedDate and selectedTime into dateTime
      if (this.selectedDateTimeDateEnd && this.selectedDateTimeTimeEnd) {
        if (this.openModalAddEvent) {
          this.eventToAdd.end = `${this.selectedDateTimeDateEnd} ${this.selectedDateTimeTimeEnd}`
        } else {
          this.eventToUpdate.end = `${this.selectedDateTimeDateEnd} ${this.selectedDateTimeTimeEnd}`
        }
      } else if (
        this.selectedDateTimeDateEnd &&
        !this.selectedDateTimeTimeEnd
      ) {
        if (this.openModalAddEvent) {
          this.eventToAdd.end = `${this.selectedDateTimeDateEnd} 00:00`
        } else {
          this.eventToUpdate.end = `${this.selectedDateTimeDateEnd} 00:00`
        }
      } else if (
        !this.selectedDateTimeDateEnd &&
        this.selectedDateTimeTimeEnd
      ) {
        this.selectedDateTimeDateEnd = new Date().toJSON().slice(0, 10)
        if (this.openModalAddEvent) {
          this.eventToAdd.end = `${this.selectedDateTimeDateEnd} ${this.selectedDateTimeTimeEnd}`
        } else {
          this.eventToUpdate.end = `${this.selectedDateTimeDateEnd} ${this.selectedDateTimeTimeEnd}`
        }
      }
    },
    handleDatePicker(date, ref) {
      if (date) {
        this.$refs[ref].resetValidation()
      }
    },
    closeDialog(ref) {
      this[ref] = false
      if (ref == 'openModalAddEvent') {
        this.$refs.openModalAddEvent.resetValidation()
      }
      if (ref == 'openModalUpdateEvent') {
        this.$refs.openModalUpdateEvent.resetValidation()
        let project_id =
          this.project && this.project.id
            ? this.project.id
            : this.$route.name == 'project' &&
              this.$route.params &&
              this.$route.params.id
            ? this.$route.params.id
            : null
        if (project_id != null) {
          this.fetchALLTypeEventOfProject({
            project_id: project_id,
            page: this.page,
            per_page: this.per_page
          })
          if (this.project && this.project.type_id) {
            this.fetchAllTypeEvent({ type_projet_id: this.project.type_id })
          }
        }
        this.eventToUpdate = null
      }
      this.resetErrorEvent()
      this.resetModal()
    },
    resetModal() {
      this.eventToAdd = {
        name: null,
        detail: null,
        start: null,
        end: null,
        type: null,
        fields: [],
        status: 'En attente',
        assigned_user_id: null
      }
      this.selectedDateTimeDateStart = null
      this.selectedDateTimeTimeStart = null
      this.selectedDateTimeDateEnd = null
      this.selectedDateTimeTimeEnd = null
    },
    updateFunctionUpdate(data) {
      this.eventToUpdate.fieldsUpdate = data
    },

    handleModifierEvent(event) {
      this.eventToUpdate = { ...event }
      this.eventToUpdateOriginal = event
      this.openModalUpdateEvent = true
      this.selectedDateTimeDateStart =
        event && event.start ? event.start.split(' ')[0] : event.start
      this.selectedDateTimeTimeStart =
        event && event.start ? event.start.split(' ')[1] : event.start
      this.selectedDateTimeDateEnd =
        event && event.end ? event.end.split(' ')[0] : event.end
      this.selectedDateTimeTimeEnd =
        event && event.end ? event.end.split(' ')[1] : event.end
    },
    async modifierEvent() {
      if (this.$refs.openModalUpdateEvent.validate()) {
        this.loading = true
        this.eventToUpdate.start = this.eventToUpdate.start
          ? moment(this.eventToUpdate.start, 'YYYY-MM-DD HH:mm').format(
              'YYYY-MM-DD HH:mm:ss'
            )
          : this.eventToUpdate.end
        this.eventToUpdate.end = this.eventToUpdate.end
          ? moment(this.eventToUpdate.end, 'YYYY-MM-DD HH:mm').format(
              'YYYY-MM-DD HH:mm:ss'
            )
          : this.eventToUpdate.end
        const response = await this.updateEvent({
          body: this.eventToUpdate,
          event: this.eventToUpdateOriginal
        })
        if (response) {
          this.closeDialog('openModalUpdateEvent')
        }
        this.loading = false
      }
    },
    handleSupprimerEvent(event) {
      this.ModalDeleteEvent = true
      this.eventToDelete = event
    },
    updateFunction(field) {
      this.eventToAdd.fields = field
    },
    handleAddEvent() {
      this.openModalAddEvent = true
    },
    async ajouterEvent() {
      if (this.$refs.openModalAddEvent.validate()) {
        this.loading = true
        this.eventToAdd.projet_id =
          this.project && this.project.id
            ? this.project.id
            : this.$route.name == 'project' &&
              this.$route.params &&
              this.$route.params.id
            ? this.$route.params.id
            : null
        this.eventToAdd.start = this.eventToAdd.start
          ? moment(this.eventToAdd.start, 'YYYY-MM-DD HH:mm').format(
              'YYYY-MM-DD HH:mm:ss'
            )
          : this.eventToAdd.end
        this.eventToAdd.end = this.eventToAdd.end
          ? moment(this.eventToAdd.end, 'YYYY-MM-DD HH:mm').format(
              'YYYY-MM-DD HH:mm:ss'
            )
          : this.eventToAdd.end
        const response = await this.addEvent(this.eventToAdd)
        if (response) {
          this.closeDialog('openModalAddEvent')
        }
        this.loading = false
      }
    },
    async supprimerEvent() {
      this.loading = true
      const response = await this.deletedEvent(this.eventToDelete.id)
      if (response) {
        this.closeDialog('ModalDeleteEvent')
      }
      this.loading = false
    },
    changeFieldsTypeEvent() {},
    refreshComponentAddEvent() {
      const componentRef = this.$refs[`editableDynamicForm`]
      if (componentRef && componentRef.length) {
        componentRef[0].resetComponentEditableDynamic()
      }
      if (
        this.getOneTypeEvent &&
        this.getOneTypeEvent.fields &&
        this.getOneTypeEvent.fields.length
      ) {
        this.eventToAdd = {
          ...this.eventToAdd,
          type: {
            ...this.eventToAdd.type,
            fields: [...this.getOneTypeEvent.fields]
          }
        }
      }
    },
    refreshComponentUpdateEvent() {
      const componentRef = this.$refs[`editableDynamicFormUpdate`]
      if (componentRef && componentRef.length) {
        componentRef[0].resetComponentEditableDynamic()
      }
      if (
        this.getOneTypeEvent &&
        this.getOneTypeEvent.fields &&
        this.getOneTypeEvent.fields.length
      ) {
        this.eventToUpdate = {
          ...this.eventToUpdate,
          fields: [...this.getOneTypeEvent.fields]
        }
      }
    }
  },
  computed: {
    ...mapGetters([
      'getEventLoading',
      'getEventError',
      'getEvent',
      'getEventOfProject',
      'getTypeEventLoading',
      'getTypeEvent',
      'getTypeEventError',
      'countPaginationEvent',
      'getOneTypeEvent',
      'getEventStatus',
      'allUsers'
    ]),
    totalPages() {
      if (this.countPaginationEvent) {
        return Math.ceil(this.countPaginationEvent / this.per_page)
      }
      return this.countPaginationEvent
    },
    hasError() {
      return this.errorMessages !== null && this.errorMessages !== undefined
    }
  },
  watch: {
    getEventError(newValue) {
      this.errorMessages = newValue
    },
    getTypeEventError(newValue) {
      this.errorMessages = newValue
    },
    selectedDateTimeDateStart() {
      this.updateDateTimeStart()
    },
    selectedDateTimeTimeStart() {
      this.updateDateTimeStart()
    },
    selectedDateTimeDateEnd() {
      this.updateDateTimeEnd()
    },
    selectedDateTimeTimeEnd() {
      this.updateDateTimeEnd()
    }
  },
  filters: {
    datetime: function(value, format = 'DD-MM-YYYY HH:mm') {
      if (!value) return ''
      return moment(value, 'YYYY-MM-DD HH:mm:ss').format(format)
    }
  },
  created() {
    this.updateDateTimeStart()
    this.updateDateTimeEnd()
  },
  async mounted() {
    this.initLoading = true
    let project_id =
      this.project && this.project.id
        ? this.project.id
        : this.$route.name == 'project' &&
          this.$route.params &&
          this.$route.params.id
        ? this.$route.params.id
        : null
    if (project_id != null) {
      await this.fetchALLTypeEventOfProject({
        project_id: project_id,
        page: this.page,
        per_page: this.per_page
      })
    }
    if (this.project && this.project.type_id) {
      this.fetchAllTypeEvent({ type_projet_id: this.project.type_id })
    }
    this.getAllUsers()

    this.initLoading = false
  }
}
</script>
<style lang="scss" scoped>
.loader-content-event {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25vh;
}
.color-date {
  padding: 6px;
  background-color: rgb(237, 231, 249);
  border: 1px solid rgb(92, 45, 211);
  color: rgb(92, 45, 211);
  border-radius: 6px;
  font-weight: 600;
  text-align: center;
}
.event-list {
  min-height: calc(100vh - 340px);
}
</style>
