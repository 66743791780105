var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"a-card"},[_c('div',{staticClass:"a-card-header"},[_c('div',{staticClass:"a-card-header-flex",class:{
        'width-comment': _vm.epinglerNote,
        'a-card-header-switch': _vm.IconSwitch,
        'a-card-header-montant': _vm.titleMontant,
        'a-card-header-type-project': _vm.titleTypeProject,
        'padding-contact': _vm.categoryType == 'contact',
        'padding-projet': _vm.categoryType == 'projet',
        'padding-info-fiscal-projet': _vm.categoryType == 'projet' && _vm.infoFiscal,
        'padding-info-fiscal-contact': _vm.categoryType == 'contact' && _vm.infoFiscal
      }},[_c('div',{class:{ flex: !_vm.epinglerNote }},[_c('div',{staticClass:"title-card",class:{
            'color-title': _vm.epinglerNote,
            'style-title': _vm.titleTypeProject
          }},[_vm._t("title")],2)]),(_vm.titleTypeProject)?_c('div',{staticClass:"style-title-type"},[_vm._v(" "+_vm._s(_vm.titleTypeProject)+" ")]):_vm._e(),(_vm.titleMontant)?_c('div',{staticClass:"body-card"},_vm._l((_vm.titleMontant),function(item){return _c('div',{key:item.id},[(item.is_header)?_c('div',{staticClass:"flex"},[_c('div',{staticClass:"bold font-sz-11"},[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"value-ttc"},[_vm._v(_vm._s(item.value))])]):_vm._e()])}),0):_vm._e()])]),_c('div',{staticClass:"a-card-body",class:{
      'padding-info-fiscal-projet': _vm.categoryType == 'projet' && _vm.infoFiscal,
      'padding-contact': _vm.categoryType == 'contact',
      'padding-projet': _vm.categoryType == 'projet'
    }},[_vm._t("body")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }