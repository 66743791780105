var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"editable-input",style:({ width: _vm.styleWidthInput })},[_c('div',{staticClass:"edit-view"},[_c('dynamic-input-vuetify',{attrs:{"input":{
        disabledInput: !_vm.readonly && !_vm.fieldKeyEdit ? false : true,
        type: _vm.readonly || _vm.fieldKeyEdit ? 'text' : _vm.field.input_type,
        type_options: _vm.field.type,
        options: _vm.field.options ? _vm.field.options.data : [],
        name: _vm.field.key,
        label: _vm.field.name,
        links: _vm.field.links ? _vm.field.links : [],
        selected: _vm.field.selection_type,
        valuePossible: _vm.field.possible_values
          ? _vm.field.possible_values.data
          : [],
        formatDate: _vm.field.format ? true : false
      },"value":_vm.newValue},on:{"save":_vm.update,"disabled":_vm.disabled},model:{value:(_vm.newValue),callback:function ($$v) {_vm.newValue=$$v},expression:"newValue"}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }