<template>
  <Card>
    <template #title>
      <div class="title">
        {{ $i18n.locale === 'fr' ? 'Facade Ouest' : 'West facade' }}
        <span class="icon-info" @click="dialog = true">
          <img src="@/assets/img/info.svg" />
        </span>

        <v-dialog v-model="dialog" width="auto">
          <v-card>
            <div class="information">
              <div class="block-flex-align-space-between">
                <h1>
                  {{ $i18n.locale === 'fr' ? 'Façades' : 'Facades' }}
                </h1>
                <v-btn
                  class="btn-close-header"
                  icon
                  @click="dialog = false"
                  :title="$t('close')"
                  color="#5C2DD3"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </div>
              <h3>
                {{
                  $i18n.locale === 'fr'
                    ? 'Marche à suivre'
                    : 'The procedure to follow'
                }}
              </h3>
              <h4>
                {{
                  $i18n.locale === 'fr'
                    ? `Cette page du relevé d'information sert à enregistrer les mesures et les photos des façades des maisons que vous visitez. Voici comment vous pouvez l'utiliser simplement :`
                    : `This page of the information sheet is used to record measurements measurements and photos of the facades of the houses you visit. Here's how you can use it :`
                }}
              </h4>

              <h2>
                {{
                  $i18n.locale === 'fr'
                    ? `Identifiez l'orientation principale de la façade :`
                    : `Identify the main orientation of the facade:`
                }}
              </h2>
              <p>
                {{
                  $i18n.locale === 'fr'
                    ? `Tenez-vous dos à la façade que vous souhaitez indentifier.`
                    : `Stand with your back to the facade you wish to identify.`
                }}

                <br />
                {{
                  $i18n.locale === 'fr'
                    ? `Utilisez l'application boussole de votre smartphone pour déterminer l'orientation principale (nord, sud, est, ou ouest).`
                    : `Use your smartphone's compass application to determine your main orientation (north, south, east or west).`
                }}

                <br />
                {{
                  $i18n.locale === 'fr'
                    ? `Sélectionnez l'orientation correspondante dans le menu déroulant du relevé d'information.`
                    : `Select the corresponding orientation from the drop-down menu drop-down menu.`
                }}
              </p>

              <h2>
                {{
                  $i18n.locale === 'fr'
                    ? `Sélectionnez le type de façade :`
                    : `Select facade type :`
                }}
              </h2>
              <p>
                {{
                  $i18n.locale === 'fr'
                    ? `Dans le menu déroulant, choisissez entre "Façade rectangle" ou "Façade pignon" selon la forme de la façade.`
                    : `In the dropdown menu, choose between 'Rectangular facade' or 'Gable facade' according to the shape of the facade.`
                }}
              </p>

              <h2>
                {{
                  $i18n.locale === 'fr'
                    ? `Prenez les mesures :`
                    : `Take the measurements :`
                }}
              </h2>
              <ul>
                <h4>
                  {{
                    $i18n.locale === 'fr'
                      ? `Pour une façade rectangulaire :`
                      : `For a rectangular façade :`
                  }}
                </h4>
                <li>
                  {{
                    $i18n.locale === 'fr'
                      ? `Utilisez un mètre laser pour mesurer la largeur de la façade.`
                      : `Use a laser meter to measure the width of the facade.`
                  }}
                </li>
                <li>
                  {{
                    $i18n.locale === 'fr'
                      ? `Utilisez le mètre laser pour mesurer la hauteur de la façade.`
                      : `Use the laser meter to measure the height of the facade.`
                  }}
                </li>
                <li>
                  {{
                    $i18n.locale === 'fr'
                      ? `Indiquez ces mesures dans les champs correspondants du relevé d'information.`
                      : `Enter these measurements in the corresponding fields of the information sheet.`
                  }}
                </li>
              </ul>

              <ul>
                <h4>
                  {{
                    $i18n.locale === 'fr'
                      ? `Pour une façade en pignon :`
                      : `For a gable facade:`
                  }}
                </h4>
                <li>
                  {{
                    $i18n.locale === 'fr'
                      ? `Utilisez le mètre laser pour mesurer la hauteur la plus haute du pignon, jusqu'à la pointe.`
                      : `Use the laser meter to measure the highest height of the gable,to the point`
                  }}
                </li>
                <li>
                  {{
                    $i18n.locale === 'fr'
                      ? `Utilisez le mètre laser pour mesurer la hauteur la plus basse du pignon, là où commence le triangle du pignon.`
                      : `Use the laser meter to measure the lowest height of the gable, where the triangle of the gable begins.`
                  }}
                </li>
                <li>
                  {{
                    $i18n.locale === 'fr'
                      ? `Utilisez le mètre laser pour mesurer la longueur de la façade.`
                      : `Use the laser meter to measure the length of the facade.`
                  }}
                </li>
              </ul>

              <h2>
                {{
                  $i18n.locale === 'fr'
                    ? `Notez ces mesures dans les champs correspondants du relevé d'information.`
                    : `Note these measurements in the corresponding fields on the information sheet.`
                }}
              </h2>

              <h2>
                {{
                  $i18n.locale === 'fr'
                    ? `Sélectionnez le matériau de la façade :`
                    : `Select the material of the facade.`
                }}
              </h2>
              <p>
                {{
                  $i18n.locale === 'fr'
                    ? `Dans le menu déroulant, choisissez parmi les options disponibles (parpaing, brique, polystyrène, etc.) le matériau de la façade.`
                    : `In the dropdown menu, choose from the available options (block, brick, polystyrene, etc.) the material of the facade.`
                }}
              </p>

              <h2>
                {{
                  $i18n.locale === 'fr'
                    ? `Prenez une photo de chaque façade entière :`
                    : `Take a picture of each entire facade:`
                }}
              </h2>
              <p>
                {{
                  $i18n.locale === 'fr'
                    ? `Utilisez l'appareil photo de votre smartphone pour capturer une photo claire et complète de chaque façade.`
                    : `Use your smartphone's camera to capture a clear and complete photo of each facade.`
                }}
                <br />
                {{
                  $i18n.locale === 'fr'
                    ? `Téléchargez les photos dans les champs prévus à cet effet dans le relevé d'information.`
                    : `Upload the photos into the designated fields in the information sheet.`
                }}
              </p>

              <h4>
                {{
                  $i18n.locale === 'fr'
                    ? `En suivant ces étapes, vous pourrez enregistrer les informations pertinentes sur les façades à l'aide d'un mètre laser et de menus déroulants dans le relevé d'information. N'hésitez pas à demander de l'aide à une personne de confiance si nécessaire.`
                    : `By following these steps, you will be able to record relevant information about the facades using a laser meter and dropdown menus in the information sheet. Don't hesitate to ask for help from a trusted person if needed.`
                }}
              </h4>
            </div>
          </v-card>
        </v-dialog>
      </div>
    </template>
    <template #body>
      <v-form @submit.prevent="submitForm" ref="myForm">
        <v-row>
          <v-col cols="6">
            <v-select
              v-model="number"
              :label="
                $i18n.locale === 'fr'
                  ? 'Nombre de type de façade'
                  : 'Number of facade types'
              "
              dense
              clearable
              :placeholder="
                $i18n.locale === 'fr' ? 'Type de Plancher' : 'Floor type'
              "
              item-text="label"
              item-value="id"
              :items="numbers"
              outlined
              :persistent-placeholder="true"
              required
              color="#704ad1"
              item-color="#704ad1"
              @change="updateFacade"
            ></v-select>
          </v-col>
        </v-row>

        <v-row v-if="form9.facades && form9.facades.length">
          <v-col cols="12" style="overflow-y: auto;">
            <table>
              <thead>
                <tr>
                  <th>
                    {{ $i18n.locale === 'fr' ? 'Mitoyenne' : 'Semi-detached' }}
                  </th>
                  <th>
                    Type
                  </th>
                  <th>
                    {{ $i18n.locale === 'fr' ? 'Longueur (m)' : 'Length (m)' }}
                  </th>
                  <th>
                    {{
                      $i18n.locale === 'fr'
                        ? 'Hauteur min (m)'
                        : 'Min. height (m)'
                    }}
                  </th>
                  <th>
                    {{
                      $i18n.locale === 'fr'
                        ? 'Hauteur max (m)*'
                        : 'Max height (m)*'
                    }}
                  </th>
                  <th>
                    Surface (m2)
                  </th>
                  <th>
                    Eligible
                  </th>
                  <th>
                    {{
                      $i18n.locale === 'fr' ? 'Matière murs' : 'Wall material'
                    }}
                  </th>
                  <th>
                    {{
                      $i18n.locale === 'fr'
                        ? 'Epaisseur (cm)'
                        : 'Thickness (cm)'
                    }}
                  </th>
                  <th>
                    Isolation
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(facade, i) in form9.facades" :key="i">
                  <td>
                    <v-select
                      v-model="facade.mitoyenne"
                      dense
                      item-text="label"
                      item-value="value"
                      :items="Mitoyenne"
                      outlined
                      color="#704ad1"
                      item-color="#704ad1"
                      hide-details
                    ></v-select>
                  </td>
                  <td>
                    <v-select
                      v-model="facade.type"
                      dense
                      item-text="label"
                      item-value="value"
                      :items="Types"
                      outlined
                      color="#704ad1"
                      item-color="#704ad1"
                      hide-details
                    ></v-select>
                  </td>
                  <td>
                    <v-text-field
                      type="number"
                      v-model="facade.longueur"
                      :min="0"
                      dense
                      outlined
                      hide-details
                      color="#704ad1"
                    ></v-text-field>
                  </td>
                  <td>
                    <v-text-field
                      type="number"
                      v-model="facade.hauteur_min"
                      :min="0"
                      dense
                      outlined
                      hide-details
                      @input="
                        () => {
                          if (facade.type == 'rectangulaire')
                            facade.hauteur_max = facade.hauteur_min
                        }
                      "
                      color="#704ad1"
                    ></v-text-field>
                  </td>
                  <td>
                    <v-text-field
                      type="number"
                      v-model="facade.hauteur_max"
                      :min="0"
                      dense
                      outlined
                      :readonly="facade.type == 'rectangulaire'"
                      hide-details
                      color="#704ad1"
                    ></v-text-field>
                  </td>
                  <td>
                    <v-text-field
                      type="number"
                      :value="
                        facade.type == 'rectangulaire'
                          ? facade.longueur * facade.hauteur_min
                          : facade.longueur * facade.hauteur_min +
                            (facade.longueur *
                              (facade.hauteur_max - facade.hauteur_min)) /
                              2
                      "
                      :min="0"
                      dense
                      outlined
                      readonly
                      hide-details
                      color="#704ad1"
                    ></v-text-field>
                  </td>
                  <td>
                    <v-select
                      v-model="facade.eligible"
                      dense
                      item-text="label"
                      item-value="value"
                      :items="Eligibles"
                      outlined
                      color="#704ad1"
                      item-color="#704ad1"
                      hide-details
                    ></v-select>
                  </td>
                  <td>
                    <v-select
                      v-model="facade.matiere"
                      dense
                      item-text="label"
                      item-value="value"
                      :items="Matieres"
                      outlined
                      color="#704ad1"
                      item-color="#704ad1"
                      hide-details
                    ></v-select>
                  </td>
                  <td>
                    <v-select
                      v-model="facade.epaisseur"
                      dense
                      item-text="label"
                      item-value="value"
                      :items="Epaisseurs"
                      outlined
                      color="#704ad1"
                      item-color="#704ad1"
                      hide-details
                    ></v-select>
                  </td>
                  <td>
                    <v-select
                      v-model="facade.isolation"
                      dense
                      item-text="label"
                      item-value="value"
                      :items="Isolations"
                      outlined
                      color="#704ad1"
                      item-color="#704ad1"
                      hide-details
                    ></v-select>
                  </td>
                </tr>
              </tbody>
            </table>
          </v-col>

          <v-col cols="6">
            <v-text-field
              :value="total_surface"
              type="number"
              :label="
                $i18n.locale === 'fr'
                  ? 'Surface Total (m2)'
                  : 'Total surface (m2)'
              "
              :field="
                $i18n.locale === 'fr'
                  ? 'Surface Total (m2)'
                  : 'Total surface (m2)'
              "
              :min="0"
              dense
              readonly
              placeholder="en m2"
              outlined
              :persistent-placeholder="true"
              color="#704ad1"
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <v-file-input
              v-model="form9.file"
              dense
              accept="image/*"
              prepend-icon="mdi-plus"
              :label="
                $i18n.locale === 'fr'
                  ? 'Ajouter photo façade Ouest'
                  : 'Add photo west facade'
              "
              underlined
              clearable
              color="#704ad1"
            ></v-file-input>
          </v-col>
        </v-row>

        <!-- Bouton "Suivant" pour chaque étape -->
        <v-col cols="12" class="bottom">
          <v-btn color="#5C2DD3" outlined @click="prevStep">
            {{ $t('precedant') }}
          </v-btn>
          <v-btn color="#5C2DD3" dark type="submit">
            {{ $t('suivant') }}
          </v-btn>
        </v-col>
      </v-form>
    </template>
  </Card>
</template>

<script>
import Card from '@/components/CrmIla26Component/Projets/Card.vue'

export default {
  components: {
    Card
  },
  data() {
    return {
      dialog: false,
      form9: {
        facades: [],
        file: null
      },
      number: 0,
      numbers: [
        {
          label:
            this.$i18n.locale === 'fr' ? '1 type de façade' : '1 facade type',
          id: 1
        },
        {
          label:
            this.$i18n.locale === 'fr' ? '2 type de façade' : '2 facade type',
          id: 2
        },
        {
          label:
            this.$i18n.locale === 'fr' ? '3 type de façade' : '3 facade type',
          id: 3
        },
        {
          label:
            this.$i18n.locale === 'fr' ? '4 type de façade' : '4 facade type',
          id: 4
        }
      ],
      Mitoyenne: [
        {
          label: this.$i18n.locale === 'fr' ? 'Non' : 'Non',
          value: 'non'
        },
        {
          label: this.$i18n.locale === 'fr' ? 'Partielle' : 'Partial',
          value: 'partielle'
        },
        {
          label: this.$i18n.locale === 'fr' ? 'Totale' : 'Total',
          value: 'totale'
        }
      ],
      Eligibles: [
        {
          label: this.$i18n.locale === 'fr' ? 'Oui' : 'Yes',
          value: true
        },
        {
          label: this.$i18n.locale === 'fr' ? 'Non' : 'Non',
          value: false
        }
      ],
      Isolations: [
        {
          label: this.$i18n.locale === 'fr' ? 'Aucune' : 'None',
          value: null
        },
        {
          label: 'ITE',
          value: 'ite'
        },
        {
          label: 'ITE',
          value: 'ite'
        },
        {
          label: 'ITI',
          value: 'iti'
        },
        {
          label: this.$i18n.locale === 'fr' ? 'Inconnue' : 'Unknown',
          value: 'inconnue'
        }
      ],
      Epaisseurs: [
        {
          label: this.$i18n.locale === 'fr' ? 'Aucune' : 'None',
          value: null
        },
        {
          label: '15cm',
          value: '15cm'
        },
        {
          label: '20cm',
          value: '20cm'
        },
        {
          label: '30cm',
          value: '30cm'
        },
        {
          label: '35cm',
          value: '35cm'
        },
        {
          label: '40cm',
          value: '40cm'
        },
        {
          label: '45cm',
          value: '45cm'
        },
        {
          label: '50cm',
          value: '50cm'
        },
        {
          label: '55cm',
          value: '55cm'
        },
        {
          label: '60cm',
          value: '60cm'
        },
        {
          label: this.$i18n.locale === 'fr' ? 'Plus de 65cm' : 'More than 65cm',
          value: '65cm'
        }
      ],
      Matieres: [
        {
          label: this.$i18n.locale === 'fr' ? 'Inconnue' : 'Unknown',
          value: 'inconnue'
        },
        {
          label: this.$i18n.locale === 'fr' ? 'Parpaing' : 'Parpaing',
          value: 'parpaing'
        },
        {
          label: this.$i18n.locale === 'fr' ? 'Pierre' : 'Pierre',
          value: 'pierre'
        },
        {
          label: this.$i18n.locale === 'fr' ? 'Briques' : 'Bricks',
          value: 'briques'
        },
        {
          label: this.$i18n.locale === 'fr' ? 'Pisé' : 'Pisé',
          value: 'pisé'
        },
        {
          label: this.$i18n.locale === 'fr' ? 'Bois' : 'Wood',
          value: 'bois'
        },
        {
          label: this.$i18n.locale === 'fr' ? 'Béton' : 'Concrete',
          value: 'beton'
        }
      ],
      Types: [
        {
          label: this.$i18n.locale === 'fr' ? 'Pignon' : 'Gable',
          value: 'pignon'
        },
        {
          label: this.$i18n.locale === 'fr' ? 'Rectangulaire' : 'Rectangular',
          value: 'rectangulaire'
        }
      ]
    }
  },
  methods: {
    resetComponent() {
      // Réinitialiser les données spécifiques à ce composant (par exemple, formulaire)
      this.form9 = {
        facades: [],
        file: null
      }
      this.number = 0
    },
    submitForm() {
      // Utilisez $refs pour accéder au formulaire
      const valid = this.$refs.myForm.validate()
      if (!valid) {
        return
      }
      this.form9.facades.forEach(facade => {
        if (facade.type == 'rectangulaire')
          facade.surface = +facade.longueur * +facade.hauteur_min
        else {
          facade.surface =
            facade.longueur * facade.hauteur_min +
            (facade.longueur * (facade.hauteur_max - facade.hauteur_min)) / 2
        }
      })
      this.$emit('nextStep', {
        surface_total: this.total_surface,
        ...this.form9
      })
    },
    prevStep() {
      this.$emit('prevStep')
    },
    updateFacade(event) {
      let boucle = 0
      if (event > this.form9.facades.length) {
        boucle = event - this.form9.facades.length
        for (let i = 0; i < boucle; i++) {
          this.form9.facades.push({
            mitoyenne: 'non',
            type: 'rectangulaire',
            longueur: 0,
            hauteur_min: 0,
            hauteur_max: 0,
            surface: 0,
            eligible: true,
            matiere: 'pierre',
            epaisseur: null,
            isolation: null
          })
        }
      } else if (event < this.form9.facades.length) {
        boucle = this.form9.facades.length - event
        this.form9.facades.splice(this.form9.facades.length - boucle, boucle)
      }
    }
  },
  computed: {
    total_surface() {
      let total = 0
      if (this.form9.facades && this.form9.facades.length) {
        this.form9.facades.forEach(facade => {
          if (facade.type == 'rectangulaire')
            total += +facade.longueur * +facade.hauteur_min
          else {
            total +=
              facade.longueur * facade.hauteur_min +
              (facade.longueur * (facade.hauteur_max - facade.hauteur_min)) / 2
          }
        })
      }

      return total
    }
  }
}
</script>

<style lang="scss" scoped>
.title {
  position: relative;
  .icon-info {
    cursor: pointer;
    position: absolute;
    top: -4px;
    right: -20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ff9000;
    width: 18px;
    height: 18px;
    border-radius: 50%;
  }
}

table {
  width: 100%;
  thead th,
  tbody td {
    border-bottom: thin solid rgba(0, 0, 0, 0.12);
    padding: 2px 5px;
    white-space: nowrap;
    color: #5e5e5e;
    text-align: center;
    position: relative;
    font-size: 12px;
    line-height: 1.5;
    overflow: hidden;
    height: 48px;
  }
}
.information {
  padding: 15px;
  h1 {
    font-size: 18px;
    font-weight: 600;
    color: #704ad1;
  }
  h2 {
    font-size: 12px;
    font-weight: 600;
    color: #704ad1;
  }
  h3 {
    font-size: 20px;
  }
  h4 {
    font-style: italic;
    font-size: 12px;
    font-weight: 600;
  }
  p {
    font-size: 12px;
  }
}
.bottom {
  display: flex !important;
  align-items: center;
  justify-content: center !important;
  gap: 16px;
}
</style>
