var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Card',{scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('v-form',{ref:"myForm",on:{"submit":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_c('v-row',_vm._l((_vm.form18.informations),function(info,i){return _c('v-col',{key:i,attrs:{"cols":"6"}},[_c('Card',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"title",class:{ link: i != 0 }},[_vm._v(" "+_vm._s(_vm.$i18n.locale === 'fr' ? `Information Fiscalité (Avis ${i + 1})` : `Tax information (Notice ${i + 1})`)+" "),(i != 0)?_c('font-awesome-icon',{staticClass:"icon",staticStyle:{"color":"#704ad1"},attrs:{"icon":"circle-minus"},on:{"click":function($event){return _vm.removeAvis(i)}}}):_vm._e()],1)]},proxy:true},{key:"body",fn:function(){return [_c('v-text-field',{attrs:{"type":"number","label":_vm.$i18n.locale === 'fr'
                    ? 'Nombre de personne'
                    : 'Number of persons',"field":_vm.$i18n.locale === 'fr'
                    ? 'Nombre de personne'
                    : 'Number of persons',"dense":"","placeholder":"-","outlined":"","persistent-placeholder":true,"color":"#704ad1"},model:{value:(info.nbre_personne),callback:function ($$v) {_vm.$set(info, "nbre_personne", $$v)},expression:"info.nbre_personne"}}),_c('v-text-field',{attrs:{"label":_vm.$i18n.locale === 'fr' ? 'Revenu Fiscal' : 'Income Tax',"field":_vm.$i18n.locale === 'fr' ? 'Revenu Fiscal' : 'Income Tax',"dense":"","placeholder":"-","outlined":"","persistent-placeholder":true,"color":"#704ad1"},model:{value:(info.rev_fiscal),callback:function ($$v) {_vm.$set(info, "rev_fiscal", $$v)},expression:"info.rev_fiscal"}}),_c('v-text-field',{attrs:{"label":_vm.$i18n.locale === 'fr' ? 'Numéro Fiscal' : 'Tax number',"field":_vm.$i18n.locale === 'fr' ? 'Numéro Fiscal' : 'Tax number',"dense":"","placeholder":"-","outlined":"","persistent-placeholder":true,"color":"#704ad1"},model:{value:(info.num_fiscal),callback:function ($$v) {_vm.$set(info, "num_fiscal", $$v)},expression:"info.num_fiscal"}}),_c('v-text-field',{attrs:{"label":_vm.$i18n.locale === 'fr'
                    ? 'Reference Avis'
                    : 'Reference Notice',"field":_vm.$i18n.locale === 'fr'
                    ? 'Reference Avis'
                    : 'Reference Notice',"dense":"","placeholder":"-","outlined":"","persistent-placeholder":true,"color":"#704ad1"},model:{value:(info.ref_avis),callback:function ($$v) {_vm.$set(info, "ref_avis", $$v)},expression:"info.ref_avis"}}),_c('v-text-field',{attrs:{"label":_vm.$i18n.locale === 'fr'
                    ? 'Classification coup de pouce'
                    : 'Coup de pouce classification',"field":_vm.$i18n.locale === 'fr'
                    ? 'Classification coup de pouce'
                    : 'Coup de pouce classification',"dense":"","placeholder":"-","outlined":"","persistent-placeholder":true},model:{value:(info.coup_pouce),callback:function ($$v) {_vm.$set(info, "coup_pouce", $$v)},expression:"info.coup_pouce"}}),_c('v-text-field',{attrs:{"label":_vm.$i18n.locale === 'fr'
                    ? 'Classification MPR'
                    : 'Classification TPM',"field":_vm.$i18n.locale === 'fr'
                    ? 'Classification MPR'
                    : 'Classification TPM',"dense":"","placeholder":"-","outlined":"","persistent-placeholder":true},model:{value:(info.mpr),callback:function ($$v) {_vm.$set(info, "mpr", $$v)},expression:"info.mpr"}}),_c('v-col',{attrs:{"cols":"12"}},[_c('v-file-input',{attrs:{"dense":"","accept":"image/*,application/pdf","prepend-icon":"mdi-plus","label":_vm.$i18n.locale === 'fr'
                      ? `Ajouter photo avis d'imposition ${i + 1} (page 1)`
                      : `Add photo tax notice ${i + 1} (page 1)`,"underlined":"","clearable":"","color":"#704ad1"},model:{value:(info.file1),callback:function ($$v) {_vm.$set(info, "file1", $$v)},expression:"info.file1"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-file-input',{attrs:{"dense":"","accept":"image/*,application/pdf","prepend-icon":"mdi-plus","label":_vm.$i18n.locale === 'fr'
                      ? `Ajouter photo avis d'imposition ${i + 1} (page 2)`
                      : `Add photo tax notice ${i + 1} (page 2)`,"underlined":"","clearable":"","color":"#704ad1"},model:{value:(info.file2),callback:function ($$v) {_vm.$set(info, "file2", $$v)},expression:"info.file2"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-file-input',{attrs:{"dense":"","accept":"image/*,application/pdf","prepend-icon":"mdi-plus","label":_vm.$i18n.locale === 'fr'
                      ? `Ajouter photo avis d'imposition ${i + 1} (page 3)`
                      : `Add photo tax notice ${i + 1} (page 3)`,"underlined":"","clearable":"","color":"#704ad1"},model:{value:(info.file3),callback:function ($$v) {_vm.$set(info, "file3", $$v)},expression:"info.file3"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-file-input',{attrs:{"dense":"","accept":"image/*,application/pdf","prepend-icon":"mdi-plus","label":_vm.$i18n.locale === 'fr'
                      ? `Ajouter photo avis d'imposition ${i + 1} (page 4)`
                      : `Add photo tax notice ${i + 1} (page 4)`,"underlined":"","clearable":"","color":"#704ad1"},model:{value:(info.file4),callback:function ($$v) {_vm.$set(info, "file4", $$v)},expression:"info.file4"}})],1)]},proxy:true}],null,true)})],1)}),1),_c('v-row',[_c('v-col',[_c('div',{staticClass:"link",on:{"click":_vm.addAvis}},[_c('font-awesome-icon',{staticClass:"icon",staticStyle:{"color":"#704ad1"},attrs:{"icon":"circle-plus"}}),_vm._v(" "+_vm._s(_vm.$i18n.locale === 'fr' ? "Ajouter un avis d'imposition" : 'Add a tax notice')+" ")],1)])],1),_c('v-col',{staticClass:"bottom",attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"color":"#5C2DD3","outlined":""},on:{"click":_vm.prevStep}},[_vm._v(" "+_vm._s(_vm.$t('precedant'))+" ")]),_c('v-btn',{attrs:{"color":"#5C2DD3","dark":"","type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t('suivant'))+" ")])],1)],1)]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }