<template>
  <div class="tabs-sous-type mt-1">
    <v-tabs
      class="mt-3 pt-3"
      :hide-slider="true"
      color="#9c27b0"
      item-color="#9c27b0"
      v-model="tabIndex"
    >
      <v-tab
        :active-class="clickTab(subtypes, index)"
        v-for="(subtypes, index) in subtypes"
        :key="index"
        @click="tabMenuSousType"
        :href="`#tab-${index}`"
        color="#9c27b0"
        item-color="#9c27b0"
      >
        <div class="sous-type">{{ subtypes.name }}</div>
        <!-- <div v-if="subtypes.is_tagged == 1" class="counter"></div> -->
      </v-tab>
    </v-tabs>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: 'sous-type-projet',
  props: {
    subtypes: {
      required: true
    },
    data: {
      required: false
    },
    readonly: {
      default: false
    }
  },
  data() {
    return {
      tabIndex: 'tab-0',
      subtypeName: null
    }
  },
  methods: {
    ...mapActions(['refrechProject']),
    clickTab(subtype, index) {
      if (`tab-${index}` == this.tabIndex) {
        this.subtypeName = subtype.name
        this.$emit('sousType', { name: this.subtypeName, id: subtype.id })
      }
    },
    tabMenuSousType() {
      if (this.data) {
        this.refrechProject({ project: this.data, reset: 1 })
      }
    }
  }
}
</script>

<style lang="scss">
.tabs-sous-type {
  .v-tabs-bar {
    border-radius: inherit;
    height: 33px;
  }
  .v-slide-group__content {
    justify-content: center;

    .v-tab {
      text-decoration: none;
      margin: 3px;
      color: #5c2dd3 !important;
      font-size: 12px !important;
      border: 1px solid #5c2dd3;
      .counter {
        color: #5c2dd3;
        background-color: #5c2dd3;
        width: 5px;
        height: 5px;
        border: 1px solid;
        margin-right: 2px;
        margin-left: 3px;
        margin-bottom: -10px;
      }
    }
    .v-tab--active {
      border: 1px solid #5c2dd3;
      color: #ffffff !important;
      background-color: #5c2dd3;
      .counter {
        color: #ffffff;
        background-color: #ffffff;
        width: 5px;
        height: 5px;
        border: 1px solid;
        margin-right: 2px;
        margin-left: 3px;
        margin-bottom: -10px;
      }
    }
  }
}
</style>
