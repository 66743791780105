<template>
  <Card>
    <template #title>
      <div class="title">
        {{ $i18n.locale === 'fr' ? 'Surface Habitable' : 'Living space' }}
      </div>

      <v-dialog v-model="dialog" width="500">
        <v-card>
          <div class="information">
            <h1>
              {{ $i18n.locale === 'fr' ? 'Attention' : 'Attention' }}
            </h1>
            <h4>
              {{
                $i18n.locale === 'fr'
                  ? 'Il manque un element :'
                  : 'One element is missing:'
              }}
            </h4>

            <h2>{{ $i18n.locale === 'fr' ? 'PHOTO :' : 'PHOTO :' }}</h2>
            <p v-for="(surface, i) in form11.surfaces" :key="i">
              <template v-if="!surface.file">
                {{
                  $i18n.locale === 'fr'
                    ? `PHOTO PIECE N° ${i + 1} - SURFACE HABITABLE.`
                    : `PHOTO PIECE N° ${i + 1} - LIVING SPACE`
                }}
              </template>
            </p>
          </div>
        </v-card>
      </v-dialog>
    </template>
    <template #body>
      <v-form @submit.prevent="submitForm" ref="myForm">
        <v-row>
          <v-col cols="6">
            <v-select
              v-model="number"
              :label="
                $i18n.locale === 'fr'
                  ? 'Nombre d’étage chauffé'
                  : 'Number of heated floors'
              "
              dense
              clearable
              :placeholder="
                $i18n.locale === 'fr'
                  ? 'Nombre d’étage chauffé'
                  : 'Number of heated floors'
              "
              item-text="label"
              item-value="id"
              :items="numbers"
              outlined
              :persistent-placeholder="true"
              required
              color="#704ad1"
              item-color="#704ad1"
            ></v-select>
          </v-col>

          <v-col cols="6">
            <v-text-field
              v-model="form11.nbr_pieces"
              :label="
                $i18n.locale === 'fr'
                  ? 'Nombre de pièces chauffées'
                  : 'Number of heated rooms'
              "
              :persistent-placeholder="true"
              type="number"
              :rules="[rules.numeric]"
              :min="0"
              :max="8"
              dense
              outlined
              hide-details
              @input="updateSurface"
              color="#704ad1"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row v-if="form11.surfaces && form11.surfaces.length">
          <v-col cols="12" style="overflow-y: auto;">
            <table>
              <thead>
                <tr>
                  <th>
                    {{ $i18n.locale === 'fr' ? 'Etage' : 'Floor' }}
                  </th>
                  <th>
                    {{
                      $i18n.locale === 'fr' ? 'Type de piece' : 'Type of piece'
                    }}
                  </th>
                  <th>
                    Surface (m2)
                  </th>
                  <th>
                    {{ $i18n.locale === 'fr' ? 'Hauteur (m)' : 'Height (m)' }}
                  </th>
                  <th>
                    Volume (m3)
                  </th>
                  <th>
                    {{
                      $i18n.locale === 'fr'
                        ? 'Nbr de radiateur'
                        : 'Number of radiators'
                    }}
                  </th>
                  <th>
                    {{
                      $i18n.locale === 'fr' ? 'Type radiateur' : 'Radiator type'
                    }}
                  </th>
                  <th>
                    Appoint
                    <!-- {{ $i18n.locale === 'fr' ? 'Appoint' : 'Appoint' }} -->
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(surface, i) in form11.surfaces" :key="i">
                  <td>
                    <v-select
                      v-model="surface.etage"
                      dense
                      item-text="label"
                      item-value="value"
                      :items="Etages"
                      outlined
                      color="#704ad1"
                      item-color="#704ad1"
                      hide-details
                    ></v-select>
                  </td>
                  <td>
                    <v-select
                      v-model="surface.type"
                      dense
                      item-text="label"
                      item-value="value"
                      :items="Types"
                      outlined
                      color="#704ad1"
                      item-color="#704ad1"
                      hide-details
                    ></v-select>
                  </td>
                  <td>
                    <v-text-field
                      v-model="surface.surface"
                      type="number"
                      :min="0"
                      dense
                      outlined
                      hide-details
                      color="#704ad1"
                      @input="
                        () => {
                          surface.volume = surface.surface * surface.hauteur
                        }
                      "
                    ></v-text-field>
                  </td>
                  <td>
                    <v-text-field
                      v-model="surface.hauteur"
                      type="number"
                      :min="0"
                      dense
                      outlined
                      hide-details
                      color="#704ad1"
                      @input="
                        () => {
                          surface.volume = surface.surface * surface.hauteur
                        }
                      "
                    ></v-text-field>
                  </td>
                  <td>
                    <v-text-field
                      v-model="surface.volume"
                      type="number"
                      :min="0"
                      dense
                      outlined
                      hide-details
                      color="#704ad1"
                      readonly
                    ></v-text-field>
                  </td>
                  <td>
                    <v-text-field
                      v-model="surface.nbrRadiateur"
                      type="number"
                      :min="0"
                      dense
                      outlined
                      hide-details
                      color="#704ad1"
                    ></v-text-field>
                  </td>
                  <td>
                    <v-select
                      v-model="surface.radiateur"
                      dense
                      item-text="label"
                      item-value="value"
                      :items="Radiateurs"
                      outlined
                      color="#704ad1"
                      item-color="#704ad1"
                      hide-details
                    ></v-select>
                  </td>
                  <td>
                    <v-select
                      v-model="surface.appoint"
                      dense
                      item-text="label"
                      item-value="value"
                      :items="Appoints"
                      outlined
                      color="#704ad1"
                      item-color="#704ad1"
                      hide-details
                    ></v-select>
                  </td>
                  <td>
                    <label :for="'fileSurface' + i" style="margin-bottom: 0">
                      <font-awesome-icon icon="camera" class="mr-2" />

                      <font-awesome-icon
                        v-if="surface.file"
                        icon="check"
                        style="color: #0cb277;"
                      />
                      <font-awesome-icon
                        v-else
                        icon="exclamation-triangle"
                        style="color: #ff9000;"
                      />
                    </label>

                    <!-- Input de type fichier (caché) -->
                    <v-file-input
                      :rules="[rules.required]"
                      v-model="surface.file"
                      :label="null"
                      accept="image/*"
                      :id="'fileSurface' + i"
                      style="display: none"
                    ></v-file-input>
                  </td>
                </tr>
              </tbody>
            </table>
          </v-col>

          <v-col cols="4">
            <v-text-field
              :value="total_surface"
              type="number"
              :label="
                $i18n.locale === 'fr'
                  ? 'Surface Habitable Total (m2)'
                  : 'Total living area (m2)'
              "
              :field="
                $i18n.locale === 'fr'
                  ? 'Surface Habitable Total (m2)'
                  : 'Total living area (m2)'
              "
              :min="0"
              dense
              readonly
              placeholder="en m2"
              outlined
              :persistent-placeholder="true"
              color="#704ad1"
            ></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field
              :value="total_volume"
              type="number"
              :label="
                $i18n.locale === 'fr'
                  ? 'Volume Total (m3)'
                  : 'Total volume (m3)'
              "
              :field="
                $i18n.locale === 'fr'
                  ? 'Volume Total (m3)'
                  : 'Total volume (m3)'
              "
              :min="0"
              dense
              readonly
              placeholder="en m3"
              outlined
              :persistent-placeholder="true"
              color="#704ad1"
            ></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field
              :value="total_radiateur"
              type="number"
              :label="$i18n.locale === 'fr' ? 'Nbr raditeur' : 'Nbr raditor'"
              :field="$i18n.locale === 'fr' ? 'Nbr raditeur' : 'Nbr raditor'"
              :min="0"
              dense
              readonly
              :placeholder="
                $i18n.locale === 'fr' ? 'Nbr raditeur' : 'Nbr raditor'
              "
              outlined
              :persistent-placeholder="true"
              color="#704ad1"
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <v-file-input
              v-model="form11.file_radiateurs"
              dense
              accept="image/*"
              prepend-icon="mdi-plus"
              :label="
                $i18n.locale === 'fr'
                  ? 'Ajouter photo type radiateur'
                  : 'Add photo radiator type'
              "
              underlined
              clearable
              color="#704ad1"
            ></v-file-input>
          </v-col>
          <v-col cols="12">
            <v-file-input
              v-model="form11.file_appoints"
              dense
              accept="image/*"
              prepend-icon="mdi-plus"
              :label="
                $i18n.locale === 'fr'
                  ? 'Ajouter photo appoint'
                  : 'Add extra photo'
              "
              underlined
              clearable
              color="#704ad1"
            ></v-file-input>
          </v-col>
        </v-row>

        <!-- Bouton "Suivant" pour chaque étape -->
        <v-col cols="12" class="bottom">
          <v-btn color="#5C2DD3" outlined @click="prevStep">
            {{ $t('precedant') }}
          </v-btn>
          <v-btn color="#5C2DD3" dark type="submit">
            {{ $t('suivant') }}
          </v-btn>
        </v-col>
      </v-form>
    </template>
  </Card>
</template>

<script>
import Card from '@/components/CrmIla26Component/Projets/Card.vue'

export default {
  components: {
    Card
  },
  data() {
    return {
      dialog: false,
      form11: {
        nbr_pieces: 0,
        surfaces: [],
        file_radiateurs: null,
        file_appoints: null
      },
      rules: {
        required: value =>
          !!value ||
          (this.$i18n.locale === 'fr'
            ? 'Ce champ est obligatoire'
            : 'This field is required'),
        numeric: value =>
          value === null ||
          value === '' ||
          /^[0-9]+$/.test(value) ||
          (this.$i18n.locale === 'fr'
            ? 'Ce champ doit être numérique'
            : 'This field must be numeric')
        // Ajoutez d'autres règles au besoin
      },
      number: 0,
      numbers: [
        {
          label:
            this.$i18n.locale === 'fr' ? '1 étage chauffé' : '1 heated floor',
          id: 1
        },
        {
          label:
            this.$i18n.locale === 'fr'
              ? '2 étages chauffés'
              : '2 heated floors',
          id: 2
        },
        {
          label:
            this.$i18n.locale === 'fr'
              ? '3 étages chauffés'
              : '3 heated floors',
          id: 3
        },
        {
          label:
            this.$i18n.locale === 'fr'
              ? '4 étages chauffés'
              : '4 heated floors',
          id: 4
        },
        {
          label:
            this.$i18n.locale === 'fr'
              ? '5 étages chauffés'
              : '5 heated floors',
          id: 5
        },
        {
          label:
            this.$i18n.locale === 'fr'
              ? '6 étages chauffés'
              : '6 heated floors',
          id: 6
        }
      ],
      Etages: [
        {
          label:
            this.$i18n.locale === 'fr' ? 'Sous-sol chauffé' : 'Heated basement',
          value: 'Sous-sol chauffé'
        },
        {
          label:
            this.$i18n.locale === 'fr' ? 'Rez de chaussée' : 'Ground floor',
          value: 'Rez de chaussée'
        },
        {
          label: this.$i18n.locale === 'fr' ? '1er Etage' : '1st floor',
          value: '1er Etage'
        },
        {
          label: this.$i18n.locale === 'fr' ? '2eme Etage' : '2nd Floor',
          value: '2eme Etage'
        },
        {
          label: this.$i18n.locale === 'fr' ? '3eme Etage' : '3rd Floor',
          value: '3eme Etage'
        },
        {
          label:
            this.$i18n.locale === 'fr' ? 'Comble aménagé' : 'Converted attic',
          value: 'Comble aménagé'
        }
      ],
      Types: [
        {
          label: this.$i18n.locale === 'fr' ? 'Salon' : 'Living room',
          value: 'Salon'
        },
        {
          label: this.$i18n.locale === 'fr' ? 'Cuisine' : 'Kitchen',
          value: 'Cuisine'
        },
        {
          label: this.$i18n.locale === 'fr' ? 'Salle à manger' : 'Dining room',
          value: 'Salle à manger'
        },
        {
          label: 'WC',
          value: 'WC'
        },
        {
          label: this.$i18n.locale === 'fr' ? 'Buanderie' : 'Laundry room',
          value: 'Buanderie'
        },
        {
          label: this.$i18n.locale === 'fr' ? 'Bureau' : 'Desk ',
          value: 'Bureau'
        },
        {
          label: this.$i18n.locale === 'fr' ? 'Entrée' : 'Entrance',
          value: 'Entrée'
        },
        {
          label: this.$i18n.locale === 'fr' ? 'Dressing' : 'Dressing room',
          value: 'Dressing'
        },
        {
          label: this.$i18n.locale === 'fr' ? 'Salle de jeux' : 'Games room',
          value: 'Salle de jeux'
        },
        {
          label: this.$i18n.locale === 'fr' ? 'Couloir' : 'Corridor',
          value: 'Couloir'
        },
        {
          label: this.$i18n.locale === 'fr' ? 'Dégagement' : 'Clearance',
          value: 'Dégagement'
        },
        {
          label: this.$i18n.locale === 'fr' ? 'Escalier' : 'Stairs',
          value: 'Escalier'
        },
        {
          label: this.$i18n.locale === 'fr' ? 'Autre' : 'Other',
          value: 'Autre'
        }
      ],
      Radiateurs: [
        {
          label:
            this.$i18n.locale === 'fr'
              ? 'Radiateur Fonte'
              : 'Cast iron radiator',
          value: 'Radiateur Fonte'
        },
        {
          label:
            this.$i18n.locale === 'fr' ? 'Radiateur Acier' : 'Steel radiator',
          value: 'Radiateur Acier'
        },
        {
          label:
            this.$i18n.locale === 'fr' ? 'Radiateur Alu.' : 'Alu radiator.',
          value: 'Radiateur Alu.'
        },
        {
          label:
            this.$i18n.locale === 'fr' ? 'Radiateur Elec.' : 'Elec. radiator',
          value: 'Radiateur Elec.'
        },
        {
          label: this.$i18n.locale === 'fr' ? 'Split' : 'Split',
          value: 'Split'
        },
        {
          label:
            this.$i18n.locale === 'fr' ? 'Plancher Chauffant' : 'Floor heating',
          value: 'Plancher Chauffant'
        },
        {
          label:
            this.$i18n.locale === 'fr'
              ? 'Radiateur basse temp.'
              : 'Low-temperature radiator',
          value: 'Radiateur basse temp.'
        }
      ],
      Appoints: [
        {
          label: this.$i18n.locale === 'fr' ? 'Aucun' : 'None',
          value: null
        },
        {
          label: this.$i18n.locale === 'fr' ? 'Cheminée' : 'Fireplace',
          value: 'Cheminée'
        },
        {
          label:
            this.$i18n.locale === 'fr' ? 'Radiateur Elec.' : 'Elec. radiator',
          value: 'Radiateur Elec.'
        },
        {
          label: this.$i18n.locale === 'fr' ? 'Poele' : 'Stove',
          value: 'Poele'
        }
      ]
    }
  },
  methods: {
    resetComponent() {
      // Réinitialiser les données spécifiques à ce composant (par exemple, formulaire)
      this.form11 = {
        nbr_pieces: 0,
        surfaces: [],
        file_radiateurs: null,
        file_appoints: null
      }
      this.number = 0
    },
    submitForm() {
      // Utilisez $refs pour accéder au formulaire
      const valid = this.$refs.myForm.validate()
      if (!valid) {
        this.dialog = true
        return
      }

      this.$emit('nextStep', {
        nbr_etages: this.total_radiateur,
        surface_total: this.total_surface,
        volume_total: this.total_volume,
        ...this.form11
      })
    },
    prevStep() {
      this.$emit('prevStep')
    },
    updateSurface(event) {
      let boucle = 0
      if (event > this.form11.surfaces.length) {
        boucle = event - this.form11.surfaces.length
        for (let i = 0; i < boucle; i++) {
          this.form11.surfaces.push({
            etage: '1er Etage',
            type: 'Salon',
            surface: 0,
            hauteur: 0,
            volume: 0,
            nbrRadiateur: 0,
            radiateur: 'Split',
            appoint: null,
            file: null
          })
        }
      } else if (event < this.form11.surfaces.length) {
        boucle = this.form11.surfaces.length - event
        this.form11.surfaces.splice(
          this.form11.surfaces.length - boucle,
          boucle
        )
      }
    }
  },
  computed: {
    total_surface() {
      let total = 0
      if (this.form11.surfaces && this.form11.surfaces.length) {
        this.form11.surfaces.forEach(surface => {
          total += +surface.surface
        })
      }

      return total
    },
    total_volume() {
      let total = 0
      if (this.form11.surfaces && this.form11.surfaces.length) {
        this.form11.surfaces.forEach(surface => {
          total += +surface.volume
        })
      }

      return total
    },
    total_radiateur() {
      let total = 0
      if (this.form11.surfaces && this.form11.surfaces.length) {
        this.form11.surfaces.forEach(surface => {
          total += +surface.nbrRadiateur
        })
      }

      return total
    }
  }
}
</script>

<style lang="scss" scoped>
.title {
  position: relative;
  .icon-info {
    position: absolute;
    top: -4px;
    right: -20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ff9000;
    width: 18px;
    height: 18px;
    border-radius: 50%;
  }
}

table {
  width: 100%;
  thead th,
  tbody td {
    border-bottom: thin solid rgba(0, 0, 0, 0.12);
    padding: 2px 5px;
    white-space: nowrap;
    color: #5e5e5e;
    text-align: center;
    position: relative;
    font-size: 12px;
    line-height: 1.5;
    overflow: hidden;
    height: 48px;
  }
}
.information {
  padding: 15px;
  h1 {
    font-size: 18px;
    font-weight: 600;
    color: #704ad1;
  }
  h2 {
    font-size: 12px;
    font-weight: 600;
    color: #704ad1;
  }
  h3 {
    font-size: 20px;
  }
  h4 {
    font-style: italic;
    font-size: 12px;
    font-weight: 600;
  }
  p {
    font-size: 12px;
  }
}
.bottom {
  display: flex !important;
  align-items: center;
  justify-content: center !important;
  gap: 16px;
}
</style>
