var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"activity-form"},[_c('v-form',[_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"label":_vm.$i18n.locale === 'fr' ? 'Nom Activité' : 'Name Activity',"dense":"","persistent-placeholder":true,"outlined":"","hide-details":"","color":"#5C2DD3","item-color":"#5C2DD3"},model:{value:(_vm.activity.name),callback:function ($$v) {_vm.$set(_vm.activity, "name", $$v)},expression:"activity.name"}})],1)],1),_c('v-row',{staticClass:"mb-2"},[_c('v-col',[_c('div',{staticClass:"activity-type"},[_c('div',{staticClass:"activity-types-tabs flex justify-between"},[_c('div',{staticClass:"activity-types-tab",class:{
                active: _vm.currentActivityType == 1
              }},[_c('font-awesome-icon',{staticClass:"mr-1 img-h-14",attrs:{"icon":"phone-alt"}}),_vm._v(" "+_vm._s(_vm.$i18n.locale === 'fr' ? 'Appel' : 'Call')+" ")],1),_c('div',{staticClass:"activity-types-tab",class:{
                active: _vm.currentActivityType == 2
              }},[_c('font-awesome-icon',{staticClass:"mr-1 img-h-14",attrs:{"icon":"briefcase"}}),_vm._v(_vm._s(_vm.$i18n.locale === 'fr' ? 'Pré-visite' : 'Pre-visit')+" ")],1),_c('div',{staticClass:"activity-types-tab",class:{
                active: _vm.currentActivityType == 3
              }},[_c('font-awesome-icon',{staticClass:"mr-1 img-h-14",attrs:{"icon":"truck"}}),_vm._v(" "+_vm._s(_vm.$i18n.locale === 'fr' ? 'Planification' : 'Planning')+" ")],1),_c('div',{staticClass:"activity-types-tab",class:{
                active: _vm.currentActivityType == 4
              }},[_c('font-awesome-icon',{staticClass:"mr-1 img-h-14",attrs:{"icon":"hourglass-start"}}),_vm._v(_vm._s(_vm.$i18n.locale === 'fr' ? 'Autre' : 'Other')+" ")],1)])])])],1),(_vm.currentActivityType === 3)?_c('v-row',[_c('v-col',[_c('div',{staticClass:"diponibility"},[_c('div',{staticClass:"flex-center"},[_c('div',{staticClass:"ml-2 font-sz-13 font-text"},[_vm._v(" "+_vm._s(_vm.$i18n.locale === 'fr' ? 'Suggestions des rendez-vous :' : 'Appointment suggestions:')+" ")]),(_vm.getProjectsSuggestionsProcessing)?_c('div',{staticClass:"loading"},[_vm._v(" "+_vm._s(_vm.$t('loading'))+" ")]):_vm._e()]),_c('div',[(_vm.planificationSuggestions)?[_c('div',{staticClass:"planning-description italic"},[_c('span',{staticClass:"planning-description--label bold"},[_vm._v(" "+_vm._s(_vm.$i18n.locale === 'fr' ? 'Estimation de durée de pose :' : 'Estimated installation time :')+" ")]),_c('span',{staticClass:"planning-description--value"},[_vm._v(" "+_vm._s(_vm._f("duration")(_vm.planificationSuggestions.pose_time_minutes))+" ")])]),_c('div',{staticClass:"suggestions-list mt-2 ml-2"},[(_vm.activity.type == 'Planning')?_c('label',{staticClass:"suggestion",class:{
                    active: _vm.selectedSuggestion == _vm.activity.date_start
                  },attrs:{"for":"radio-suggest-update-selected"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.activity.date_start),expression:"activity.date_start"}],attrs:{"type":"radio","name":"radio-suggest","id":"radio-suggest-update-selected"},domProps:{"value":_vm._f("date")(_vm.selectedSuggestion,'YYYY-MM-DD HH:mm:ss'),"checked":_vm._q(_vm.activity.date_start,_vm._f("date")(_vm.selectedSuggestion,'YYYY-MM-DD HH:mm:ss'))},on:{"change":function($event){_vm.$set(_vm.activity, "date_start", _vm._f("date")(_vm.selectedSuggestion,'YYYY-MM-DD HH:mm:ss'))}}}),_c('div',{staticClass:"suggestion-container"},[_c('div',{staticClass:"date-flag"},[_c('font-awesome-icon',{attrs:{"icon":"calendar-day"}})],1),_c('div',{staticClass:"date-details"},[_c('span',{staticClass:"capitalize"},[_vm._v(" "+_vm._s(_vm._f("date")(_vm.selectedSuggestion,'ddd DD MMM'))+" ")]),_vm._v(" à "+_vm._s(_vm._f("time")(_vm.selectedSuggestion,'HH:mm'))+" ")])])]):_vm._e()]),_vm._l((_vm.planificationSuggestions.weeks),function(week,week_index){return _c('Collapse',{key:'planification-suggestions-update-' + week.week_id,attrs:{"init":week_index == 0 ? true : false,"selected":_vm.collapseSuggestions},on:{"toggle":function($event){_vm.collapseSuggestions = $event}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(week_index == 0 ? `Semaine en cours (N°${week.week_nbr})` : week_index == 1 ? `Semaine prochaine (N°${week.week_nbr})` : `Semaine (N°${week.week_nbr})`)+" ")]},proxy:true},{key:"body",fn:function(){return [_c('div',{staticClass:"suggestions-list"},_vm._l((week.available_dates_hours_techs),function(date,index){return _c('label',{key:'available-dates-hours-techs-' + index,staticClass:"suggestion",class:{
                        active:
                          _vm.activity.date_start == `${date.day} ${date.hour}:00`
                      },attrs:{"for":`radio-suggest-update-${week_index}-${index}`}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.activity.date_start),expression:"activity.date_start"}],attrs:{"id":`radio-suggest-update-${week_index}-${index}`,"type":"radio","name":"radio-suggest"},domProps:{"value":`${date.day} ${date.hour}:00`,"checked":_vm._q(_vm.activity.date_start,`${date.day} ${date.hour}:00`)},on:{"change":function($event){return _vm.$set(_vm.activity, "date_start", `${date.day} ${date.hour}:00`)}}}),_c('div',{staticClass:"suggestion-container"},[_c('div',{staticClass:"date-flag"},[_c('font-awesome-icon',{attrs:{"icon":"calendar-day"}})],1),_c('div',{staticClass:"date-details"},[_c('span',{staticClass:"capitalize"},[_vm._v(" "+_vm._s(_vm._f("date")(date.day,'ddd DD MMM'))+" ")]),_vm._v(" à "+_vm._s(date.hour)+" ")])])])}),0)]},proxy:true}],null,true)})})]:_vm._e()],2)])])],1):_c('v-row',[_c('v-col',[_c('v-menu',{attrs:{"close-on-content-click":false,"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.activity.date_start,"label":"Date","outlined":"","readonly":"","clearable":"","dense":"","hide-details":"","persistent-placeholder":true,"placeholder":_vm.$t('msgSelectDate'),"prepend-inner-icon":"mdi-calendar","color":"#5C2DD3"},on:{"click:clear":function($event){return _vm.handleResetFilter(
                  'date_start',
                  'selectedDateTimeDate',
                  'selectedDateTimeTime'
                )}},model:{value:(_vm.activity.date_start),callback:function ($$v) {_vm.$set(_vm.activity, "date_start", $$v)},expression:"activity.date_start"}},'v-text-field',attrs,false),on))]}}])},[_c('v-card',[_c('v-card-text',[_c('v-date-picker',{attrs:{"value-type":"format","format":"YYYY-MM-DD HH:mm","no-title":"","value":_vm.selectedDateTimeDate,"locale":"fr","color":"#5C2DD3","item-color":"#5C2DD3"},model:{value:(_vm.selectedDateTimeDate),callback:function ($$v) {_vm.selectedDateTimeDate=$$v},expression:"selectedDateTimeDate"}}),_c('v-time-picker',{staticClass:"time-dynamique",attrs:{"value":_vm.selectedDateTimeTime,"color":"#5C2DD3","item-color":"#5C2DD3"},model:{value:(_vm.selectedDateTimeTime),callback:function ($$v) {_vm.selectedDateTimeTime=$$v},expression:"selectedDateTimeTime"}})],1)],1)],1)],1),(
          !_vm.activity.terminer &&
            (_vm.activity.type == 'Appel' || _vm.activity.type == 'Autre')
        )?_c('v-col',{staticClass:"activity-me-pevenir"},[_c('v-menu',{attrs:{"close-on-content-click":false,"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.activity.me_prevenir,"label":_vm.$i18n.locale === 'fr'
                  ? 'Me prévenir avant'
                  : 'Notify me before',"outlined":"","readonly":"","hide-details":"","dense":"","clearable":"","persistent-placeholder":true,"placeholder":_vm.$i18n.locale === 'fr' ? 'Sélectionner heure' : 'Select time',"prepend-inner-icon":"mdi-clock-time-four-outline","color":"#5C2DD3"},model:{value:(_vm.activity.me_prevenir),callback:function ($$v) {_vm.$set(_vm.activity, "me_prevenir", $$v)},expression:"activity.me_prevenir"}},'v-text-field',attrs,false),on))]}}],null,false,2519453532)},[_c('v-time-picker',{attrs:{"value":_vm.activity.me_prevenir,"color":"#5C2DD3","item-color":"#5C2DD3","format":"24hr"},model:{value:(_vm.activity.me_prevenir),callback:function ($$v) {_vm.$set(_vm.activity, "me_prevenir", $$v)},expression:"activity.me_prevenir"}})],1)],1):_vm._e(),_c('v-col',[(_vm.activity.type == 'Appel' || _vm.activity.type == 'Autre')?_c('v-checkbox',{staticClass:"input-checkbox msg-error mt-2 label-check",attrs:{"label":_vm.$i18n.locale === 'fr' ? 'Activité terminée' : 'Activity completed',"color":"#5C2DD3","hide-details":""},model:{value:(_vm.activity.terminer),callback:function ($$v) {_vm.$set(_vm.activity, "terminer", $$v)},expression:"activity.terminer"}}):_vm._e()],1)],1),_c('v-row',[_c('v-col',[_c('div',{staticClass:"ml-2 font-sz-13 font-text"},[_vm._v(" Description : ")]),_c('e-editor',{ref:"activityEditor",model:{value:(_vm.activity.description),callback:function ($$v) {_vm.$set(_vm.activity, "description", $$v)},expression:"activity.description"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-autocomplete',{staticClass:"mr-2",attrs:{"clearable":true,"color":"#5C2DD3","placeholder":_vm.$t('searchMsg', {
              msg: _vm.$t('utilisateurs')
            }),"item-color":"#5C2DD3","value":_vm.activity.user,"items":_vm.activityUsersList,"persistent-placeholder":true,"label":_vm.$i18n.locale === 'fr' ? 'Affectée à' : 'Assigned to',"return-object":"","item-text":"text","dense":"","item-value":"value","loading":_vm.getProjectsUsersNotDeletedLoading,"no-data-text":_vm.$t('noDataOptionMsg', {
              option: _vm.$t('utilisateurs')
            }),"outlined":"","menu-props":{
            bottom: true,
            offsetY: true
          }},model:{value:(_vm.activity.user),callback:function ($$v) {_vm.$set(_vm.activity, "user", $$v)},expression:"activity.user"}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }