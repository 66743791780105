<template>
  <div class="dynamic-input-wrapper">
    <!-- checkbox -->
    <div
      class="pl-2 block-check-border mb-3"
      v-if="
        input.type_options === 'checkbox' && input.type === 'multiple_select'
      "
    >
      <div class="label-champ">{{ input.label }}</div>
      <v-checkbox
        label="Tous"
        v-model="checkAll"
        @change="handlecheckAll()"
        color="#704ad1"
        :value="true"
        :true-value="true"
        :false-value="false"
      ></v-checkbox>
      <div v-for="(item, index) in options" :key="index" class="ml-3 pl-3">
        <v-checkbox
          class="input-checkbox"
          :label="item.label"
          v-model="item.checked"
          @change="itemToCheck(item)"
          color="#704ad1"
          :value="true"
          :true-value="true"
          :false-value="false"
        ></v-checkbox>
      </div>
    </div>
    <!--  SELECT ONE -->
    <div
      v-else-if="input.type_options === 'one_select' && input.type === 'radio'"
    >
      <v-autocomplete
        :disabled="input.disabledInput"
        :clearable="true"
        color="#5C2DD3"
        item-color="#5C2DD3"
        v-model="valueOptions"
        :value="valueOptions"
        @input="handleMultiselectSet"
        @click:clear="handleSelectSetsClear"
        @click="appelRouteDynamic"
        :items="options"
        :multiple="selectedOptions"
        :placeholder="input.placeholder || '-'"
        :persistent-placeholder="true"
        return-object
        :chips="selectedOptions ? true : false"
        :deletable-chips="selectedOptions ? true : false"
        :small-chips="selectedOptions ? true : false"
        item-text="label"
        :label="input.label"
        dense
        class="mb-1"
        item-value="id"
        :auto-focus="false"
        no-data-text="Aucun option trouvée"
        outlined
        :menu-props="{
          bottom: true,
          offsetY: true
        }"
      >
      </v-autocomplete>
    </div>
    <!-- input -->
    <v-text-field
      :disabled="input.disabledInput"
      v-else-if="input.type === 'string'"
      :label="input.label"
      :persistent-placeholder="true"
      dense
      outlined
      v-model="valueText"
      color="#5C2DD3"
      :class="input.class"
      :type="input.type"
      :name="input.name"
      :value="value"
      @input="handleInput"
      :placeholder="input.placeholder || '-'"
    >
    </v-text-field>
    <!-- text(textarea) -->
    <v-textarea
      :disabled="input.disabledInput"
      v-else-if="input.type === 'text'"
      :class="input.class"
      :placeholder="input.placeholder || '-'"
      :name="input.name"
      :value="value"
      @input="handleInput"
      outlined
      :label="input.label"
      v-model="valueText"
      :persistent-placeholder="true"
      rows="3"
      color="#5C2DD3"
    ></v-textarea>
    <!-- integer|double(input:number) -->
    <v-text-field
      :disabled="input.disabledInput"
      :placeholder="input.placeholder || '-'"
      v-else-if="
        input.type === 'integer' ||
          input.type === 'double' ||
          input.type === 'number'
      "
      :label="input.label"
      :persistent-placeholder="true"
      dense
      outlined
      color="#5C2DD3"
      :name="input.name"
      :min="input.min"
      :max="input.max"
      :step="input.step"
      :type="input.type"
      v-model="valueText"
      :value="value"
      @input="handleInput"
    >
    </v-text-field>
    <!-- radio OUI / NON -->
    <v-radio-group
      :label="input.label"
      v-model="valueOptions"
      row
      v-else-if="input.type === 'radio_oui_non'"
      class="radio-dynamique"
      :class="{ 'option-sup-3': options && options.length >= 3 }"
      hide-details
    >
      <v-radio
        :disabled="input.disabledInput"
        v-for="(n, index) in options"
        :key="index"
        :label="n.text ? n.text : 'vide'"
        :value="n.value"
        @click="handleRadio(n.value)"
        class="radio-btn"
        color="#5C2DD3"
      ></v-radio>
    </v-radio-group>
    <!-- radio -->
    <div
      class="pl-2 block-check-border mb-3"
      v-else-if="input.type === 'radio'"
    >
      <div class="label-champ">{{ input.label }}</div>
      <v-radio-group
        v-model="valueOptions"
        row
        class="radio-dynamique pt-3 pb-3"
        :class="{ 'option-sup-3': options && options.length >= 3 }"
        hide-details
      >
        <v-radio
          v-for="(n, index) in options"
          :key="index"
          :label="n.text ? n.text : 'vide'"
          :value="n.value"
          @click="handleRadio(n.value)"
          class="radio-btn"
          color="#5C2DD3"
          :disabled="input.disabledInput"
        ></v-radio>
      </v-radio-group>
    </div>
    <!-- multiple_list -->
    <div v-else-if="input.type === 'multiple_list'">
      <div class="flex align-center">
        <div
          class="flex"
          v-if="input.name == 'phones' && options && options.length"
        >
          <v-text-field
            :disabled="input.disabledInput"
            :label="input.label"
            :persistent-placeholder="true"
            dense
            outlined
            color="#5C2DD3"
            :class="input.class"
            :type="input.type"
            :name="input.name"
            :value="options[0].number"
            :placeholder="input.placeholder || '-'"
            @input="handleInputList($event, 'numberPhone', 'phones')"
          >
          </v-text-field>
        </div>
        <div
          class="flex"
          v-if="input.name == 'emails' && options && options.length"
        >
          <v-text-field
            :disabled="input.disabledInput"
            :label="input.label"
            :persistent-placeholder="true"
            dense
            outlined
            color="#5C2DD3"
            :class="input.class"
            :type="input.type"
            :name="input.name"
            :value="options[0].email"
            @input="handleInputList($event, 'email', 'emails')"
            :placeholder="input.placeholder || '-'"
          >
          </v-text-field>
        </div>
      </div>
    </div>

    <!-- enum(select) -->
    <v-autocomplete
      :disabled="input.disabledInput"
      :clearable="true"
      color="#5C2DD3"
      item-color="#5C2DD3"
      v-else-if="input.type === 'enum'"
      :value="computedEnumValue"
      @input="handleMultiselectEnum"
      :items="input.options || []"
      :multiple="false"
      :placeholder="input.placeholder || '-'"
      :persistent-placeholder="true"
      required
      return-object
      item-text="label"
      :label="input.label"
      dense
      class="mb-1"
      item-value="id"
      :auto-focus="false"
      no-data-text="Aucun option trouvée"
      outlined
      :menu-props="{
        bottom: true,
        offsetY: true
      }"
    >
    </v-autocomplete>

    <!-- set(multiselect) -->
    <v-autocomplete
      :disabled="input.disabledInput"
      :clearable="true"
      v-else-if="input.type === 'multiple_select'"
      color="#5C2DD3"
      item-color="#5C2DD3"
      v-model="valueOptions"
      :value="valueOptions"
      @input="handleMultiselectSet"
      @click:clear="handleSelectSetsClear"
      @click="appelRouteDynamic"
      :items="options"
      :multiple="selectedOptions"
      :placeholder="input.placeholder || '-'"
      :loading="getRouteDynamicProcessing"
      :persistent-placeholder="true"
      return-object
      :chips="selectedOptions ? true : false"
      :deletable-chips="selectedOptions ? true : false"
      :small-chips="selectedOptions ? true : false"
      item-text="label"
      :label="input.label"
      dense
      class="mb-1"
      item-value="id"
      :auto-focus="false"
      no-data-text="Aucun option trouvée"
      outlined
      :menu-props="{
        bottom: true,
        offsetY: true
      }"
    >
    </v-autocomplete>
    <!-- table -->
    <v-autocomplete
      :disabled="input.disabledInput"
      :clearable="true"
      color="#5C2DD3"
      item-color="#5C2DD3"
      v-else-if="input.type === 'table'"
      :value="computedTableValue"
      @input="handleMultiselectTable"
      :items="input.options || []"
      :multiple="false"
      :close-on-select="true"
      :clear-on-select="false"
      :show-labels="false"
      :placeholder="input.placeholder || '-'"
      :persistent-placeholder="true"
      required
      return-object
      item-text="label"
      :label="input.label"
      dense
      class="mb-1"
      item-value="id"
      :auto-focus="false"
      no-data-text="Aucun option trouvée"
      outlined
      :menu-props="{
        bottom: true,
        offsetY: true
      }"
    >
    </v-autocomplete>
    <!-- date -->
    <v-menu
      v-else-if="input.type === 'date'"
      :close-on-content-click="false"
      offset-y
      content-class="input-date-dynamique custom-menu-position"
      min-width="288px !important"
      max-width="288px !important"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="selectedDate"
          :value="selectedDate"
          :label="input.label"
          outlined
          readonly
          hide-details
          dense
          clearable
          v-on="on"
          v-bind="attrs"
          :persistent-placeholder="true"
          :placeholder="input.placeholder || '-'"
          @click:clear="handleDatePickerClear"
          prepend-inner-icon="mdi-calendar"
          color="#5C2DD3"
        ></v-text-field>
      </template>

      <v-date-picker
        v-model="selectedDate"
        @input="handleDatePicker"
        :value="selectedDate"
        no-title
        locale="fr"
        color="#5C2DD3"
        item-color="#5C2DD3"
        :disabled="input.disabledInput"
      >
      </v-date-picker>
    </v-menu>
    <!-- daterange -->
    <v-menu
      v-else-if="input.type === 'daterange'"
      :close-on-content-click="false"
      offset-y
      min-width="288px !important"
      content-class="input-date-dynamique custom-menu-position"
      max-width="288px !important"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="selectedDate"
          :value="selectedDate"
          :label="input.label"
          outlined
          readonly
          hide-details
          dense
          clearable
          v-on="on"
          v-bind="attrs"
          :persistent-placeholder="true"
          :placeholder="input.placeholder || '-'"
          prepend-inner-icon="mdi-calendar"
          @click:clear="handleDatePickerClear"
          color="#5C2DD3"
        ></v-text-field>
      </template>

      <v-date-picker
        v-model="selectedDate"
        @input="handleDatePickerRange"
        :value="selectedDate"
        no-title
        locale="fr"
        color="#5C2DD3"
        item-color="#5C2DD3"
        range
        :disabled="input.disabledInput"
      >
      </v-date-picker>
    </v-menu>
    <!-- time -->
    <v-menu
      v-else-if="input.type === 'time'"
      :close-on-content-click="true"
      offset-y
      class="input-date-dynamique"
      min-width="288px !important"
      max-width="288px !important"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="selectedTime"
          :value="selectedTime"
          :label="input.label"
          outlined
          readonly
          hide-details
          dense
          clearable
          v-on="on"
          v-bind="attrs"
          :persistent-placeholder="true"
          :placeholder="input.placeholder || '-'"
          prepend-inner-icon="mdi-clock-time-four-outline"
          color="#5C2DD3"
          @click="appelTimePicker"
          @click:clear="handleDatePickerClear"
        ></v-text-field>
      </template>

      <v-time-picker
        v-model="selectedTime"
        :value="selectedTime"
        @input="handleTimePicker"
        :disabled="input.disabledInput"
        color="#5C2DD3"
        item-color="#5C2DD3"
        format="24hr"
      ></v-time-picker>
    </v-menu>

    <!-- timerange -->
    <v-menu
      v-else-if="input.type === 'timerange'"
      :close-on-content-click="false"
      offset-y
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="selectedTimeRange"
          :value="selectedTime"
          :label="input.label"
          outlined
          readonly
          hide-details
          dense
          clearable
          v-on="on"
          v-bind="attrs"
          :persistent-placeholder="true"
          :placeholder="input.placeholder || '-'"
          prepend-inner-icon="mdi-clock-time-four-outline"
          color="#5C2DD3"
          @click="appelTimePickerRange"
          @click:clear="handleDatePickerClear"
        ></v-text-field>
      </template>

      <v-time-picker
        v-model="selectedTimeStart"
        :value="selectedTimeStart"
        @input="handleTimePickerRange"
        :disabled="input.disabledInput"
        color="#5C2DD3"
        item-color="#5C2DD3"
        format="24hr"
      ></v-time-picker>

      <v-time-picker
        v-model="selectedTimeEnd"
        :value="selectedTimeEnd"
        @input="handleTimePickerRange"
        :disabled="input.disabledInput"
        color="#5C2DD3"
        item-color="#5C2DD3"
        format="24hr"
      ></v-time-picker>
    </v-menu>
    <!-- datetime -->
    <v-menu
      v-else-if="input.type === 'datetime'"
      :close-on-content-click="false"
      offset-y
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="selectedDateTime"
          :value="selectedDateTime"
          :label="input.label"
          @click:clear="
            handleResetFilter(
              'selectedDateTime',
              'selectedDateTimeDate',
              'selectedDateTimeTime'
            )
          "
          outlined
          readonly
          hide-details
          dense
          clearable
          v-on="on"
          v-bind="attrs"
          :persistent-placeholder="true"
          :placeholder="input.placeholder || '-'"
          prepend-inner-icon="mdi-calendar"
          color="#5C2DD3"
        ></v-text-field>
      </template>
      <v-card>
        <v-card-text>
          <v-date-picker
            value-type="format"
            format="YYYY-MM-DD HH:mm"
            :value="selectedDateTimeDate"
            no-title
            v-model="selectedDateTimeDate"
            :class="input.class"
            locale="fr"
            :disabled="input.disabledInput"
            @input="handleDateTimePicker"
            color="#5C2DD3"
            item-color="#5C2DD3"
          ></v-date-picker>
          <v-time-picker
            class="time-dynamique"
            v-model="selectedDateTimeTime"
            :value="selectedDateTimeTime"
            @input="handleDateTimePicker"
            color="#5C2DD3"
            item-color="#5C2DD3"
            format="24hr"
          ></v-time-picker>
        </v-card-text>
      </v-card>
    </v-menu>
    <!-- subventions / object_table -->
    <!-- <div
      class="subventions"
      v-else-if="input.type === 'subventions' || input.type === 'object_table'"
    >
      <div class="bold">LOGIN</div>
      <input
        type="text"
        :value="value.login"
        @input="handleInputStatus_login"
        :disabled="input.disabledInput"
      />
      <div class="bold">MOT DE PASSE</div>
      <input
        type="password"
        :value="value.mdp"
        @input="handleInputStatus_mdp"
        :disabled="input.disabledInput"
      />
      <div class="bold">STATUT</div>
      <select
        class="selectSubventions"
        :value="value.status"
        @input="handleInputStatus_status"
      >
        <option
          :disabled="input.disabledInput"
          v-for="statut in optionsStatus"
          :key="statut.value"
          :value="statut.label"
          >{{ statut.label }}</option
        ></select
      >
      <div class="bold">MONTANT DISPONIBLE</div>
      <input
        type="number"
        :value="value.montant_disponible"
        @input="handleInputStatus_montant"
        :disabled="input.disabledInput"
      />
    </div> -->
    <div v-else>
      <v-text-field
        :disabled="input.disabledInput"
        :label="input.label"
        :persistent-placeholder="true"
        v-model="value"
        dense
        outlined
        color="#5C2DD3"
        :class="input.class"
        :type="input.type"
        :name="input.name"
        :value="value"
        @input="handleInput"
        :placeholder="input.placeholder || '-'"
      >
      </v-text-field>
    </div>
    <!-- end input type if -->
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import * as moment from 'moment'
moment.locale('fr')
/**
 * @Module Dynamic Input - all supported inputs
 * @param {Object} input - an input configuration
 * @param {String} input.type(Required) - The type of input supported inputtypes(text,email,password,hidden,tel,date,number,select,textarea)
 * @param {String} input.name(Required) - The name of the input field
 * @param {any} input.value(Optional) - The value of the input field
 * @param {String} input.label(Optional) - The label of the input field
 * @param {String} input.class(Optional) - The Css class(es) of the input field
 *
 * **Input specific parameters**
 * @param input.min - minimum value of the input field(date,number)
 * @param input.max - maximum value of the input field(date,number)
 * @param {Number} input.step - increments of the value on increase & decrease(number)
 * @param {Array} options - al the options in a selectbox
 */
export default {
  name: 'dynamic-input',
  components: {},
  props: {
    error: {
      required: false
    },
    input: {
      type: Object,
      required: true
    },
    value: {
      required: false
    },
    doubleValue: { required: false },
    formatDate: {
      required: false
    },
    classInput: {
      required: false
    },
    objectProject: { required: false },
    indexInput: { required: false }
  },
  data() {
    return {
      test: '',
      login: this.value ? this.value.login : '',
      mdp: this.value ? this.value.mdp : '',
      montant_disponible: this.value ? this.value.montant_disponible : '',
      status: this.value ? this.value.status : '',
      optionsStatus: [
        { label: '', value: null },
        { label: 'Eligible', value: 'Eligible' },
        { label: 'Non éligible', value: 'Non éligible' }
      ],
      phoneTypes: ['Travail', 'Domicile', 'Mobile', 'Autre'],
      emailTypes: ['Personnel', 'Professionnel', 'Autre'],
      options: [],
      selectedOptions: false,
      valueOptions: null,
      selectedDate: null,
      selectedTime: null,
      selectedDateTimeDate: null,
      selectedDateTimeTime: null,
      selectedDateTime: null,
      valueText: null,
      valueSelect: null,
      valueDynamic: this.value,
      selectedTimeRange: null,
      selectedTimeStart: null,
      selectedTimeEnd: null,
      checkAll: false,
      tableItemCheck: []
    }
  },
  methods: {
    ...mapActions(['fetchProjectRouteDynamic']),
    handlecheckAll() {
      this.options.map(item => {
        item.checked = this.checkAll
        this.itemToCheck(item)
      })
    },
    itemToCheck(item) {
      if (item.checked == true) {
        this.tableItemCheck.push(item)
      } else {
        this.tableItemCheck = this.tableItemCheck.filter(
          item => item.checked == true
        )
      }
      //  RFRESH SELECTED ALL
      this.$nextTick(() => {
        if (this.tableItemCheck && this.tableItemCheck.length) {
          this.checkAll = true
        } else {
          this.checkAll = false
        }
        this.handleMultiselectSet(this.tableItemCheck)
      })
    },
    formatDateInput(date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      if (this.input.formatDate == true) {
        return `${day}/${month}/${year}`
      } else {
        return `${year}/${month}/${day}`
      }
    },
    parseDate(date) {
      if (!date) return null
      const [month, day, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    ajouterInputList() {
      if (this.input.name === 'emails') {
        this.options.push({ email: '', typeE: 'Personnel' })
      } else {
        this.options.push({ number: '', typeP: 'Travail' })
      }
    },
    removeInputList(index) {
      this.options.splice(index, 1)
    },
    handleInput(e) {
      this.$emit('save', e)
      this.$emit('disabled')
    },
    handleInputStatus_login(e) {
      this.login = e.target.value
      this.handleInputvalider()
    },
    handleInputStatus_montant(e) {
      this.montant_disponible = e.target.value
      this.handleInputvalider()
    },
    handleInputStatus_mdp(e) {
      this.mdp = e.target.value
      this.handleInputvalider()
    },
    handleInputStatus_status(e) {
      this.status = e.target.value
      this.handleInputvalider()
    },
    handleInputvalider() {
      const payload = {
        login: this.login,
        mdp: this.mdp,
        montant_disponible: this.montant_disponible,
        status: this.status
      }
      if (
        this.login != this.value.login ||
        this.status != this.value.status ||
        this.mdp != this.value.mdp ||
        this.montant_disponible != this.value.montant_disponible
      ) {
        this.$emit('input', payload)
      }
    },
    appelTimePicker() {
      const now = new Date()
      const hours = now.getHours()
      const minutes = now.getMinutes()
      // const seconds = now.getSeconds()
      // :${seconds.toString().padStart(2, '0')
      this.selectedTime =
        this.selectedTime == null
          ? `${hours.toString().padStart(2, '0')}:${minutes
              .toString()
              .padStart(2, '0')}`
          : this.selectedTime
      this.$emit('disabled')
    },
    appelTimePickerRange() {},
    handleTimePickerRange() {
      this.selectedTimeRange = [this.selectedTimeStart, this.selectedTimeEnd]
      if (this.selectedTimeStart && this.selectedTimeEnd) {
        this.$emit('save', this.selectedTimeRange)
        this.$emit('disabled')
      }
    },
    handleTimePicker(time) {
      this.selectedTime = time
      this.$emit('disabled')
    },
    handleResetFilter(ref_1, ref_2, ref_3) {
      if (ref_1) {
        this[ref_1] = null
      }
      if (ref_2) {
        this[ref_2] = null
      }
      if (ref_3) {
        this[ref_3] = null
      }
    },
    handleDateTimePicker() {
      this.$emit('disabled')
      // this.$emit('save', this.selectedDateTime)
    },
    handleDatePickerRange(data) {
      this.$emit('save', data.join(', '))
      this.$emit('disabled')
    },
    handleDatePicker(data) {
      this.$emit('save', moment(data, 'YYYY-MM-DD').format('DD-MM-YYYY'))
      this.$emit('disabled')
    },
    handleDatePickerClear() {
      this.$emit('save', ' ')
      this.$emit('disabled')
    },
    handleInputList(data, key, name) {
      let tabValue = [...this.options]
      for (let index = 0; index < tabValue.length; index++) {
        if (name === 'phones') {
          if (key == 'numberPhone') {
            tabValue[index].number = data
              .replace(/[^0-9]/g, '')
              .replace(/(\..*)\./g, '$1')
          } else {
            tabValue[index].typeP = 'Travail'
          }
          this.$emit('save', tabValue)
          this.$emit('disabled')
          tabValue = []
        }
        if (name === 'emails') {
          if (key == 'email') {
            tabValue[index].email = data
          } else {
            tabValue[index].typeE = 'Personnel'
          }
          this.$emit('save', tabValue)
          this.$emit('disabled')
          tabValue = []
        }
      }
    },
    handleMultiselectEnum(data) {
      if (data) {
        this.$emit('input', data.label)
      }
    },
    handleMultiselectTable(data) {
      this.$emit('input', data.id)
    },
    handleRadio(data) {
      this.$emit('save', data)
      this.$emit('disabled')
    },
    async appelRouteDynamic() {
      if (this.input.links && this.input.links.length) {
        for (let index = 0; index < this.input.links.length; index++) {
          await this.fetchProjectRouteDynamic(this.input.links[index].value)
        }
        if (this.getProjectRouteDynamic && this.getProjectRouteDynamic.length) {
          this.options = this.getProjectRouteDynamic.map(t => {
            if (t.full_name) {
              return { label: t.full_name, id: t.id }
            } else {
              return { label: t.name, id: t.id }
            }
          })
          if (this.value) {
            this.valueOptions = this.options.find(e => {
              return e.id == this.value.id
            })
          }
        }
      } else if (this.input.valuePossible && this.input.valuePossible.length) {
        this.options = this.input.valuePossible.map(t => {
          return {
            label: t.name,
            id: t.id
          }
        })
        this.valueOptions = this.value
      }
    },
    handleSelectSetsClear() {
      this.valueOptions = null
      this.$emit('save', this.valueOptions)
      this.$emit('disabled')
    },
    handleMultiselectSet(data) {
      this.valueOptions = data
      let dataMultipleSelect = null
      if (data && data.length && Array.isArray(data)) {
        dataMultipleSelect = data.map(item =>
          item && item.id ? item.id : item && item.value ? item.value : item
        )
      } else {
        dataMultipleSelect =
          data && data.id ? data.id : data && data.value ? data.value : data
      }
      this.$emit('save', dataMultipleSelect)
      this.$emit('disabled')
    },
    updateDateTime() {
      // Combine selectedDate and selectedTime into dateTime
      if (this.selectedDateTimeDate && this.selectedDateTimeTime) {
        this.selectedDateTime = `${this.selectedDateTimeDate} ${this.selectedDateTimeTime}`
      } else if (this.selectedDateTimeDate && !this.selectedDateTimeTime) {
        this.selectedDateTime = `${this.selectedDateTimeDate} 00:00`
      } else if (!this.selectedDateTimeDate && this.selectedDateTimeTime) {
        this.selectedDateTimeDate = new Date().toJSON().slice(0, 10)
        this.selectedDateTime = `${this.selectedDateTimeDate} ${this.selectedDateTimeTime}`
      }
      this.$emit('save', this.selectedDateTime)
    }
  },
  computed: {
    ...mapGetters([
      'getProjectRouteDynamic',
      'getRouteDynamicProcessing',
      'getProjectRouteDynamicProjectTH',
      'getRouteDynamicProcessingProjectTH'
    ]),
    computedStyle() {
      if (this.input.indexInput == '2') {
        return {
          width: '50%',
          'text-align': 'left',
          'margin-left': '5px',
          'margin-right': '5px'
        }
      } else if (this.input.indexInput == '1') {
        return {
          width: '50%',
          'text-align': 'right',
          'margin-left': '5px',
          'margin-right': '5px'
        }
      } else {
        return { width: '100%', 'margin-left': '5px', 'margin-right': '5px' }
      }
    },
    computedEnumValue: function() {
      let value = null
      this.input.options.forEach(option => {
        if (option.label == this.value) {
          value = option
        }
      })
      return value
    },
    computedTableValue: function() {
      let value = null
      this.input.options.forEach(option => {
        if (option.id == this.value) {
          value = option
        }
      })
      return value
    },
    computedSetValue: function() {
      const value = []
      if (!this.value || !Array.isArray(this.value)) {
        return value
      }
      this.input.options.forEach(option => {
        if (this.value.find(v => v == option.label)) {
          value.push(option)
        }
      })
      return value
    }
  },
  watch: {
    selectedDateTimeDate() {
      this.updateDateTime()
    },
    selectedDateTimeTime() {
      this.updateDateTime()
    },
    selectedTime() {
      this.$emit('save', this.selectedTime)
    },
    value(val) {
      this.valueOptions = val
    }
  },
  created() {
    this.updateDateTime()
  },
  filters: {
    time: function(value) {
      if (!value) return ''
      return moment(value, 'YYYY-MM-DD HH:mm:ss').format('LT DD MMM')
    }
  },
  async mounted() {
    // Project RENO Global + CPF
    if (this.input.name === 'phones' || this.input.name === 'emails') {
      if (!this.value || !this.value.length) {
        if (this.input.name === 'phones') {
          this.options = [{ number: '', typeP: 'Travail' }]
        } else {
          this.options = [{ email: '', typeE: 'Personnel' }]
        }
      }
      if (this.value && this.value.length) {
        this.options = this.value
      }
    }
    // type input select / radio / checked
    if (this.input.selected) {
      if (this.input.selected == 'one') {
        this.selectedOptions = false
      } else {
        this.selectedOptions = true
      }
    }
    // type input select / radio / checked
    if (this.input.links && this.input.links.length) {
      for (let index = 0; index < this.input.links.length; index++) {
        await this.fetchProjectRouteDynamic(this.input.links[index].value)
      }
      if (this.getProjectRouteDynamic && this.getProjectRouteDynamic.length) {
        this.options = this.getProjectRouteDynamic.map(t => {
          if (t.full_name) {
            return { label: t.full_name, id: t.id }
          } else {
            return { label: t.name, id: t.id }
          }
        })
        if (this.value) {
          this.valueOptions = this.options.find(e => {
            return e.id == this.value.id
          })
        }
      }
    }
    if (
      (this.input.type === 'radio_oui_non' || this.input.type === 'radio') &&
      this.input.valuePossible &&
      this.input.valuePossible.length
    ) {
      if (this.input.type_options == 'one_select') {
        this.options = this.input.valuePossible.map(t => {
          return {
            label: t.name,
            id: t.id
          }
        })
      } else {
        this.options = this.input.valuePossible.map(t => {
          return {
            text: t.name,
            value: t.id
          }
        })
      }
      this.valueOptions = this.value
    }
    if (this.input.type === 'radio_oui_non') {
      this.options = this.input.options.map(t => {
        return {
          text: t.name,
          value: t.id
        }
      })
      this.valueOptions = this.value == 1 || this.value == 'Oui' ? 1 : 0
    }
    if (this.input.type == 'multiple_select') {
      if (this.input.valuePossible && this.input.valuePossible.length) {
        // checkbox
        if (this.input.type_options == 'checkbox') {
          // Mapper les options avec la vérification du statut de chaque option
          this.options = this.input.valuePossible.map(option => ({
            label: option.name,
            id: option.id,
            checked: this.value && this.value.includes(option.id)
          }))
          // Remplir le tableItemCheck
          this.tableItemCheck = this.options.filter(
            item => item.checked == true
          )
          // Tester si au moins une option est cochée
          this.checkAll =
            this.value &&
            this.value.length &&
            this.input.valuePossible.some(option =>
              this.value.includes(option.id)
            )
        }
        // input select
        else {
          this.options = this.input.valuePossible.map(option => {
            return {
              label: option.name,
              id: option.id
            }
          })
          if (this.input.selected == 'multiple') {
            //multilpe
            let arrayOfValues = []
            this.valueOptions = this.options.filter(t => {
              if (this.value && this.value.length) {
                this.value.map(item => {
                  if (item == t.label) {
                    arrayOfValues.push({
                      label: item,
                      id: item
                    })
                  }
                })
              }
            })
            this.valueOptions = arrayOfValues
          } else {
            //one
            this.valueOptions = this.options.find(t => {
              return t.label == this.value
            })
          }
        }
      }
    }
    if (this.input.type == 'date' && this.value) {
      this.selectedDate = moment(this.value.split(' ')[0], 'DD-MM-YYYY').format(
        'YYYY-MM-DD'
      )
    }
    if (this.input.type == 'daterange' && this.value) {
      this.selectedDate = this.value.split(',').map(date => date.trim())
    }
    if (this.input.type == 'datetime' && this.value) {
      this.selectedDateTime = `${this.value}`
      this.selectedDateTimeTime = `${this.value.split(' ')[1]}`
      this.selectedDateTimeDate = `${this.value.split(' ')[0]}`
    }
    if (this.input.type == 'time' && this.value) {
      this.selectedTime = this.value
    }
    if (
      this.input.type == 'text' ||
      this.input.type == 'integer' ||
      this.input.type == 'double' ||
      this.input.type == 'number' ||
      this.input.type == 'string'
    ) {
      this.valueText = this.value
    }
  }
}
</script>
