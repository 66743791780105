<template>
  <div class="cadre-content">
    <v-card elevation="0"> 
      <v-tabs v-model="active" color="#5C2DD3" @change="changeTabReleve">
        <v-tab :value="0">
          {{ $i18n.locale === 'fr' ? 'Nouveau releve' : 'New statement' }}
        </v-tab>
        <v-tab :value="1">
          {{ $i18n.locale === 'fr' ? 'Téléchargement' : 'Download' }}</v-tab
        >
      </v-tabs>

      <v-card-text>
        <v-window v-model="active">
          <v-window-item :value="0">
            <v-col cols="12" class="progress-sticky">
              <v-progress-linear
                class="custom-progress"
                :value="(progress.value / progress.max) * 100"
                :height="10"
                striped
                color="#5c2dd3"
              ></v-progress-linear>

              <step-1-vue
                ref="step1"
                v-show="progress.value == 1"
                @nextStep="nextStep"
              ></step-1-vue>
              <step-2-vue
                ref="step2"
                v-show="progress.value == 2"
                @nextStep="nextStep"
                @prevStep="prevStep"
              ></step-2-vue>
              <step-3-vue
                ref="step3"
                v-show="progress.value == 3"
                @nextStep="nextStep"
                @prevStep="prevStep"
              ></step-3-vue>
              <step-4-vue
                ref="step4"
                v-show="progress.value == 4"
                @nextStep="nextStep"
                @prevStep="prevStep"
                :facade_nord="formulaire.step3"
              ></step-4-vue>
              <step-5-vue
                ref="step5"
                v-show="progress.value == 5"
                @nextStep="nextStep"
                @prevStep="prevStep"
              ></step-5-vue>
              <step-6-vue
                ref="step6"
                v-show="progress.value == 6"
                @nextStep="nextStep"
                @prevStep="prevStep"
                :facade_sud="formulaire.step5"
              ></step-6-vue>
              <step-7-vue
                ref="step7"
                v-show="progress.value == 7"
                @nextStep="nextStep"
                @prevStep="prevStep"
              ></step-7-vue>
              <step-8-vue
                ref="step8"
                v-show="progress.value == 8"
                @nextStep="nextStep"
                @prevStep="prevStep"
                :facade_est="formulaire.step7"
              ></step-8-vue>
              <step-9-vue
                ref="step9"
                v-show="progress.value == 9"
                @nextStep="nextStep"
                @prevStep="prevStep"
              ></step-9-vue>
              <step-10-vue
                ref="step10"
                v-show="progress.value == 10"
                @nextStep="nextStep"
                @prevStep="prevStep"
                :facade_ouest="formulaire.step9"
              ></step-10-vue>
              <step-11-vue
                ref="step11"
                v-show="progress.value == 11"
                @nextStep="nextStep"
                @prevStep="prevStep"
              ></step-11-vue>
              <step-12-vue
                ref="step12"
                v-show="progress.value == 12"
                @nextStep="nextStep"
                @prevStep="prevStep"
              ></step-12-vue>
              <step-13-vue
                ref="step13"
                v-show="progress.value == 13"
                @nextStep="nextStep"
                @prevStep="prevStep"
              ></step-13-vue>
              <step-14-vue
                ref="step14"
                v-show="progress.value == 14"
                @nextStep="nextStep"
                @prevStep="prevStep"
                :surface_comble="formulaire.step13?.surface_total"
                :hauteur_comble="formulaire.step13?.hauteur_total"
              ></step-14-vue>
              <step-15-vue
                ref="step15"
                v-show="progress.value == 15"
                @nextStep="nextStep"
                @prevStep="prevStep"
              ></step-15-vue>
              <step-16-vue
                ref="step16"
                v-show="progress.value == 16"
                @nextStep="nextStep"
                @prevStep="prevStep"
              ></step-16-vue>
              <step-17-vue
                ref="step17"
                v-show="progress.value == 17"
                @nextStep="nextStep"
                @prevStep="prevStep"
              ></step-17-vue>
              <step-18-vue
                ref="step18"
                v-show="progress.value == 18"
                @nextStep="nextStep"
                @prevStep="prevStep"
              ></step-18-vue>
              <step-19-vue
                ref="step19"
                v-show="progress.value == 19"
                @nextStep="nextStep"
                @prevStep="prevStep"
              ></step-19-vue>
              <step-20-vue
                ref="step20"
                v-show="progress.value == 20"
                @nextStep="nextStep"
                @prevStep="prevStep"
              ></step-20-vue>
              <step-21-vue
                ref="step21"
                v-show="progress.value == 21"
                @handleStore="nextStep"
                @prevStep="prevStep"
              ></step-21-vue>
              <div v-if="loading" class="loading">
                {{ $t('loading') }}
              </div>
            </v-col>
          </v-window-item>
          <v-window-item :value="1">
            <v-col cols="12">
              <table>
                <thead>
                  <tr>
                    <th>
                      {{
                        $i18n.locale === 'fr'
                          ? 'Numéro du fichier'
                          : 'File number'
                      }}
                    </th>
                    <th>
                      {{
                        $i18n.locale === 'fr'
                          ? 'Date de création'
                          : 'Creation date'
                      }}
                    </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(file, i) in getAllFiles"
                    :key="i"
                    :class="{ empty: !file.file_path }"
                  >
                    <td>{{ file.id }}</td>
                    <td>{{ $options.filters.dateTime(file.created_at) }}</td>
                    <td>
                      <v-btn
                        icon
                        small
                        fab
                        @click="handleDownload(file)"
                        :title="$t('telecharger')"
                      >
                        <font-awesome-icon icon="download" />
                      </v-btn>
                      <v-btn
                        icon
                        small
                        fab
                        @click="openDeleteModal(file)"
                        :title="$t('deleted')"
                      >
                        <font-awesome-icon icon="trash" />
                      </v-btn>
                    </td>
                  </tr>
                  <tr v-if="!getAllFiles.length">
                    <td colspan="2">
                      {{
                        $i18n.locale === 'fr'
                          ? 'Aucun fichier disponible'
                          : 'No files available'
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </v-col>
          </v-window-item>
        </v-window>
      </v-card-text>
      <div class="form-steps-error">{{ getPdfError }}</div>
    </v-card>

    <!-- Delete User Modal -->
    <v-dialog v-model="deleteModal" max-width="500" persistent scrollable>
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">
            {{ $i18n.locale === 'fr' ? 'Supprimer PDF' : 'Delete PDF' }}
          </v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click="closeDeleteModal()"
            :title="$t('close')"
            color="#5C2DD3"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>

        <v-card-text class="body-card center-text  mt-2">
          <p>
            {{
              $t('msgdeleted', {
                msg: $i18n.locale === 'fr' ? ' le PDF' : 'the PDF'
              })
            }}
            <strong class="name">
              {{ itemToDelete ? itemToDelete.id : '' }}</strong
            >
            ?
          </p>
        </v-card-text>

        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <div class="message">
            <div v-if="loading" class="loading">
              {{ $t('loading') }}
            </div>
            <div v-if="getPdfError" class="error-msg">
              <ul v-if="Array.isArray(getPdfError)">
                <li v-for="(e, index) in getPdfError" :key="index">
                  {{ e }}
                </li>
              </ul>
              <span v-else>{{ getPdfError }}</span>
            </div>
          </div>

          <v-spacer></v-spacer>
          <v-btn
            color="#5C2DD3"
            dark
            @click.prevent.stop="handleDelete"
            :loading="loading"
            small
          >
            {{ $t('btnValider') }}
          </v-btn>
          <v-btn text @click="closeDeleteModal()" small>{{
            $t('btnCancel')
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- END Delete User Modal -->
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import step1Vue from './Step/step1.vue'
import step2Vue from './Step/step2.vue'
import step3Vue from './Step/step3.vue'
import step4Vue from './Step/step4.vue'
import step5Vue from './Step/step5.vue'
import step6Vue from './Step/step6.vue'
import step7Vue from './Step/step7.vue'
import step8Vue from './Step/step8.vue'
import step9Vue from './Step/step9.vue'
import step10Vue from './Step/step10.vue'
import step11Vue from './Step/step11.vue'
import step12Vue from './Step/step12.vue'
import step13Vue from './Step/step13.vue'
import step14Vue from './Step/step14.vue'
import step15Vue from './Step/step15.vue'
import step16Vue from './Step/step16.vue'
import step17Vue from './Step/step17.vue'
import step18Vue from './Step/step18.vue'
import step19Vue from './Step/step19.vue'
import step20Vue from './Step/step20.vue'
import step21Vue from './Step/step21.vue'
import domain from '@/environment'
import moment from 'moment'
import momentDurationFormatSetup from 'moment-duration-format'
momentDurationFormatSetup(moment)
moment.locale('fr')
export default {
  components: {
    step1Vue,
    step2Vue,
    step3Vue,
    step4Vue,
    step5Vue,
    step6Vue,
    step7Vue,
    step8Vue,
    step9Vue,
    step10Vue,
    step11Vue,
    step12Vue,
    step13Vue,
    step14Vue,
    step15Vue,
    step16Vue,
    step17Vue,
    step18Vue,
    step19Vue,
    step20Vue,
    step21Vue
  },
  data() {
    return {
      active: 0,
      progress: {
        value: 1,
        max: 21
      },
      currentPDF: null,
      formulaire: {},
      error: null,
      itemToDelete: null,
      deleteModal: false,
      loading: false
    }
  },
  methods: {
    ...mapActions(['storePDF', 'all_PDF', 'delete_PDF', 'resetErrorPDF']),
    changeTabReleve() {
      this.resetErrorPDF()
    },
    nextStep(form) {
      // send data to api
      this.loading = true
      let generatePDF = false

      if (this.progress.value == 1) this.formulaire.step1 = form
      if (this.progress.value == 2) this.formulaire.step2 = form
      if (this.progress.value == 3) this.formulaire.step3 = form
      if (this.progress.value == 4) this.formulaire.step4 = form
      if (this.progress.value == 5) this.formulaire.step5 = form
      if (this.progress.value == 6) this.formulaire.step6 = form
      if (this.progress.value == 7) this.formulaire.step7 = form
      if (this.progress.value == 8) this.formulaire.step8 = form
      if (this.progress.value == 9) this.formulaire.step9 = form
      if (this.progress.value == 10) this.formulaire.step10 = form
      if (this.progress.value == 11) this.formulaire.step11 = form
      if (this.progress.value == 12) this.formulaire.step12 = form
      if (this.progress.value == 13) this.formulaire.step13 = form
      if (this.progress.value == 14) this.formulaire.step14 = form
      if (this.progress.value == 15) this.formulaire.step15 = form
      if (this.progress.value == 16) this.formulaire.step16 = form
      if (this.progress.value == 17) this.formulaire.step17 = form
      if (this.progress.value == 18) this.formulaire.step18 = form
      if (this.progress.value == 19) this.formulaire.step19 = form
      if (this.progress.value == 20) this.formulaire.step20 = form
      if (this.progress.value == 21) {
        this.formulaire.step21 = form
        generatePDF = true
      }

      const formData = new FormData()
      this.objectToFormData(formData, { [`step${this.progress.value}`]: form })

      formData.append('id_project', this.$route.params.id)
      if (generatePDF) {
        formData.append('generate', 1)
      }
      if (this.currentPDF) {
        formData.append('pdf_id', this.currentPDF)
      }
      this.error = null
      this.storePDF(formData)
        .then(res => {
          if (res) {
            if (this.progress.value === 21) {
              this.active = 1
              this.progress.value = 1
              this.formulaire = {}
              this.currentPDF = null
              this.resetSteps()
            } else {
              this.progress.value++
              this.currentPDF = res.id
            }
            this.resetErrorPDF()
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
    prevStep() {
      this.progress.value--
    },
    resetSteps() {
      // Accéder aux références des composants enfants et appeler une méthode de réinitialisation
      for (let step = 1; step <= 21; step++) {
        const stepRef = this.$refs[`step${step}`]
        if (stepRef && stepRef.resetComponent) {
          stepRef.resetComponent()
        }
      }
    },
    // Fonction pour convertir l'objet JSON en FormData
    objectToFormData(formData, object, parentKey = '') {
      for (let key in object) {
        if (Object.prototype.hasOwnProperty.call(object, key)) {
          let formKey = parentKey ? `${parentKey}[${key}]` : key
          let value = object[key]
          if (value instanceof File && value) {
            // If the value is a file, append it to FormData
            formData.append(formKey, value)
          } else if (typeof value === 'object' && value !== null) {
            // If the value is an object, recursively convert the object to FormData
            this.objectToFormData(formData, value, formKey)
          } else {
            // Otherwise, append the key-value pair to FormData
            if (this.progress.value == 14) {
              if (value != null) {
                formData.append(formKey, value)
              } else {
                formData.append(formKey, '')
              }
            } else {
              if (value) {
                formData.append(formKey, value)
              } else {
                formData.append(formKey, '')
              }
            }
          }
        }
      }
    },
    handleDownload(file) {
      if (file.file_path) {
        const link = document.createElement('a')
        link.href = domain + '/' + file.file_path
        link.setAttribute('download', 'releve_' + file.id + '.pdf')
        link.setAttribute('target', '_blank')
        document.body.appendChild(link)
        link.click()
        // Remove the dynamically created link from the DOM after the click event
        document.body.removeChild(link)
      }
    },
    async handleDelete() {
      this.loading = true
      if (this.itemToDelete) {
        const res = await this.delete_PDF(this.itemToDelete.id)
        if (res) {
          this.closeDeleteModal()
        }
      }
      this.loading = false
    },
    openDeleteModal(item) {
      this.itemToDelete = item
      this.deleteModal = true
    },
    closeDeleteModal() {
      this.itemToDelete = null
      this.deleteModal = false
    }
  },
  watch: {
    active(newValue) {
      if (newValue == 1) {
        this.all_PDF(this.$route.params.id)
      }
    }
  },
  filters: {
    dateTime: function(value, format = 'DD/MM/YYYY HH:mm:ss') {
      if (!value) return ''
      return moment(value).format(format)
    }
  },
  computed: {
    ...mapGetters(['getProjectsContact', 'getAllFiles', 'getPdfError'])
  },
  async mounted() {
    const res = await this.all_PDF(this.$route.params.id)
    if (!res || !res.length) {
      this.active = 0
    }
  }
}
</script>

<style lang="scss">
.cadre-content {
  .progress-sticky {
    line-height: 1.235;
    text-transform: initial;
    margin: 0px 0px 0px 0px;
    position: sticky;
    top: 0rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 15px;
    background-color: #ffffff;
    z-index: 2;
  }
}

.progress-bar {
  background-color: #704ad1;
}

.form-steps-error {
  text-align: center;
  font-size: 12px;
  color: red;
  font-weight: 700;
  font-family: 'Montserrat', sans-serif;
  padding-bottom: 10px;
}

table {
  width: 100%;

  thead th,
  tbody td {
    border-bottom: thin solid rgba(0, 0, 0, 0.12);
    padding: 2px 5px;
    white-space: nowrap;
    color: #5e5e5e;
    text-align: center;
    position: relative;
    font-size: 12px;
    line-height: 1.5;
    overflow: hidden;
    height: 48px;
  }

  tbody {
    tr {
      &.empty {
        td {
          color: red;
        }
      }
    }
  }
}
</style>
