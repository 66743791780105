<template>
  <div class="page-content-history">
    <div class="description font-text font-sz-12">
      <span class="bold"> {{ entity.name }} :</span>
      <template v-if="entity.previous || entity.current">
        {{ entity.previous }} →
        {{ entity.current }}
      </template>
    </div>
    <div class="informations capitalize">
      <span>
        {{ entity.date | date('DD MMM') }}
        {{ entity.date | time('HH:mm') }}
      </span>
      -
      <span v-if="entity.from_api_pixel_externe">
        {{ entity.from_api_pixel_externe }}
      </span>
      <span v-else-if="entity.user">
        {{ entity.user.full_name }}
      </span>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import momentDurationFormatSetup from 'moment-duration-format'
momentDurationFormatSetup(moment)
moment.locale('fr')
export default {
  props: {
    entity: { required: true }
  },
  filters: {
    date: function(value, format = 'DD/MM/YYYY') {
      if (!value) return ''
      return moment(value).format(format)
    },
    time: function(value, format = 'HH:mm:ss') {
      if (!value) return '--:--'
      return moment(value).format(format)
    },
    key: value => {
      switch (value) {
        case 'status':
          return 'Statut'
        case 'cstage_id':
          return 'Stade'
        case 'csubstage_id':
          return 'Sous-stade'
        case 'technician':
          return 'Poseur'
        case 'co_technician':
          return 'Co poseur'
        case 'statut_previsite':
          return 'Pré visite statut'
        case 'statut_travaux':
          return 'Travaux statut'
        case 'date_travaux':
          return 'Travaux date'
        case 'date_previsite':
          return 'Pré visite date'
        case 'zipCode':
          return 'Code postal'
        case 'adresse':
          return 'Adresse'
        case 'city':
          return 'Ville'
        case 'photo':
          return 'Photos'
        case 'confirmation':
          return 'Confirmation poseur'
        case 'confirmation_previsite':
          return 'Confirmation commercial'
        case 'dossier':
          return 'Dossier'
        case 'source':
          return 'Source'
        case 'type_lead':
          return 'Type Lead'
        case 'quality_manager':
          return 'Resp. planning'
        case 'title':
          return 'Titre'
        case 'housing_type':
          return 'Type de logement'
        case 'commercial_sedentaire':
          return 'Commercial sédentaire'
        case 'commercial_itinerant':
          return 'Pré-visiteur'
        case 'commercial_terrain_id':
          return 'Commercial terrain'
        case 'support':
          return 'Support'
        case 'channel':
          return 'CANAL'
        case 'campaign':
          return 'Campagne'
        case 'user_final_name':
          return 'Utilisateur final'
        case 'date_closing':
          return 'Closing le'
        case 'statut_mpr':
          return 'Prime statut'
        case 'date_mpr':
          return 'Prime date'
        case 'date_credit':
          return 'Crédit date'
        case 'statut_credit':
          return 'Crédit statut'
        case 'statut_devis':
          return 'Devis statut'
        case 'date_devis':
          return 'Devis date'
        case 'type_heating':
          return 'Type de chauffage'
        case 'cadastral_plot':
          return 'Parcelle cadastrale'
        case 'year_construction':
          return 'Année de construction'
        case 'proprietor':
          return 'Propriétaire'
        case 'tax_household':
          return 'Foyer fiscal'
        case 'occupation':
          return 'Occupation'
        case 'type_energie':
          return "Type d'énergie"
        case 'type_combles':
          return 'Type de combles'
        case 'type_plancher':
          return 'Type de plancher'
        case 'type_charpente':
          return 'Type de charpente'
        case 'type_plafond':
          return 'Type de plafond'
        case 'type_vide_sanitaires':
          return 'Type de vide sanitaires'
        case 'acces_combles':
          return 'Accés aux combles'
        case 'hauteur_plafond':
          return 'Hauteur du plafond'
        case 'total_101':
          return 'Total 101'
        case 'total_102':
          return 'Total 102'
        case 'total_103':
          return 'Total 103'
        case 'total_realiser':
          return 'Total réalisé'
        case 'date_devis_edition':
          return 'Date devis edition'
        case 'date_devis_signature':
          return 'Date devis signature'
        case 'age_building':
          return 'Age du bâtiment'
        case 'filiale_projet':
          return 'Filiale projet'
        case 'filial_travaux':
          return 'Filiale travaux'
        case 'tax_fiscal_number':
          return 'Nombre de foyers fiscaux'
        case 'total':
          return 'Total planifié'
        case 'hour_trav_planif':
          return 'Heure planification'
        case 'hour_previsite':
          return 'Heure de pré-visite'
        case 'date_realiser':
          return 'Travaux fait le'
        case 'surface_habitable_chauffe':
          return 'Surface habitable chauffée(m2)'
        case 'source_id':
          return 'Source'
        case 'surface_iti':
          return 'Surface ITI+ planifié'
        case 'surface_iti_pose':
          return 'Surface ITI+ posé'
        case 'surface_souffle':
          return 'Surface soufflé planifié'
        case 'surface_souffle_pose':
          return 'Surface soufflé posé'
        case 'surface_deroule':
          return 'Surface déroulé planifié'
        case 'surface_deroule_pose':
          return 'Surface déroulé posé'
        case 'surface_rampant':
          return 'Surface rampant planifié'
        case 'surface_rampant_pose':
          return 'Surface rampant posé'
        case 'surface_mur':
          return 'Surface mur planifié'
        case 'surface_mur_pose':
          return 'Surface mur posé'
        case 'surface_pignon':
          return 'Surface pignon planifié'
        case 'surface_pignon_pose':
          return 'Surface pignon posé'
        case 'surface_plafond':
          return 'Surface plafond planifié'
        case 'surface_plafond_pose':
          return 'Surface plafond posé'
        case 'surface_vide_sanitaire':
          return 'Surface vide sanitaire planifié'
        case 'surface_vide_sanitaire_pose':
          return 'Surface vide sanitaire posé'
        case 'pac':
          return 'PAC'
        case 'pmr':
          return 'PMR'
        case 'year':
          return 'Année'
        case 'revenu_fiscal':
          return 'Revenu fiscal'
        case 'reference_fiscal':
          return 'Reference fiscal'
        default:
          return value
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.page-content-history {
  position: relative;
  padding-bottom: 5px;
  .informations {
    color: #747678;
    font-size: 12px;
    font-family: 'Montserrat', sans-serif;
  }

  &::before {
    content: '';
    position: absolute;
    left: -24px;
    top: 4px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 1px solid #e5e5e5;
    background: #fff;
    z-index: 2;
  }
}
</style>
