<template>
  <div class="activity-file" :class="{ preview: preview }">
    <div class="icon-entity-file">
      <font-awesome-icon
        class="icon-historique-files img-h-14"
        icon="paperclip"
      />
    </div>
    <div class="page-content-file">
      <div class="description-bloc-file">
        <div class="activity-file-icon">
          <font-awesome-icon
            v-if="isImage"
            icon="file-image"
            class="img-h-30"
          />
          <font-awesome-icon
            v-else-if="isWord"
            icon="file-word"
            class="img-h-30"
          />
          <font-awesome-icon
            v-else-if="isPowerpoint"
            icon="file-powerpoint"
            class="img-h-30"
          />
          <font-awesome-icon
            v-else-if="isExcel"
            icon="file-excel"
            class="img-h-30"
          />
          <font-awesome-icon
            v-else-if="isPdf"
            icon="file-pdf"
            class="img-h-30"
          />
          <font-awesome-icon
            v-else-if="isCsv"
            icon="file-csv"
            class="img-h-30"
          />
          <font-awesome-icon
            v-else-if="isDocument"
            icon="file-alt"
            class="img-h-30"
          />
          <font-awesome-icon
            v-else-if="isArchive"
            icon="file-archive"
            class="img-h-30"
          />
          <font-awesome-icon
            v-else-if="isAudio"
            icon="file-audio"
            class="img-h-30"
          />
          <font-awesome-icon v-else icon="file" class="img-h-30" />
        </div>
        <div class="file-name-action">
          <a v-if="isImage" @click.prevent.stop="showImg" href="" class="name">
            {{ entity.title }}
          </a>
          <a v-else :href="entity.url" target="_blank" class="name">
            {{ entity.title }}
          </a>
          <vue-easy-lightbox
            :visible="visible"
            :imgs="entity.url"
            @hide="handleHide"
          >
          </vue-easy-lightbox>
          <v-menu
            v-if="!readonly"
            offset-y
            close-on-click
            min-width="197px !important"
            max-width="197px !important"
          >
            <template v-slot:activator="{ attrs, on }">
              <v-btn
                small
                v-bind="attrs"
                v-on="on"
                text
                class="mr-3 btn-comment-actions"
              >
                <font-awesome-icon class="img-h-14" icon="ellipsis-h" />
              </v-btn>
            </template>

            <v-list>
              <v-list-item @click.prevent.stop="hundleClickUpdateFile">
                <v-list-item-title>
                  {{
                    $i18n.locale === 'fr'
                      ? 'Modifier la description'
                      : 'Modify description'
                  }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item @click.prevent.stop="hundleClickTelechargerFile">
                <v-list-item-title>
                  {{ $i18n.locale === 'fr' ? 'Télécharger' : 'Download' }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item @click.prevent.stop="hundleClickDeleteFile">
                <v-list-item-title>
                  {{ $i18n.locale === 'fr' ? 'Supprimer' : 'Delete' }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
        <div class="file-category mb-2" v-if="entity && entity.description">
          <span class="file-category-label">Description :</span>
          {{ entity.description }}
        </div>
        <div class="file-category mb-2">
          <span class="file-category-label">{{
            $i18n.locale === 'fr' ? 'Catégorie :' : 'Category :'
          }}</span>
          {{ entity.categorie }}
        </div>
        <div class="file-info">
          <span>
            {{ entity.created_at | date('DD MMM') }}
            {{ entity.created_at | time('HH:mm') }}
          </span>
          -
          <span class="file-info-user">
            {{ entity.created_by.full_name }}
          </span>
        </div>
      </div>
    </div>
    <v-dialog v-model="updateFile" max-width="500" persistent scrollable>
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">
            {{
              $i18n.locale === 'fr'
                ? 'Modifier la description'
                : 'Modify description'
            }}
          </v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click.prevent.stop="hideModal('updateFile')"
            :title="$t('close')"
            color="#5C2DD3"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>

        <v-card-text class="body-card center-text  mt-2">
          <div class="name-file mb-3 font-text">
            <strong class="color-black font-text">
              {{ $i18n.locale === 'fr' ? 'Fichier :' : 'File :' }}
            </strong>
            {{ entity.title }}
          </div>
          <v-textarea
            dense
            label="Description"
            v-model="description"
            :persistent-placeholder="true"
            rows="3"
            outlined
            color="#5C2DD3"
            hide-details=""
          ></v-textarea>
        </v-card-text>

        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <div class="message">
            <div v-if="getProjectsError" class="error-msg">
              <ul v-if="Array.isArray(getProjectsError)">
                <li v-for="(e, index) in getProjectsError" :key="index">
                  {{ e }}
                </li>
              </ul>
              <span v-else>{{ getProjectsError }}</span>
            </div>
          </div>

          <v-spacer></v-spacer>
          <v-btn
            color="#5C2DD3"
            dark
            @click.prevent.stop="addDescriptionFile"
            :loading="loading"
            small
          >
            {{ $t('btnValider') }}
          </v-btn>
          <v-btn text @click.prevent.stop="hideModal('updateFile')" small>{{
            $t('btnCancel')
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="deletedFile" max-width="500" persistent scrollable>
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">
            {{
              $i18n.locale === 'fr' ? 'Supprimer le fichier :' : 'Delete file'
            }}
          </v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click.prevent.stop="hideModal('deletedFile')"
            :title="$t('close')"
            color="#5C2DD3"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>

        <v-card-text class="body-card center-text  mt-2">
          <p>
            {{
              $t('msgdeleted', {
                msg: $i18n.locale === 'fr' ? 'ce fichier' : 'this file'
              })
            }}
            ?
          </p>
        </v-card-text>

        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <div class="message">
            <div v-if="getProjectsError" class="error-msg">
              <ul v-if="Array.isArray(getProjectsError)">
                <li v-for="(e, index) in getProjectsError" :key="index">
                  {{ e }}
                </li>
              </ul>
              <span v-else>{{ getProjectsError }}</span>
            </div>
          </div>

          <v-spacer></v-spacer>
          <v-btn
            color="#5C2DD3"
            dark
            @click.prevent.stop="doDeleteFile"
            :loading="loading"
            small
          >
            {{ $t('btnValider') }}
          </v-btn>
          <v-btn text @click.prevent.stop="hideModal('deletedFile')" small>{{
            $t('btnCancel')
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from 'moment'
import momentDurationFormatSetup from 'moment-duration-format'
momentDurationFormatSetup(moment)
moment.locale('fr')
import { mapActions, mapGetters } from 'vuex'
import VueEasyLightbox from 'vue-easy-lightbox'

export default {
  components: {
    VueEasyLightbox
  },
  props: {
    entity: { required: true },
    project: { required: false },
    preview: { default: false },
    readonly: {
      default: false
    }
  },
  data() {
    return {
      visible: false,
      description: this.entity.description,
      deletedFile: false,
      updateFile: false,
      loading: false
    }
  },
  methods: {
    ...mapActions([
      'UpdateFile',
      'deleteFile',
      'downloadFile',
      'fetchProjectHistoriques',
      'resetPageValue',
      'resetErrorProject'
    ]),
    showImg(event) {
      event.preventDefault()
      this.visible = true
    },
    handleHide() {
      this.visible = false
    },
    hundleClickUpdateFile() {
      this.resetErrorProject()
      this.description = this.entity.description
      this.updateFile = true
    },
    hideModal(ref) {
      this.resetErrorProject()
      this[ref] = false
    },
    hundleClickTelechargerFile() {
      this.downloadFile(this.entity)
    },
    async addDescriptionFile() {
      this.loading = true
      const response = await this.UpdateFile({
        data: this.description,
        file: this.entity,
        project: this.project
      })
      if (response) {
        this.hideModal('updateFile')
      }
      this.loading = false
    },
    async hundleClickDeleteFile() {
      this.resetErrorProject()
      this.deletedFile = true
    },
    async doDeleteFile() {
      this.loading = true
      const response = await this.deleteFile({
        file: this.entity,
        project: this.project
      })
      if (response) {
        this.resetPageValue({ object: 'tous' })
        this.fetchProjectHistoriques({
          project: this.project,
          page: 1,
          reset: 1
        })
        this.hideModal('deletedFile')
      }
      this.loading = false
    }
  },
  computed: {
    ...mapGetters(['getProjectsError']),
    isImage: function() {
      return (
        this.entity.extension &&
        ['jpg', 'jpeg', 'png', 'bmp', 'gif'].find(
          e => e == this.entity.extension.toLowerCase()
        )
      )
    },
    isWord: function() {
      return (
        this.entity.extension &&
        ['doc', 'docx'].find(e => e == this.entity.extension.toLowerCase())
      )
    },
    isPowerpoint: function() {
      return (
        this.entity.extension &&
        ['ppt', 'pptx'].find(e => e == this.entity.extension.toLowerCase())
      )
    },
    isExcel: function() {
      return (
        this.entity.extension &&
        ['xls', 'xlsx'].find(e => e == this.entity.extension.toLowerCase())
      )
    },
    isPdf: function() {
      return (
        this.entity.extension && this.entity.extension.toLowerCase() == 'pdf'
      )
    },
    isCsv: function() {
      return (
        this.entity.extension && this.entity.extension.toLowerCase() == 'csv'
      )
    },
    isDocument: function() {
      return (
        this.entity.extension &&
        ['txt', 'odt', 'ods'].find(
          e => e == this.entity.extension.toLowerCase()
        )
      )
    },
    isArchive: function() {
      return (
        this.entity.extension &&
        ['rar', 'zip', '7z'].find(e => e == this.entity.extension.toLowerCase())
      )
    },
    isAudio: function() {
      return (
        this.entity.extension &&
        ['mp3', 'ogg', 'wav', 'wma'].find(
          e => e == this.entity.extension.toLowerCase()
        )
      )
    }
  },
  filters: {
    date: function(value, format = 'DD/MM/YYYY') {
      if (!value) return ''
      return moment(value).format(format)
    },
    time: function(value, format = 'HH:mm:ss') {
      if (!value) return '--:--'
      return moment(value).format(format)
    }
  }
}
</script>

<style lang="scss">
.activity-file {
  .activity-dropdown-button {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    margin: 0;
    padding: 9px;
    width: 28px;
    height: 25px;
    line-height: 0;
    box-shadow: none !important;
  }
}
</style>
<style lang="scss" scoped>
.activity-file {
  position: relative;
  .icon-entity-file {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 35px;
    height: 35px;
    z-index: 2;
    top: 12px;
    left: -33px;
    border: 1px solid #e5e5e5;
    background-color: #fff;
    border-radius: 50%;
    font-size: 16px;
    .icon-historique-files {
      transform: rotateY(180deg);
    }
  }
  .page-content-file {
    padding-bottom: 9px;
    .description-bloc-file {
      position: relative;
      height: auto;
      font-size: 12px;
      margin-top: 6px;
      padding: 8px 15px 8px 50px;
      background-color: #f9f9f97a;
      border: 1px solid #d8d8d8;
      border-radius: 4px;
      margin-left: 26px;
      .activity-file-icon {
        position: absolute;
        display: block;
        left: 10px;
        top: 20px; //8px
        font-size: 40px;
      }
      .file-name-action {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        .name {
          display: block;
          max-width: 85%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          color: #5c2dd3;
        }
      }

      .file-category {
        .file-category-label {
          font-weight: 600;
        }
      }
      .file-info {
        font-weight: 600;
        .file-info-user {
          text-transform: capitalize;
        }
      }

      &:before {
        content: '';
        position: absolute;
        border-top: 8px solid transparent;
        border-bottom: 8px solid transparent;
        border-right: 8px solid #d8d8d8;
        left: -9px;
        top: 20px;
      }

      &:after {
        content: '';
        position: absolute;
        border-top: 8px solid transparent;
        border-bottom: 8px solid transparent;
        border-right: 8px solid #fcfcfc;
        left: -8px;
        top: 20px;
      }
    }
  }

  &.preview {
    .icon-entity-file {
      display: none;
    }
    .page-content-file {
      .description-bloc-file {
        margin-left: 0;
        &:before {
          display: none;
        }
        &:after {
          display: none;
        }
      }
    }
  }
}
</style>
