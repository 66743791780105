var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Card',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$i18n.locale === 'fr' ? 'Information sous-sol' : 'Basement information')+" ")])]},proxy:true},{key:"body",fn:function(){return [_c('v-form',{ref:"myForm",on:{"submit":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-select',{attrs:{"label":_vm.$i18n.locale === 'fr'
                ? 'Nombre de plancher bas différents'
                : 'Number of different low floors',"dense":"","clearable":"","placeholder":_vm.$i18n.locale === 'fr' ? 'Type de Plancher' : 'Floor type',"item-text":"label","item-value":"id","items":_vm.numbers,"outlined":"","persistent-placeholder":true,"required":"","color":"#704ad1","item-color":"#704ad1"},on:{"change":_vm.updatePlancher},model:{value:(_vm.number),callback:function ($$v) {_vm.number=$$v},expression:"number"}})],1)],1),_c('v-row',_vm._l((_vm.form12.planchers),function(plancher,i){return _c('v-col',{key:i,attrs:{"cols":"6"}},[_c('Card',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm.$i18n.locale === 'fr' ? 'Plancher Bas' : 'Low floor')+" "+_vm._s(i + 1)+" ")]},proxy:true},{key:"body",fn:function(){return [_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-select',{attrs:{"label":"Type","dense":"","clearable":"","placeholder":_vm.$t('choose'),"item-text":"label","item-value":"value","items":_vm.Types,"outlined":"","persistent-placeholder":true,"required":"","color":"#704ad1","item-color":"#704ad1","hide-details":""},model:{value:(plancher.type),callback:function ($$v) {_vm.$set(plancher, "type", $$v)},expression:"plancher.type"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-select',{attrs:{"label":"Isolation","dense":"","clearable":"","placeholder":_vm.$t('choose'),"item-text":"label","item-value":"value","items":_vm.Isolations,"outlined":"","persistent-placeholder":true,"required":"","color":"#704ad1","item-color":"#704ad1","hide-details":""},model:{value:(plancher.isolation),callback:function ($$v) {_vm.$set(plancher, "isolation", $$v)},expression:"plancher.isolation"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-select',{attrs:{"label":_vm.$i18n.locale === 'fr'
                        ? 'Epaisseur Iso.'
                        : 'Iso. thickness',"dense":"","clearable":"","placeholder":_vm.$t('choose'),"item-text":"label","item-value":"value","items":_vm.Epaisseurs,"outlined":"","persistent-placeholder":true,"required":"","color":"#704ad1","item-color":"#704ad1","hide-details":""},model:{value:(plancher.epaisseur),callback:function ($$v) {_vm.$set(plancher, "epaisseur", $$v)},expression:"plancher.epaisseur"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-select',{attrs:{"label":_vm.$i18n.locale === 'fr'
                        ? 'Repartition iso'
                        : 'Iso-distribution',"dense":"","clearable":"","placeholder":_vm.$t('choose'),"item-text":"label","item-value":"value","items":_vm.Repartitions,"outlined":"","persistent-placeholder":true,"required":"","color":"#704ad1","item-color":"#704ad1","hide-details":""},model:{value:(plancher.repartition),callback:function ($$v) {_vm.$set(plancher, "repartition", $$v)},expression:"plancher.repartition"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"label":"Surface (m2)","placeholder":"Surface (m2)","persistent-placeholder":true,"type":"number","min":0,"dense":"","outlined":"","hide-details":"","color":"#704ad1"},model:{value:(plancher.surface),callback:function ($$v) {_vm.$set(plancher, "surface", $$v)},expression:"plancher.surface"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"label":_vm.$i18n.locale === 'fr' ? 'Hauteur(m)' : 'Height (m)',"placeholder":_vm.$i18n.locale === 'fr' ? 'Hauteur(m)' : 'Height (m)',"persistent-placeholder":true,"type":"number","min":0,"dense":"","outlined":"","hide-details":"","color":"#704ad1"},model:{value:(plancher.hauteur),callback:function ($$v) {_vm.$set(plancher, "hauteur", $$v)},expression:"plancher.hauteur"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-file-input',{attrs:{"dense":"","accept":"image/*","prepend-icon":"mdi-plus","label":_vm.$i18n.locale === 'fr'
                        ? 'Ajouter photo du plancher bas'
                        : 'Add photo of low floor',"underlined":"","clearable":"","color":"#704ad1"},model:{value:(plancher.file),callback:function ($$v) {_vm.$set(plancher, "file", $$v)},expression:"plancher.file"}})],1)],1)]},proxy:true}],null,true)})],1)}),1),_c('v-col',{staticClass:"bottom",attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"color":"#5C2DD3","outlined":""},on:{"click":_vm.prevStep}},[_vm._v(" "+_vm._s(_vm.$t('precedant'))+" ")]),_c('v-btn',{attrs:{"color":"#5C2DD3","dark":"","type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t('suivant'))+" ")])],1)],1)]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }