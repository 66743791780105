<template>
  <Card>
    <template #title>
      <div class="title">
        {{
          $i18n.locale === 'fr'
            ? 'Information Bénéficiaire Principal'
            : 'Primary Beneficiary Information'
        }}
      </div>
    </template>
    <template #body>
      <v-form @submit.prevent="submitForm" ref="myForm">
        <v-row>
          <v-col cols="6">
            <v-text-field
              v-model="form1.nom"
              :label="$t('name')"
              field="nom"
              dense
              placeholder="-"
              :rules="[rules.required]"
              outlined
              :persistent-placeholder="true"
              color="#5C2DD3"
            >
            </v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="form1.prenom"
              :label="$t('first_name')"
              field="prenom"
              dense
              placeholder="-"
              :rules="[rules.required]"
              outlined
              color="#5C2DD3"
              :persistent-placeholder="true"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="form1.adresse"
              :label="$t('adresse')"
              field="adresse"
              dense
              color="#5C2DD3"
              placeholder="-"
              :rules="[rules.required]"
              outlined
              :persistent-placeholder="true"
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="form1.ville"
              :label="$t('ville')"
              field="ville"
              dense
              color="#5C2DD3"
              placeholder="-"
              :rules="[rules.required]"
              outlined
              :persistent-placeholder="true"
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="form1.code_postal"
              :label="$t('zip_code')"
              field="cp"
              dense
              color="#5C2DD3"
              placeholder="-"
              :rules="[rules.required]"
              outlined
              :persistent-placeholder="true"
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="form1.email"
              label="Mail"
              field="mail"
              dense
              color="#5C2DD3"
              placeholder="-"
              :rules="[rules.required, rules.email]"
              outlined
              :persistent-placeholder="true"
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="form1.tel"
              label="Tel"
              field="tel"
              dense
              placeholder="-"
              color="#5C2DD3"
              :rules="[rules.required]"
              outlined
              :persistent-placeholder="true"
            ></v-text-field>
          </v-col>
        </v-row>
        <!-- Bouton "Suivant" pour chaque étape -->
        <v-col cols="12" class="bottom">
          <v-btn color="#5C2DD3" dark type="submit">
            {{ $t('suivant') }}
          </v-btn>
        </v-col>
      </v-form>
    </template>
  </Card>
</template>

<script>
import { mapGetters } from 'vuex'
import Card from '@/components/CrmIla26Component/Projets/Card.vue'

export default {
  components: {
    Card
  },
  data() {
    return {
      form1: {
        nom: null,
        prenom: null,
        adresse: null,
        ville: null,
        code_postal: null,
        email: null,
        tel: null
      },
      rules: {
        required: value =>
          !!value ||
          (this.$i18n.locale === 'fr'
            ? 'Ce champ est obligatoire'
            : 'This field is required'),
        email: value =>
          /.+@.+\..+/.test(value) ||
          (this.$i18n.locale === 'fr'
            ? 'Le Mail doit être valide'
            : 'Mail must be valid')
        // Ajoutez d'autres règles au besoin
      }
    }
  },
  methods: {
    resetComponent() {
      // Réinitialiser les données spécifiques à ce composant (par exemple, formulaire)
      this.form1 = {
        nom: null,
        prenom: null,
        adresse: null,
        ville: null,
        code_postal: null,
        email: null,
        tel: null
      }
      if (!this.form1.nom && this.getProjectsContact.last_name)
        this.form1.nom = this.getProjectsContact.last_name
      if (!this.form1.prenom && this.getProjectsContact.first_name)
        this.form1.prenom = this.getProjectsContact.first_name
      if (!this.form1.adresse && this.getProjectsContact.adresse)
        this.form1.adresse = this.getProjectsContact.adresse
      if (!this.form1.ville && this.getProjectsContact.ville)
        this.form1.ville = this.getProjectsContact.ville
      if (!this.form1.tel && this.getProjectsContact.telephones.length)
        this.form1.tel = this.getProjectsContact.telephones[0].number
      this.$refs.myForm.resetValidation()
    },
    submitForm() {
      // Utilisez $refs pour accéder au formulaire
      const valid = this.$refs.myForm.validate()
      if (!valid) {
        return
      }

      this.$emit('nextStep', this.form1)
    }
  },
  computed: {
    ...mapGetters(['getProjectsContact'])
  },
  mounted() {
    if (!this.form1.nom && this.getProjectsContact.last_name)
      this.form1.nom = this.getProjectsContact.last_name
    if (!this.form1.prenom && this.getProjectsContact.first_name)
      this.form1.prenom = this.getProjectsContact.first_name
    if (!this.form1.adresse && this.getProjectsContact.adresse)
      this.form1.adresse = this.getProjectsContact.adresse
    if (!this.form1.ville && this.getProjectsContact.ville)
      this.form1.ville = this.getProjectsContact.ville
    if (!this.form1.tel && this.getProjectsContact.telephones.length)
      this.form1.tel = this.getProjectsContact.telephones[0].number
  }
}
</script>

<style lang="css" scoped>
.bottom {
  display: flex !important;
  align-items: center;
  justify-content: center !important;
  gap: 24px;
  margin-top: 16px;
}
</style>
