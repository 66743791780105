<template>
  <v-card class="a-card">
    <div class="a-card-header">
      <div
        class="a-card-header-flex"
        :class="{
          'width-comment': epinglerNote,
          'a-card-header-switch': IconSwitch,
          'a-card-header-montant': titleMontant,
          'a-card-header-type-project': titleTypeProject,
          'padding-contact': categoryType == 'contact',
          'padding-projet': categoryType == 'projet',
          'padding-info-fiscal-projet': categoryType == 'projet' && infoFiscal,
          'padding-info-fiscal-contact': categoryType == 'contact' && infoFiscal
        }"
      >
        <div :class="{ flex: !epinglerNote }">
          <div
            class="title-card"
            :class="{
              'color-title': epinglerNote,
              'style-title': titleTypeProject
            }"
          >
            <slot name="title"></slot>
          </div>
          <!-- <div
            v-if="IconSwitch && IconSwitch.define_subtype"
            class="button-switch"
          >
            <v-switch
              :value="1"
              :unchecked-value="0"
              v-model="IconSwitch.is_active"
              @change="toggleSwitch(IconSwitch)"
              color="#5C2DD3"
            >
            </v-switch>
          </div> -->
        </div>
        <div v-if="titleTypeProject" class="style-title-type">
          {{ titleTypeProject }}
        </div>
        <div class="body-card" v-if="titleMontant">
          <div v-for="item in titleMontant" :key="item.id">
            <div v-if="item.is_header" class="flex">
              <div class="bold font-sz-11">{{ item.name }}</div>
              <div class="value-ttc">{{ item.value }}</div>
            </div>
          </div>
        </div>
        <!-- COPY INFO CONTACT
        <div v-if="IconSwitch && IconSwitch.id == 54 && !readonly">
          <div
            title="Copier les informations de contact"
            class="cadre-icon"
            @click.prevent="handleCopyInfoClick"
          >
            <font-awesome-icon icon="copy" class="color-crm img-h-14" />
          </div>

          <v-dialog v-model="copyInfoAddressModal" persistent max-width="600">
            <v-card>
              <v-card-title class="text-h6 title-modal">
                <v-label class="label-header">
                  Copier les informations de contact
                </v-label>

                <v-btn
                  class="btn-close-header"
                  icon
                  @click="closeModal('copyInfoAddressModal')"
                  title="Fermer"
                  color="#5C2DD3"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text class="mt-2">
                <div>
                  Êtes-vous sûr de vouloir copier les informations de l'adresse
                  de contact ?
                </div>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions class="actions-msg-btn-modal">
                <div class="message">
                  <div
                    v-if="getProjectsProcessing || getContactsProcessing"
                    class="loading"
                  >
                     {{ $t('loading') }}
                  </div>
                  <div v-if="getProjectsError" class="error-msg">
                    <ul v-if="Array.isArray(getProjectsError)">
                      <li v-for="(e, index) in getProjectsError" :key="index">
                        {{ e }}
                      </li>
                    </ul>
                    <span v-else>{{ getProjectsError }}</span>
                  </div>
                </div>
                <v-spacer></v-spacer>
                <v-btn color="#5C2DD3" dark @click="copyInfoAddress" small>
                  Copier
                </v-btn>
                <v-btn text @click="closeModal('copyInfoAddressModal')" small>
                  {{ $t('btnCancel') }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div> -->
        <!-- COPY INFO FISCAL -->
        <!-- <div v-if="!readonly && IconSwitch && IconSwitch.id == 25">
          <div
            class="cadre-icon"
            @click.prevent="handleCopyFiscalInfoClick"
            title="Copier les informations fiscals de contact"
          >
            <font-awesome-icon icon="copy" class="color-crm img-h-12" />
          </div>
          <v-dialog
            v-model="copyInfoFiscalModal"
            persistent
            :max-width="
              project.reference_fiscal_avis &&
              project.reference_fiscal_avis.length
                ? 600
                : 500
            "
          >
            <v-card>
              <v-card-title class="text-h6 title-modal">
                <v-label class="label-header">
                  Copier les informations fiscals
                </v-label>

                <v-btn
                  class="btn-close-header"
                  icon
                  @click="closeModal('copyInfoFiscalModal')"
                  title="Fermer"
                  color="#5C2DD3"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text class="mt-2">
                <template
                  v-if="
                    project.reference_fiscal_avis &&
                      project.reference_fiscal_avis.length
                  "
                >
                  <div>
                    Selectionner les informations fiscals qui correspondent à ce
                    projet :
                  </div>
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-center text-dark"></th>
                          <th class="text-center text-dark">
                            RE.FISCAL DE L'AVIS
                          </th>
                          <th class="text-center text-dark">REVENU FISCAL</th>
                          <th class="text-center text-dark">ANNÉE</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(item, index) in project.reference_fiscal_avis"
                          :key="'fiscal-' + index"
                        >
                          <td class="text-center">
                            <v-checkbox
                              @input="changeCopyFiscal($event)"
                              v-model="selectedFiscalCopy[index]"
                              :disabled="item.disable"
                              color="#5C2DD3"
                              class="input-checkbox"
                              :value="item"
                              hide-details
                            ></v-checkbox>
                          </td>
                          <td class="text-center">
                            {{ item.reference_fiscal }}
                          </td>
                          <td class="text-center">{{ item.revenu_fiscal }}</td>
                          <td class="text-center">{{ item.year }}</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </template>
                <div v-else>
                  Le contact n'a pas des informations sur le revenu fiscal.
                </div>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions class="actions-msg-btn-modal">
                <div class="message">
                  <div class="error-msg" v-if="errorCopyFiscal">
                    Aucun revenu fiscal selectionné!
                  </div>
                  <div
                    v-if="getProjectsProcessing || getContactsProcessing"
                    class="loading"
                  >
                     {{ $t('loading') }}
                  </div>
                  <div v-if="getProjectsError" class="error-msg">
                    <ul v-if="Array.isArray(getProjectsError)">
                      <li v-for="(e, index) in getProjectsError" :key="index">
                        {{ e }}
                      </li>
                    </ul>
                    <span v-else>{{ getProjectsError }}</span>
                  </div>
                </div>
                <v-spacer></v-spacer>
                <v-btn
                  color="#5C2DD3"
                  dark
                  v-if="
                    project.reference_fiscal_avis &&
                      project.reference_fiscal_avis.length
                  "
                  @click="copyInfoFiscal"
                  small
                >
                  Copier
                </v-btn>
                <v-btn text @click="closeModal('copyInfoFiscalModal')" small>
                  {{ $t('btnCancel') }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div> -->
      </div>
      <!-- <hr class="line-hr" v-if="!cardPrincipal && !notBody" /> -->
    </div>
    <div
      class="a-card-body"
      :class="{
        'padding-info-fiscal-projet': categoryType == 'projet' && infoFiscal,
        'padding-contact': categoryType == 'contact',
        'padding-projet': categoryType == 'projet'
      }"
    >
      <slot name="body"></slot>
      <!-- <div
        class="show-button"
        v-if="showButton && IconSwitch && IconSwitch.id == 63"
      >
        {{ showButton }}
      </div> -->
    </div>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  props: {
    cardPrincipal: {
      required: false
    },
    titleMontant: {
      required: false
    },
    notBody: {
      type: Boolean,
      required: false
    },
    IconSwitch: {
      required: false
    },
    project: {
      required: false
    },
    showButton: {
      type: String,
      required: false
    },
    epinglerNote: {
      type: Boolean,
      required: false
    },
    titleTypeProject: { type: String, required: false },
    subtypes: {
      required: false
    },
    categorie: {
      required: false
    },
    readonly: {
      required: false
    },
    categoryType: {
      required: false
    },
    infoFiscal: {
      required: false
    }
  },
  data() {
    return {
      copyInfoAddressModal: false,
      copyInfoFiscalModal: false,
      selectedFiscalCopy: [],
      errorCopyFiscal: false
    }
  },
  methods: {
    ...mapActions([
      'deleteProject',
      'updateProjectBash',
      'resetErrorProject',
      'updateProject'
    ]),
    handleFiscalCopyChange() {
      this.errorCopyFiscal = false
    },
    changeCopyFiscal(fiscalObject) {
      let checkCopy = fiscalObject.year
      if (fiscalObject) {
        for (
          let index = 0;
          index < this.data.reference_fiscal_avis.length;
          index++
        ) {
          const copy = this.data.reference_fiscal_avis[index]
          if (copy.year == checkCopy) {
            copy.disable = false
          } else {
            copy.disable = true
          }
        }
      } else {
        if (this.selectedFiscalCopy && this.selectedFiscalCopy.length) {
          let checkDisable = this.selectedFiscalCopy.filter(copy => {
            if (!copy.disable) {
              return copy
            }
          })
          if (checkDisable && checkDisable.length) {
            checkCopy = checkDisable[0].year
          } else {
            for (
              let index = 0;
              index < this.data.reference_fiscal_avis.length;
              index++
            ) {
              const copy = this.data.reference_fiscal_avis[index]
              copy.disable = false
            }
          }
        }
      }
    },
    copyInfoFiscal() {
      if (!this.selectedFiscalCopy || !this.selectedFiscalCopy.length) {
        this.errorCopyFiscal = true
        return
      }
      let cp_reference_fiscal_avis = []
      this.selectedFiscalCopy.forEach((fiscalCopy, index) => {
        if (fiscalCopy) {
          cp_reference_fiscal_avis[index] = {
            reference_fiscal: fiscalCopy.reference_fiscal,
            revenu_fiscal: fiscalCopy.revenu_fiscal,
            year: fiscalCopy.year
          }
        } else {
          this.errorCopyFiscal = true
        }
      })
      const payload = {
        entity: this.project,
        value: {
          cp_reference_fiscal_avis: cp_reference_fiscal_avis.filter(fiscal => {
            return fiscal != null
          })
        }
      }
      if (cp_reference_fiscal_avis && cp_reference_fiscal_avis.length) {
        this.updateProject(payload)
        this.closeModal('copyInfoFiscalModal')
      }
    },
    handleCopyFiscalInfoClick() {
      this.selectedFiscalCopy = []
      this.errorCopyFiscal = false
      if (
        this.project.reference_fiscal_avis &&
        this.project.reference_fiscal_avis.length
      ) {
        for (
          let index = 0;
          index < this.project.reference_fiscal_avis.length;
          index++
        ) {
          const copy = this.project.reference_fiscal_avis[index]
          copy.disable = false
        }
      }
      this.copyInfoFiscalModal = true
    },
    handleCopyInfoClick() {
      this.copyInfoAddressModal = true
    },
    closeModal(ref) {
      this[ref] = false
      this.resetErrorProject()
    },
    async copyInfoAddress() {
      const payload = {
        entity: this.project,
        data: {
          zipCode: this.project.zipCode,
          adresse: this.project.adresse,
          city: this.project.city
        }
      }
      const response = await this.updateProjectBash(payload)
      if (response) {
        this.copyInfoAddressModal = false
      }
    },
    toggleSwitch() {
      let payload = {
        project_id: this.project.id,
        sousCategorie: this.IconSwitch,
        sousType: this.subtypes,
        categorie: this.categorie
      }
      this.$emit('switchActive', payload)
    }
  },
  computed: {
    ...mapGetters([
      'currentUser',
      'getProjectsError',
      'getProjectsProcessing',
      'getContactsProcessing'
    ])
  }
}
</script>

<style lang="scss" scoped>
.a-card {
  box-shadow: none !important;
}
.a-card-header {
  .a-card-header-flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &.width-comment {
      display: block;
      width: 100%;
      padding: 10px 30px 0px 28px !important;
    }
    &.padding-info-fiscal-projet {
      padding: 10px 30px 0px 28px !important;
    }
    &.padding-info-fiscal-contact {
      padding: 10px 0px 0px 22px !important;
    }
    &.padding-contact {
      padding: 9px 0px 0px 10px;
    }
    &.padding-projet {
      padding: 9px 23px 0px 23px;
    }
    &.a-card-header-montant,
    &.a-card-header-type-project {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .title-card {
      color: #5c2dd3;
      font-weight: 700;
      font-size: 14px !important;
      font-family: 'Montserrat', sans-serif;
      margin: 10px 0px;
      &.color-title {
        color: #5e5e5e;
        font-weight: initial;
      }
      &.style-title {
        font-style: italic;
      }
    }
    .style-title-type {
      font-size: 10px;
      font-weight: bold;
    }
    .type-projet {
      font-weight: bold;
    }
    .body-card {
      .value-ttc {
        margin-left: 12px;
      }
    }
  }
  .line-hr {
    margin-top: 10px;
  }
}
.a-card-body {
  &.padding-contact {
    padding: 7px 9px 7px 9px;
  }
  &.padding-projet {
    padding: 9px 23px 0px 23px;
  }
  &.padding-info-fiscal-projet {
    padding: 10px 40px 0px 14px !important;
  }
  .show-button {
    background-color: #e5e5e580;
    border: 1px solid #6c757d52;
    margin-left: 270px;
    text-align: center;
    padding: 3px;
    border-radius: 10px;
    font-weight: bold;
    min-width: 50px;
    max-width: 100px;
  }
}
</style>
<style lang="scss">
.a-card-header {
  .a-card-header-flex {
    &.a-card-header-switch {
      .button-switch {
        margin-top: -8px;
        margin-left: 15px;
        .custom-switch.b-custom-control-lg .custom-control-label::before,
        .input-group-lg .custom-switch .custom-control-label::before {
          top: 4px;
          height: 23px;
          left: -2.8125rem;
          width: 50px;
          border-radius: 7px;
        }
        .custom-switch.b-custom-control-lg .custom-control-label::after,
        .input-group-lg .custom-switch .custom-control-label::after {
          transform: translateX(-1.25rem);
          top: calc(0.3125rem + 2px);
          left: -19px;
          width: 20px;
          height: 16px;
          border-radius: 5px;
          background-size: 50% 50%;
        }
        .custom-control-input:checked ~ .custom-control-label::before {
          color: #9460f2;
          border-color: #e5e5e5;
          background-color: #e5e5e5;
        }
        .custom-switch.b-custom-control-lg
          .custom-control-input:checked
          ~ .custom-control-label::after,
        .input-group-lg
          .custom-switch
          .custom-control-input:checked
          ~ .custom-control-label::after {
          -webkit-transform: translateX(-0.0625rem);
          transform: translateX(-0.0625rem);
        }
        .custom-switch
          .custom-control-input:checked
          ~ .custom-control-label::after {
          background-color: #9460f2c4;
          -webkit-transform: translateX(0.75rem);
          transform: translateX(0.75rem);
        }
        .custom-control-input:not(:disabled):active
          ~ .custom-control-label::before {
          color: #fff;
          background-color: #e5e5e5;
          border-color: #e5e5e5;
        }
        .custom-control-label::before {
          position: absolute;
          top: 0.25rem;
          left: -1.5rem;
          display: block;
          width: 1rem;
          height: 1rem;
          pointer-events: none;
          content: '';
          background-color: #e5e5e5;
          border: #e5e5e5 solid 1px;
        }
      }
    }
  }
}
</style>
