<template>
  <Card>
    <template #title>
      <div class="title">
        {{
          $i18n.locale === 'fr'
            ? 'Information Générale Logement'
            : 'General Information Housing'
        }}
      </div>
    </template>
    <template #body>
      <v-form @submit.prevent="submitForm" ref="myForm">
        <v-row>
          <v-col cols="12">
            <v-text-field
              type="number"
              v-model="form2.annee"
              :label="
                $i18n.locale === 'fr'
                  ? 'Année de construction'
                  : 'Year of construction'
              "
              :field="
                $i18n.locale === 'fr'
                  ? 'Année de construction'
                  : 'Year of construction'
              "
              dense
              :placeholder="
                $i18n.locale === 'fr' ? 'exemple: 1978' : 'example: 1978'
              "
              :rules="[rules.numeric]"
              outlined
              :persistent-placeholder="true"
              color="#5C2DD3"
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <v-radio-group
              v-model="form2.forme"
              row
              hide-details
              color="#5C2DD3"
            >
              <template v-slot:label>
                {{
                  $i18n.locale === 'fr' ? 'Forme du logement' : 'Housing form'
                }}
              </template>
              <v-col cols="6">
                <v-radio value="R" color="#5C2DD3">
                  <template v-slot:label>
                    <img src="@/assets/img/forme/allonge.png" alt="Rectangle" />
                  </template>
                </v-radio>
              </v-col>
              <v-col cols="6">
                <v-radio value="L" color="#5C2DD3">
                  <template v-slot:label>
                    <img src="@/assets/img/forme/enL.png" alt="en L" />
                  </template>
                </v-radio>
              </v-col>
              <v-col cols="6">
                <v-radio value="T" color="#5C2DD3">
                  <template v-slot:label>
                    <img src="@/assets/img/forme/enT.gif" alt="en T" />
                  </template>
                </v-radio>
              </v-col>
              <v-col cols="6">
                <v-radio value="U" color="#5C2DD3">
                  <template v-slot:label>
                    <img src="@/assets/img/forme/enU.png" alt="en U" />
                  </template>
                </v-radio>
              </v-col>
            </v-radio-group>
          </v-col>

          <v-col cols="12">
            <v-file-input
              v-model="form2.file"
              dense
              accept="image/*"
              prepend-icon="mdi-plus"
              :label="
                $i18n.locale === 'fr'
                  ? 'Ajouter une photo de la maison'
                  : 'Add a photo of the house'
              "
              underlined
              clearable
              color="#5C2DD3"
              hide-details
            ></v-file-input>
          </v-col>
        </v-row>
        <!-- Bouton "Suivant" pour chaque étape -->
        <v-col cols="12" class="bottom">
          <v-btn color="#5C2DD3" outlined @click="prevStep">
            {{ $t('precedant') }}
          </v-btn>
          <v-btn color="#5C2DD3" dark type="submit">
            {{ $t('suivant') }}
          </v-btn>
        </v-col>
      </v-form>
    </template>
  </Card>
</template>

<script>
import Card from '@/components/CrmIla26Component/Projets/Card.vue'

export default {
  components: {
    Card
  },
  data() {
    return {
      form2: {
        annee: null,
        forme: null,
        file: null
      },
      rules: {
        numeric: value =>
          value === null ||
          value === '' ||
          /^[0-9]+$/.test(value) ||
          (this.$i18n.locale === 'fr'
            ? 'Ce champ doit être numérique'
            : 'This field must be numeric')
        // Ajoutez d'autres règles au besoin
      }
    }
  },
  methods: {
    resetComponent() {
      // Réinitialiser les données spécifiques à ce composant (par exemple, formulaire)
      this.form2 = {
        annee: null,
        forme: null,
        file: null
      }
    },
    submitForm() {
      // Utilisez $refs pour accéder au formulaire
      const valid = this.$refs.myForm.validate()
      if (!valid) {
        return
      }

      this.$emit('nextStep', this.form2)
    },
    prevStep() {
      this.$emit('prevStep')
    }
  }
}
</script>

<style lang="css" scoped>
.bottom {
  display: flex !important;
  align-items: center;
  justify-content: center !important;
  gap: 16px;
  margin-top: 16px;
}
</style>
