<template>
  <Card>
    <template #title>
      <div class="title">
        {{ $i18n.locale === 'fr' ? 'Factures' : 'Invoices' }}
      </div>
    </template>
    <template #body>
      <v-form @submit.prevent="submitForm" ref="myForm">
        <v-row>
          <v-container fluid>
            <v-row>
              <v-col cols="2" v-for="(option, i) in options" :key="i">
                <v-checkbox
                  v-model="form19.type_factures"
                  :label="option.label"
                  color="#704ad1"
                  :value="option.value"
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-container>
        </v-row>
        <v-row v-if="isSelected('electricite')">
          <v-col cols="6">
            <v-text-field
              v-model="electricite.prix"
              :label="$i18n.locale === 'fr' ? 'Electricité' : 'Electricity'"
              :placeholder="
                $i18n.locale === 'fr'
                  ? 'Electricité ( € / An )'
                  : 'Electricity ( € / Year )'
              "
              outlined
              :persistent-placeholder="true"
              dense
              hide-details
              color="#704ad1"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-file-input
              v-model="electricite.file"
              dense
              accept="image/*"
              prepend-icon="mdi-plus"
              :label="
                $i18n.locale === 'fr'
                  ? 'Ajouter photo factures Electricité'
                  : 'Add photo invoices Electricity'
              "
              underlined
              clearable
              color="#704ad1"
            ></v-file-input>
          </v-col>
        </v-row>
        <v-row v-if="isSelected('gaz')">
          <v-col cols="6">
            <v-text-field
              v-model="gaz.prix"
              :label="$i18n.locale === 'fr' ? 'Gaz' : 'Gaz'"
              :placeholder="
                $i18n.locale === 'fr' ? 'Gaz ( € / An )' : 'Gas ( € / Year )'
              "
              outlined
              :persistent-placeholder="true"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-file-input
              v-model="gaz.file"
              dense
              accept="image/*"
              prepend-icon="mdi-plus"
              :label="
                $i18n.locale === 'fr'
                  ? 'Ajouter photo factures Gaz'
                  : 'Add photo invoices Gas'
              "
              underlined
              clearable
              color="#704ad1"
            ></v-file-input>
          </v-col>
        </v-row>
        <v-row v-if="isSelected('fioul')">
          <v-col cols="6">
            <v-text-field
              v-model="fioul.prix"
              :label="$i18n.locale === 'fr' ? 'Fioul' : 'Fuel'"
              :placeholder="
                $i18n.locale === 'fr' ? 'Fioul ( € / An )' : 'Fuel ( € / Year )'
              "
              outlined
              :persistent-placeholder="true"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-file-input
              v-model="fioul.file"
              dense
              accept="image/*"
              prepend-icon="mdi-plus"
              :label="
                $i18n.locale === 'fr'
                  ? 'Ajouter photo factures Fioul'
                  : 'Add photo invoices Fuel'
              "
              underlined
              clearable
              color="#704ad1"
            ></v-file-input
          ></v-col>
        </v-row>
        <v-row v-if="isSelected('granule')">
          <v-col cols="6">
            <v-text-field
              v-model="granule.prix"
              :label="$i18n.locale === 'fr' ? 'Granule' : 'Granule'"
              :placeholder="
                $i18n.locale === 'fr'
                  ? 'Granule ( € / An )'
                  : 'Granule ( € / Year )'
              "
              outlined
              :persistent-placeholder="true"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-file-input
              v-model="granule.file"
              dense
              accept="image/*"
              prepend-icon="mdi-plus"
              :label="
                $i18n.locale === 'fr'
                  ? 'Ajouter photo factures Granule'
                  : 'Add photo invoices Granule'
              "
              underlined
              clearable
              color="#704ad1"
            ></v-file-input>
          </v-col>
        </v-row>
        <v-row v-if="isSelected('bois')">
          <v-col cols="6">
            <v-text-field
              v-model="bois.prix"
              :label="$i18n.locale === 'fr' ? 'Bois' : 'Wood'"
              :placeholder="
                $i18n.locale === 'fr' ? 'Bois ( € / An )' : 'Wood ( € / Year )'
              "
              :persistent-placeholder="true"
              outlined
            ></v-text-field>
          </v-col>
          <v-col>
            <v-file-input
              v-model="bois.file"
              dense
              accept="image/*"
              prepend-icon="mdi-plus"
              :label="
                $i18n.locale === 'fr'
                  ? 'Ajouter photo factures Bois'
                  : 'Add photo invoices Wood'
              "
              underlined
              clearable
              color="#704ad1"
            ></v-file-input>
          </v-col>
        </v-row>
        <v-row v-if="isSelected('propane')">
          <v-col cols="6">
            <v-text-field
              v-model="propane.prix"
              label="Propane"
              :placeholder="
                $i18n.locale === 'fr'
                  ? 'Propane ( € / An )'
                  : 'Propane ( € / Year )'
              "
              outlined
              dense
              :persistent-placeholder="true"
            ></v-text-field>
          </v-col>
          <v-col>
            <v-file-input
              v-model="propane.file"
              dense
              accept="image/*"
              prepend-icon="mdi-plus"
              :label="
                $i18n.locale === 'fr'
                  ? 'Ajouter photo factures Propane'
                  : 'Add photo invoices Propane'
              "
              underlined
              clearable
              color="#704ad1"
            ></v-file-input
          ></v-col>
        </v-row>
        <!-- Bouton "Suivant" pour chaque étape -->
        <v-col cols="12" class="bottom">
          <v-btn color="#5C2DD3" outlined @click="prevStep">
            {{ $t('precedant') }}
          </v-btn>
          <v-btn color="#5C2DD3" dark type="submit">
            {{ $t('suivant') }}
          </v-btn>
        </v-col>
      </v-form>
    </template>
  </Card>
</template>

<script>
import Card from '@/components/CrmIla26Component/Projets/Card.vue'

export default {
  components: {
    Card
  },
  data() {
    return {
      form19: {
        type_factures: []
      },
      factureType: 'fioul',
      options: [
        {
          label: this.$i18n.locale === 'fr' ? 'Electricité' : 'Electricity',
          value: 'electricite'
        },
        { label: this.$i18n.locale === 'fr' ? 'Gaz' : 'Gas', value: 'gaz' },
        {
          label: this.$i18n.locale === 'fr' ? 'Fioul' : 'Fuel',
          value: 'fioul'
        },
        { label: 'Granule', value: 'granule' },
        { label: this.$i18n.locale === 'fr' ? 'Bois' : 'Wood', value: 'bois' },
        { label: 'Propane', value: 'propane' }
      ],
      electricite: {
        prix: null,
        file: null
      },
      gaz: {
        prix: null,
        file: null
      },
      fioul: {
        prix: null,
        file: null
      },
      granule: {
        prix: null,
        file: null
      },
      bois: {
        prix: null,
        file: null
      },
      propane: {
        prix: null,
        file: null
      }
    }
  },
  methods: {
    resetComponent() {
      // Réinitialiser les données spécifiques à ce composant (par exemple, formulaire)
      this.form19 = {
        type_factures: []
      }
    },
    submitForm() {
      // Utilisez $refs pour accéder au formulaire
      const valid = this.$refs.myForm.validate()
      if (!valid) {
        return
      }
      let data = []
      if (this.electricite.prix)
        data.push({
          type: 'electricite',
          ...this.electricite
        })
      if (this.gaz.prix)
        data.push({
          type: 'gaz',
          ...this.gaz
        })
      if (this.fioul.prix)
        data.push({
          type: 'fioul',
          ...this.fioul
        })
      if (this.granule.prix)
        data.push({
          type: 'granule',
          ...this.granule
        })
      if (this.bois.prix)
        data.push({
          type: 'bois',
          ...this.bois
        })
      if (this.propane.prix)
        data.push({
          type: 'propane',
          ...this.propane
        })

      this.$emit('nextStep', data)
    },
    prevStep() {
      this.$emit('prevStep')
    },
    isSelected(text) {
      const find = this.form19.type_factures.find(item => item == text)
      return find
    }
  }
}
</script>

<style lang="scss" scoped>
.bottom {
  display: flex !important;
  align-items: center;
  justify-content: center !important;
  gap: 16px;
}
</style>

<style lang="scss">
.v-label {
  margin-bottom: 0 !important;
}
</style>
