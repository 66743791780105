var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Card',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$i18n.locale === 'fr' ? 'Information Générale Logement' : 'General Information Housing')+" ")])]},proxy:true},{key:"body",fn:function(){return [_c('v-form',{ref:"myForm",on:{"submit":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"type":"number","label":_vm.$i18n.locale === 'fr'
                ? 'Année de construction'
                : 'Year of construction',"field":_vm.$i18n.locale === 'fr'
                ? 'Année de construction'
                : 'Year of construction',"dense":"","placeholder":_vm.$i18n.locale === 'fr' ? 'exemple: 1978' : 'example: 1978',"rules":[_vm.rules.numeric],"outlined":"","persistent-placeholder":true,"color":"#5C2DD3"},model:{value:(_vm.form2.annee),callback:function ($$v) {_vm.$set(_vm.form2, "annee", $$v)},expression:"form2.annee"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-radio-group',{attrs:{"row":"","hide-details":"","color":"#5C2DD3"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.$i18n.locale === 'fr' ? 'Forme du logement' : 'Housing form')+" ")]},proxy:true}]),model:{value:(_vm.form2.forme),callback:function ($$v) {_vm.$set(_vm.form2, "forme", $$v)},expression:"form2.forme"}},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-radio',{attrs:{"value":"R","color":"#5C2DD3"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('img',{attrs:{"src":require("@/assets/img/forme/allonge.png"),"alt":"Rectangle"}})]},proxy:true}])})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-radio',{attrs:{"value":"L","color":"#5C2DD3"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('img',{attrs:{"src":require("@/assets/img/forme/enL.png"),"alt":"en L"}})]},proxy:true}])})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-radio',{attrs:{"value":"T","color":"#5C2DD3"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('img',{attrs:{"src":require("@/assets/img/forme/enT.gif"),"alt":"en T"}})]},proxy:true}])})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-radio',{attrs:{"value":"U","color":"#5C2DD3"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('img',{attrs:{"src":require("@/assets/img/forme/enU.png"),"alt":"en U"}})]},proxy:true}])})],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-file-input',{attrs:{"dense":"","accept":"image/*","prepend-icon":"mdi-plus","label":_vm.$i18n.locale === 'fr'
                ? 'Ajouter une photo de la maison'
                : 'Add a photo of the house',"underlined":"","clearable":"","color":"#5C2DD3","hide-details":""},model:{value:(_vm.form2.file),callback:function ($$v) {_vm.$set(_vm.form2, "file", $$v)},expression:"form2.file"}})],1)],1),_c('v-col',{staticClass:"bottom",attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"color":"#5C2DD3","outlined":""},on:{"click":_vm.prevStep}},[_vm._v(" "+_vm._s(_vm.$t('precedant'))+" ")]),_c('v-btn',{attrs:{"color":"#5C2DD3","dark":"","type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t('suivant'))+" ")])],1)],1)]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }