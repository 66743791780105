<template>
  <div class="history-file-projet">
    <v-tabs
      class="mt-3 pt-3"
      color="#5C2DD3"
      item-color="#5C2DD3"
      v-model="tabIndex"
    >
      <v-tab
        v-for="(menu, index) in menuActivite"
        :key="index"
        color="#5C2DD3"
        item-color="#5C2DD3"
      >
        <div class="sous-type">{{ menu.label }}</div>
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tabIndex" class="content-items-tab mt-3">
      <!-- TOUS -->
      <v-tab-item>
        <div
          class="color-crm font-sz-12 font-text text-center bold-700 mb-2"
          v-if="getProjectsProcessingHistory"
        >
          {{ $t('loading') }}
        </div>
        <template v-if="project.timeline">
          <ProjectTimeLine
            v-for="(item, index) in project.timeline"
            :key="`${index}-${item.id}`"
            :entity="item"
            :project="project"
            :readonly="readonly"
          />
        </template>
        <ProjectHistory
          :entity="{
            name: $i18n.locale === 'fr' ? 'Prospect créé' : 'Prospect created',
            date: project.created_at,
            user: project.user_creator
          }"
        />
        <div
          class="color-crm font-sz-12 font-text text-center bold-700"
          v-if="loadingVoirPlus"
        >
          {{ $t('loading') }}
        </div>
        <div
          class="pagination-counter font-sz-12 color-crm font-text  bold-700"
          @click.prevent.stop="handlePaginationChange('tous')"
          v-if="calculTotalPageTous && !loadingVoirPlus"
        >
          {{ $i18n.locale === 'fr' ? 'Voir plus ...' : 'See more ...' }}
        </div>
      </v-tab-item>

      <v-tab-item>
        <div
          v-if="getProjectsProcessingHistory"
          class="color-crm font-sz-12 font-text bold-700"
        >
          {{ $t('loading') }}
        </div>
        <div v-if="activityDone && activityDone.length">
          <ProjectActivity
            v-for="activity in activityDone"
            :entity="activity"
            :key="'project-activity-' + activity.id"
            :project="project"
            :readonly="readonly"
          />
        </div>
        <div v-else class="div-aucun mb-2 mt-2">
          <div class="titre">
            {{ $i18n.locale === 'fr' ? 'Aucune activité' : 'No activity' }}
          </div>
        </div>
        <div
          class="color-crm font-sz-12 font-text bold-700"
          v-if="loadingVoirPlus"
        >
          {{ $t('loading') }}
        </div>
        <div
          class="pagination-counter font-sz-12 color-crm font-text  bold-700"
          @click.prevent.stop="handlePaginationChange('activites')"
          v-if="calculTotalPageActivity && !loadingVoirPlus"
        >
          {{ $i18n.locale === 'fr' ? 'Voir plus ...' : 'See more ...' }}
        </div>
      </v-tab-item>
      <v-tab-item>
        <div
          v-if="getProjectsProcessingHistory"
          class="color-crm font-sz-12 font-text bold-700"
        >
          {{ $t('loading') }}
        </div>
        <div v-if="project && project.comments && project.comments.length">
          <ProjectComment
            v-for="note in project.comments"
            :entity="note"
            :key="'project-comment-' + note.id"
            :project="project"
            :readonly="readonly"
          />
        </div>
        <div v-else class="div-aucun mb-2 mt-2">
          <div class="titre">
            {{ $i18n.locale === 'fr' ? 'Aucune note' : 'No note' }}
          </div>
        </div>
        <div
          class="color-crm font-sz-12 font-text bold-700"
          v-if="loadingVoirPlus"
        >
          {{ $t('loading') }}
        </div>
        <div
          class="pagination-counter font-sz-12 color-crm font-text  bold-700"
          @click.prevent.stop="handlePaginationChange('notes')"
          v-if="calculTotalPageComments && !loadingVoirPlus"
        >
          {{ $i18n.locale === 'fr' ? 'Voir plus ...' : 'See more ...' }}
        </div>
      </v-tab-item>
      <v-tab-item>
        <div
          v-if="getProjectsProcessingHistory"
          class="color-crm font-sz-12 font-text bold-700"
        >
          {{ $t('loading') }}
        </div>
        <ProjectHistory
          v-for="journal in project.journals"
          :entity="journal"
          :key="'project-history-' + journal.id"
          :project="project"
        />
        <ProjectHistory
          :entity="{
            name: $i18n.locale === 'fr' ? 'Prospect créé' : 'Prospect created',
            date: project.created_at,
            user: project.user_creator
          }"
        />
        <div
          class="color-crm font-sz-12 font-text bold-700"
          v-if="loadingVoirPlus"
        >
          {{ $t('loading') }}
        </div>
        <div
          class="pagination-counter font-sz-12 color-crm font-text bold-700"
          @click.prevent.stop="handlePaginationChange('journal')"
          v-if="calculTotalPageJournal && !loadingVoirPlus"
        >
          {{ $i18n.locale === 'fr' ? 'Voir plus ...' : 'See more ...' }}
        </div>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import ProjectTimeLine from './ProjectTimeLine'
import ProjectHistory from './ProjectHistory'
import ProjectActivity from './ProjectActivity'
import ProjectComment from './ProjectComment'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'Files',
  props: {
    project: {
      required: true
    },
    readonly: {
      default: false
    }
  },
  components: {
    ProjectTimeLine,
    ProjectHistory,
    ProjectActivity,
    ProjectComment
  },
  data() {
    return {
      tabIndex: null,
      pageNote: 1,
      pageActivity: 1,
      pageJournal: 1,
      pageTous: 1,
      loadingVoirPlus: false,
      menuActivite: [
        { label: this.$i18n.locale === 'fr' ? 'Tous' : 'All', menu_id: 0 },
        {
          label: this.$i18n.locale === 'fr' ? 'Activités' : 'Activities',
          menu_id: 1
        },
        { label: this.$i18n.locale === 'fr' ? 'Notes' : 'Notes', menu_id: 2 },
        {
          label:
            this.$i18n.locale === 'fr'
              ? 'Journal des modifications'
              : 'Change log',
          menu_id: 3
        }
      ]
    }
  },
  methods: {
    ...mapActions([
      'fetchComments',
      'fetchAllActivities',
      'fetchJournalDesModifications',
      'fetchProjectHistoriques'
    ]),
    async handlePaginationChange(tab) {
      this.loadingVoirPlus = true
      if (tab == 'notes') {
        this.pageNote = this.pageNote + 1
        await this.fetchComments({ project: this.project, page: this.pageNote })
      }
      if (tab == 'activites') {
        this.pageActivity = this.pageActivity + 1
        await this.fetchAllActivities({
          project: this.project,
          page: this.pageActivity
        })
      }
      if (tab == 'journal') {
        this.pageJournal = this.pageJournal + 1
        await this.fetchJournalDesModifications({
          project: this.project,
          page: this.pageJournal
        })
      }
      if (tab == 'tous') {
        this.pageTous = this.pageTous + 1
        await this.fetchProjectHistoriques({
          project: this.project,
          page: this.pageTous
        })
      }
      this.loadingVoirPlus = false
    }
  },
  computed: {
    ...mapGetters([
      'getProjectsProcessingHistory',
      'getProjectsCommentstotalRows',
      'getProjectsActivitytotalRows',
      'getProjectsJournaltotalRows',
      'getProjectsHistoriquetotalRows',
      'getChangePageValue',
      'getObjectPrj'
    ]),
    activityDone() {
      return this.project.activities.filter(t => t.terminer == 1)
    },
    calculTotalPageComments: function() {
      if (
        this.getProjectsCommentstotalRows > 10 &&
        this.getProjectsCommentstotalRows - this.pageNote * 10 > 0
      ) {
        return true
      } else {
        return false
      }
    },
    calculTotalPageActivity: function() {
      if (
        this.getProjectsActivitytotalRows > 10 &&
        this.getProjectsActivitytotalRows - this.pageActivity * 10 > 0
      ) {
        return true
      } else {
        return false
      }
    },
    calculTotalPageJournal: function() {
      if (
        this.getProjectsJournaltotalRows > 10 &&
        this.getProjectsJournaltotalRows - this.pageJournal * 10 > 0
      ) {
        return true
      } else {
        return false
      }
    },
    calculTotalPageTous: function() {
      if (
        this.getProjectsHistoriquetotalRows > 10 &&
        this.getProjectsHistoriquetotalRows - this.pageTous * 10 > 0
      ) {
        return true
      } else {
        return false
      }
    }
  },
  watch: {
    getChangePageValue: function() {
      if (this.getObjectPrj && this.getObjectPrj.object == 'comment') {
        this.pageTous = 1
        this.pageNote = 1
      }
      if (this.getObjectPrj && this.getObjectPrj.object == 'activity') {
        this.pageActivity = 1
        this.pageTous = 1
      }
      if (this.getObjectPrj && this.getObjectPrj.object == 'journal') {
        this.pageJournal = 1
      }
      if (this.getObjectPrj && this.getObjectPrj.object == 'tous') {
        this.pageTous = 1
      }
      if (this.getObjectPrj && this.getObjectPrj.object == 'tousWithjournal') {
        this.pageJournal = 1
        this.pageTous = 1
      }
    }
  }
}
</script>

<style lang="scss">
.history-tabs {
  .nav-tabs {
    justify-content: center;
    background-color: #f3f3f3;
    border-radius: 5px;
    padding: 6px 0;
    margin: 10px 0 36px 0;
    border: none;
    .history-tab-title {
      position: relative;
      font-size: 10px;
      font-weight: bold;
      color: #404346;
      background-color: #e5e5e5;
      border: 1px solid #d5d5d5;
      border-radius: 4px;
      padding: 0px 10px;
      margin: 2px 4px;
      cursor: pointer;
      user-select: none;
      transition: all 0.4s ease;

      .counter {
        color: #858585;
        margin-left: 5px;
      }

      &.active {
        color: #5e5e5e;
        background-color: #fff;
        .counter {
          color: #5c2dd3;
        }
      }
    }
  }
  .pagination-counter {
    font-size: 12px;
    color: #5c2dd3;
    cursor: pointer;
  }
}
.history-file-projet {
  .content-items-tab {
    // .theme--light.v-tabs-items {
    // padding: 0px 32px !important;
    padding: 0px 20px 0px 51px;
    // }
  }
  .v-tabs-bar {
    height: 28px !important;
  }
  .v-tabs {
    padding: 0px 20px 0px 51px !important;
  }
  .v-tab {
    font-size: 12px !important;
    padding: 0 19px !important;
  }
}
</style>
