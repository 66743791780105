<template>
  <Card>
    <template #title>
      <div class="title">
        {{
          $i18n.locale === 'fr'
            ? 'Préconisation Travaux / Travaux réalisable'
            : 'Recommended work / Work feasible'
        }}
      </div>
    </template>
    <template #body>
      <v-form @submit.prevent="submitForm" ref="myForm">
        <v-row>
          <v-col cols="12">
            <v-alert
              v-if="conforme"
              color="success"
              icon="$success"
              dense
              dark
              closable
            >
              {{
                $i18n.locale === 'fr'
                  ? '“Préconisation Conforme“'
                  : '“Recommendation compliant“'
              }}
            </v-alert>
            <v-alert v-else color="error" icon="$error" dark dense closable>
              {{
                $i18n.locale === 'fr'
                  ? '“Préconisation Non-conforme“'
                  : '“Non-compliant recommendation“'
              }}
            </v-alert>
            <v-radio-group v-model="form20.type_travaux" hide-details>
              <v-radio
                :label="
                  $i18n.locale === 'fr'
                    ? 'Préconisation Travaux'
                    : 'Recommended work'
                "
                value="preconisation"
                color="#704ad1"
              ></v-radio>
              <v-radio
                :label="
                  $i18n.locale === 'fr' ? 'Travaux réalisable' : 'Feasible work'
                "
                value="realisable"
                color="#704ad1"
              ></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="3">
            <Card>
              <template #title>
                {{ $i18n.locale === 'fr' ? 'Chauffage' : 'Heating' }}
              </template>
              <template #body>
                <v-select
                  v-for="(chauffage, i) in form20.chauffages"
                  :key="i"
                  v-model="form20.chauffages[i]"
                  label="Type"
                  dense
                  clearable
                  :placeholder="$t('choose')"
                  item-text="label"
                  item-value="value"
                  :items="Chauffages"
                  outlined
                  :persistent-placeholder="true"
                  required
                  color="#704ad1"
                  item-color="#704ad1"
                ></v-select>

                <div class="link" @click="form20.chauffages.push(null)">
                  <font-awesome-icon
                    class="icon"
                    icon="circle-plus"
                    style="color: #704ad1;"
                  />

                  {{
                    $i18n.locale === 'fr'
                      ? 'Ajouter un produit'
                      : 'Add a product'
                  }}
                </div>
              </template>
            </Card>
          </v-col>
          <v-col cols="3">
            <Card>
              <template #title>
                {{ $i18n.locale === 'fr' ? 'Isolation' : 'insulation' }}
              </template>
              <template #body>
                <v-text-field
                  v-for="(isolation, i) in form20.isolations"
                  :key="i"
                  v-model="form20.isolations[i]"
                  placeholder="m2"
                  :persistent-placeholder="true"
                  type="number"
                  :min="0"
                  dense
                  outlined
                  color="#704ad1"
                ></v-text-field>

                <div class="link" @click="form20.isolations.push(0)">
                  <font-awesome-icon
                    class="icon"
                    icon="circle-plus"
                    style="color: #704ad1;"
                  />
                  {{
                    $i18n.locale === 'fr'
                      ? 'Ajouter un produit'
                      : 'Add a product'
                  }}
                </div>
              </template>
            </Card>
          </v-col>
          <v-col cols="3">
            <Card>
              <template #title>
                {{ $i18n.locale === 'fr' ? 'Eau Chaude' : 'Hot water' }}
              </template>
              <template #body>
                <v-select
                  v-for="(eau_chaude, i) in form20.eau_chaudes"
                  :key="i"
                  v-model="form20.eau_chaudes[i]"
                  label="Type"
                  dense
                  clearable
                  :placeholder="$t('choose')"
                  item-text="label"
                  item-value="id"
                  :items="Sanitaires"
                  outlined
                  :persistent-placeholder="true"
                  required
                  color="#704ad1"
                  item-color="#704ad1"
                ></v-select>

                <div class="link" @click="form20.eau_chaudes.push(null)">
                  <font-awesome-icon
                    class="icon"
                    icon="circle-plus"
                    style="color: #704ad1;"
                  />
                  {{
                    $i18n.locale === 'fr'
                      ? 'Ajouter un produit'
                      : 'Add a product'
                  }}
                </div>
              </template>
            </Card>
          </v-col>
          <v-col cols="3">
            <Card>
              <template #title>
                Ventilation
              </template>
              <template #body>
                <v-select
                  v-for="(ventilation, i) in form20.ventilations"
                  :key="i"
                  v-model="form20.ventilations[i]"
                  label="Type"
                  dense
                  clearable
                  :placeholder="$t('choose')"
                  item-text="label"
                  item-value="value"
                  :items="Ventilations"
                  outlined
                  :persistent-placeholder="true"
                  required
                  color="#704ad1"
                  item-color="#704ad1"
                ></v-select>

                <div class="link" @click="form20.ventilations.push(null)">
                  <font-awesome-icon
                    class="icon"
                    icon="circle-plus"
                    style="color: #704ad1;"
                  />
                  {{
                    $i18n.locale === 'fr'
                      ? 'Ajouter un produit'
                      : 'Add a product'
                  }}
                </div>
              </template>
            </Card>
          </v-col>
        </v-row>
        <!-- Bouton "Suivant" pour chaque étape -->
        <v-col cols="12" class="bottom">
          <v-btn color="#5C2DD3" outlined @click="prevStep">
            {{ $t('precedant') }}
          </v-btn>
          <v-btn color="#5C2DD3" dark type="submit">
            {{ $t('suivant') }}
          </v-btn>
        </v-col>
      </v-form>
    </template>
  </Card>
</template>

<script>
import Card from '@/components/CrmIla26Component/Projets/Card.vue'

export default {
  components: {
    Card
  },
  data() {
    return {
      form20: {
        type_travaux: 'preconisation',
        chauffages: [],
        isolations: [],
        eau_chaudes: [],
        ventilations: []
      },
      Chauffages: [
        {
          label: this.$i18n.locale === 'fr' ? 'PAC air/eau' : 'PAC air/water',
          value: 'PAC air/eau'
        },
        {
          label: 'PAC air/air',
          value: 'PAC air/air'
        },
        {
          label:
            this.$i18n.locale === 'fr' ? 'Poele à granules' : 'Pellet stove',
          value: 'Poele à granules'
        },
        {
          label:
            this.$i18n.locale === 'fr' ? 'Chaudiere Granules' : 'Pellet boiler',
          value: 'Chaudière à granules'
        }
      ],
      Sanitaires: [
        {
          label:
            this.$i18n.locale === 'fr' ? 'Ballon thermo.' : 'Thermo balloon.',
          value: 'Ballon thermo.'
        },
        {
          label:
            this.$i18n.locale === 'fr' ? 'Ballon solaire' : 'Solar balloon',
          value: 'Ballon solaire'
        }
      ],
      Ventilations: [
        {
          label:
            this.$i18n.locale === 'fr' ? 'VMC simple flux' : 'Single-flow VMC',
          value: 'VMC simple flux'
        },
        {
          label:
            this.$i18n.locale === 'fr' ? 'VMC double flux' : 'Double-flow VMC',
          value: 'VMC double flux'
        }
      ],
      Isolations: [
        {
          label: this.$i18n.locale === 'fr' ? 'Aucune' : 'None',
          value: null
        },
        {
          label:
            this.$i18n.locale === 'fr'
              ? 'Plus de 10 ans'
              : 'More than 10 years',
          value: 'Plus de 10 ans'
        },
        {
          label:
            this.$i18n.locale === 'fr'
              ? 'Entre 5 et 10 ans'
              : 'Between 5 and 10 years',
          value: 'Entre 5 et 10 ans'
        },
        {
          label:
            this.$i18n.locale === 'fr' ? 'Moins de 5 ans' : 'Less than 5 years',
          value: 'Moins de 5 ans'
        }
      ]
    }
  },
  computed: {
    conforme() {
      var result = true
      if (this.form20.chauffages.length && !this.form20.chauffages[0])
        result = false
      if (this.form20.isolations.length && !this.form20.isolations[0])
        result = false
      if (this.form20.eau_chaudes.length && !this.form20.eau_chaudes[0])
        result = false
      if (this.form20.ventilations.length && !this.form20.ventilations[0])
        result = false

      return result
    }
  },
  methods: {
    resetComponent() {
      // Réinitialiser les données spécifiques à ce composant (par exemple, formulaire)
      this.form20 = {
        type_travaux: 'preconisation',
        chauffages: [],
        isolations: [],
        eau_chaudes: [],
        ventilations: []
      }
    },
    submitForm() {
      // Utilisez $refs pour accéder au formulaire
      const valid = this.$refs.myForm.validate()
      if (!valid) {
        return
      }

      this.$emit('nextStep', this.form20)
    },
    prevStep() {
      this.$emit('prevStep')
    }
  }
}
</script>

<style lang="scss" scoped>
.link {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 4px;
  margin-bottom: 12px;
  .icon {
    cursor: pointer;
    width: 18px;
    height: 18px;
  }
}
.bottom {
  display: flex !important;
  align-items: center;
  justify-content: center !important;
  gap: 16px;
}
</style>
