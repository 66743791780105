var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Card',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$i18n.locale === 'fr' ? 'Ouvrant Facade Nord' : 'Opening north facade')+" ")]),_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('div',{staticClass:"information"},[_c('h1',[_vm._v(" "+_vm._s(_vm.$i18n.locale === 'fr' ? 'Attention' : 'Attention')+" ")]),_c('h4',[_vm._v(" "+_vm._s(_vm.$i18n.locale === 'fr' ? 'Il manque un element :' : 'One element is missing:')+" ")]),_c('h2',[_vm._v(_vm._s(_vm.$i18n.locale === 'fr' ? 'PHOTO :' : 'PHOTO :'))]),_vm._l((_vm.form4.facades),function(facade,i){return _c('p',{key:i},[(!facade.file)?[_vm._v(" "+_vm._s(_vm.$i18n.locale === 'fr' ? `TYPE OUVRANT N° ${i + 1} - FAÇADE NORD.` : `OPENING TYPE N° ${i + 1} - NORTH FACADE.`)+" ")]:_vm._e()],2)})],2)])],1)]},proxy:true},{key:"body",fn:function(){return [_c('v-form',{ref:"myForm",on:{"submit":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-select',{attrs:{"label":_vm.$i18n.locale === 'fr'
                ? 'Nombre de type d’ouvrant'
                : 'Number Type Opening',"dense":"","clearable":"","placeholder":_vm.$i18n.locale === 'fr' ? 'type d’ouvrant' : 'Type of opening',"item-text":"label","item-value":"id","items":_vm.numbers,"outlined":"","persistent-placeholder":true,"required":"","color":"#704ad1","item-color":"#704ad1"},on:{"change":_vm.updateFacade},model:{value:(_vm.number),callback:function ($$v) {_vm.number=$$v},expression:"number"}})],1)],1),(_vm.form4.facades && _vm.form4.facades.length)?_c('v-row',[_c('v-col',{staticStyle:{"overflow-y":"auto"},attrs:{"cols":"12"}},[_c('table',[_c('thead',[_c('tr',[_c('th',[_vm._v(" Type ")]),_c('th',[_vm._v(" "+_vm._s(_vm.$i18n.locale === 'fr' ? 'Largeur (m)' : 'Width (m)')+" ")]),_c('th',[_vm._v(" "+_vm._s(_vm.$i18n.locale === 'fr' ? 'Hauteur (m)' : 'Height (m)')+" ")]),_c('th',[_vm._v(" "+_vm._s(_vm.$i18n.locale === 'fr' ? 'Nombre' : 'Number')+" ")]),_c('th',[_vm._v(" Surface ")]),_c('th',[_vm._v(" "+_vm._s(_vm.$i18n.locale === 'fr' ? 'Vitrage' : 'Glazing')+" ")]),_c('th',[_vm._v(" "+_vm._s(_vm.$i18n.locale === 'fr' ? 'Menuiserie' : 'Joinery')+" ")]),_c('th')])]),_c('tbody',_vm._l((_vm.form4.facades),function(facade,i){return _c('tr',{key:i},[_c('td',[_c('v-select',{attrs:{"dense":"","item-text":"label","item-value":"value","items":_vm.Types,"outlined":"","color":"#704ad1","item-color":"#704ad1","hide-details":""},model:{value:(facade.type),callback:function ($$v) {_vm.$set(facade, "type", $$v)},expression:"facade.type"}})],1),_c('td',[_c('v-text-field',{attrs:{"type":"number","min":0,"dense":"","outlined":"","hide-details":"","color":"#704ad1"},on:{"input":() => {
                        facade.surface =
                          facade.largeur * facade.hauteur * facade.nombre
                      }},model:{value:(facade.largeur),callback:function ($$v) {_vm.$set(facade, "largeur", $$v)},expression:"facade.largeur"}})],1),_c('td',[_c('v-text-field',{attrs:{"type":"number","min":0,"dense":"","outlined":"","hide-details":"","color":"#704ad1"},on:{"input":() => {
                        facade.surface =
                          facade.largeur * facade.hauteur * facade.nombre
                      }},model:{value:(facade.hauteur),callback:function ($$v) {_vm.$set(facade, "hauteur", $$v)},expression:"facade.hauteur"}})],1),_c('td',[_c('v-text-field',{attrs:{"type":"number","min":0,"dense":"","outlined":"","hide-details":"","color":"#704ad1"},on:{"input":() => {
                        facade.surface =
                          facade.largeur * facade.hauteur * facade.nombre
                      }},model:{value:(facade.nombre),callback:function ($$v) {_vm.$set(facade, "nombre", $$v)},expression:"facade.nombre"}})],1),_c('td',[_c('v-text-field',{attrs:{"type":"number","min":0,"dense":"","outlined":"","hide-details":"","readonly":"","color":"#704ad1"},model:{value:(facade.surface),callback:function ($$v) {_vm.$set(facade, "surface", $$v)},expression:"facade.surface"}})],1),_c('td',[_c('v-select',{attrs:{"dense":"","item-text":"label","item-value":"value","items":_vm.Vitrages,"outlined":"","color":"#704ad1","item-color":"#704ad1","hide-details":""},model:{value:(facade.vitrage),callback:function ($$v) {_vm.$set(facade, "vitrage", $$v)},expression:"facade.vitrage"}})],1),_c('td',[_c('v-select',{attrs:{"dense":"","item-text":"label","item-value":"value","items":_vm.Menuiseries,"outlined":"","color":"#704ad1","item-color":"#704ad1","hide-details":""},model:{value:(facade.menuiserie),callback:function ($$v) {_vm.$set(facade, "menuiserie", $$v)},expression:"facade.menuiserie"}})],1),_c('td',[_c('label',{staticStyle:{"margin-bottom":"0"},attrs:{"for":'fileInputNord' + i}},[_c('font-awesome-icon',{staticClass:"mr-2",attrs:{"icon":"camera"}}),(facade.file)?_c('font-awesome-icon',{staticStyle:{"color":"#0cb277"},attrs:{"icon":"check"}}):_c('font-awesome-icon',{staticStyle:{"color":"#ff9000"},attrs:{"icon":"exclamation-triangle"}})],1),_c('v-file-input',{staticStyle:{"display":"none"},attrs:{"rules":[_vm.rules.required],"label":null,"accept":"image/*","id":'fileInputNord' + i},model:{value:(facade.file),callback:function ($$v) {_vm.$set(facade, "file", $$v)},expression:"facade.file"}})],1)])}),0)])]),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"value":_vm.total_surface_ouvrant,"type":"number","label":_vm.$i18n.locale === 'fr'
                ? 'Surface des ouvrants'
                : 'Opening surface',"field":_vm.$i18n.locale === 'fr'
                ? 'Surface des ouvrants'
                : 'Opening surface',"min":0,"dense":"","readonly":"","placeholder":"en m2","outlined":"","persistent-placeholder":true,"color":"#704ad1"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"value":_vm.total_isolable,"type":"number","label":"Surface Isolable","field":"Surface Isolable","min":0,"dense":"","readonly":"","placeholder":"en m2","outlined":"","persistent-placeholder":true,"color":"#704ad1"}})],1)],1):_vm._e(),_c('v-col',{staticClass:"bottom",attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"color":"#5C2DD3","outlined":""},on:{"click":_vm.prevStep}},[_vm._v(" "+_vm._s(_vm.$t('precedant'))+" ")]),_c('v-btn',{attrs:{"color":"#5C2DD3","dark":"","type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t('suivant'))+" ")])],1)],1)]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }