<template>
  <div class="fiche-projet">
    <div class="loader-content" v-if="initLoading">
      <v-progress-circular
        indeterminate
        class="ml-3"
        color="#5C2DD3"
      ></v-progress-circular>
    </div>
    <div v-else>
      <div class="content-header-projet border-top-solid">
        <!-- Title projet -->
        <div class="header-content">
          <div class="header-type-projet">
            <div class="btn-back" @click="BackTableProjet">
              <v-btn dark color="#5C2DD3" class="back">
                <v-icon class="icon-back">mdi-chevron-left</v-icon>
              </v-btn>
            </div>
            <div class="title-type-projet">
              <div class="title">
                {{ $i18n.locale == 'fr' ? 'Projet -' : 'Project -' }}
                <span class="type-projet">
                  {{ data && data.type_name ? data.type_name : '- -' }}
                </span>
              </div>
            </div>
          </div>
          <div
            v-if="
              (getProjectsProcessing || getContactsProcessing) &&
                (!errorMessages || !errorMessages.length)
            "
            class="font-sz-12 color-crm ml-3"
          >
            {{ $t('loading') }}
          </div>
          <div v-if="hasError" class="error-msg ml-3">
            <ul v-if="Array.isArray(errorMessages)">
              <li v-for="(e, index) in errorMessages" :key="index">
                {{ e }}
              </li>
            </ul>
            <span v-else>{{ errorMessages }}</span>
          </div>
          <div class="action-btn-contact">
            <div class="confirmation-items flex-end">
              <div
                :class="{
                  active: data && data.dossier,
                  loading: loading['dossier'],
                  readonly: readonly
                }"
                @click="toggleConfirmation('dossier')"
                class="item"
                :title="$i18n.locale == 'fr' ? 'Dossier' : 'Folder'"
              >
                <v-icon class="color-crm">mdi-file</v-icon>
              </div>
              <div
                :class="{
                  active: data && data.photo,
                  loading: loading['photo'],
                  readonly: readonly
                }"
                @click="toggleConfirmation('photo')"
                class="item"
                :title="$i18n.locale == 'fr' ? 'Photos' : 'Pictures'"
              >
                <v-icon class="color-crm">mdi-image</v-icon>
              </div>
              <div
                :class="{
                  active: data && data.confirmation_previsite,
                  loading: loading['confirmation_previsite'],
                  readonly: readonly
                }"
                @click="toggleConfirmation('confirmation_previsite')"
                class="item"
                title="Confirmation commercial"
              >
                <v-icon class="color-crm">mdi-account</v-icon>
              </div>
              <div
                :class="{
                  active: data.confirmation,
                  loading: loading['confirmation'],
                  readonly: readonly
                }"
                @click="toggleConfirmation('confirmation')"
                class="item"
                title="Confirmation poseur"
              >
                <v-icon class="color-crm">mdi-account-tie</v-icon>
              </div>
            </div>
          </div>
        </div>
        <!-- Information de contact de projet -->
        <div class="info-global-contact">
          <div class="affichage-contact">
            <div class="profile-pic">
              <img
                src="@/assets/img/user-empty.png"
                id="output"
                width="110"
                height="110"
                v-if="data && data.logo == null"
              />
              <img
                id="output"
                width="100"
                height="110"
                :src="data.logo"
                v-else
              />
              <div class="icon-upload-file">
                <v-btn
                  color="#FF9000"
                  dark
                  x-small
                  fab
                  @click="openFileInput"
                  :title="
                    $i18n.locale == 'fr' ? 'Importer image' : 'Import image'
                  "
                >
                  <v-icon color="white">mdi-pencil</v-icon>
                </v-btn>
                <input
                  ref="fileInput"
                  id="file"
                  type="file"
                  style="display: none;"
                />
              </div>
            </div>
            <div class="info-contact">
              <div class="nom-user">Mr.{{ data.contact_full_name }}</div>
              <div class="nom-contact">
                Contact : {{ data.contact_full_name }}
              </div>
              <div class="phone">
                {{ $i18n.locale == 'fr' ? 'Téléphone :' : 'Phone :' }}
                <span>{{
                  data && data.phones
                    ? $options.filters.formatTel(data.phones)
                    : '- -'
                }}</span>
              </div>
              <div class="statut">
                {{ $i18n.locale == 'fr' ? 'Statuts :' : 'Statuts :' }}
                <span
                  :class="{
                    [data.status]: true
                  }"
                  >{{
                    $options.filters.status(data.status, $i18n.locale)
                  }}</span
                >
              </div>
            </div>
          </div>
          <div class="show-statut ml-2">
            <div class="font-sz-12 bold-600 font-text">
              {{
                $i18n.locale == 'fr'
                  ? 'Catégorie du stade :'
                  : 'Stage category :'
              }}
              <span class="bold-700 font-text color-black font-sz-13">
                {{
                  data && data.stage && data.stage.name ? data.stage.name : '-'
                }}</span
              >
            </div>
            <div class="flex">
              <div class="font-sz-12 bold-600 font-text mt-1">
                {{ $i18n.locale == 'fr' ? 'Stade :' : 'Stage :' }}
              </div>
              <div
                class="ml-2"
                v-if="currentSubStages && currentSubStages.length"
              >
                <EditableSelect
                  :readonly="readonly"
                  field="csubstage_id"
                  :options="currentSubStages"
                  :entity="data"
                  :value="data.subStage ? data.subStage.id : null"
                  :updateFunction="updateProject"
                  :autoWidth="true"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- GLOBAL LISTE DES CATEGORIES AND PIPELINES -->
      <div class="block-flex-align-space-between menu-header-categ-pipeline">
        <!-- LISTE DES CATEGORIES -->
        <!--  CATEGORIES -->
        <div v-if="initLoading" class="loader-container">
          <LoadingComponent />
        </div>
        <div class="categories-header" v-else>
          <!-- Menu-peojet -->
          <div class="menu-projet">
            <div class="tabs-project">
              <v-tabs
                :hide-slider="false"
                slider-color="#5C2DD3"
                color="#5C2DD3"
                item-color="#5C2DD3"
                v-model="menuFile"
              >
                <v-tab
                  v-for="item in menuProjects"
                  :key="item.value"
                  color="#5C2DD3"
                  item-color="#5C2DD3"
                  class="tab-menu-projet"
                  @click="toggleLeftTabs(item.value)"
                >
                  {{ item.text }}
                </v-tab>
              </v-tabs>
            </div>
          </div>
        </div>
      </div>
      <v-divider class="line-hr-projet"></v-divider>
      <!-- MENU AND CATEGORIE DE PROJET  -->
      <div class="content-global mt-3">
        <div class="body-content">
          <div class="partie-vide"></div>
          <div class="flex">
            <!-- MENU Categories AND  SOUS CATEGORIIS -->
            <div class="sous-categories-and-menu">
              <div class="menu-header">
                <!-- MENU Categories -->
                <div
                  class="tabs-categories mr-3"
                  :class="{
                    'width-activite': currentTabLeft == 3
                  }"
                >
                  <v-tabs
                    v-if="
                      currentTabLeft == 1 &&
                        data.categoriesContacts &&
                        data.categoriesContacts.length
                    "
                    bg-color="#5C2DD3"
                    :hide-slider="false"
                    slider-color="#5C2DD3"
                    color="#5C2DD3"
                    item-color="#5C2DD3"
                    v-model="menuContact"
                  >
                    <v-tab
                      v-for="(item, index) in data.categoriesContacts"
                      :key="'contact' + index"
                      class="tab-contact-category"
                      :href="`#tab-${index}`"
                      color="#5C2DD3"
                      item-color="#5C2DD3"
                      @click="toggleLeftTabsCategoryContact(index)"
                    >
                      <div
                        class="contact-ctaegory-title"
                        :class="{
                          active: currentTabLeftCategrie == index
                        }"
                      >
                        {{ item.name }}
                      </div>
                    </v-tab>
                  </v-tabs>
                  <!-- PARTIE CONTACT CAS AUCUN CATEGORIES -->
                  <v-tabs
                    v-else-if="
                      currentTabLeft == 1 &&
                        (!data.categoriesContacts ||
                          !data.categoriesContacts.length)
                    "
                    :hide-slider="false"
                    slider-color="#5C2DD3"
                    color="#5C2DD3"
                    item-color="#5C2DD3"
                    v-model="menuProjet"
                  >
                    <v-tab
                      class="tab-contact-category"
                      :href="`#tab-${0}`"
                      color="#5C2DD3"
                      item-color="#5C2DD3"
                    >
                      <div class="contact-ctaegory-title"></div>
                    </v-tab>
                  </v-tabs>
                  <!-- PARTIE PROJET -->
                  <v-tabs
                    v-if="
                      currentTabLeft == 2 &&
                        data.categoriesProjets &&
                        data.categoriesProjets.length
                    "
                    :hide-slider="false"
                    slider-color="#5C2DD3"
                    color="#5C2DD3"
                    item-color="#5C2DD3"
                    v-model="menuProjet"
                  >
                    <v-tab
                      v-for="(item, index) in data.categoriesProjets"
                      :key="'projet' + index"
                      class="tab-contact-category"
                      :href="`#tab-${index}`"
                      color="#5C2DD3"
                      item-color="#5C2DD3"
                      @change="toggleLeftTabsCategoryProjet(index)"
                    >
                      <div
                        class="contact-ctaegory-title"
                        :class="{
                          active: currentTabLeftCategrieProjet == index
                        }"
                      >
                        {{ item.name }}
                      </div>
                    </v-tab>
                  </v-tabs>
                  <!-- PARTIE Projet CAS AUCUN CATEGORIES -->
                  <v-tabs
                    v-else-if="
                      currentTabLeft == 2 &&
                        (!data.categoriesProjets ||
                          !data.categoriesProjets.length)
                    "
                    :hide-slider="false"
                    slider-color="#5C2DD3"
                    color="#5C2DD3"
                    item-color="#5C2DD3"
                    v-model="menuProjet"
                  >
                    <v-tab
                      class="tab-contact-category"
                      :href="`#tab-${0}`"
                      color="#5C2DD3"
                      item-color="#5C2DD3"
                    >
                      <div class="contact-ctaegory-title"></div>
                    </v-tab>
                  </v-tabs>
                  <!-- PARTIE ACTIVITE -->
                  <v-tabs
                    v-if="currentTabLeft == 3"
                    :hide-slider="false"
                    slider-color="#5C2DD3"
                    color="#5C2DD3"
                    item-color="#5C2DD3"
                    v-model="menuActivite"
                  >
                    <v-tab
                      v-for="(item, index) in categoryActivites"
                      :key="'activite' + index"
                      class="tab-contact-category"
                      :href="`#tab-${index}`"
                      color="#5C2DD3"
                      item-color="#5C2DD3"
                      :disabled="item.permission"
                      @click="toggleRightTabs(index + 1)"
                    >
                      <div class="contact-ctaegory-title">{{ item.text }}</div>
                    </v-tab>
                  </v-tabs>
                  <!-- PARTIE FICHIER -->
                  <v-tabs
                    v-if="currentTabLeft == 4"
                    :hide-slider="false"
                    slider-color="#5C2DD3"
                    color="#5C2DD3"
                    item-color="#5C2DD3"
                    v-model="menuFichier"
                  >
                    <v-tab
                      class="tab-contact-category"
                      :href="`#tab-${0}`"
                      color="#5C2DD3"
                      item-color="#5C2DD3"
                    >
                      <div class="contact-ctaegory-title">
                        {{ $i18n.locale === 'fr' ? 'Fichiers' : 'Files' }}
                      </div>
                    </v-tab>
                  </v-tabs>
                </div>
                <!-- MESSAGE CONFIRM -->
                <div
                  class="color-red font-sz-13 bold-600 font-text"
                  v-if="
                    (!data.categoriesProjets ||
                      !data.categoriesProjets.length) &&
                      currentTabLeft == 2
                  "
                >
                  Vous devez créer des catégories et des sous catégories pour ce
                  menu
                </div>
                <div
                  class="color-red font-sz-13 bold-600 font-text"
                  v-if="
                    (!data.categoriesContacts ||
                      !data.categoriesContacts.length) &&
                      currentTabLeft == 1
                  "
                >
                  Vous devez créer des catégories et des sous catégories pour ce
                  menu
                </div>
                <!-- DELETED PROJET -->
                <div
                  v-if="currentUser && currentUser.isSuper"
                  :title="
                    $i18n.locale === 'fr'
                      ? 'Supprimer ce projet'
                      : 'Delete this project'
                  "
                  @click.prevent.stop="handleDeleteProject"
                  class="cursor-pointer mt-1"
                >
                  <font-awesome-icon icon="trash" class="color-crm img-h-19" />
                </div>
              </div>
              <div
                class="flex"
                :class="{ 'margin-releve': currentTabLeft == 5 }"
              >
                <!-- SOUS CATEGORIIS  -->
                <div
                  class="sous-categories"
                  :class="{
                    'loading-data':
                      (!data.categoriesProjets ||
                        !data.categoriesProjets.length) &&
                      currentTabLeft == 2,
                    activite: currentTabLeft == 3,
                    'scroll-releve': currentTabLeft == 5
                  }"
                >
                  <!-- PARTIE CONTACT -->
                  <!-- Sous categories contact -->
                  <div
                    class="cadre-content"
                    v-if="
                      currentTabLeft == 1 &&
                        data.categoriesContacts &&
                        data.categoriesContacts.length
                    "
                  >
                    <template
                      v-for="(sous_category, index) in data.categoriesContacts[
                        currentTabLeftCategrie
                      ].subcategories"
                    >
                      <!-- PARTIE DES CHAMPS DYNAMIQUE SELON LES SOUS TYPES -->
                      <Card
                        :key="'sous_category_subtypes' + index"
                        v-if="
                          sous_category.define_subtype &&
                            currentSousTypes ==
                              sous_category.define_subtype.subtype_name
                        "
                        :IconSwitch="sous_category"
                        :titleMontant="sous_category.fields"
                        :project="data"
                        @switchActive="etatSwitch"
                        :categorie="
                          data.categoriesProjets[currentTabLeftCategrieProjet]
                        "
                        :cardPrincipal="true"
                        categoryType="contact"
                      >
                        <template #title>
                          {{ sous_category.name }}
                        </template>
                        <template #body>
                          <div
                            v-for="field in sous_category.fields"
                            :key="field.id"
                          >
                            <div
                              class="flex justify-between pt-1 mb-2"
                              v-if="field.is_header == 0"
                            >
                              <div class="bold title">{{ field.name }}</div>
                              <div class="value"></div>
                            </div>
                          </div>
                        </template>
                      </Card>
                      <!-- PARTIE DYNAMIQUE DE CONTACT -->
                      <Card
                        :key="'sous_category' + index"
                        v-if="!sous_category.define_subtype"
                        :IconSwitch="sous_category"
                        :titleMontant="sous_category.fields"
                        :project="data"
                        @switchActive="etatSwitch"
                        :cardPrincipal="true"
                        categoryType="contact"
                      >
                        <template #title>
                          {{ sous_category.name }}
                        </template>
                        <template #body>
                          <!-- les champs dynamique -->
                          <v-row>
                            <v-col
                              v-for="field in computedFields(
                                sous_category.fields
                              )"
                              :key="field.id"
                              cols="6"
                            >
                              <div
                                v-if="
                                  field.object && field.object == 'contacts'
                                "
                              >
                                <EditableCustomFieldCategorie
                                  :readonly="readonly"
                                  :field="field"
                                  :entity="data"
                                  :value="field.value"
                                  :category="
                                    data.categoriesContacts[
                                      currentTabLeftCategrie
                                    ].subcategories
                                  "
                                  @updateFunction="actionUpdateContact"
                                  @disabledBtnSave="disabledBtnSave"
                                  :fieldKeyEdit="false"
                                />
                              </div>
                              <div class="value" v-else>
                                <EditableCustomFieldCategorie
                                  :readonly="readonly"
                                  :field="field"
                                  :category="
                                    data.categoriesContacts[
                                      currentTabLeftCategrie
                                    ].subcategories
                                  "
                                  :entity="data"
                                  :value="field.value"
                                  @updateFunction="actionUpdateProject"
                                  @disabledBtnSave="disabledBtnSave"
                                  :fieldKeyEdit="false"
                                />
                              </div>
                            </v-col>
                            <v-col
                              cols="11"
                              v-if="
                                !computedFields(sous_category.fields) ||
                                  !computedFields(sous_category.fields).length
                              "
                              class="div-aucun ml-3"
                            >
                              <div class="titre">
                                {{
                                  $i18n.locale === 'fr'
                                    ? 'Aucun champ disponible pour cette sous-catégorie'
                                    : 'No fields available for this sub-category'
                                }}
                              </div>
                            </v-col>
                          </v-row>
                        </template>
                      </Card>
                    </template>

                    <!-- Sous categories Contact : Liste des projets de contact -->
                  </div>
                  <!-- PARTIE PROJET  -->
                  <!-- sous-types des projet -->
                  <div
                    v-if="
                      currentTabLeft == 2 &&
                        data.categoriesProjets &&
                        data.categoriesProjets.length &&
                        data.categoriesProjets[currentTabLeftCategrieProjet] &&
                        data.categoriesProjets[currentTabLeftCategrieProjet]
                          .subtypes &&
                        data.categoriesProjets[currentTabLeftCategrieProjet]
                          .subtypes.length
                    "
                  >
                    <SousTypeProjet
                      :subtypes="
                        data.categoriesProjets[currentTabLeftCategrieProjet]
                          .subtypes
                      "
                      :readonly="readonly"
                      @sousType="changeSousType"
                      :data="data"
                    ></SousTypeProjet>
                  </div>
                  <!-- Sous categories projet -->
                  <div
                    class="tab-content"
                    v-if="
                      currentTabLeft == 2 &&
                        data.categoriesProjets &&
                        data.categoriesProjets.length
                    "
                  >
                    <template
                      v-for="(sous_category, index) in data.categoriesProjets[
                        currentTabLeftCategrieProjet
                      ].subcategories"
                    >
                      <!-- PARTIE DES CHAMPS DYNAMIQUE SELON LES SOUS TYPES -->
                      <Card
                        :key="'sous_category_subtypes' + index"
                        v-if="
                          sous_category.define_subtype &&
                            currentSousTypes ==
                              sous_category.define_subtype.subtype_name
                        "
                        :IconSwitch="sous_category"
                        :titleMontant="sous_category.fields"
                        :project="data"
                        @switchActive="etatSwitch"
                        :subtypes="
                          data.categoriesProjets[currentTabLeftCategrieProjet]
                            .subtypes
                        "
                        :categorie="
                          data.categoriesProjets[currentTabLeftCategrieProjet]
                        "
                        categoryType="projet"
                      >
                        <template #title>
                          {{ sous_category.name }}
                        </template>
                        <template #body>
                          <!-- les champs dynamiques -->
                          <v-row>
                            <v-col
                              v-for="field in computedFields(
                                sous_category.fields
                              )"
                              :key="field.id"
                              cols="6"
                            >
                              <div>
                                <EditableCustomFieldCategorie
                                  :readonly="readonly"
                                  :field="field"
                                  :entity="data"
                                  :value="field.value"
                                  @updateFunction="actionUpdateProject"
                                  @disabledBtnSave="disabledBtnSave"
                                  :category="
                                    data.categoriesProjets[
                                      currentTabLeftCategrieProjet
                                    ].subcategories
                                  "
                                  :fieldKeyEdit="false"
                                />
                              </div>
                            </v-col>
                            <v-col
                              cols="11"
                              v-if="
                                !computedFields(sous_category.fields) ||
                                  !computedFields(sous_category.fields).length
                              "
                              class="div-aucun ml-3"
                            >
                              <div class="titre">
                                {{
                                  $i18n.locale === 'fr'
                                    ? 'Aucun champ disponible pour cette sous-catégorie'
                                    : 'No fields available for this sub-category'
                                }}
                              </div>
                            </v-col>
                          </v-row>
                        </template>
                      </Card>
                      <!-- PARTIE DES CHAMP DYNAMIQUE  -->
                      <Card
                        :key="'sous_category' + index"
                        v-if="!sous_category.define_subtype"
                        :IconSwitch="sous_category"
                        :titleMontant="sous_category.fields"
                        :project="data"
                        @switchActive="etatSwitch"
                        :showButton="
                          data && data.precarity ? data.precarity : '-'
                        "
                        :readonly="readonly"
                        categoryType="projet"
                      >
                        <template #title>
                          {{ sous_category.name }}
                        </template>
                        <template #body>
                          <!-- PARTIE DES CHAMP DYNAMIQUE  -->
                          <v-row>
                            <v-col
                              v-for="field in computedFields(
                                sous_category.fields
                              )"
                              :key="field.id"
                              cols="6"
                            >
                              <div v-if="field.is_header == 0">
                                <div
                                  v-if="
                                    data.categoriesProjets[
                                      currentTabLeftCategrieProjet
                                    ].id != 22
                                  "
                                >
                                  <div v-if="field.object == 'contacts'">
                                    <div>
                                      <EditableCustomFieldCategorie
                                        :readonly="readonly"
                                        :field="field"
                                        :entity="data"
                                        :value="field.value"
                                        @updateFunction="actionUpdateProject"
                                        @disabledBtnSave="disabledBtnSave"
                                        :category="
                                          data.categoriesProjets[
                                            currentTabLeftCategrieProjet
                                          ].subcategories
                                        "
                                        :fieldKeyEdit="false"
                                      />
                                    </div>
                                  </div>
                                  <div
                                    v-else
                                    :class="{
                                      'block-flex-align-space-between ':
                                        field.key == 'date_previsite' ||
                                        field.key == 'date_travaux'
                                    }"
                                  >
                                    <EditableCustomFieldCategorie
                                      :readonly="readonly"
                                      :field="field"
                                      :entity="data"
                                      :value="field.value"
                                      @updateFunction="actionUpdateProject"
                                      @disabledBtnSave="disabledBtnSave"
                                      :category="
                                        data.categoriesProjets[
                                          currentTabLeftCategrieProjet
                                        ].subcategories
                                      "
                                      :styleWidthInput="
                                        field.key == 'date_previsite' ||
                                        field.key == 'date_travaux'
                                          ? '95%'
                                          : '100%'
                                      "
                                      :fieldKeyEdit="false"
                                    />
                                    <div
                                      v-if="field.key == 'date_previsite'"
                                      class="injected-planning"
                                    >
                                      <router-link
                                        :to="
                                          '/planning-commerciaux/depot/' +
                                            data.depot_id_planning_comms +
                                            '/semaine/' +
                                            data.week_id_planning_comms
                                        "
                                        target="_blank"
                                        class="disabled"
                                        :class="{
                                          active:
                                            data.depot_id_planning_comms &&
                                            data.week_id_planning_comms
                                        }"
                                      >
                                        <font-awesome-icon
                                          class="icon color-crm"
                                          icon="external-link-alt"
                                          title="Voir planning commerciaux"
                                        />
                                      </router-link>
                                    </div>
                                    <div
                                      v-if="field.key == 'date_travaux'"
                                      class="injected-planning"
                                    >
                                      <router-link
                                        :to="
                                          '/planning-poseurs/depot/' +
                                            data.depot_id_planning_poseurs +
                                            '/semaine/' +
                                            data.week_id_planning_poseurs
                                        "
                                        target="_blank"
                                        class="disabled"
                                        :class="{
                                          active:
                                            data.depot_id_planning_poseurs &&
                                            data.week_id_planning_poseurs
                                        }"
                                      >
                                        <font-awesome-icon
                                          class="icon color-crm"
                                          icon="external-link-alt"
                                          title="Voir planning poseurs"
                                        />
                                      </router-link>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </v-col>
                            <v-col
                              cols="11"
                              v-if="
                                !computedFields(sous_category.fields) ||
                                  !computedFields(sous_category.fields).length
                              "
                              class="div-aucun ml-3"
                            >
                              <div class="titre">
                                {{
                                  $i18n.locale === 'fr'
                                    ? 'Aucun champ disponible pour cette sous-catégorie'
                                    : 'No fields available for this sub-category'
                                }}
                              </div>
                            </v-col>
                          </v-row>
                        </template>
                      </Card>
                    </template>
                  </div>
                  <!-- cas aucun sous category -->

                  <!-- Sous categories Activites -->
                  <div class="tab-content" v-if="currentTabLeft == 3">
                    <Card :notBody="true">
                      <template #body>
                        <!-- Ajouter Activites -->
                        <div
                          class="tab-content margin-content"
                          v-if="currentTabRight == 1 && !readonly"
                        >
                          <div class="activity-form">
                            <Event :project="data" :readonly="readonly"></Event>
                          </div>
                        </div>
                        <!-- Ajouter Commentaire -->
                        <div
                          class="tab-content margin-content"
                          v-if="currentTabRight == 2"
                        >
                          <v-row>
                            <v-col class="scroll-content-note">
                              <div class="ml-2 font-sz-13 font-text mb-1">
                                {{
                                  $i18n.locale === 'fr'
                                    ? 'Description :'
                                    : 'Description :'
                                }}
                              </div>
                              <e-editor ref="eeditor" v-model="commentToAdd"
                            /></v-col>
                          </v-row>

                          <v-row>
                            <v-col>
                              <div class="action-form-activity float-right">
                                <v-btn
                                  class="mr-2"
                                  color="#5C2DD3"
                                  dark
                                  @click.prevent.stop="addNote"
                                >
                                  {{ $t('btnValider') }}
                                </v-btn>
                                <v-btn text @click.prevent.stop="deleteComment">
                                  {{ $t('btnCancel') }}
                                </v-btn>
                              </div></v-col
                            >
                          </v-row>
                        </div>
                        <!-- Ajouter Fichiers -->
                        <div
                          class="tab-content margin-content"
                          v-if="currentTabRight == 3 && !readonly"
                        >
                          <v-form ref="refAjouterFile">
                            <v-row>
                              <v-col class="input-file-projet">
                                <v-file-input
                                  :label="
                                    $i18n.locale === 'fr' ? 'Fichier' : 'File'
                                  "
                                  v-model="files"
                                  :placeholder="
                                    $i18n.locale === 'fr'
                                      ? 'Aucun fichier sélectionné'
                                      : 'No files selected'
                                  "
                                  multiple
                                  clearable
                                  persistent-hint
                                  outlined
                                  dense
                                  :persistent-placeholder="true"
                                  prepend-inner-icon
                                  color="#5C2DD3"
                                  item-color="#5C2DD3"
                                  required
                                  validate-on-blur
                                  :rules="[
                                    v =>
                                      (v && v.length > 0) ||
                                      $t('msgObligMsg', {
                                        msg:
                                          $i18n.locale === 'fr'
                                            ? 'Fichiers'
                                            : 'Files'
                                      })
                                  ]"
                                >
                                  <template v-slot:selection="{ index, text }">
                                    <v-chip
                                      v-if="index < 2"
                                      label
                                      small
                                      close
                                      @click:close="remove(text)"
                                    >
                                      {{ text }}
                                    </v-chip></template
                                  >
                                </v-file-input>
                              </v-col>
                            </v-row>
                            <v-row
                              class="scroll-content-file"
                              v-if="files && files.length"
                            >
                              <v-col>
                                <div
                                  v-if="files && files.length"
                                  class="file-header-titles"
                                >
                                  <div class="file-name-title">
                                    {{
                                      $i18n.locale === 'fr'
                                        ? 'Fichiers'
                                        : 'Files'
                                    }}
                                  </div>
                                  <div class="file-category-title">
                                    {{
                                      $i18n.locale === 'fr'
                                        ? 'Catégories'
                                        : 'Category'
                                    }}
                                  </div>
                                </div>
                                <template v-for="(item, index) in files">
                                  <div
                                    class="files-to-upload"
                                    :key="'files-' + item.name"
                                  >
                                    <p class="file-name text-center">
                                      <font-awesome-icon
                                        class="file-upload-icon mr-1 img-h-14"
                                        icon="paperclip"
                                      />
                                      {{ item.name }}
                                    </p>
                                    <v-select
                                      v-model="selectCategories[index]"
                                      dense
                                      :label="
                                        $i18n.locale === 'fr'
                                          ? 'Catégories'
                                          : 'Category'
                                      "
                                      :no-data-text="
                                        $t('noDataOptionMsg', {
                                          option:
                                            $i18n.locale === 'fr'
                                              ? 'Catégories'
                                              : 'Category'
                                        })
                                      "
                                      :items="getProjectsCategories"
                                      outlined
                                      return-object
                                      :persistent-placeholder="true"
                                      required
                                      validate-on-blur
                                      :rules="[
                                        v =>
                                          !!v ||
                                          $t('msgObligMsg', {
                                            msg:
                                              $i18n.locale === 'fr'
                                                ? 'Catégories'
                                                : 'Category'
                                          })
                                      ]"
                                      class="msg-error mt-2 select-category-file"
                                      :menu-props="{
                                        bottom: true,
                                        offsetY: true
                                      }"
                                      color="#5C2DD3"
                                      item-color="#5C2DD3"
                                    ></v-select>
                                  </div>
                                </template>
                              </v-col>
                            </v-row>
                          </v-form>
                          <v-row>
                            <v-col>
                              <div
                                v-if="loadingFile"
                                class="color-crm font-text font-sz-12 bold-700"
                              >
                                {{ $t('loading') }}
                              </div>
                            </v-col>
                            <v-col>
                              <div class="action-form-activity float-right">
                                <v-btn
                                  class="mr-2"
                                  color="#5C2DD3"
                                  dark
                                  @click.prevent.stop="ajouterFichier"
                                >
                                  {{ $t('btnValider') }}
                                </v-btn>
                                <v-btn
                                  text
                                  @click.prevent.stop="handleCancelFile"
                                >
                                  {{ $t('btnCancel') }}
                                </v-btn>
                              </div></v-col
                            >
                          </v-row>
                        </div>
                        <!-- Ajouter Injection -->
                        <div
                          class="tab-content margin-content"
                          v-if="currentTabRight == 4"
                        >
                          <div
                            v-if="
                              getProjectsSousTypes &&
                                getProjectsSousTypes.length
                            "
                          >
                            <div
                              class="content-injection"
                              v-for="(item, index) in getProjectsSousTypes"
                              :key="index"
                            >
                              <div class="inject-type-pixel">
                                <div class="soustype-pixel">
                                  {{ item.name_subtype }}
                                </div>

                                <div class="type-pixel">
                                  <v-select
                                    v-model="selectedTypeInjecte[index]"
                                    @change="
                                      changeTypePixel(
                                        selectedTypeInjecte[index],
                                        item
                                      )
                                    "
                                    label="Pixel"
                                    dense
                                    :no-data-text="$t('noDataOption')"
                                    item-text="name"
                                    item-value="id"
                                    :items="[
                                      { id: null, name: '' },
                                      ...getProjectsTypesPixels
                                    ]"
                                    outlined
                                    :loading="getProjectsTypesPixelsLoading"
                                    :persistent-placeholder="true"
                                    :menu-props="{
                                      bottom: true,
                                      offsetY: true
                                    }"
                                    color="#5C2DD3"
                                    item-color="#5C2DD3"
                                  ></v-select>
                                </div>
                                <div class="button-injection">
                                  <b-button
                                    v-if="
                                      item &&
                                        item.pixel_id &&
                                        item.pixel_type &&
                                        item.colorButton
                                    "
                                    variant="success"
                                  >
                                    <a
                                      v-if="item.pixel_id"
                                      :href="
                                        `https://crm.pixel-crm.com/Dossiers/Isolation/Fiche/EditMain?id=${item.pixel_id}`
                                      "
                                      target="_blank"
                                      :title="
                                        $i18n.locale === 'fr'
                                          ? 'Voir Pixel CRM'
                                          : 'See Pixel CRM'
                                      "
                                      class="pixel-lien"
                                    >
                                      {{
                                        $i18n.locale === 'fr'
                                          ? 'Injecté'
                                          : 'Injected'
                                      }}
                                      <font-awesome-icon
                                        class="icon ml-1"
                                        icon="external-link-alt"
                                      />
                                    </a>
                                  </b-button>
                                  <b-button
                                    v-else
                                    variant="danger"
                                    @click="
                                      handleInjection(
                                        item,
                                        index,
                                        selectedTypeInjecte[index]
                                      )
                                    "
                                  >
                                    {{
                                      $i18n.locale === 'fr'
                                        ? 'À injecter'
                                        : 'Injection'
                                    }}
                                  </b-button>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            v-if="
                              !getProjectsProcessing &&
                                (!getProjectsSousTypes ||
                                  !getProjectsSousTypes.length)
                            "
                            class="div-aucun ml-3 mr-3"
                          >
                            <div class="titre">
                              {{
                                $i18n.locale === 'fr'
                                  ? 'Aucun sous type'
                                  : 'No sub-type'
                              }}
                            </div>
                          </div>
                          <div class="message">
                            <div
                              v-if="getProjectsProcessing"
                              class="loading text-center"
                            >
                              {{ $t('loading') }}
                            </div>
                            <div v-if="getProjectsError" class="error-msg">
                              <ul v-if="Array.isArray(getProjectsError)">
                                <li
                                  v-for="(e, index) in getProjectsError"
                                  :key="index"
                                >
                                  {{ e }}
                                </li>
                              </ul>
                              <span v-else>{{ getProjectsError }}</span>
                            </div>
                          </div>
                        </div>
                      </template>
                    </Card>
                  </div>
                  <!-- Partie fichier -->
                  <!-- Sous categories fichiers -->
                  <div class="tab-content" v-if="currentTabLeft == 4">
                    <Card :notBody="true">
                      <template #body>
                        <WAFiles
                          :project="data"
                          :readonly="readonly"
                          :categories="getProjectsCategories"
                        />
                      </template>
                    </Card>
                  </div>
                  <!-- PARTIE RELEVE -->
                  <div class="tab-content" v-if="currentTabLeft == 5">
                    <Card :notBody="true">
                      <template #body>
                        <ProjectReleve></ProjectReleve>
                      </template>
                    </Card>
                  </div>
                  <!-- PARTIE PROJET  -->
                </div>
                <!-- LES CHAMPS FIELD INJECTION ENTETE -->
                <!--  CATEGORIES -->
                <div
                  class="champ-fields"
                  :class="{ activite: currentTabLeft == 3 }"
                >
                  <div
                    class="tabs-categories-fields"
                    :class="{ 'scroll-activite': currentTabLeft == 3 }"
                  >
                    <Card
                      :notBody="true"
                      v-if="currentTabLeft == 3"
                      :epinglerNote="true"
                    >
                      <template #title>
                        <!-- epingler une commentaire -->
                        <ProjectComment
                          v-for="comment in computedNoteDesepingle"
                          :key="comment.id"
                          :entity="comment"
                          :project="data"
                          :sticky="true"
                          :readonly="readonly"
                        />
                      </template>
                      <template #body>
                        <!-- HISTORIQUE INJECTION -->
                        <div
                          class="historique-injection"
                          v-if="currentTabRight == 4"
                        >
                          <div class="title-historique">
                            {{
                              $i18n.locale === 'fr'
                                ? 'Historique des injections'
                                : 'Injection history'
                            }}
                          </div>
                          <div
                            v-if="getProjectshistoryInjectionPixelLoading"
                            class="loading text-center"
                          >
                            {{ $t('loading') }}
                          </div>
                          <WAHistoryInjection
                            :historyPixel="getProjectshistoryInjectionPixel"
                            :readonly="readonly"
                          />
                          <div
                            class="time-line"
                            v-if="
                              getProjectshistoryInjectionPixel &&
                                getProjectshistoryInjectionPixel.length
                            "
                          >
                            <div class="time-line-container">
                              <div class="line"></div>
                            </div>
                          </div>
                        </div>
                        <!-- HISTORIQUE ACTIVITES -->
                        <div class="historique-activities" v-else>
                          <div class="title-historique">
                            {{
                              $i18n.locale === 'fr' ? 'Programmée' : 'Scheduled'
                            }}
                          </div>
                          <div class="incoming mt-3">
                            <div
                              v-if="getProjectsProcessingHistory"
                              class="color-crm font-text font-sz-12 text-center bold-700 mb-2"
                            >
                              {{ $t('loading') }}
                            </div>
                            <ProjectActivity
                              v-for="activity in computedNonTerminer"
                              :entity="activity"
                              :key="'computed-non-terminer-' + activity.id"
                              :project="data"
                              :readonly="readonly"
                              :page="currentPage"
                            />
                          </div>
                          <div class="title-historique">
                            {{ $i18n.locale === 'fr' ? 'Fait' : 'Done' }}
                          </div>
                          <WAHistory :project="data" :readonly="readonly" />
                          <div class="time-line">
                            <div class="time-line-container">
                              <div class="line"></div>
                            </div>
                          </div>
                        </div>
                      </template>
                    </Card>
                  </div>
                  <!-- FOOTER ACTION UPDATE  -->
                  <div
                    class="action-file-projet flex center"
                    v-if="
                      currentTabLeft != 3 &&
                        currentTabLeft != 4 &&
                        currentTabLeft != 5 &&
                        computedVisibilityBtn
                    "
                  >
                    <v-btn
                      color="#5C2DD3"
                      dark
                      @click="saveUpdateCategory"
                      :disabled="disabledBtnSaveFields"
                      :loading="loadingProjet"
                      :class="{ 'disabled-button': disabledBtnSaveFields }"
                    >
                      {{ $t('btnValider') }}
                    </v-btn>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- DELETED  PROJET -->
    <v-dialog v-model="deleteProjectModal" persistent max-width="600">
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">
            {{
              $i18n.locale === 'fr' ? 'Supprimer un projet' : 'Delete project'
            }}
          </v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click="closeModal('deleteProjectModal')"
            :title="$t('close')"
            color="#5C2DD3"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="mt-2">
          <div>
            {{
              $t('msgdeleted', {
                msg: $i18n.locale === 'fr' ? 'ce projet' : 'this project'
              })
            }}
            <strong>
              {{ data.title ? ', ' + data.title : '' }}
            </strong>
            ?
            <div class="error-msg mt-3 mb-2 font-sz-14">
              <p class="flex-center mb-0">
                <span>
                  <font-awesome-icon
                    class="mr-2 img-h-19 mt-1"
                    icon="exclamation-circle"
                /></span>
                <span class="">
                  {{
                    $i18n.locale === 'fr'
                      ? 'La suppression sera définitive !'
                      : 'The deletion will be definitive!'
                  }}</span
                >
              </p>
            </div>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <div class="message">
            <div
              v-if="getProjectsProcessing || getContactsProcessing"
              class="loading"
            >
              {{ $t('loading') }}
            </div>
            <div v-if="getProjectsError" class="error-msg">
              <ul v-if="Array.isArray(getProjectsError)">
                <li v-for="(e, index) in getProjectsError" :key="index">
                  {{ e }}
                </li>
              </ul>
              <span v-else>{{ getProjectsError }}</span>
            </div>
          </div>
          <v-spacer></v-spacer>
          <v-btn
            color="#5C2DD3"
            dark
            @click.prevent.stop="suprimerProject"
            small
          >
            {{ $t('btnValider') }}
          </v-btn>
          <v-btn text @click="closeModal('deleteProjectModal')" small>
            {{ $t('btnCancel') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Card from '@/components/CrmIla26Component/Projets/Card.vue'
import SousTypeProjet from '@/components/CrmIla26Component/Projets/SousTypeProjet.vue'
import LoadingComponent from '@/components/ui/LoadingComponent.vue'
// import EditableInput from '@/components/CrmIla26Component/EditableInput.vue'
// import EditableInputListFiscal from '@/components/CrmIla26Component/EditableInputListFiscal.vue'
import EditableCustomFieldCategorie from '@/components/CrmIla26Component/EditableCustomFieldCategorie.vue'
import EditableSelect from '@/components/CrmIla26Component/EditableSelect'
import moment from 'moment'
import momentDurationFormatSetup from 'moment-duration-format'
import ProjectComment from '@/components/CrmIla26Component/Projets/ProjectComment'
import EEditor from '@/components/CrmIla26Component/Projets/EEditor'
import ProjectActivity from '@/components/CrmIla26Component/Projets/ProjectActivity'
import WAHistory from '@/components/CrmIla26Component/Projets/WAHistory'
import WAFiles from '@/components/CrmIla26Component/Projets/WAFiles'
import Event from '@/components/CrmIla26Component/Projets/Event'
import ProjectReleve from './ProjectReleve.vue'
import WAHistoryInjection from '@/components/CrmIla26Component/Projets/WAHistoryInjection'
momentDurationFormatSetup(moment)
moment.locale('fr')
export default {
  name: 'project-modal',
  components: {
    Card,
    SousTypeProjet,
    LoadingComponent,
    EditableCustomFieldCategorie,
    EditableSelect,
    ProjectComment,
    EEditor,
    ProjectActivity,
    WAHistory,
    WAFiles,
    Event,
    ProjectReleve,
    WAHistoryInjection
  },
  props: {
    data: {
      default: null
    },
    readonly: {
      default: false
    }
  },
  data() {
    return {
      openAction: false,
      menuFile: 0,
      menuContact: 0,
      menuProjet: 0,
      menuFichier: 0,
      menuActivite: 0,
      choisiSubstage: false,
      deleteProjectModal: false,
      menuProjects: [
        { text: 'Contact', value: 1 },
        { text: this.$i18n.locale === 'fr' ? 'Projet' : 'Project', value: 2 },
        {
          text: this.$i18n.locale === 'fr' ? 'Activités' : 'Activities',
          value: 3
        },
        { text: this.$i18n.locale === 'fr' ? 'Fichiers' : 'Files', value: 4 },
        { text: this.$i18n.locale === 'fr' ? 'Releve' : 'Releve', value: 5 }
      ],
      categoryActivites: [
        {
          text: this.$i18n.locale === 'fr' ? 'Activités' : 'Activities',
          value: 1,
          permission: this.readonly ? true : false
        },
        {
          text: this.$i18n.locale === 'fr' ? 'Notes' : 'Notes',
          value: 2,
          permission: false
        },
        {
          text: this.$i18n.locale === 'fr' ? 'Fichiers' : 'Files',
          value: 3,
          permission: this.readonly ? true : false
        },
        {
          text: this.$i18n.locale === 'fr' ? 'Injection' : 'Injection',
          value: 4,
          permission: this.readonly ? true : false
        }
      ],
      // update project data
      currentTabLeft: 1,
      currentTabLeftCategrie: 0,
      currentTabLeftCategrieProjet: 0,
      currentTabRight: !this.readonly ? 1 : 2,
      currentSousTypes: 0,
      commentToAdd: '',
      files: [],
      selectCategories: [],
      loading: [],
      nextStage: null,
      nextSubStages: [],
      nextSubStage: null,
      nextSubStageError: null,
      error: null,
      currentSousTypesId: null,
      selectedTypeInjecte: [],
      colorButton: [],
      currentPage: 1,
      reset: 0,
      payloadContactCategory: null,
      payloadProjetCategory: null,
      payloadCategory: null,
      disabledBtnSaveFields: true,
      initLoading: false,
      loadingFile: false,
      loadingProjet: false,
      errorMessages: null
    }
  },
  methods: {
    ...mapActions([
      'updateProject',
      'updateContact',
      'addComment',
      'addFichier',
      'fetchProjectHistoriques',
      'fetchAllActivities',
      'fetchJournalDesModifications',
      'fetchComments',
      'fetchFiles',
      'updateNote',
      'downloadFile',
      'deleteFile',
      'UpdateFile',
      'updateProjectBash',
      'refrechProject',
      'deleteProject',
      'fetchProjectsByContact',
      'fetchCategorieType',
      'activeDesactiveSwitchSousCategory',
      'fetchSingleProject',
      'fetchProjectTypesPixel',
      'injectionPixel',
      'fetchProjectsHistoryPixel',
      'fetchProjectSousType',
      'resetErrorProject',
      'resetPageValue',
      'resetErrorContact',
      'fetchEtapeStage',
      'fetchEtapeSubstage'
    ]),
    remove(item) {
      this.files.splice(this.files.indexOf(item), 1)
    },
    rotateFlechPipeline(stage) {
      stage.flech = !stage.flech
    },
    disabledBtnSave() {
      this.disabledBtnSaveFields = false
    },
    async saveUpdateCategory() {
      this.loadingProjet = true
      if (
        this.payloadCategory &&
        this.payloadCategory.fieldContact &&
        this.payloadCategory.fieldContact.length
      ) {
        await this.updateContact({
          entity: this.payloadCategory.entity,
          field: this.payloadCategory.fieldContact
        })
      }
      if (
        this.payloadCategory &&
        this.payloadCategory.fieldProjet &&
        this.payloadCategory.fieldProjet.length
      ) {
        await this.updateProject({
          entity: this.payloadCategory.entity,
          field: this.payloadCategory.fieldProjet
        })
      }
      if (!this.errorMessages || !this.errorMessages.length) {
        this.refrechProject({ project: this.data, reset: 1 })
      }
      this.loadingProjet = false
    },
    actionUpdateProject(payload) {
      this.payloadCategory = payload
    },
    actionUpdateContact(payload) {
      this.payloadCategory = payload
    },
    BackTableProjet() {
      // if (this.$route.path !== '/projects') {
      //   this.$router.push('/projects')
      // }
      this.data.checked = false
      this.$emit('closeModal', this.data.checked)
    },
    openFileInput() {
      this.$refs.fileInput.click()
    },
    changeTypePixel(type_pixel, object) {
      if (
        type_pixel.id == object.pixel_type_id &&
        type_pixel.name == object.pixel_type
      ) {
        object.colorButton = true
      } else {
        object.colorButton = false
      }
    },
    uploadFile(event) {
      this.files = event.target.files
    },
    handleCancelFile() {
      this.currentTabRight = 3
      this.resetInputFile()
      this.$refs.refAjouterFile.resetValidation()
    },
    resetInputFile() {
      this.files = []
      this.selectCategories = []
    },
    async ajouterFichier() {
      if (this.$refs.refAjouterFile.validate()) {
        this.loadingFile = true
        const formData = new FormData()
        for (const i of Object.keys(this.files)) {
          formData.append('fichier' + '[' + i + ']' + '[file]', this.files[i])
          formData.append(
            'fichier' + '[' + i + ']' + '[categorie]',
            this.selectCategories[i]
          )
        }
        formData.append('projet_id', this.data.id)
        await this.addFichier({
          data: formData,
          project: this.data
        })
        this.resetPageValue({ object: 'tous' })
        this.fetchProjectHistoriques({
          project: this.data,
          page: this.currentPage,
          reset: 1
        })
        this.resetInputFile()
        this.loadingFile = false
      }
    },
    handleClose() {
      this.data.checked = false
      this.$emit('closeModal', this.data.checked)
    },
    updateStage(stage) {
      if (this.readonly) {
        return
      }

      if (stage.subStages && stage.subStages.length) {
        this.nextSubStage = null
        this.nextSubStageError = null
        this.nextStage = stage
        this.nextSubStages = stage.subStages
        if (this.nextSubStages.length > 0) {
          this.nextSubStage = this.nextSubStages[0].id
        }
        this.choisiSubstage = true
      } else {
        this.updateProject({
          entity: this.data,
          field: 'cstage_id',
          value: stage.id
        })
      }
    },
    async toggleConfirmation(field) {
      if (this.readonly) {
        return
      }
      this.loading[field] = true
      const payload = {
        entity: this.data,
        field: field,
        value: this.data[field] == 1 ? 0 : 1
      }
      await this.updateProject(payload)
      this.resetPageValue({ object: 'journal' })
      this.loading[field] = false
    },
    async toggleRightTabs(tab) {
      if (this.currentTabRight === tab) {
        this.currentTabRight = tab
        return
      }
      this.currentTabRight = tab
      if (this.currentTabRight == '4') {
        if (this.getProjectsSousTypes && this.getProjectsSousTypes.length) {
          for (
            let index = 0;
            index < this.getProjectsSousTypes.length;
            index++
          ) {
            const type = this.getProjectsSousTypes[index]
            this.selectedTypeInjecte[index] = {
              id: type.pixel_type_id,
              name: type.pixel_type
            }
            if (type.pixel_type_id && type.pixel_type) {
              type.colorButton = true
            } else {
              type.colorButton = false
            }
          }
        }
        if (
          (!this.getProjectsTypesPixels ||
            !this.getProjectsTypesPixels.length) &&
          !this.getProjectsTypesPixelsLoading
        ) {
          this.fetchProjectTypesPixel()
        }
        if (this.getProjectsError && this.getProjectsError.length) {
          this.resetErrorProject()
          this.resetErrorContact()
        }
        this.resetActivities()
      }
    },
    toggleLeftTabs(tab) {
      this.currentTabLeft = tab
      this.menuContact = 0
      this.menuProjet = 0
      this.menuFichier = 0
      this.menuActivite = 0
      this.currentTabLeftCategrie = 0
      this.currentTabLeftCategrieProjet = 0
      this.currentTabRight = !this.readonly ? 1 : 2
      this.resetErrorProject()
      this.resetErrorContact()
      if (tab == 1 || tab == 2) {
        this.refrechProject({ project: this.data, reset: 1 })
      }
      if (tab == 3) {
        this.fetchProjectSousType(this.data.id)
        this.resetActivities()
      }
    },
    resetActivities() {
      this.fetchProjectHistoriques({
        project: this.data,
        page: this.currentPage,
        reset: 1
      })
      this.$nextTick(() => {
        this.fetchAllActivities({
          project: this.data,
          page: this.currentPage,
          reset: 1
        })
        this.fetchComments({
          project: this.data,
          page: this.currentPage,
          reset: 1
        })
        this.fetchJournalDesModifications({
          project: this.data,
          page: this.currentPage,
          reset: 1
        })
      })
    },
    toggleLeftTabsCategoryProjet(index) {
      this.disabledBtnSaveFields = true
      this.refrechProject({ project: this.data, reset: 1 })
      // this.currentTabLeftCategrieProjet = 0
      this.currentTabLeftCategrieProjet = index
      this.resetErrorProject()
      this.resetErrorContact()
    },
    toggleLeftTabsCategoryContact(index) {
      this.disabledBtnSaveFields = true
      this.refrechProject({ project: this.data, reset: 1 })
      this.currentTabLeftCategrie = index
      this.resetErrorContact()
      this.resetErrorProject()
    },

    async addNote() {
      const payload = {
        comment: this.commentToAdd,
        project: this.data
      }
      const response = await this.addComment(payload)
      if (response) {
        this.resetPageValue({ object: 'comment' })
        this.fetchProjectHistoriques({
          project: this.data,
          page: this.currentPage,
          reset: 1
        })
        this.fetchComments({
          project: this.data,
          page: this.currentPage,
          reset: 1
        })
        this.commentToAdd = ''
        this.$refs.eeditor.reset()
      }
    },
    deleteComment() {
      this.currentTabRight = 2
      this.$refs.eeditor.reset()
    },
    hideModal(ref) {
      if (ref && this.$refs[ref]) {
        this.$refs[ref].hide()
      }
    },
    closeModal(ref) {
      this[ref] = false
    },
    // async setNewStageWithSub(stage, subStages) {
    //   // if (!this.nextSubStage) {
    //   //   this.nextSubStageError = 'Le sous-statut est obligatoire'
    //   //   return
    //   // }
    //   stage.flech = true
    //   this.nextStage = stage
    //   this.nextSubStage = subStages && subStages.id ? subStages.id : null
    //   this.resetPageValue({ object: 'tousWithjournal' })
    //   const response = await this.updateProject({
    //     entity: this.data,
    //     field: 'cstage_id',
    //     value: this.nextStage.id,
    //     substage: {
    //       field: 'csubstage_id',
    //       value: this.nextSubStage
    //     }
    //   })
    //   if (response) {
    //     this.currentPipeline.stages.map(item => {
    //       if (this.data && this.data.stage && this.data.stage.id != stage.id) {
    //         stage.flech = false
    //       }
    //       return { ...item }
    //     })
    //   }
    //   this.$emit('refreshPipeline')
    //   // this.closeModal('choisiSubstage')
    // },
    handleDeleteProject() {
      this.deleteProjectModal = true
    },
    async suprimerProject() {
      const response = await this.deleteProject(this.data)
      if (response) {
        this.deleteProjectModal = false
      }
      if (this.$route && this.$route.path != '/projects') {
        this.$router.replace('/projects')
      }
    },
    changeSousType(sousType) {
      this.currentSousTypes = sousType.name
      this.currentSousTypesId = sousType.id
    },
    async etatSwitch(etat) {
      this.activeDesactiveSwitchSousCategory(etat)
    },
    async handleInjection(objectSousTypes, index) {
      if (this.selectedTypeInjecte) {
        const response = await this.injectionPixel({
          typePixel: this.selectedTypeInjecte[index],
          subtypes: objectSousTypes.id_subtype,
          project: this.data,
          pixel: objectSousTypes
        })
        if (response) {
          objectSousTypes.colorButton = true
        }
      }
    }
  },
  computed: {
    ...mapGetters([
      'currentUser',
      'getProjectsProcessing',
      'getProjectsError',
      'getContactsError',
      'getContactsProcessing',
      'getEtapes',
      'getProjectsProcessingHistory',
      'getProjectsCategories',
      'getProjectsFilesProcessing',
      'getProjectsExtraProject',
      'getProjectsTypesPixels',
      'getProjectsTypesPixelsLoading',
      'getProjectshistoryInjectionPixel',
      'getProjectshistoryInjectionPixelLoading',
      'getProjectsSousTypes'
    ]),
    computedVisibilityBtn: function() {
      if (
        (!this.data.categoriesProjets || !this.data.categoriesProjets.length) &&
        this.currentTabLeft == 2
      ) {
        return false
      } else if (
        (!this.data.categoriesContacts ||
          !this.data.categoriesContacts.length) &&
        this.currentTabLeft == 1
      ) {
        return false
      }
      return true
    },
    hasError() {
      return this.errorMessages !== null && this.errorMessages !== undefined
    },

    computedFields() {
      return function(fields) {
        return fields.filter(item => item.is_header != 1)
      }
    },
    // currentPipeline: function() {
    //   if (!this.data) {
    //     return {}
    //   }
    //   let pipeline = {}
    //   for (const pipe of this.getEtapes) {
    //     if (
    //       this.data.type_id &&
    //       pipe.type.some(element => element.id == this.data.type_id)
    //     ) {
    //       pipeline = pipe
    //       break
    //     }
    //   }
    //   if (pipeline && pipeline.stages && pipeline.stages.length) {
    //     pipeline.stages.map(item => {
    //       if (this.data && this.data.stage && item.id == this.data.stage.id) {
    //         item.flech = true
    //       } else {
    //         item.flech = false
    //       }
    //     })
    //   }
    //   return pipeline
    // },
    currentSubStages: function() {
      if (!this.data || !this.data.pipeline) {
        return []
      }
      let subStages = []
      if (this.getEtapes && this.getEtapes.length) {
        this.getEtapes.forEach(pipeline => {
          if (this.data.pipeline && pipeline.id == this.data.pipeline.id) {
            pipeline.stages.forEach(stage => {
              if (this.data.stage && stage.id == this.data.stage.id) {
                if (!stage.subStages || !stage.subStages.length) {
                  this.fetchEtapeSubstage({
                    cstage_id: stage.id,
                    stage: stage
                  })
                }
                subStages = stage.subStages.map(ss => {
                  return {
                    text: ss.name,
                    value: ss.id
                  }
                })
              }
            })
          }
        })
      }
      return subStages
    },
    computedNonTerminer: function() {
      if (this.data && this.data.activities) {
        return this.data.activities.filter(t => !t.terminer)
      }
      return []
    },
    computedNoteDesepingle: function() {
      if (this.data && this.data.comments) {
        return this.data.comments.filter(t => t.epingler == 1)
      }
      return []
    }
  },
  filters: {
    date: function(value, format = 'DD/MM/YYYY') {
      if (!value) return ''
      return moment(value).format(format)
    },
    time: function(value, format = 'HH:mm:ss') {
      if (!value) return '--:--'
      return moment(value).format(format)
    },
    duration: function(value) {
      if (value === null || value === undefined) return ''
      if (!value) return '00h00'
      if (!Number.isInteger(value)) {
        value = parseInt(value)
      }
      if (value < 60) return '00h' + value
      value = moment.duration(value, 'minutes').format('HH[h]mm')
      return value
    },
    datetime: function(value, format = 'YYYY-DD-MM HH:mm:ss') {
      if (!value) return ''
      return moment(value, 'YYYY-MM-DD H').format(format)
    },
    filterField: function(value, section) {
      if (!value || !section) return []
      return value.filter(v => {
        return v.category == section
      })
    },
    status: function(value, locale) {
      switch (value) {
        case 'Open':
        case 'open':
          return locale == 'fr' ? 'Ouvert' : 'Open'
        case 'Lost':
        case 'lost':
          return locale == 'fr' ? 'Perdu' : 'Lost'
        case 'Won':
        case 'won':
          return locale == 'fr' ? 'Conclu' : 'Concluded'
      }
      return ''
    },
    formatTel: function(value) {
      if (!value) {
        return 0
      }
      return value.replace(/(\d\d(?!$))/g, '$1 ')
    }
  },
  watch: {
    getProjectsError(newValue) {
      this.errorMessages = newValue
    },
    getContactsError(newValue) {
      this.errorMessages = newValue
    },
    async $route() {
      this.menuFile = 0
      this.menuContact = 0
      this.menuProjet = 0
      this.menuFichier = 0
      this.menuActivite = 0
      // update project data
      this.currentTabLeft = 1
      this.currentTabLeftCategrie = 0
      this.currentTabLeftCategrieProjet = 0
      this.currentTabRight = !this.readonly ? 1 : 2
      // if (route.name === 'project_activity' && route.params.id) {
      //   this.data.id = route.params.id
      //   await this.fetchAllActivities({
      //     project: this.data,
      //     page: this.currentPage
      //   })
      //   this.$nextTick(() => {
      //     this.fetchProjectHistoriques({
      //       project: this.data,
      //       page: this.currentPage
      //     })
      //     this.fetchComments({
      //       project: this.data,
      //       page: this.currentPage
      //     })
      //     this.fetchFiles(this.data)
      //     this.toggleLeftTabs(3)
      //   })
      // } else if (route.name == 'project' && route.params && route.params.id) {
      //   if (route.params.id != this.data.id) {
      //     await this.fetchSingleProject({
      //       id: route.params.id,
      //       checked: true
      //     })
      //   }
      // }
    }
  },
  async mounted() {
    this.initLoading = true
    if (this.data && this.$route.name == 'projects') {
      if (this.$route.path != '/projects') {
        this.$router.push('/projects')
      }
      this.data.checked = false
    }

    if (
      this.$route.name == 'project' &&
      this.$route.params &&
      this.$route.params.id
    ) {
      document.title =
        // NOM_PRENOM
        this.data.contact_full_name +
        '/' +
        // TYPE PROJET
        this.data.type_name +
        '/' +
        //PHONES
        this.$options.filters.formatTel(this.data.phones) +
        ' - CRM Abondance'
      // // APPEL API FETCH SIGNLE FILE PROJECT
      // await this.fetchSingleProject({
      //   id: this.$route.params.id,
      //   checked: true
      // })
      // this.data.checked = true
      this.refrechProject({ project: this.data, reset: 1 })
    }
    this.menuProjet = 'tab-0'
    this.menuFile = 0
    this.initLoading = false
    await this.fetchAllActivities({
      project: this.data,
      page: this.currentPage
    })
    this.$nextTick(() => {
      this.fetchProjectHistoriques({
        project: this.data,
        page: this.currentPage
      })
      this.fetchComments({
        project: this.data,
        page: this.currentPage
      })
      this.fetchProjectsHistoryPixel(this.data.id)
      this.fetchJournalDesModifications({
        project: this.data,
        page: this.currentPage
      })
      this.fetchFiles(this.data)
      if (this.data && this.data.type_id) {
        this.fetchCategorieType({ type: this.data.type_id })
      }
    })
    if (this.$route.name === 'project_activity') {
      this.toggleLeftTabs(3)
    }
  }
}
</script>

<style lang="scss" scoped>
.title-card {
  font-weight: bold;
  color: #9460f2;
  font-size: 13px;
  &.color-title {
    color: #5e5e5e;
    font-weight: initial;
  }
  &.style-title {
    font-style: italic;
  }
}
.fiche-projet {
  max-height: calc(100vh - 190px) !important;
  height: calc(100vh - 190px) !important;
  overflow-y: auto;
  .content-header-projet {
    border-bottom: 1px solid #e0e0e0;
    .header-content {
      border-bottom: 1px solid #e0e0e0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 5px 15px;

      .header-type-projet {
        display: flex;
        align-items: center;
        .btn-back {
          .back {
            height: 31px !important;
            width: 33px !important;
            min-width: 26px !important;
            padding: 13px 13px;
            .icon-back {
              font-size: 20px !important;
              font-weight: bold !important;
            }
          }
        }
        .title-type-projet {
          margin-left: 20px;
          .title {
            font-family: 'Montserrat', sans-serif;
            color: #5c2dd3;
            font-weight: 700;
            font-size: 14px !important;
          }
          .type-projet {
            font-family: 'Montserrat', sans-serif;
            color: rgba(0, 0, 0, 0.6);
            font-weight: 600;
            font-size: 12px !important;
          }
        }
      }
      .action-btn-contact {
        .confirmation-items {
          display: flex;
          .item {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 30px;
            height: 30px;
            font-size: 15px;
            margin-left: 10px;

            color: #5c2dd3;
            background-color: #fff;
            border: 1px solid #5c2dd3;
            border-radius: 6px;
            cursor: pointer;
            user-select: none;
            transition: all 0.4s ease;
            transform: rotateY(180deg);
            .v-icon {
              font-size: 21px !important;
            }
            &:hover {
              background-color: rgba(112, 74, 209, 0.1);
            }
            &.active {
              color: #5c2dd3;
              border: 1px solid #5c2dd3;
              background-color: rgba(112, 74, 209, 0.1);
              transform: rotateY(0deg);
              &:hover {
                background-color: #fff;
              }
            }
            &.readonly {
              cursor: default;
            }
          }
        }
      }
    }
    .info-global-contact {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      padding: 8px 10px;
      .affichage-contact {
        display: flex;
        align-items: center;
        width: 40%;
        border-right: 1px solid #e0e0e0;
      }
      .show-statut {
        width: 60%;
        margin-top: 45px;
      }
    }
    @mixin object-center {
      display: flex;
      //   justify-content: center;
      align-items: center;
    }
    .info-contact {
      .nom-user {
        font-family: 'Montserrat', sans-serif !important;
        color: #2f2f2f !important;
        font-size: 13px;
        font-weight: 700;
      }
      .nom-contact,
      .phone,
      .statut {
        font-family: 'Montserrat', sans-serif !important;
        color: rgba(47, 47, 47, 0.8) !important;
        font-size: 12px;
        font-weight: 500;
      }
      .statut {
        .open {
          color: #0cb277 !important;
        }
        .lost {
          color: #ff6961 !important;
        }
        .won {
          color: #feca1d !important;
        }
      }
    }

    $circleSize: 100px;
    $radius: 100px;
    $shadow: 0 0 10px 0 rgba(255, 255, 255, 0.35);
    $fontColor: rgb(250, 250, 250);

    .profile-pic {
      padding: 6px 6px 6px 17px;
      height: 100px;
      width: 135px;
      color: transparent;
      transition: all 0.3s ease;
      @include object-center;
      position: relative;
      transition: all 0.3s ease;
      .icon-upload-file {
        position: absolute;
        bottom: 6px;
        left: 88px;
      }
      input {
        display: none;
      }

      img {
        position: absolute;
        object-fit: cover;
        width: $circleSize;
        height: $circleSize;
        box-shadow: $shadow;
        border-radius: $radius;
        z-index: 0;
      }

      .-label {
        cursor: pointer;
        height: $circleSize;
        width: $circleSize;
        font-size: 16px;
      }

      &:hover {
        .-label {
          @include object-center;
          background-color: rgba(0, 0, 0, 0.8);
          z-index: 10000;
          color: $fontColor;
          transition: background-color 0.2s ease-in-out;
          border-radius: $radius;
          margin-bottom: 0;
        }
      }

      span {
        display: inline-flex;
        padding: 0.2em;
        height: 2em;
      }
    }
    body {
      height: 100vh;
      background-color: rgb(25, 24, 21);
      @include object-center;

      a:hover {
        text-decoration: none;
      }
    }
  }
  .pipeline-global {
    &.padding {
      padding: 3px 8px;
    }
    .stages {
      margin: 4px 0px 4px;
      .label {
        padding-bottom: calc(0.375rem + 1px);
        margin-bottom: 0;
        font-size: inherit;
        line-height: 1.5;
        color: #8a8383;
        font-family: 'Montserrat', sans-serif;
      }
      .options {
        display: flex;
        width: 100%;
        overflow: hidden;
        margin-bottom: 4px;
        table-layout: fixed;
        .stage {
          // height: 28px;
          margin-right: 10px;
          height: 27px;
          width: 100%;
          border-radius: 6px;
          cursor: pointer;
          display: table-cell;
          text-align: center;
          padding: 0;
          line-height: 27px;
          font-size: 11px;
          font-weight: 700;
          color: #8a8383;
          border: 1px solid;
          // background: #28a745;
          background: #fff;
          position: relative;

          &.lost,
          &.red {
            background: #ff6961;
            color: #fff;
            &:before {
              background: #ff6961;
              color: #fff;
            }
          }
          &.yellow {
            background: #ffeb3b;
            color: #fff;
            &:before {
              background: #ffeb3b;
              color: #fff;
            }
          }
          &.open,
          &.green,
          &:before {
            background-color: #0cb277 !important;
            color: #fff;
            &.active {
              color: #fff;
            }
          }
          &.lost,
          &:before {
            background-color: #ff6961 !important;
            color: #fff;
            &.active {
              color: #fff;
            }
          }

          &.won,
          &:before {
            background-color: #feca1d !important;
            color: #fff;
            &.active {
              color: #fff;
            }
          }
          &.orange {
            background: #feca1d;
            color: #fff;
            &:before {
              background: #feca1d;
              color: #fff;
            }
          }

          input {
            width: auto;
            position: absolute;
            opacity: 0;
            cursor: pointer;
            border: none;
            padding: 0;
            box-shadow: none;
            margin-right: 3px;
          }

          &.active {
            // background: #28a745;
            color: #fff;
            background: rgba(58, 22, 127, 1);
          }

          &.lost,
          &.red {
            &.active {
              color: #fff;
              background: #ff6961;
            }
          }

          &.orange {
            &.active {
              color: #fff;
              background: #feca1d;
            }
          }

          &.active ~ label {
            background: #fff;
            border: 1px solid;
            // color: #cdcdcd;
          }

          &.active ~ label:before {
            background: #fff;
            border: 1px solid;
            // color: #cdcdcd;
          }
        }
      }

      &.readonly {
        .options {
          .stage {
            cursor: default;
            &:before {
              cursor: default;
            }
            input {
              cursor: default;
            }
          }
        }
      }
    }
  }
  .loader-container {
    height: 35px;
    width: 100%;
    padding: 5px 8px;
  }
  .menu-header-categ-pipeline {
    // margin: 4px 0px;
    .categories-header {
      .tabs-categories {
        .v-slide-group__next,
        .v-slide-group__prev {
          max-width: 30px !important;
        }
      }
    }
    .choisi-actions {
      .list-item-global-scroll {
        .list-groupe {
          .v-list-item {
            padding: 0px 8px !important;
          }
        }
      }
    }
  }
  .line-hr-projet {
    margin-top: 0rem !important;
    margin-bottom: 0rem !important;
  }
  .content-global {
    padding: 0 8px;
    margin-bottom: 10px;
    .body-content {
      // max-height: calc(100vh - 263px) !important;
      // height: calc(100vh - 263px) !important;
      // margin-bottom: 0;
      // overflow-y: hidden;
      // overflow-x: hidden;
      // display: flex;
      // align-items: center;
      background-color: #fafafa;
      border: 1px solid #e0e0e0;
      border-radius: 11px 11px 0px 0px;
      .partie-vide {
        // background-color: #fefefe;
        // padding: 15px 0px;
      }
      .champ-fields {
        width: 51vh;
        // margin: 0px 10px;
        &.activite {
          width: 74vh;
          margin: 0px 6px;
          background-color: #fff;
          margin-bottom: 9px;
          margin-top: 11px;
        }

        .historique-injection {
          padding: 0px 20px 0px 51px !important;
          .title-historique {
            text-align: center;
            border: 1px solid #c5c5c505;
            background-color: #5c2dd3;
            border-radius: 15px;
            font-size: 14px;
            font-weight: bold;
            padding: 4px;
            color: white;
            width: 80%;
            margin: 5px 315px 5px 49px;
          }
        }
        .historique-activities {
          position: relative;
          // padding: 0px 25px 0px 25px;
          .title-historique {
            text-align: center;
            border: 1px solid #c5c5c505;
            background-color: #5c2dd3;
            border-radius: 15px;
            font-size: 14px;
            font-weight: bold;
            padding: 4px;
            color: white;
            width: 80%;
            margin: 5px 87px 5px 87px;
          }
          .create-activity {
            .action {
              cursor: pointer;
              margin-top: 10px;
              font-weight: 600;
              color: #2dabe2;
              user-select: none;
            }
            .inputs {
              margin-top: 5px;
              margin-top: 5px;
              background: #e5e5e5;
              padding: 7px;
              .appel {
                display: flex;
                .name {
                  flex-grow: 2;
                }
              }
              .fast-date {
                .btn-group-toggle {
                  display: flex;
                  .btn {
                    span {
                      white-space: nowrap;
                      overflow: hidden;
                      text-overflow: ellipsis;
                      max-width: 102px;
                      display: block;
                    }
                  }
                }
              }
            }
          }
          .time-line {
            top: 0;
            left: 33px;
            position: absolute;
            height: 100%;
            width: 1px;
            padding-top: 50px;
            padding-bottom: 20px;
            .time-line-container {
              position: relative;
              height: 100%;
              width: 1px;
              .line {
                position: absolute;
                left: 1px;
                top: 0px;
                bottom: 0px;
                width: 2px;
                z-index: 1;
                background-color: #eff1f3;
              }
            }
          }
          .incoming {
            // padding: 0px 26px !important;
            padding: 0px 20px 0px 51px !important;
          }
        }
        .tabs-categories-fields {
          // max-height: calc(100vh - 531px) !important;
          // height: calc(100vh - 531px) !important;
          // margin-bottom: -8px;
          // overflow-y: hidden;
          // overflow-x: hidden;
          &.scroll-activite {
            // background-color: #fff;
            // overflow-y: auto;
            // max-height: calc(100vh - 515px) !important;
            // height: calc(100vh - 515px) !important;
            // cursor: pointer;
          }
        }
        .tabs-categories-fields::-webkit-scrollbar {
          width: 7px;
          height: 7px;
        }
        .tabs-categories-fields::-webkit-scrollbar-track {
          box-shadow: inset 0 0 5px #f0eef8;
          border-radius: 4px;
          background-color: #fafafa !important;
        }
        .tabs-categories-fields::-webkit-scrollbar-thumb {
          border-radius: 4px;
          background-color: black;
        }
        .action-file-projet {
          display: flex;
          justify-content: center;
          // width: 98%;
          // float: right;
          // padding: 4px 4px;
          // margin: 2px 20px 0px 0px;
          // height: 68vh;
          .disabled-button {
            background-color: #5c2dd382 !important;
          }
        }
      }
      .sous-categories-and-menu {
        width: 100%;
        .menu-header {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 99%;
          padding: 3px 3px;
          .menu-projet {
            width: 50%;
            background-color: #fff;
          }
        }
        .margin-releve {
          margin-top: -28px;
        }
        .sous-categories {
          width: 195vh;
          background-color: #fff;
          // overflow-y: auto;
          // max-height: calc(100vh - 498px);
          min-height: calc(100vh - 190px);
          cursor: pointer;
          &.activite {
            width: 139vh;
            margin: 11px 5px;
          }
          .cadre-content {
            margin: 0px 18px;
          }
          // &.loading-data {
          //   max-height: calc(100vh - 510px);
          //   height: calc(100vh - 490px);
          // }
          // &.scroll-releve {
          //   max-height: calc(100vh - 453px);
          //   height: calc(100vh - 453px);
          // }
        }
        .sous-categories::-webkit-scrollbar {
          width: 7px;
          height: 7px;
        }
        .sous-categories::-webkit-scrollbar-track {
          box-shadow: inset 0 0 5px #f0eef8;
          border-radius: 4px;
          background-color: #fafafa !important;
        }
        .sous-categories::-webkit-scrollbar-thumb {
          border-radius: 4px;
          background-color: black;
        }
        .scroll-content-file {
          // overflow: hidden;
          // overflow-y: auto;
          // overflow-x: auto;
          // max-height: calc(100vh - 531px);
          // height: calc(100vh - 531px);

          .file-header-titles {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 4px 20px;
            .file-name-title {
              width: 60%;
              margin-right: 10px;
            }
            .file-category-title {
              width: 40%;
            }
            .select-category-file {
              &.v-input {
                width: 40% !important;
                max-width: 100% !important;
              }
            }
            .file-name-title,
            .file-category-title {
              text-align: center;
              color: #ffffff;
              font-weight: 600;
              background-color: #5c2dd3;
              border: 2px solid #5c2dd3;
            }
          }
          .files-to-upload {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 2px 20px;
            .file-name {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              width: 60%;
              margin-right: 10px;
              .file-upload-icon {
                color: #5c2dd3;
              }
            }
            .file-category {
              width: 40%;
              font-size: 13px;
            }
          }
        }
        .scroll-content-file::-webkit-scrollbar {
          width: 7px;
          height: 7px;
        }
        .scroll-content-file::-webkit-scrollbar-track {
          box-shadow: inset 0 0 5px #f0eef8;
          border-radius: 4px;
          background-color: #fafafa !important;
        }
        .scroll-content-file::-webkit-scrollbar-thumb {
          border-radius: 4px;
          background-color: rgba(189, 189, 189, 1);
        }
        // .scroll-content-note {
        //   overflow-y: auto;
        //   overflow-x: auto;
        //   max-height: calc(100vh - 453px);
        //   height: calc(100vh - 453px);
        // }
        // .scroll-content-note::-webkit-scrollbar {
        //   width: 7px;
        //   height: 7px;
        // }
        // .scroll-content-note::-webkit-scrollbar-track {
        //   box-shadow: inset 0 0 5px #f0eef8;
        //   border-radius: 4px;
        //   background-color: #fafafa !important;
        // }
        // .scroll-content-note::-webkit-scrollbar-thumb {
        //   border-radius: 4px;
        //   background-color: rgba(189, 189, 189, 1);
        // }
        .activity-type {
          .activity-types-tabs {
            margin-top: 5px;
            .activity-types-tab {
              white-space: nowrap;
              padding: 4px 6px;
              font-size: 14px;
              font-weight: bold;
              width: 34%;
              text-align: center;
              user-select: none;
              cursor: pointer;
              background-color: #5c2dd3;
              border: 1px solid #fff;
              color: #fff;
              &.active {
                background-color: #fff;
                color: #5c2dd3;
                padding: 4px 6px;
                border-color: #5c2dd3;
              }
            }
          }
        }
        .margin-left-block {
          margin-left: -13px;
        }
        .block-montant-prime {
          width: 100%;
        }
        .mt-row {
          margin-top: -30px;
        }
        .table-contact {
          .tab-button-contact {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 4px 0;
            width: 34%;
            background-color: #e5e5e5;
            border: 1px solid #d5d5d5;
            color: #5e5e5e;
            font-size: 9px;
          }
          .link {
            border: 1px solid #d5d5d5;
            display: flex;
            align-items: center;
            padding: 4px 0;
            width: 100%;
            background-color: #ffffff;
            color: #5e5e5e;
            cursor: pointer;

            &:hover {
              color: #5e5e5e;
              background-color: transparent;
              text-decoration: none;
              font-weight: bold;
            }
            .border-top {
              border-top: 1px solid #dee2e6;
            }
            .margin {
              margin-left: 5px;
            }
          }
        }
      }
    }
  }
}
</style>
<style lang="scss">
// .label-statut {
//   overflow: hidden;
//   text-overflow: ellipsis;
//   white-space: nowrap;
//   width: 70%;
// }
// .list-item-global-scroll {
//   .list-groupe {
//     .v-list-item {
//       padding: 0px 8px !important;
//     }
//   }
// }
.input-file-projet {
  .v-file-input .v-file-input__text {
    font-size: 12px !important;
  }
}
.tabs-categories {
  // .v-tabs {
  //   // width: 124vh !important;
  // }
  &.width-activite {
    .v-tabs {
      width: 79vh !important;
    }
  }
  .tab-contact-category {
    &.v-tab {
      text-decoration: none;
      color: #000000 !important;
      line-height: 24px !important;
      padding: 9px 16px 9px 16px !important;
      font-size: 11px !important;
      font-family: 'Roboto' sans-serif !important;
      font-weight: 500 !important;
      height: 52px !important;
      &.v-tab--active {
        color: #5c2dd3 !important;
      }
    }
  }
  .v-tabs-bar {
    height: 48px !important;
  }
}
.select-category-file {
  &.v-input {
    width: 38% !important;
    max-width: 38% !important;
  }
}
.tabs-project {
  // .v-tabs-bar {
  //   // height: 35px !important;
  // }
  .v-slide-group__content {
    margin-left: 12px;
  }

  .tab-menu-projet {
    &.v-tab {
      text-decoration: none;
      color: #000000 !important;
      line-height: 24px !important;
      padding: 9px 16px 9px 16px !important;
      font-size: 12px !important;
      font-family: 'Roboto' sans-serif !important;
      font-weight: 500 !important;
      // height: 42px !important;
      &.v-tab--active {
        color: #5c2dd3 !important;
      }
    }
  }
}
.icon-upload-file {
  .v-btn--fab.v-size--x-small {
    height: 28px;
    width: 28px;
  }
}
</style>
