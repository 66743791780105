<template>
  <Card>
    <template #title>
      <div class="title">
        {{
          $i18n.locale === 'fr'
            ? 'Préconisation Travaux / Travaux réalisable'
            : 'Recommended work / Work feasible'
        }}
      </div>
    </template>
    <template #body>
      <v-form @submit.prevent="handleStore" ref="myForm">
        <v-row>
          <v-col cols="12">
            <v-textarea
              v-model="form21.observations"
              rows="2"
              dense
              label="Observations"
              :persistent-placeholder="true"
              outlined
              color="#704ad1"
              required
              validate-on-blur
              hide-details
            ></v-textarea>
          </v-col>
          <v-col cols="12">
            <v-file-input
              v-model="form21.file_doc"
              dense
              multiple
              accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,
  text/plain, application/pdf, image/*"
              prepend-icon="mdi-plus"
              :label="
                $i18n.locale === 'fr'
                  ? 'Documents complémentaires (multiple)'
                  : 'Additional documents (multiple)'
              "
              underlined
              clearable
              color="#704ad1"
            ></v-file-input>
          </v-col>
        </v-row>

        <!-- Bouton "Suivant" pour chaque étape -->
        <v-col cols="12" class="bottom">
          <v-btn color="#5C2DD3" outlined @click="prevStep" v-if="!hiddenBtn">
            {{ $t('precedant') }}
          </v-btn>
          <v-btn color="#5C2DD3" dark type="submit" :loading="getPdfLoading">
            {{ $t('btnValider') }}
          </v-btn>
        </v-col>
      </v-form>
    </template>
  </Card>
</template>

<script>
import Card from '@/components/CrmIla26Component/Projets/Card.vue'
import { mapGetters } from 'vuex'
export default {
  components: {
    Card
  },
  data() {
    return {
      form21: {
        observations: null,
        files: []
      },
      hiddenBtn: false
    }
  },
  methods: {
    resetComponent() {
      // Réinitialiser les données spécifiques à ce composant (par exemple, formulaire)
      this.form21 = {
        observations: null,
        files: []
      }
    },
    handleStore() {
      // Utilisez $refs pour accéder au formulaire
      const valid = this.$refs.myForm.validate()
      if (!valid) {
        return
      }
      this.$emit('handleStore', this.form21)
      this.hiddenBtn = true
    },
    prevStep() {
      this.$emit('prevStep')
    }
  },
  computed: {
    ...mapGetters(['getPdfLoading'])
  }
}
</script>

<style lang="css" scoped>
.bottom {
  display: flex !important;
  align-items: center;
  justify-content: center !important;
  gap: 16px;
}
</style>
