<template>
  <div class="editable-input" :class="{ 'auto-width': autoWidth }">
    <div class="edit-view" v-if="editing">
      <v-select
        v-model="newValue"
        :items="options"
        label=""
        outlined
        dense
        hide-details
        :no-data-text="$t('noDataOption')"
        :menu-props="{
          bottom: true,
          offsetY: true
        }"
        color="#5C2DD3"
        item-color="#5C2DD3"
      ></v-select>
      <div class="edit-action flex justify-content-end mt-1">
        <v-spacer></v-spacer>
        <v-btn color="#5C2DD3" dark @click="update" small class="mr-2">
          {{ $t('btnValider') }}
        </v-btn>
        <v-btn text @click="deleted" outlined small>
          {{ $t('btnCancel') }}
        </v-btn>
      </div>
    </div>
    <div class="show-view" v-else>
      <div
        class="value mr-2 bold-700 font-text color-black font-sz-13 mt-1"
        :class="{ 'auto-width': autoWidth }"
      >
        {{ cumputedValue || '-' }}
      </div>
      <div v-if="!readonly" class="show-action" @click="editing = true">
        <!-- <font-awesome-icon class="icon" icon="pencil-alt" /> -->

        <v-icon class="" color="#FF9000"> mdi-square-edit-outline</v-icon>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: 'EditableSelect',
  props: {
    field: { required: true },
    entity: { required: true },
    value: { required: false },
    options: { required: true },
    updateFunction: { required: true },
    autoWidth: { default: false },
    readonly: { default: false }
  },
  data() {
    return {
      editing: false,
      newValue: this.value
    }
  },
  methods: {
    ...mapActions(['resetPageValue']),
    deleted() {
      this.editing = false
      this.newValue = this.value
    },
    update() {
      this.resetPageValue({ object: 'tousWithjournal' })
      if (this.value != this.newValue) {
        this.updateFunction({
          entity: this.entity,
          field: this.field,
          value: this.newValue
        })
      }
      this.editing = false
    }
  },
  computed: {
    cumputedValue: function() {
      let value = null
      this.options.forEach(option => {
        if (this.value == option.value) {
          value = option.text
        }
      })
      return value
    }
  }
}
</script>

<style lang="scss" scoped>
.editable-input {
  width: 187px;
  .edit-view {
    display: block;
    .style-select {
      box-shadow: unset;
    }
    // .edit-action {
    //  // div {
    //   //   cursor: pointer;
    //   //   color: #5e5e5e;
    //   //   border: 1px solid #a9adb1;
    //   //   user-select: none;
    //   //   margin: 0;
    //   //   padding: 0px 3px 0px 2px;
    //   //   border-radius: 2px;
    //   //   font-size: 11px;
    //   //   font-weight: 600;
    //   //   &.green {
    //   //     background: #8bc34a;
    //   //     color: #fff;
    //   //   }
    //   // }
    // }
    .edit-action {
      .v-btn.v-size--small {
        font-size: 10px;
      }
    }
    .custom-select {
      height: 26px;
      padding-top: 2px;
      border: 1px solid #e5e5e5;
      border-radius: 2px;
    }
  }
  .show-view {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    // &:hover {
    //   .show-action {
    //     display: flex;
    //     align-items: center;
    //     justify-content: center;
    //     color: #bdbdbd;
    //     border-radius: 4px;
    //     border: 1px solid #bdbdbd;
    //     width: 25px;
    //     height: 20px;
    //   }
    // }

    // .show-action {
    //   // display: none;
    //   &:hover {
    //     border: 1px solid #404346 !important;
    //     color: #404346 !important;
    //   }
    // }

    .value {
      width: 130px;
      &.auto-width {
        width: auto;
      }
    }
  }
  &.auto-width {
    width: auto;
  }
}
</style>
