var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Card',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$i18n.locale === 'fr' ? 'Factures' : 'Invoices')+" ")])]},proxy:true},{key:"body",fn:function(){return [_c('v-form',{ref:"myForm",on:{"submit":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_c('v-row',[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',_vm._l((_vm.options),function(option,i){return _c('v-col',{key:i,attrs:{"cols":"2"}},[_c('v-checkbox',{attrs:{"label":option.label,"color":"#704ad1","value":option.value},model:{value:(_vm.form19.type_factures),callback:function ($$v) {_vm.$set(_vm.form19, "type_factures", $$v)},expression:"form19.type_factures"}})],1)}),1)],1)],1),(_vm.isSelected('electricite'))?_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"label":_vm.$i18n.locale === 'fr' ? 'Electricité' : 'Electricity',"placeholder":_vm.$i18n.locale === 'fr'
                ? 'Electricité ( € / An )'
                : 'Electricity ( € / Year )',"outlined":"","persistent-placeholder":true,"dense":"","hide-details":"","color":"#704ad1"},model:{value:(_vm.electricite.prix),callback:function ($$v) {_vm.$set(_vm.electricite, "prix", $$v)},expression:"electricite.prix"}})],1),_c('v-col',[_c('v-file-input',{attrs:{"dense":"","accept":"image/*","prepend-icon":"mdi-plus","label":_vm.$i18n.locale === 'fr'
                ? 'Ajouter photo factures Electricité'
                : 'Add photo invoices Electricity',"underlined":"","clearable":"","color":"#704ad1"},model:{value:(_vm.electricite.file),callback:function ($$v) {_vm.$set(_vm.electricite, "file", $$v)},expression:"electricite.file"}})],1)],1):_vm._e(),(_vm.isSelected('gaz'))?_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"label":_vm.$i18n.locale === 'fr' ? 'Gaz' : 'Gaz',"placeholder":_vm.$i18n.locale === 'fr' ? 'Gaz ( € / An )' : 'Gas ( € / Year )',"outlined":"","persistent-placeholder":true},model:{value:(_vm.gaz.prix),callback:function ($$v) {_vm.$set(_vm.gaz, "prix", $$v)},expression:"gaz.prix"}})],1),_c('v-col',[_c('v-file-input',{attrs:{"dense":"","accept":"image/*","prepend-icon":"mdi-plus","label":_vm.$i18n.locale === 'fr'
                ? 'Ajouter photo factures Gaz'
                : 'Add photo invoices Gas',"underlined":"","clearable":"","color":"#704ad1"},model:{value:(_vm.gaz.file),callback:function ($$v) {_vm.$set(_vm.gaz, "file", $$v)},expression:"gaz.file"}})],1)],1):_vm._e(),(_vm.isSelected('fioul'))?_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"label":_vm.$i18n.locale === 'fr' ? 'Fioul' : 'Fuel',"placeholder":_vm.$i18n.locale === 'fr' ? 'Fioul ( € / An )' : 'Fuel ( € / Year )',"outlined":"","persistent-placeholder":true},model:{value:(_vm.fioul.prix),callback:function ($$v) {_vm.$set(_vm.fioul, "prix", $$v)},expression:"fioul.prix"}})],1),_c('v-col',[_c('v-file-input',{attrs:{"dense":"","accept":"image/*","prepend-icon":"mdi-plus","label":_vm.$i18n.locale === 'fr'
                ? 'Ajouter photo factures Fioul'
                : 'Add photo invoices Fuel',"underlined":"","clearable":"","color":"#704ad1"},model:{value:(_vm.fioul.file),callback:function ($$v) {_vm.$set(_vm.fioul, "file", $$v)},expression:"fioul.file"}})],1)],1):_vm._e(),(_vm.isSelected('granule'))?_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"label":_vm.$i18n.locale === 'fr' ? 'Granule' : 'Granule',"placeholder":_vm.$i18n.locale === 'fr'
                ? 'Granule ( € / An )'
                : 'Granule ( € / Year )',"outlined":"","persistent-placeholder":true},model:{value:(_vm.granule.prix),callback:function ($$v) {_vm.$set(_vm.granule, "prix", $$v)},expression:"granule.prix"}})],1),_c('v-col',[_c('v-file-input',{attrs:{"dense":"","accept":"image/*","prepend-icon":"mdi-plus","label":_vm.$i18n.locale === 'fr'
                ? 'Ajouter photo factures Granule'
                : 'Add photo invoices Granule',"underlined":"","clearable":"","color":"#704ad1"},model:{value:(_vm.granule.file),callback:function ($$v) {_vm.$set(_vm.granule, "file", $$v)},expression:"granule.file"}})],1)],1):_vm._e(),(_vm.isSelected('bois'))?_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"label":_vm.$i18n.locale === 'fr' ? 'Bois' : 'Wood',"placeholder":_vm.$i18n.locale === 'fr' ? 'Bois ( € / An )' : 'Wood ( € / Year )',"persistent-placeholder":true,"outlined":""},model:{value:(_vm.bois.prix),callback:function ($$v) {_vm.$set(_vm.bois, "prix", $$v)},expression:"bois.prix"}})],1),_c('v-col',[_c('v-file-input',{attrs:{"dense":"","accept":"image/*","prepend-icon":"mdi-plus","label":_vm.$i18n.locale === 'fr'
                ? 'Ajouter photo factures Bois'
                : 'Add photo invoices Wood',"underlined":"","clearable":"","color":"#704ad1"},model:{value:(_vm.bois.file),callback:function ($$v) {_vm.$set(_vm.bois, "file", $$v)},expression:"bois.file"}})],1)],1):_vm._e(),(_vm.isSelected('propane'))?_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"label":"Propane","placeholder":_vm.$i18n.locale === 'fr'
                ? 'Propane ( € / An )'
                : 'Propane ( € / Year )',"outlined":"","dense":"","persistent-placeholder":true},model:{value:(_vm.propane.prix),callback:function ($$v) {_vm.$set(_vm.propane, "prix", $$v)},expression:"propane.prix"}})],1),_c('v-col',[_c('v-file-input',{attrs:{"dense":"","accept":"image/*","prepend-icon":"mdi-plus","label":_vm.$i18n.locale === 'fr'
                ? 'Ajouter photo factures Propane'
                : 'Add photo invoices Propane',"underlined":"","clearable":"","color":"#704ad1"},model:{value:(_vm.propane.file),callback:function ($$v) {_vm.$set(_vm.propane, "file", $$v)},expression:"propane.file"}})],1)],1):_vm._e(),_c('v-col',{staticClass:"bottom",attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"color":"#5C2DD3","outlined":""},on:{"click":_vm.prevStep}},[_vm._v(" "+_vm._s(_vm.$t('precedant'))+" ")]),_c('v-btn',{attrs:{"color":"#5C2DD3","dark":"","type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t('suivant'))+" ")])],1)],1)]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }