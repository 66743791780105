<template>
  <Card>
    <template #title>
      <div class="title">
        {{
          $i18n.locale === 'fr'
            ? 'Information Equipement - Chauffage'
            : 'Equipment Information - Heating'
        }}
      </div>
    </template>
    <template #body>
      <v-form @submit.prevent="submitForm" ref="myForm">
        <v-row>
          <v-col cols="6">
            <v-select
              v-model="number"
              :label="
                $i18n.locale === 'fr'
                  ? 'Nombre de chauffage différents'
                  : 'Number of different heaters'
              "
              dense
              clearable
              :placeholder="
                $i18n.locale === 'fr' ? 'Type de Chauffage' : 'Heating type'
              "
              item-text="label"
              item-value="id"
              :items="numbers"
              outlined
              :persistent-placeholder="true"
              required
              color="#704ad1"
              item-color="#704ad1"
              @change="updateChauffage"
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" v-for="(chauffage, i) in form15.chauffages" :key="i">
            <Card>
              <template #title
                >{{ $i18n.locale === 'fr' ? 'Chauffage Bas' : 'Low Heating ' }}
                {{ i + 1 }}
              </template>
              <template #body>
                <v-row>
                  <v-col cols="3">
                    <v-select
                      v-model="chauffage.energie"
                      :label="$i18n.locale === 'fr' ? 'Energie' : 'Energy'"
                      dense
                      clearable
                      :placeholder="$t('choose')"
                      item-text="label"
                      item-value="value"
                      :items="Energies"
                      outlined
                      :persistent-placeholder="true"
                      required
                      color="#704ad1"
                      item-color="#704ad1"
                      hide-details
                    ></v-select>
                  </v-col>
                  <v-col cols="3">
                    <v-select
                      v-model="chauffage.generateur"
                      :label="
                        $i18n.locale === 'fr' ? 'Générateur' : 'Generator'
                      "
                      dense
                      clearable
                      :placeholder="$t('choose')"
                      item-text="label"
                      item-value="value"
                      :items="Generateurs"
                      outlined
                      :persistent-placeholder="true"
                      required
                      color="#704ad1"
                      item-color="#704ad1"
                      hide-details
                    ></v-select>
                  </v-col>
                  <v-col cols="3">
                    <v-text-field
                      v-model="chauffage.nombre"
                      :label="$i18n.locale === 'fr' ? 'Nombre' : 'Number'"
                      :placeholder="$i18n.locale === 'fr' ? 'Nombre' : 'Number'"
                      :persistent-placeholder="true"
                      type="number"
                      :min="0"
                      dense
                      outlined
                      hide-details
                      color="#704ad1"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="3">
                    <v-text-field
                      v-model="chauffage.annee"
                      :label="$i18n.locale === 'fr' ? 'Année' : 'Year'"
                      :placeholder="$i18n.locale === 'fr' ? 'Année' : 'Year'"
                      :persistent-placeholder="true"
                      type="number"
                      :min="0"
                      dense
                      outlined
                      hide-details
                      color="#704ad1"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="3">
                    <v-text-field
                      v-model="chauffage.puissance"
                      :label="
                        $i18n.locale === 'fr' ? 'Puissance (kw)' : 'Power (kw)'
                      "
                      :placeholder="
                        $i18n.locale === 'fr' ? 'Puissance (kw)' : 'Power (kw)'
                      "
                      :persistent-placeholder="true"
                      type="number"
                      :min="0"
                      dense
                      outlined
                      hide-details
                      color="#704ad1"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="3">
                    <v-select
                      v-model="chauffage.etat"
                      :label="$i18n.locale === 'fr' ? 'Etat' : 'State'"
                      dense
                      clearable
                      :placeholder="$t('choose')"
                      item-text="label"
                      item-value="value"
                      :items="Etats"
                      outlined
                      :persistent-placeholder="true"
                      required
                      color="#704ad1"
                      item-color="#704ad1"
                      hide-details
                    ></v-select>
                  </v-col>
                  <v-col cols="3">
                    <v-select
                      v-model="chauffage.fumisterie"
                      :label="
                        $i18n.locale === 'fr' ? 'Fumisterie' : 'Fumisterie'
                      "
                      dense
                      clearable
                      :placeholder="$t('choose')"
                      item-text="label"
                      item-value="value"
                      :items="Fumisteries"
                      outlined
                      :persistent-placeholder="true"
                      required
                      color="#704ad1"
                      it
                      em-color="#704ad1"
                      hide-details
                    ></v-select>
                  </v-col>
                  <v-col cols="3">
                    <v-select
                      v-model="chauffage.position"
                      :label="$i18n.locale === 'fr' ? 'Position' : 'Position'"
                      dense
                      clearable
                      :placeholder="$t('choose')"
                      item-text="label"
                      item-value="value"
                      :items="Positions"
                      outlined
                      :persistent-placeholder="true"
                      required
                      color="#704ad1"
                      item-color="#704ad1"
                      hide-details
                    ></v-select>
                  </v-col>
                  <v-col cols="3">
                    <v-select
                      v-model="chauffage.installation"
                      :label="
                        $i18n.locale === 'fr' ? 'Installation' : 'Installation'
                      "
                      dense
                      clearable
                      :placeholder="$t('choose')"
                      item-text="label"
                      item-value="value"
                      :items="Installations"
                      outlined
                      :persistent-placeholder="true"
                      required
                      color="#704ad1"
                      item-color="#704ad1"
                      hide-details
                    ></v-select>
                  </v-col>
                  <v-col cols="3">
                    <v-select
                      v-model="chauffage.conduits"
                      :label="$i18n.locale === 'fr' ? 'Conduits' : 'Ducts'"
                      dense
                      clearable
                      :placeholder="$t('choose')"
                      item-text="label"
                      item-value="value"
                      :items="Conduits"
                      outlined
                      :persistent-placeholder="true"
                      required
                      color="#704ad1"
                      item-color="#704ad1"
                      hide-details
                    ></v-select>
                  </v-col>
                  <v-col cols="12">
                    <v-file-input
                      v-model="chauffage.file"
                      dense
                      accept="image/*"
                      prepend-icon="mdi-plus"
                      :label="
                        $i18n.locale === 'fr'
                          ? 'Ajouter photo du systeme de chauffage'
                          : 'Add photo of heating system'
                      "
                      underlined
                      clearable
                      color="#704ad1"
                    ></v-file-input>
                  </v-col>
                </v-row>
              </template>
            </Card>
          </v-col>
        </v-row>
        <!-- Bouton "Suivant" pour chaque étape -->
        <v-col cols="12" class="bottom">
          <v-btn color="#5C2DD3" outlined @click="prevStep">
            {{ $t('precedant') }}
          </v-btn>
          <v-btn color="#5C2DD3" dark type="submit">
            {{ $t('suivant') }}
          </v-btn>
        </v-col>
      </v-form>
    </template>
  </Card>
</template>

<script>
import Card from '@/components/CrmIla26Component/Projets/Card.vue'

export default {
  components: {
    Card
  },
  data() {
    return {
      form15: {
        chauffages: []
      },
      number: null,
      numbers: [
        {
          label:
            this.$i18n.locale === 'fr'
              ? '1 type de Chauffage'
              : '1 type of heating',
          id: 1
        },
        {
          label:
            this.$i18n.locale === 'fr'
              ? '2 type de Chauffage'
              : '2 types of heating',
          id: 2
        },
        {
          label:
            this.$i18n.locale === 'fr'
              ? '3 type de Chauffage'
              : '3 types of heating',
          id: 3
        }
      ],
      Energies: [
        {
          label: this.$i18n.locale === 'fr' ? 'FIOUL' : 'FUEL',
          value: 'FIOUL'
        },
        {
          label: this.$i18n.locale === 'fr' ? 'GAZ' : 'GAS',
          value: 'GAZ'
        },
        {
          label: this.$i18n.locale === 'fr' ? 'ELECTRICITE' : 'ELECTRICITY',
          value: 'ELECTRICITE'
        },
        {
          label: this.$i18n.locale === 'fr' ? 'BOIS' : 'WOOD',
          value: 'BOIS'
        },
        {
          label: this.$i18n.locale === 'fr' ? 'ENR' : 'ENR',
          value: 'ENR'
        }
      ],
      Generateurs: [
        {
          label: this.$i18n.locale === 'fr' ? 'Chaudiere' : 'Boiler',
          value: 'Chaudiere'
        },
        {
          label:
            this.$i18n.locale === 'fr'
              ? 'Chaudiere condens.'
              : 'Condensing boiler.',
          value: 'Chaudiere condens.'
        },
        {
          label: this.$i18n.locale === 'fr' ? 'Cheminée' : 'Chimney',
          value: 'Cheminée'
        },
        {
          label: this.$i18n.locale === 'fr' ? 'Insert fermé' : 'Closed insert',
          value: 'Insert fermé'
        },
        {
          label: this.$i18n.locale === 'fr' ? 'Poele' : 'Stove',
          value: 'Poele'
        },
        {
          label:
            this.$i18n.locale === 'fr' ? 'Chaudiere Granules' : 'Pellet boiler',
          value: 'Chaudiere Granules'
        },
        {
          label: this.$i18n.locale === 'fr' ? 'PAC air/eau' : 'PAC air/water',
          value: 'PAC air/eau'
        },
        {
          label: 'PAC air/air',
          value: 'PAC air/air'
        }
      ],
      Etats: [
        {
          label: this.$i18n.locale === 'fr' ? 'Vetuste' : 'Vetuste',
          value: 'Vetuste'
        },
        {
          label: this.$i18n.locale === 'fr' ? 'Mauvais' : 'Bad',
          value: 'Mauvais'
        },
        {
          label: this.$i18n.locale === 'fr' ? 'Moyen' : 'Medium',
          value: 'Moyen'
        },
        {
          label: this.$i18n.locale === 'fr' ? 'Bon' : 'Good',
          value: 'Bon'
        }
      ],
      Fumisteries: [
        {
          label: this.$i18n.locale === 'fr' ? 'Ventouse' : 'Suction cup',
          value: 'Ventouse'
        },
        {
          label: this.$i18n.locale === 'fr' ? 'Cheminée' : 'Chimney',
          value: 'Cheminée'
        }
      ],
      Positions: [
        {
          label: this.$i18n.locale === 'fr' ? 'Mural' : 'Mural',
          value: 'Mural'
        },
        {
          label: this.$i18n.locale === 'fr' ? 'Au sol' : 'On the ground',
          value: 'Au sol'
        }
      ],
      Installations: [
        {
          label:
            this.$i18n.locale === 'fr' ? 'Volume chauffé' : 'Heated volume',
          value: 'Volume chauffé'
        },
        {
          label:
            this.$i18n.locale === 'fr'
              ? 'Volume non-chauffé'
              : 'Unheated volume',
          value: 'Volume non-chauffé'
        }
      ],
      Conduits: [
        {
          label: this.$i18n.locale === 'fr' ? 'Isolés' : 'Isolated',
          value: 'Isolés'
        },
        {
          label: this.$i18n.locale === 'fr' ? 'Non-Isolés' : 'Non-isolated',
          value: 'Non-Isolés'
        }
      ]
    }
  },
  methods: {
    resetComponent() {
      // Réinitialiser les données spécifiques à ce composant (par exemple, formulaire)
      this.form15 = {
        chauffages: []
      }
      this.number = 0
    },
    submitForm() {
      // Utilisez $refs pour accéder au formulaire
      const valid = this.$refs.myForm.validate()
      if (!valid) {
        return
      }

      this.$emit('nextStep', this.form15)
    },
    prevStep() {
      this.$emit('prevStep')
    },
    updateChauffage(event) {
      let boucle = 0
      if (event > this.form15.chauffages.length) {
        boucle = event - this.form15.chauffages.length
        for (let i = 0; i < boucle; i++) {
          this.form15.chauffages.push({
            energie: null,
            generateur: null,
            nombre: null,
            annee: null,
            puissance: null,
            etat: null,
            fumisterie: null,
            position: null,
            installation: null,
            conduits: null,
            file: null
          })
        }
      } else if (event < this.form15.chauffages.length) {
        boucle = this.form15.chauffages.length - event
        this.form15.chauffages.splice(
          this.form15.chauffages.length - boucle,
          boucle
        )
      }
    }
  }
}
</script>

<style lang="css" scoped>
.bottom {
  display: flex !important;
  align-items: center;
  justify-content: center !important;
  gap: 16px;
}
</style>
