<template>
  <div class="activity-activity">
    <div class="icon-entity-activity">
      <font-awesome-icon
        v-if="entity.type == 'Appel'"
        class="icon-historique-activity img-h-14"
        icon="phone-alt"
      />
      <font-awesome-icon
        v-if="entity.type == 'Pré visite'"
        class="icon-historique-activity-previsite img-h-14"
        icon="briefcase"
      />
      <font-awesome-icon
        v-if="entity.type == 'Planning'"
        class="icon-historique-activity img-h-14"
        icon="truck"
      />
      <font-awesome-icon
        v-if="entity.type == 'Autre'"
        class="icon-historique-activity-autre img-h-14"
        icon="hourglass-start"
      />
    </div>
    <div class="page-content-activity">
      <div class="description-bloc-activity">
        <div class="activity-name">
          <div class="done-activity">
            <v-checkbox
              class="input-checkbox label-check"
              v-model="done"
              @change="toggleActivityDone"
              :disabled="readonly"
              color="#5C2DD3"
              hide-details
            >
            </v-checkbox>
            <span>{{ entity.name }}</span>
          </div>
          <v-menu
            v-if="!readonly"
            offset-y
            close-on-click
            min-width="250px !important"
            max-width="250px !important"
          >
            <template v-slot:activator="{ attrs, on }">
              <v-btn
                small
                v-bind="attrs"
                v-on="on"
                text
                class="mr-3 btn-comment-actions"
              >
                <font-awesome-icon class="img-h-14" icon="ellipsis-h" />
              </v-btn>
            </template>

            <v-list>
              <v-list-item @click.prevent.stop="hundleClickUpdateActivity">
                <v-list-item-title>
                  {{ $t('update') }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item @click.prevent.stop="toggleActivityDone">
                <v-list-item-title>
                  {{ $i18n.locale === 'fr' ? 'Marquer comme' : 'Mark as' }}
                  {{
                    entity.terminer
                      ? $i18n.locale === 'fr'
                        ? '« À faire »'
                        : '« To do »'
                      : $i18n.locale === 'fr'
                      ? 'terminée'
                      : 'finished'
                  }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item @click.prevent.stop="hundleClickDeleteActivity">
                <v-list-item-title>
                  {{ $t('deleted') }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
        <div
          class="activity-description mt-1"
          v-html="entity.description"
        ></div>
        <div class="informations capitalize mt-2">
          <span>
            {{ entity.date_start | date('DD MMM') }}
            {{ entity.date_start | time('HH:mm') }}
          </span>
          -
          <span v-if="entity.user">
            {{ entity.user.full_name }}
          </span>
        </div>
      </div>
    </div>

    <v-dialog v-model="modifierActivite" max-width="700" persistent scrollable>
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">
            {{
              $i18n.locale === 'fr'
                ? "Modification de l'activité"
                : 'Change activity'
            }}
          </v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click.prevent.stop="hideModal('modifierActivite')"
            :title="$t('close')"
            color="#5C2DD3"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>

        <v-card-text class="body-card center-text  mt-2">
          <FormActivityProject :activity="activity" :project="project" />
        </v-card-text>

        <v-divider class="line-hr-footer"></v-divider>

        <v-card-actions class="actions-msg-btn-modal">
          <div class="message">
            <div class="error-msg" v-if="activityToAddError">
              {{ activityToAddError }}
            </div>
            <div v-if="getProjectsError" class="error-msg">
              <ul v-if="Array.isArray(getProjectsError)">
                <li v-for="(e, index) in getProjectsError" :key="index">
                  {{ e }}
                </li>
              </ul>
              <span v-else>{{ getProjectsError }}</span>
            </div>
          </div>

          <v-spacer></v-spacer>
          <v-btn
            color="#5C2DD3"
            dark
            @click.prevent.stop="hundleClickUpdate"
            :loading="loading"
            small
          >
            {{ $t('btnValider') }}
          </v-btn>
          <v-btn text @click.prevent.stop="hideModal('modifierActivite')" small>
            {{ $t('btnCancel') }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="supprimerActivite" max-width="500" persistent scrollable>
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">
            {{
              $i18n.locale === 'fr'
                ? "Suppression de l'activité"
                : 'Deletion of activity'
            }}
          </v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click.prevent.stop="hideModal('supprimerActivite')"
            :title="$t('close')"
            color="#5C2DD3"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>

        <v-card-text class="body-card center-text  mt-2">
          <p>
            {{
              $t('msgdeleted', {
                msg: $i18n.locale === 'fr' ? 'cette activité' : 'this activity'
              })
            }}
            ?
          </p>
        </v-card-text>

        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <div class="message">
            <div v-if="getProjectsError" class="error-msg">
              <ul v-if="Array.isArray(getProjectsError)">
                <li v-for="(e, index) in getProjectsError" :key="index">
                  {{ e }}
                </li>
              </ul>
              <span v-else>{{ getProjectsError }}</span>
            </div>
          </div>

          <v-spacer></v-spacer>
          <v-btn
            color="#5C2DD3"
            dark
            @click.prevent.stop="supprimerActivity"
            :loading="loading"
            small
          >
            {{ $t('btnValider') }}
          </v-btn>
          <v-btn
            text
            @click.prevent.stop="hideModal('supprimerActivite')"
            small
          >
            {{ $t('btnCancel') }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import FormActivityProject from './FormActivityProject'
import moment from 'moment'
import momentDurationFormatSetup from 'moment-duration-format'
momentDurationFormatSetup(moment)
moment.locale('fr')
import { mapGetters, mapActions } from 'vuex'
export default {
  components: { FormActivityProject },
  props: {
    entity: { required: true },
    project: { required: false },
    readonly: {
      default: false
    },
    page: { required: false }
  },
  data() {
    return {
      done: this.entity.terminer,
      activity: { ...this.entity },
      reset: 1,
      loading: false,
      supprimerActivite: false,
      modifierActivite: false,
      activityToAddError: null
    }
  },
  methods: {
    ...mapActions([
      'updateActivity',
      'deleteActivity',
      'toggleActivity',
      'refrechProject',
      'fetchProjectHistoriques',
      'fetchJournalDesModifications',
      'resetPageValue',
      'fetchAllActivities',
      'fetchProjectPrevisiteurNotDeleted',
      'fetchProjectPoseurNotDeleted',
      'fetchProjectCommSedNotDeleted',
      'fetchUsersRespPlanningNotDeleted',
      'fetchListNotification',
      'resetErrorProject'
    ]),
    hideModal(ref) {
      this.resetErrorProject()
      this.activityToAddError = null
      this[ref] = false
    },
    async toggleActivityDone() {
      if (this.readonly) {
        return
      }

      this.done = this.entity.terminer ? 0 : 1
      await this.toggleActivity({
        value: this.entity.terminer ? 0 : 1,
        activity: this.entity,
        project: this.project
      })
      this.resetPageValue({ object: 'activity' })
      this.fetchProjectHistoriques({
        project: this.project,
        page: 1,
        reset: 1
      })
      this.fetchAllActivities({
        project: this.project,
        page: this.page,
        reset: 1
      })
      this.refrechProject({ project: this.project, reset: 1 })
      this.fetchListNotification(this.currentUser.id)
    },
    async hundleClickUpdateActivity() {
      this.resetErrorProject()
      this.activity = { ...this.entity }
      this.modifierActivite = true
      if (
        (!this.getProjectsUsersNotDeleted ||
          !this.getProjectsUsersNotDeleted.length) &&
        !this.getProjectsUsersNotDeletedLoading
      ) {
        await this.fetchProjectPrevisiteurNotDeleted()
        await this.fetchProjectPoseurNotDeleted()
        await this.fetchProjectCommSedNotDeleted()
        await this.fetchUsersRespPlanningNotDeleted()
      }
    },
    async hundleClickUpdate() {
      this.resetErrorProject()
      this.activityToAddError = null
      if (!this.activity.name) {
        this.activityToAddError =
          this.$i18n.locale === 'fr'
            ? "Le titre de l'activité est obligatoire"
            : 'The title of the activity is required'
        return
      }
      if (!this.activity.date_start) {
        this.activityToAddError =
          this.$i18n.locale === 'fr'
            ? "La date de début de l'activité est obligatoire"
            : 'The start date of the activity is required'
        return
      }
      if (!this.activity.user || !this.activity.user.value) {
        this.activityToAddError =
          this.$i18n.locale === 'fr'
            ? "L'activité doit etre affectée à un utilisateur"
            : 'The activity must be assigned to a user'
        return
      }
      this.loading = true
      this.activity.date_start = moment(this.activity.date_start).format(
        'YYYY-MM-DD HH:mm:ss'
      )
      if (this.activity.me_prevenir) {
        this.activity.me_prevenir = moment(
          this.activity.me_prevenir,
          'HH:mm'
        ).format('HH:mm')
      }
      const toggle = this.entity.terminer != this.activity.terminer
      const response = await this.updateActivity({
        toggle: toggle,
        entity: this.entity,
        activity: this.activity,
        project: this.project
      })
      if (response) {
        this.resetPageValue({ object: 'activity' })
        this.fetchAllActivities({
          project: this.project,
          page: this.page,
          reset: 1
        })
        this.fetchProjectHistoriques({
          project: this.project,
          page: 1,
          reset: 1
        })
        this.refrechProject({ project: this.project, reset: 1 })
        this.fetchListNotification(this.currentUser.id)
        this.hideModal('modifierActivite')
      }
      this.loading = false
    },
    hundleClickDeleteActivity() {
      this.resetErrorProject()
      this.supprimerActivite = true
    },
    async supprimerActivity() {
      this.loading = true
      const response = await this.deleteActivity({
        activity: this.entity,
        project: this.project
      })
      if (response) {
        this.resetPageValue({ object: 'activity' })
        this.fetchProjectHistoriques({
          project: this.project,
          page: 1,
          reset: 1
        })
        this.fetchAllActivities({
          project: this.project,
          page: 1,
          reset: 1
        })
        this.refrechProject({ project: this.project, reset: 1 })
        this.fetchListNotification(this.currentUser.id)
        this.hideModal('supprimerActivite')
      }
      this.loading = false
    }
  },
  computed: {
    ...mapGetters([
      'getProjectsUsersNotDeletedLoading',
      'getProjectsUsersNotDeleted',
      'currentUser',
      'getProjectsError'
    ])
  },
  filters: {
    date: function(value, format = 'DD/MM/YYYY') {
      if (!value) return ''
      return moment(value).format(format)
    },
    time: function(value, format = 'HH:mm:ss') {
      if (!value) return '--:--'
      return moment(value).format(format)
    }
  }
}
</script>

<style lang="scss">
.activity-activity {
  .done-activity {
    .custom-control-input:checked ~ .custom-control-label::before {
      color: #fff;
      border-color: #8bc34a;
      background-color: #8bc34a;
    }
    .custom-checkbox {
      .custom-control-label {
        &:before {
          border-radius: 10px;
        }
      }
    }
  }
  .activity-description {
    p {
      margin: 0;
    }
  }

  .activity-dropdown-button {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    margin: 0;
    padding: 9px;
    width: 28px;
    height: 25px;
    line-height: 0;
    box-shadow: none !important;
  }
}
</style>
<style lang="scss" scoped>
.page-content-activity {
  padding-bottom: 9px;
}
.activity-activity {
  position: relative;
  .activity-name {
    color: #5e5e5e;
    font-weight: 600;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    .done-activity {
      display: flex;
      align-items: center;
    }
  }
  .informations {
    font-weight: 600;
  }
}
.description-bloc-activity {
  position: relative;
  height: auto;
  font-size: 12px;
  margin-top: 6px;
  padding: 8px 15px;
  background-color: #f9f9f97a;
  border: 1px solid #d8d8d8;
  border-radius: 4px;
  margin-left: 26px;
  &.contact {
    margin-top: 50px;
    padding: 38px 20px;
  }
  &:before {
    content: '';
    position: absolute;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-right: 8px solid #d8d8d8;
    left: -9px;
    top: 20px;
  }
  &:after {
    content: '';
    position: absolute;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-right: 8px solid #fcfcfc;
    left: -8px;
    top: 20px;
  }
}

.icon-entity-activity {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 35px;
  height: 35px;
  z-index: 2;
  top: 8px;
  left: -33px;
  border: 1px solid #e5e5e5;
  background-color: #fff;
  border-radius: 50%;
  font-size: 16px;
}

#supprimerActivite {
  .text {
    padding-bottom: 10px;
  }
}
</style>
