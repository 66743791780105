<template>
  <Card>
    <template #title>
      <div class="title">
        {{
          $i18n.locale === 'fr' ? 'Information Toiture' : 'Roofing Information'
        }}
      </div>
      <v-dialog v-model="dialog" width="500">
        <v-card>
          <div class="information">
            <h1>
              {{ $i18n.locale === 'fr' ? 'Attention' : 'Attention' }}
            </h1>
            <h4>
              {{
                $i18n.locale === 'fr'
                  ? 'Il manque un element :'
                  : 'One element is missing:'
              }}
            </h4>

            <h2>{{ $i18n.locale === 'fr' ? 'PHOTO :' : 'PHOTO :' }}</h2>
            <p v-for="(toiture, i) in form14.toitures" :key="i">
              <template v-if="!toiture.file">
                {{
                  $i18n.locale === 'fr'
                    ? `TYPE TOITURE N° ${i + 1} - ORIENTATION`
                    : `ROOF TYPE N° ${i + 1} - ORIENTATION`
                }}
                <span class="text-uppercase">{{ toiture.orientation }}</span
                >.
              </template>
            </p>
          </div>
        </v-card>
      </v-dialog>
    </template>
    <template #body>
      <v-form @submit.prevent="submitForm" ref="myForm">
        <v-row>
          <v-col cols="12">
            <v-radio-group v-model="type" hide-details>
              <v-radio
                :label="
                  $i18n.locale === 'fr'
                    ? 'Configuration Manuelle'
                    : 'Manual configuration'
                "
                value="manuelle"
                color="#704ad1"
              ></v-radio>
              <v-radio
                :label="
                  $i18n.locale === 'fr'
                    ? 'Configuration Automatique'
                    : 'Automatic configuration'
                "
                value="auto"
                color="#704ad1"
              ></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="6">
            <v-select
              v-model="number"
              :label="$i18n.locale === 'fr' ? 'Nombre de pan' : 'Number of pan'"
              dense
              clearable
              :placeholder="
                $i18n.locale === 'fr' ? 'Nombre de pan' : 'Number of pan'
              "
              item-text="label"
              item-value="id"
              :items="numbers"
              outlined
              :persistent-placeholder="true"
              required
              color="#704ad1"
              item-color="#704ad1"
              @change="updateToiture"
            ></v-select>
          </v-col>
        </v-row>

        <v-row
          v-if="form14.toitures && form14.toitures.length && type == 'manuelle'"
        >
          <v-col cols="12" style="overflow-y: auto;">
            <table>
              <thead>
                <tr>
                  <th>
                    {{ $i18n.locale === 'fr' ? 'Orientation' : 'Orientation' }}
                  </th>
                  <th>
                    {{
                      $i18n.locale === 'fr' ? 'Type de Toiture' : 'Roof type'
                    }}
                  </th>
                  <th>
                    {{ $i18n.locale === 'fr' ? 'Longueur (m)' : 'Length (m)' }}
                  </th>
                  <th>
                    {{
                      $i18n.locale === 'fr' ? 'Largeur/Hauteur' : 'Width/Height'
                    }}
                  </th>
                  <th>
                    Surface (m2)
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(toiture, i) in form14.toitures" :key="i">
                  <td>
                    <v-select
                      v-model="toiture.orientation"
                      dense
                      item-text="label"
                      item-value="value"
                      :items="Orientation"
                      outlined
                      color="#704ad1"
                      item-color="#704ad1"
                      hide-details
                    ></v-select>
                  </td>
                  <td>
                    <v-select
                      v-model="toiture.type"
                      dense
                      item-text="label"
                      item-value="value"
                      :items="Types"
                      outlined
                      color="#704ad1"
                      item-color="#704ad1"
                      hide-details
                    ></v-select>
                  </td>
                  <td>
                    <v-text-field
                      type="number"
                      v-model="toiture.longueur"
                      @change="
                        () =>
                          (toiture.surface = toiture.longueur * toiture.largeur)
                      "
                      :min="0"
                      dense
                      outlined
                      hide-details
                      color="#704ad1"
                    ></v-text-field>
                  </td>
                  <td>
                    <v-text-field
                      type="number"
                      v-model="toiture.largeur"
                      @change="
                        () =>
                          (toiture.surface = toiture.longueur * toiture.largeur)
                      "
                      :min="0"
                      dense
                      outlined
                      hide-details
                      color="#704ad1"
                    ></v-text-field>
                  </td>
                  <td>
                    <v-text-field
                      type="number"
                      v-model="toiture.surface"
                      :min="0"
                      dense
                      readonly
                      outlined
                      hide-details
                      color="#704ad1"
                    ></v-text-field>
                  </td>
                  <td>
                    <label
                      :for="'fileInputToiture' + i"
                      style="margin-bottom: 0"
                    >
                      <font-awesome-icon icon="camera" class="mr-2" />

                      <font-awesome-icon
                        v-if="toiture.file"
                        icon="check"
                        style="color: #0cb277;"
                      />
                      <font-awesome-icon
                        v-else
                        icon="exclamation-triangle"
                        style="color: #ff9000;"
                      />
                    </label>

                    <!-- Input de type fichier (caché) -->
                    <v-file-input
                      :rules="[rules.required]"
                      v-model="toiture.file"
                      :label="null"
                      accept="image/*"
                      :id="'fileInputToiture' + i"
                      style="display: none"
                    ></v-file-input>
                  </td>
                </tr>
              </tbody>
            </table>
          </v-col>
          <v-col cols="6">
            <v-text-field
              :value="total_surface"
              type="number"
              :label="
                $i18n.locale === 'fr'
                  ? 'Total surface Toiture'
                  : 'Total Roof surface'
              "
              :field="
                $i18n.locale === 'fr'
                  ? 'Total surface Toiture'
                  : 'Total Roof surface'
              "
              :min="0"
              dense
              readonly
              placeholder="en m2"
              outlined
              hide-details
              :persistent-placeholder="true"
              color="#704ad1"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row v-if="type == 'auto'">
          <v-col cols="6">
            <v-text-field
              :value="surface_auto"
              type="number"
              :label="
                $i18n.locale === 'fr'
                  ? 'Total surface Toiture'
                  : 'Total Roof surface'
              "
              :field="
                $i18n.locale === 'fr'
                  ? 'Total surface Toiture'
                  : 'Total Roof surface'
              "
              :min="0"
              dense
              readonly
              placeholder="en m2"
              outlined
              hide-details
              :persistent-placeholder="true"
              color="#704ad1"
            ></v-text-field>
          </v-col>
        </v-row>

        <!-- Bouton "Suivant" pour chaque étape -->
        <v-col cols="12" class="bottom">
          <v-btn color="#5C2DD3" outlined @click="prevStep">
            {{ $t('precedant') }}
          </v-btn>
          <v-btn color="#5C2DD3" dark type="submit">
            {{ $t('suivant') }}
          </v-btn>
        </v-col>
      </v-form>
    </template>
  </Card>
</template>

<script>
import Card from '@/components/CrmIla26Component/Projets/Card.vue'

export default {
  components: {
    Card
  },
  data() {
    return {
      dialog: false,
      type: 'manuelle',
      form14: {
        toitures: []
      },
      number: 0,
      numbers: [
        {
          label: '1 pans',
          id: 1
        },
        {
          label: '2 pans',
          id: 2
        },
        {
          label: '3 pans',
          id: 3
        },
        {
          label: '4 pans',
          id: 4
        },
        {
          label: '5 pans',
          id: 5
        },
        {
          label: '6 pans',
          id: 6
        },
        {
          label: '7 pans',
          id: 7
        },
        {
          label: '8 pans',
          id: 8
        }
      ],
      Orientation: [
        {
          label: this.$i18n.locale === 'fr' ? 'Nord' : 'North',
          value: 'nord'
        },
        {
          label: this.$i18n.locale === 'fr' ? 'Sud' : 'South',
          value: 'sud'
        },
        {
          label: this.$i18n.locale === 'fr' ? 'Est' : 'East',
          value: 'est'
        },
        {
          label: this.$i18n.locale === 'fr' ? 'Ouest' : 'West',
          value: 'ouest'
        }
      ],
      Types: [
        {
          label: this.$i18n.locale === 'fr' ? 'Pignon' : 'Pinion',
          value: 'pignon'
        },
        {
          label: this.$i18n.locale === 'fr' ? 'Pan trad' : 'Pan trad',
          value: 'pan_trad'
        }
      ],
      rules: {
        required: value =>
          !!value ||
          (this.$i18n.locale === 'fr'
            ? 'Ce champ est obligatoire'
            : 'This field is required')
      }
    }
  },
  props: {
    surface_comble: {
      type: Number
    },
    hauteur_comble: {
      type: Number
    }
  },
  methods: {
    resetComponent() {
      // Réinitialiser les données spécifiques à ce composant (par exemple, formulaire)
      this.form14 = {
        toitures: []
      }
      this.number = 0
    },
    submitForm() {
      // Utilisez $refs pour accéder au formulaire
      const valid = this.$refs.myForm.validate()
      if (!valid) {
        this.dialog = true
        return
      }
      this.$emit(
        'nextStep',
        this.type === 'manuelle'
          ? this.form14
          : {
              surface_total: this.surface_auto
            }
      )
    },
    prevStep() {
      this.$emit('prevStep')
    },
    updateToiture(event) {
      let boucle = 0
      if (event > this.form14.toitures.length) {
        boucle = event - this.form14.toitures.length
        for (let i = 0; i < boucle; i++) {
          this.form14.toitures.push({
            orientation: 'sud',
            type: 'pignon',
            longueur: 0,
            largeur: 0,
            surface: 0,
            file: null
          })
        }
      } else if (event < this.form14.toitures.length) {
        boucle = this.form14.toitures.length - event
        this.form14.toitures.splice(
          this.form14.toitures.length - boucle,
          boucle
        )
      }
    }
  },
  computed: {
    total_surface() {
      let total = 0
      if (this.form14.toitures && this.form14.toitures.length) {
        this.form14.toitures.forEach(toiture => {
          total += +toiture.longueur * +toiture.largeur
        })
      }

      return total
    },
    surface_auto() {
      let total = 0
      if (this.type == 'auto') {
        total = this.surface_comble * (1 + this.hauteur_comble / 100)

        if (+this.number >= 4) {
          total *= 1.4
        }
        // total est un entier (arrondi)
        total = Math.round(total)
      }
      return total
    }
  }
}
</script>

<style lang="scss" scoped>
.title {
  position: relative;
  .icon-info {
    position: absolute;
    top: -4px;
    right: -20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ff9000;
    width: 18px;
    height: 18px;
    border-radius: 50%;
  }
}

table {
  width: 100%;
  thead th,
  tbody td {
    border-bottom: thin solid rgba(0, 0, 0, 0.12);
    padding: 2px 5px;
    white-space: nowrap;
    color: #5e5e5e;
    text-align: center;
    position: relative;
    font-size: 12px;
    line-height: 1.5;
    overflow: hidden;
    height: 48px;
  }
}
.information {
  padding: 15px;
  h1 {
    font-size: 18px;
    font-weight: 600;
    color: #704ad1;
  }
  h2 {
    font-size: 12px;
    font-weight: 600;
    color: #704ad1;
  }
  h3 {
    font-size: 20px;
  }
  h4 {
    font-style: italic;
    font-size: 12px;
    font-weight: 600;
  }
  p {
    font-size: 12px;
  }
}
.bottom {
  display: flex !important;
  align-items: center;
  justify-content: center !important;
  gap: 16px;
}
</style>
