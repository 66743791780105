<template>
  <div class="editable-input" :style="{ width: styleWidthInput }">
    <div class="edit-view">
      <dynamic-input-vuetify
        :input="{
          disabledInput: !readonly && !fieldKeyEdit ? false : true,
          type: readonly || fieldKeyEdit ? 'text' : field.input_type,
          type_options: field.type,
          options: field.options ? field.options.data : [],
          name: field.key,
          label: field.name,
          links: field.links ? field.links : [],
          selected: field.selection_type,
          valuePossible: field.possible_values
            ? field.possible_values.data
            : [],
          formatDate: field.format ? true : false
        }"
        :value="newValue"
        v-model="newValue"
        @save="update"
        @disabled="disabled"
      ></dynamic-input-vuetify>
    </div>
  </div>
</template>

<script>
import dynamicInputVuetify from '@/components/ui/dynamic-form/dynamic-input-vuetify.vue'
import { mapGetters } from 'vuex'
export default {
  name: 'EditableCustomField',
  components: {
    dynamicInputVuetify
  },
  props: {
    field: { required: true },
    entity: { required: true },
    value: { required: false },
    // updateFunction: { required: true },
    autoWidth: { default: false },
    readonly: { default: false },
    fieldKeyEdit: { required: false },
    styleWidthInput: { required: false },
    category: { required: false }
  },
  data() {
    return {
      editing: false,
      newValue: this.value
    }
  },
  mounted() {
    this.newValue = this.value
    if (this.field.input_type === 'table') {
      if (this.field.table_name === 'users') {
        this.field.options.data = this.usersList
      }
    }
  },
  watch: {
    value(val) {
      this.newValue = val
      this.$emit('disabled')
    }
  },
  methods: {
    update(value) {
      this.newValue = value

      // Construisez l'objet payload avec les champs mis à jour
      const mappingFields = []
      this.category.map(category => {
        return {
          ...category,
          fields: category.fields.map(field => {
            if (field.is_header !== 1) {
              // Mettez à jour les valeurs des champs pour chaque catégorie
              if (JSON.stringify(field) === JSON.stringify(this.field)) {
                this.updateFieldValuesNull(
                  category.fields,
                  this.field.key,
                  value
                )
              }
              const valueField =
                field.value && field.value.id ? field.value.id : field.value

              mappingFields.push({
                field: {
                  [field.key]:
                    valueField == null
                      ? field.lastValue && field.lastValue.id
                        ? field.lastValue.id
                        : field.lastValue
                      : valueField
                },
                object: field.object
              })
            }
            return field
          })
        }
      })
      // FIELDS CONTACT
      const fieldContact = this.extractFieldsByObject(mappingFields, 'contacts')
      // FIELDS PROJET
      const fieldProjet = this.extractFieldsByObject(mappingFields, 'projets')
      // Construisez l'objet payload final
      const payload = {
        entity: this.entity,
        field: mappingFields,
        fieldContact,
        fieldProjet
      }

      // Émettez l'événement avec l'objet payload
      this.$emit('updateFunction', payload)
    },
    // Fonction pour mettre à jour les valeurs des champs
    updateFieldValues(fields, newValue) {
      if (
        fields.input_type === 'timerange' ||
        fields.input_type === 'daterange'
      ) {
        fields.value = newValue.join('/')
      } else {
        fields.value = newValue
      }
    },
    // Fonction pour mettre à jour la valeur d'un champ
    updateFieldValuesNull(fields, key, newValue) {
      for (let i = 0; i < fields.length; i++) {
        if (fields[i].key === key) {
          // Stockez la dernière valeur du champ
          fields[i].lastValue = fields[i].value
          // Mettez à jour la valeur du champ
          fields[i].value = newValue
          break
        }
      }
    },
    // Fonction pour extraire les champs en fonction de l'objet
    extractFieldsByObject(mappingFields, objectName) {
      return mappingFields
        .filter(item => item.object === objectName)
        .map(item => ({ ...item.field }))
    },
    disabled() {
      this.$emit('disabledBtnSave')
    }
  },
  computed: {
    ...mapGetters(['getProjectsUsers']),
    usersList: function() {
      return this.getProjectsUsers.map(u => {
        return {
          text: u.full_name,
          value: u.id
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.editable-input {
  .edit-view {
    &.input-radio {
      border: 1px solid #9e9e9e;
      padding: 8px;
      width: 100%;
      border-radius: 4px;
    }
  }
}
</style>
