<template>
  <Card>
    <template #title>
      <div class="title">
        {{
          $i18n.locale === 'fr'
            ? 'Information compteur Elec.'
            : 'Elec. meter information'
        }}
      </div>
    </template>
    <template #body>
      <v-form @submit.prevent="submitForm" ref="myForm">
        <v-row>
          <v-col cols="6">
            <Card>
              <template #title>{{
                $i18n.locale === 'fr' ? 'Compteur' : 'Meter'
              }}</template>
              <template #body>
                <v-row>
                  <v-col cols="6">
                    <v-select
                      v-model="form17.tension"
                      :label="$i18n.locale === 'fr' ? 'Tension' : 'Voltage'"
                      dense
                      clearable
                      :placeholder="$t('choose')"
                      item-text="label"
                      item-value="id"
                      :items="Tensions"
                      outlined
                      :persistent-placeholder="true"
                      required
                      color="#704ad1"
                      item-color="#704ad1"
                      hide-details
                    ></v-select>
                  </v-col>
                  <v-col cols="6">
                    <v-select
                      v-model="form17.puissance"
                      :label="$i18n.locale === 'fr' ? 'Puissance' : 'Power'"
                      dense
                      clearable
                      :placeholder="$t('choose')"
                      item-text="label"
                      item-value="id"
                      :items="Puissances"
                      outlined
                      :persistent-placeholder="true"
                      required
                      color="#704ad1"
                      item-color="#704ad1"
                      hide-details
                    ></v-select>
                  </v-col>
                  <v-col cols="6">
                    <v-select
                      v-model="form17.type"
                      label="Type"
                      dense
                      clearable
                      :placeholder="$t('choose')"
                      item-text="label"
                      item-value="id"
                      :items="Types"
                      outlined
                      :persistent-placeholder="true"
                      required
                      color="#704ad1"
                      item-color="#704ad1"
                      hide-details
                    ></v-select>
                  </v-col>
                </v-row>
              </template>
            </Card>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <Card>
              <template #title>{{
                $i18n.locale === 'fr'
                  ? 'Information Tableau Elec.'
                  : 'Information Tableau Elec.'
              }}</template>
              <template #body>
                <v-row>
                  <v-col cols="6">
                    <v-select
                      v-model="form17.normes"
                      :label="$i18n.locale === 'fr' ? 'Normes' : 'Standards'"
                      dense
                      clearable
                      :placeholder="$t('choose')"
                      item-text="label"
                      item-value="value"
                      :items="Normes"
                      outlined
                      :persistent-placeholder="true"
                      required
                      color="#704ad1"
                      item-color="#704ad1"
                      hide-details
                    ></v-select>
                  </v-col>
                  <v-col cols="6">
                    <v-select
                      v-model="form17.etat"
                      :label="$i18n.locale === 'fr' ? 'Etat' : 'State'"
                      dense
                      clearable
                      :placeholder="$t('choose')"
                      item-text="label"
                      item-value="value"
                      :items="Etats"
                      outlined
                      :persistent-placeholder="true"
                      required
                      color="#704ad1"
                      item-color="#704ad1"
                      hide-details
                    ></v-select>
                  </v-col>
                </v-row>
              </template>
            </Card>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-file-input
              v-model="form17.file_compteur"
              dense
              accept="image/*"
              prepend-icon="mdi-plus"
              :label="
                $i18n.locale === 'fr'
                  ? 'Ajouter photo compteur'
                  : 'Add photo counter'
              "
              underlined
              clearable
              hide-details
              color="#704ad1"
            ></v-file-input>
          </v-col>
          <v-col cols="12">
            <v-file-input
              v-model="form17.file_tableau"
              dense
              accept="image/*"
              prepend-icon="mdi-plus"
              :label="
                $i18n.locale === 'fr'
                  ? 'Ajouter photo tableau'
                  : 'Add photo table'
              "
              underlined
              clearable
              color="#704ad1"
            ></v-file-input>
          </v-col>
        </v-row>
        <!-- Bouton "Suivant" pour chaque étape -->
        <v-col cols="12" class="bottom">
          <v-btn color="#5C2DD3" outlined @click="prevStep">
            {{ $t('precedant') }}
          </v-btn>
          <v-btn color="#5C2DD3" dark type="submit">
            {{ $t('suivant') }}
          </v-btn>
        </v-col>
      </v-form>
    </template>
  </Card>
</template>

<script>
import Card from '@/components/CrmIla26Component/Projets/Card.vue'

export default {
  components: {
    Card
  },
  data() {
    return {
      form17: {
        tension: null,
        puissance: null,
        type: null,
        normes: null,
        etat: null,
        file_compteur: null,
        file_tableau: null
      },
      Tensions: [
        {
          label:
            this.$i18n.locale === 'fr'
              ? 'Compteur Monophasé'
              : 'Single-phase meter',
          value: 'compteur_monophase'
        },
        {
          label:
            this.$i18n.locale === 'fr'
              ? 'Compteur Triphasée'
              : 'Three-phase meter',
          value: 'compteur_triphasé'
        }
      ],
      Puissances: [
        {
          label: '3kva',
          value: '3kva'
        },
        {
          label: '6kva',
          value: '6kva'
        },
        {
          label: '9kva',
          value: '9kva'
        },
        {
          label: '12kva',
          value: '12kva'
        },
        {
          label: '15kva',
          value: '15kva'
        },
        {
          label: '18kva',
          value: '18kva'
        }
      ],
      Types: [
        {
          label:
            this.$i18n.locale === 'fr' ? 'Compteur classique' : 'Classic meter',
          value: 'compteur classique'
        },
        {
          label: this.$i18n.locale === 'fr' ? 'Compteur Linky' : 'Linky meter',
          value: 'compteur Linky'
        }
      ],
      Normes: [
        {
          label: this.$i18n.locale === 'fr' ? 'OUI' : 'YES',
          value: true
        },
        {
          label: 'NON',
          value: false
        }
      ],
      Etats: [
        {
          label: 'Vetuste',
          value: 'Vetuste'
        },
        {
          label: this.$i18n.locale === 'fr' ? 'Mauvais' : 'Bad',
          value: 'Mauvais'
        },
        {
          label: this.$i18n.locale === 'fr' ? 'Moyen' : 'Medium',
          value: 'Moyen'
        },
        {
          label: this.$i18n.locale === 'fr' ? 'Bon' : 'Good',
          value: 'Bon'
        }
      ]
    }
  },
  methods: {
    resetComponent() {
      // Réinitialiser les données spécifiques à ce composant (par exemple, formulaire)
      this.form17 = {
        tension: null,
        puissance: null,
        type: null,
        normes: null,
        etat: null,
        file_compteur: null,
        file_tableau: null
      }
    },
    submitForm() {
      // Utilisez $refs pour accéder au formulaire
      const valid = this.$refs.myForm.validate()
      if (!valid) {
        return
      }

      this.$emit('nextStep', this.form17)
    },
    prevStep() {
      this.$emit('prevStep')
    }
  }
}
</script>

<style lang="css" scoped>
.bottom {
  display: flex !important;
  align-items: center;
  justify-content: center !important;
  gap: 16px;
}
</style>
