<template>
  <div class="activity-note-projet" :class="{ 'sticky-comment': sticky }">
    <div class="icon-visibility">
      <font-awesome-icon
        v-if="entity.public"
        icon="globe-europe"
        class="img-h-14"
      />
      <font-awesome-icon v-else icon="lock" class="img-h-14" />
    </div>
    <div class="icon-entity-note">
      <font-awesome-icon
        class="icon-entity-note-comment img-h-14"
        icon="comment-alt"
      />
    </div>
    <div class="icon-pin-note">
      <font-awesome-icon
        class="icon-pin-note-comment img-h-14"
        icon="thumbtack"
      />
    </div>

    <div class="page-content-note">
      <template v-if="clickUpdate">
        <e-editor v-model="commentBody" />
        <div class="action-note">
          <v-btn
            small
            outlined
            color="#5C2DD3"
            class="mr-1"
            @click.prevent.stop="clickupdateNote"
          >
            {{ $t('btnValider') }}
          </v-btn>
          <v-btn small text @click.prevent.stop="annulerUpdate">
            {{ $t('btnCancel') }}
          </v-btn>
        </div>
      </template>
      <div v-else class="description-bloc-comment">
        <div class="description-note">
          <div class="informations capitalize">
            <span>
              {{ entity.created_at | date('DD MMM') }}
              {{ entity.created_at | time('HH:mm') }}
            </span>
            -
            <span>
              {{ entity.created_by.full_name }}
            </span>
          </div>
          <v-menu
            v-if="!readonly"
            offset-y
            close-on-click
            min-width="197px !important"
            max-width="197px !important"
          >
            <template v-slot:activator="{ attrs, on }">
              <v-btn
                small
                v-bind="attrs"
                v-on="on"
                text
                class="mr-3 btn-comment-actions"
              >
                <font-awesome-icon class="img-h-14" icon="ellipsis-h" />
              </v-btn>
            </template>

            <v-list>
              <v-list-item @click.prevent.stop="handelUpdateNote">
                <v-list-item-title>
                  {{ $t('update') }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item @click.prevent.stop="handelEpingleNote">
                <v-list-item-title>
                  {{
                    entity.epingler
                      ? $i18n.locale === 'fr'
                        ? 'Désépingler cette note'
                        : 'Unpin this note'
                      : $i18n.locale === 'fr'
                      ? 'Épingler cette note'
                      : 'Pin this note'
                  }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item @click.prevent.stop="handelVisibility">
                <v-list-item-title>
                  {{
                    entity.public
                      ? $i18n.locale === 'fr'
                        ? 'Rendre privé'
                        : 'Make private'
                      : $i18n.locale === 'fr'
                      ? 'Rendre public'
                      : 'Make public'
                  }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item @click.prevent.stop="handleDeleteClick">
                <v-list-item-title>
                  {{ $t('deleted') }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
        <div class="body-note">
          <div v-html="entity.body"></div>
        </div>
      </div>
    </div>
    <v-dialog v-model="supprimerComment" max-width="500" persistent scrollable>
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">
            {{
              $i18n.locale === 'fr' ? 'Supprimer commentaire' : 'Delete comment'
            }}
          </v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click.prevent.stop="hideModal('supprimerComment')"
            :title="$t('close')"
            color="#5C2DD3"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>

        <v-card-text class="body-card center-text  mt-2">
          <p>
            {{
              $t('msgdeleted', {
                msg: $i18n.locale === 'fr' ? 'ce commentaire' : 'this comment'
              })
            }}
            ?
          </p>
        </v-card-text>

        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <div class="message">
            <div v-if="getProjectsError" class="error-msg">
              <ul v-if="Array.isArray(getProjectsError)">
                <li v-for="(e, index) in getProjectsError" :key="index">
                  {{ e }}
                </li>
              </ul>
              <span v-else>{{ getProjectsError }}</span>
            </div>
          </div>

          <v-spacer></v-spacer>
          <v-btn
            color="#5C2DD3"
            dark
            @click.prevent.stop="clickDeleteNote"
            :loading="loading"
            small
          >
            {{ $t('btnValider') }}
          </v-btn>
          <v-btn text @click.prevent.stop="hideModal('supprimerComment')" small>
            {{ $t('btnCancel') }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import EEditor from '@/components/CrmIla26Component/Projets/EEditor'
import moment from 'moment'
import momentDurationFormatSetup from 'moment-duration-format'
momentDurationFormatSetup(moment)
moment.locale('fr')
import { mapActions, mapGetters } from 'vuex'
export default {
  components: {
    EEditor
  },
  props: {
    entity: { required: true },
    project: { required: false },
    sticky: { default: false },
    readonly: { defalut: false }
  },
  data() {
    return {
      clickUpdate: false,
      commentBody: this.entity.body,
      reset: 0,
      supprimerComment: false,
      loading: false
    }
  },
  methods: {
    ...mapActions([
      'updateNote',
      'deleteNote',
      'resetPageValue',
      'fetchProjectHistoriques',
      'fetchComments',
      'resetErrorProject'
    ]),

    hideModal(ref) {
      this[ref] = false
      this.resetErrorProject()
    },
    async clickDeleteNote() {
      this.loading = true
      const response = await this.deleteNote({
        comment: this.entity,
        project: this.project
      })
      if (response) {
        this.hideModal('supprimerComment')
        this.resetPageValue({ object: 'comment' })
        this.fetchProjectHistoriques({
          project: this.project,
          page: 1,
          reset: 1
        })
        this.fetchComments({
          project: this.project,
          page: 1,
          reset: 1
        })
      }
      this.loading = false
    },
    handelEpingleNote() {
      this.$nextTick(() => {
        const epingler = this.entity.epingler ? 0 : 1
        this.updateNote({
          data: { epingler: epingler },
          entity: this.entity,
          project: this.project
        })
      })
    },
    handelVisibility() {
      const public_comment = this.entity.public ? 0 : 1
      this.updateNote({
        data: { public: public_comment },
        entity: this.entity,
        project: this.project
      })
    },
    clickupdateNote() {
      if (this.commentBody != this.entity.body) {
        this.updateNote({
          data: { body: this.commentBody },
          entity: this.entity,
          project: this.project
        })
      }
      this.clickUpdate = false
    },
    handelUpdateNote() {
      this.commentBody = this.entity.body
      this.clickUpdate = true
    },
    handleDeleteClick() {
      this.resetErrorProject()
      this.supprimerComment = true
    },
    annulerUpdate() {
      this.clickUpdate = false
    }
  },
  computed: {
    ...mapGetters(['getProjectsError'])
  },
  filters: {
    date: function(value, format = 'DD/MM/YYYY') {
      if (!value) return ''
      return moment(value).format(format)
    },
    time: function(value, format = 'HH:mm:ss') {
      if (!value) return '--:--'
      return moment(value).format(format)
    },
    key: value => {
      switch (value) {
        case 'status':
          return 'Statut'
        case 'cstage_id':
          return 'Stade'
        case 'csubstage_id':
          return 'Sous-stade'
        default:
          return ''
      }
    }
  }
}
</script>
<style lang="scss">
.activity-note-projet {
  .activity-dropdown-button {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    margin: 0;
    padding: 9px;
    width: 28px;
    height: 25px;
    line-height: 0;
    box-shadow: none !important;
  }
  .btn-comment-actions {
    // .v-btn--fab.v-size--small{
    height: 18px !important;
    width: 18px !important;
    // }
  }
}
</style>
<style lang="scss" scoped>
.activity-note-projet {
  position: relative;
  .icon-visibility {
    position: absolute;
    top: 0px;
    right: 10px;
    z-index: 1;
    font-size: 10px;
  }

  .icon-entity-note {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 35px;
    height: 35px;
    z-index: 2;
    top: 12px;
    left: -33px;
    border: 1px solid #e5e5e5;
    background-color: #fff;
    border-radius: 50%;
    font-size: 16px;
    .icon-entity-note-comment {
      transform: rotateY(180deg);
    }
  }

  .icon-pin-note {
    display: none;
    position: absolute;
    left: -7px;
    top: -6px;
    font-size: 18px;
    z-index: 1;
    .icon-pin-note-comment {
      transform: rotateZ(-45deg);
    }
  }

  .page-content-note {
    padding-bottom: 9px;
    outline: none;
    .action-note {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 5px;
    }
    .description-bloc-comment {
      position: relative;
      height: auto;
      font-size: 12px;
      margin-top: 3px;
      padding: 11px 11px;
      background-color: #ebe5f9;
      border: 1px solid #ebe5f9;
      border-radius: 6px;
      margin-left: 26px;

      .description-note {
        font-weight: 600;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        color: #989898;
        border-bottom: 1px solid #989898;
        padding-bottom: 5px;
        margin-bottom: 5px;
      }

      &:before {
        content: '';
        position: absolute;
        border-top: 8px solid transparent;
        border-bottom: 8px solid transparent;
        border-right: 8px solid #ebe5f9;
        left: -9px;
        top: 20px;
      }

      &:after {
        content: '';
        position: absolute;
        border-top: 8px solid transparent;
        border-bottom: 8px solid transparent;
        border-right: 8px solid #ebe5f9;
        left: -8px;
        top: 20px;
      }

      &.error {
        background-color: #ff6961;
        &:before {
          border-right: 20px solid #ff6961;
        }
      }
    }
    .editor {
      position: relative;
      background-attachment: fixed;
      border: 1px solid #ebe5f9;
      background-color: #ebe5f9;
      padding: 6px;
      max-width: inherit;
      margin-left: 25px;
    }
  }

  &.sticky-comment {
    .icon-entity-note {
      display: none;
    }
    .icon-pin-note {
      display: block;
    }

    .page-content-note {
      .description-bloc-comment {
        margin-left: 0;
        &:before {
          display: none;
        }

        &:after {
          display: none;
        }
      }

      .editor {
        margin-left: 0;
      }
    }
  }
}
</style>
