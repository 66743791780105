<template>
  <Card>
    <template #title>
      <div class="title">
        {{
          $i18n.locale === 'fr' ? 'Ouvrant Facade Est' : 'Opening east facade'
        }}
      </div>

      <v-dialog v-model="dialog" width="500">
        <v-card>
          <div class="information">
            <h1>
              {{ $i18n.locale === 'fr' ? 'Attention' : 'Attention' }}
            </h1>
            <h4>
              {{
                $i18n.locale === 'fr'
                  ? 'Il manque un element :'
                  : 'One element is missing:'
              }}
            </h4>

            <h2>{{ $i18n.locale === 'fr' ? 'PHOTO :' : 'PHOTO :' }}</h2>
            <p v-for="(facade, i) in form8.facades" :key="i">
              <template v-if="!facade.file">
                {{
                  $i18n.locale === 'fr'
                    ? `TYPE OUVRANT N° ${i + 1} - FAÇADE EST.`
                    : `OPENING TYPE N° ${i + 1} - East FACADE.`
                }}
              </template>
            </p>
          </div>
        </v-card>
      </v-dialog>
    </template>
    <template #body>
      <v-form @submit.prevent="submitForm" ref="myForm">
        <v-row>
          <v-col cols="6">
            <v-select
              v-model="number"
              :label="
                $i18n.locale === 'fr'
                  ? 'Nombre de type d’ouvrant'
                  : 'Number Type Opening'
              "
              dense
              clearable
              :placeholder="
                $i18n.locale === 'fr' ? 'type d’ouvrant' : 'Type of opening'
              "
              item-text="label"
              item-value="id"
              :items="numbers"
              outlined
              :persistent-placeholder="true"
              required
              color="#704ad1"
              item-color="#704ad1"
              @change="updateFacade"
            ></v-select>
          </v-col>
        </v-row>

        <v-row v-if="form8.facades && form8.facades.length">
          <v-col cols="12" style="overflow-y: auto;">
            <table>
              <thead>
                <tr>
                  <th>
                    Type
                  </th>
                  <th>
                    {{ $i18n.locale === 'fr' ? 'Largeur (m)' : 'Width (m)' }}
                  </th>
                  <th>
                    {{ $i18n.locale === 'fr' ? 'Hauteur (m)' : 'Height (m)' }}
                  </th>
                  <th>
                    {{ $i18n.locale === 'fr' ? 'Nombre' : 'Number' }}
                  </th>
                  <th>
                    Surface
                  </th>
                  <th>
                    {{ $i18n.locale === 'fr' ? 'Vitrage' : 'Glazing' }}
                  </th>
                  <th>
                    {{ $i18n.locale === 'fr' ? 'Menuiserie' : 'Joinery' }}
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(facade, i) in form8.facades" :key="i">
                  <td>
                    <v-select
                      v-model="facade.type"
                      dense
                      item-text="label"
                      item-value="value"
                      :items="Types"
                      outlined
                      color="#704ad1"
                      item-color="#704ad1"
                      hide-details
                    ></v-select>
                  </td>
                  <td>
                    <v-text-field
                      type="number"
                      :min="0"
                      v-model="facade.largeur"
                      dense
                      outlined
                      hide-details
                      @input="
                        () => {
                          facade.surface =
                            facade.largeur * facade.hauteur * facade.nombre
                        }
                      "
                      color="#704ad1"
                    ></v-text-field>
                  </td>
                  <td>
                    <v-text-field
                      type="number"
                      :min="0"
                      v-model="facade.hauteur"
                      dense
                      outlined
                      hide-details
                      @input="
                        () => {
                          facade.surface =
                            facade.largeur * facade.hauteur * facade.nombre
                        }
                      "
                      color="#704ad1"
                    ></v-text-field>
                  </td>
                  <td>
                    <v-text-field
                      type="number"
                      :min="0"
                      v-model="facade.nombre"
                      dense
                      outlined
                      hide-details
                      @input="
                        () => {
                          facade.surface =
                            facade.largeur * facade.hauteur * facade.nombre
                        }
                      "
                      color="#704ad1"
                    ></v-text-field>
                  </td>
                  <td>
                    <v-text-field
                      type="number"
                      :min="0"
                      v-model="facade.surface"
                      dense
                      outlined
                      hide-details
                      readonly
                      color="#704ad1"
                    ></v-text-field>
                  </td>
                  <td>
                    <v-select
                      v-model="facade.vitrage"
                      dense
                      item-text="label"
                      item-value="value"
                      :items="Vitrages"
                      outlined
                      color="#704ad1"
                      item-color="#704ad1"
                      hide-details
                    ></v-select>
                  </td>
                  <td>
                    <v-select
                      v-model="facade.menuiserie"
                      dense
                      item-text="label"
                      item-value="value"
                      :items="Menuiseries"
                      outlined
                      color="#704ad1"
                      item-color="#704ad1"
                      hide-details
                    ></v-select>
                  </td>
                  <td>
                    <label :for="'fileInputEst' + i" style="margin-bottom: 0">
                      <font-awesome-icon icon="camera" class="mr-2" />

                      <font-awesome-icon
                        v-if="facade.file"
                        icon="check"
                        style="color: #0cb277;"
                      />
                      <font-awesome-icon
                        v-else
                        icon="exclamation-triangle"
                        style="color: #ff9000;"
                      />
                    </label>

                    <!-- Input de type fichier (caché) -->
                    <v-file-input
                      :rules="[rules.required]"
                      v-model="facade.file"
                      :label="null"
                      accept="image/*"
                      :id="'fileInputEst' + i"
                      style="display: none"
                    ></v-file-input>
                  </td>
                </tr>
              </tbody>
            </table>
          </v-col>

          <v-col cols="6">
            <v-text-field
              :value="total_surface_ouvrant"
              type="number"
              :label="
                $i18n.locale === 'fr'
                  ? 'Surface des ouvrants'
                  : 'Opening surface'
              "
              :field="
                $i18n.locale === 'fr'
                  ? 'Surface des ouvrants'
                  : 'Opening surface'
              "
              :min="0"
              dense
              readonly
              placeholder="en m2"
              outlined
              :persistent-placeholder="true"
              color="#704ad1"
            ></v-text-field>
          </v-col>

          <v-col cols="6">
            <v-text-field
              :value="total_isolable"
              type="number"
              label="Surface Isolable"
              field="Surface Isolable"
              :min="0"
              dense
              readonly
              placeholder="en m2"
              outlined
              :persistent-placeholder="true"
              color="#704ad1"
            ></v-text-field>
          </v-col>
        </v-row>

        <!-- Bouton "Suivant" pour chaque étape -->
        <v-col cols="12" class="bottom">
          <v-btn color="#5C2DD3" outlined @click="prevStep">
            {{ $t('precedant') }}
          </v-btn>
          <v-btn color="#5C2DD3" dark type="submit">
            {{ $t('suivant') }}
          </v-btn>
        </v-col>
      </v-form>
    </template>
  </Card>
</template>

<script>
import Card from '@/components/CrmIla26Component/Projets/Card.vue'

export default {
  components: {
    Card
  },
  data() {
    return {
      dialog: false,
      form8: {
        facades: []
      },
      number: 0,
      numbers: [
        {
          label:
            this.$i18n.locale === 'fr'
              ? '1 type d’ouvrant'
              : '1 type of opening',
          id: 1
        },
        {
          label:
            this.$i18n.locale === 'fr'
              ? '2 types d’ouvrants'
              : '2 type of opening',
          id: 2
        },
        {
          label:
            this.$i18n.locale === 'fr'
              ? '3 types d’ouvrants'
              : '3 type of opening',
          id: 3
        },
        {
          label:
            this.$i18n.locale === 'fr'
              ? '4 types d’ouvrants'
              : '4 type of opening',
          id: 4
        },
        {
          label:
            this.$i18n.locale === 'fr'
              ? '5 types d’ouvrants'
              : '5 type of opening',
          id: 5
        },
        {
          label:
            this.$i18n.locale === 'fr'
              ? '6 types d’ouvrants'
              : '6 type of opening',
          id: 6
        },
        {
          label:
            this.$i18n.locale === 'fr'
              ? '7 types d’ouvrants'
              : '7 type of opening',
          id: 7
        },
        {
          label:
            this.$i18n.locale === 'fr'
              ? '8 types d’ouvrants'
              : '8 type of opening',
          id: 8
        },
        {
          label:
            this.$i18n.locale === 'fr'
              ? '9 types d’ouvrants'
              : '9 type of opening',
          id: 9
        },
        {
          label:
            this.$i18n.locale === 'fr'
              ? '10 types d’ouvrants'
              : '10 type of opening',
          id: 10
        }
      ],
      Types: [
        {
          label: this.$i18n.locale === 'fr' ? 'Porte' : 'Door',
          value: 'porte'
        },
        {
          label: this.$i18n.locale === 'fr' ? 'Fenetre' : 'Window',
          value: 'fenetre'
        },
        {
          label: this.$i18n.locale === 'fr' ? 'Porte Fenetre' : 'Window Door',
          value: 'porte_fenetre'
        },
        {
          label: this.$i18n.locale === 'fr' ? 'Baie vitre' : 'Bay window',
          value: 'baie_vitre'
        },
        {
          label: this.$i18n.locale === 'fr' ? 'Lucarne' : 'Skylight',
          value: 'lucarne'
        },
        {
          label: 'Velux',
          value: 'velux'
        }
      ],
      Vitrages: [
        {
          label: this.$i18n.locale === 'fr' ? 'Aucun(plein)' : 'None (full)',
          value: null
        },
        {
          label:
            this.$i18n.locale === 'fr' ? 'Simple vitrage' : 'Single glazing',
          value: 'fenetre'
        },
        {
          label: this.$i18n.locale === 'fr' ? 'Double ancien' : 'Double old',
          value: 'double_ancien'
        },
        {
          label: this.$i18n.locale === 'fr' ? 'Double récent' : 'Recent double',
          value: 'double_recent'
        }
      ],
      Menuiseries: [
        {
          label: this.$i18n.locale === 'fr' ? 'Bois' : 'Wood',
          value: 'bois'
        },
        {
          label: 'PVC',
          value: 'pvc'
        },
        {
          label: 'Alu',
          value: 'alu'
        }
      ],
      rules: {
        required: value =>
          !!value ||
          (this.$i18n.locale === 'fr'
            ? 'Ce champ est obligatoire'
            : 'This field is required')
      }
    }
  },
  props: {
    facade_est: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    resetComponent() {
      // Réinitialiser les données spécifiques à ce composant (par exemple, formulaire)
      this.form8 = {
        facades: []
      }
      this.number = 0
    },
    submitForm() {
      // Utilisez $refs pour accéder au formulaire
      const valid = this.$refs.myForm.validate()
      if (!valid) {
        this.dialog = true
        return
      }

      this.$emit('nextStep', {
        surface_ouvrant: this.total_surface_ouvrant,
        surface_isolable: this.total_isolable,
        ...this.form8
      })
    },
    prevStep() {
      this.$emit('prevStep')
    },
    updateFacade(event) {
      let boucle = 0
      if (event > this.form8.facades.length) {
        boucle = event - this.form8.facades.length
        for (let i = 0; i < boucle; i++) {
          this.form8.facades.push({
            type: 'porte',
            largeur: 0,
            hauteur: 0,
            surface: 0,
            nombre: 0,
            vitrage: null,
            menuiserie: 'bois',
            file: null
          })
        }
      } else if (event < this.form8.facades.length) {
        boucle = this.form8.facades.length - event
        this.form8.facades.splice(this.form8.facades.length - boucle, boucle)
      }
    }
  },
  computed: {
    total_surface_ouvrant() {
      let total = 0
      if (this.form8.facades && this.form8.facades.length) {
        this.form8.facades.forEach(facade => {
          total += +facade.surface
        })
      }

      return total
    },
    total_isolable() {
      let total = 0
      if (this.facade_est.facades && this.facade_est.facades.length) {
        this.facade_est.facades.forEach(facade => {
          total += +facade.surface
        })
      }
      total -= this.total_surface_ouvrant

      if (this.facade_est.facades && this.facade_est.facades.length) {
        this.facade_est.facades.forEach(facade => {
          if (!facade.eligible) total -= +facade.surface
        })
      }

      return total
    }
  }
}
</script>

<style lang="scss" scoped>
.title {
  position: relative;
  .icon-info {
    position: absolute;
    top: -4px;
    right: -20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ff9000;
    width: 18px;
    height: 18px;
    border-radius: 50%;
  }
}

table {
  width: 100%;
  thead th,
  tbody td {
    border-bottom: thin solid rgba(0, 0, 0, 0.12);
    padding: 2px 5px;
    white-space: nowrap;
    color: #5e5e5e;
    text-align: center;
    position: relative;
    font-size: 12px;
    line-height: 1.5;
    overflow: hidden;
    height: 48px;
  }
}
.information {
  padding: 15px;
  h1 {
    font-size: 18px;
    font-weight: 600;
    color: #704ad1;
  }
  h2 {
    font-size: 12px;
    font-weight: 600;
    color: #704ad1;
  }
  h3 {
    font-size: 20px;
  }
  h4 {
    font-style: italic;
    font-size: 12px;
    font-weight: 600;
  }
  p {
    font-size: 12px;
  }
}

.bottom {
  display: flex !important;
  align-items: center;
  justify-content: center !important;
  gap: 16px;
}
</style>
