<template>
  <Card>
    <template #title>
      <div class="title">
        {{ $i18n.locale === 'fr' ? 'Information comble' : 'Attic information' }}
      </div>
    </template>
    <template #body>
      <v-form @submit.prevent="submitForm" ref="myForm">
        <v-row>
          <v-col cols="6">
            <v-select
              v-model="number"
              :label="
                $i18n.locale === 'fr'
                  ? 'Nombre de combles différents'
                  : 'Number of different attics'
              "
              dense
              clearable
              :placeholder="
                $i18n.locale === 'fr' ? 'Type de Comble' : 'Attic type'
              "
              item-text="label"
              item-value="id"
              :items="numbers"
              outlined
              :persistent-placeholder="true"
              required
              color="#704ad1"
              item-color="#704ad1"
              @change="updateComble"
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6" v-for="(comble, i) in form13.combles" :key="i">
            <Card>
              <template #title
                >{{ $i18n.locale === 'fr' ? 'Combles' : 'Attic' }} {{ i + 1 }}
              </template>
              <template #body>
                <v-row>
                  <v-col cols="6">
                    <v-select
                      v-model="comble.type"
                      label="Type"
                      dense
                      clearable
                      :placeholder="$t('choose')"
                      item-text="label"
                      item-value="value"
                      :items="Types"
                      outlined
                      :persistent-placeholder="true"
                      required
                      color="#704ad1"
                      item-color="#704ad1"
                      hide-details
                    ></v-select>
                  </v-col>
                  <v-col cols="6">
                    <v-select
                      v-model="comble.isolation"
                      label="Isolation"
                      dense
                      clearable
                      :placeholder="$t('choose')"
                      item-text="label"
                      item-value="value"
                      :items="Isolations"
                      outlined
                      :persistent-placeholder="true"
                      required
                      color="#704ad1"
                      item-color="#704ad1"
                      hide-details
                    ></v-select>
                  </v-col>
                  <v-col cols="6">
                    <v-select
                      v-model="comble.epaisseur"
                      :label="
                        $i18n.locale === 'fr'
                          ? 'Epaisseur Iso.'
                          : 'Iso. thickness'
                      "
                      dense
                      clearable
                      :placeholder="$t('choose')"
                      item-text="label"
                      item-value="value"
                      :items="Epaisseurs"
                      outlined
                      :persistent-placeholder="true"
                      required
                      color="#704ad1"
                      item-color="#704ad1"
                      hide-details
                    ></v-select>
                  </v-col>
                  <v-col cols="6">
                    <v-select
                      v-model="comble.repartition"
                      :label="
                        $i18n.locale === 'fr'
                          ? 'Repartition iso'
                          : 'Iso-distribution'
                      "
                      dense
                      clearable
                      :placeholder="$t('choose')"
                      item-text="label"
                      item-value="value"
                      :items="Repartitions"
                      outlined
                      :persistent-placeholder="true"
                      required
                      color="#704ad1"
                      item-color="#704ad1"
                      hide-details
                    ></v-select>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      v-model="comble.surface"
                      label="Surface (m2)"
                      placeholder="Surface (m2)"
                      :persistent-placeholder="true"
                      type="number"
                      :min="0"
                      dense
                      outlined
                      hide-details
                      color="#704ad1"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      v-model="comble.hauteur"
                      :label="
                        $i18n.locale === 'fr' ? 'Hauteur(m)' : 'Height (m)'
                      "
                      :placeholder="
                        $i18n.locale === 'fr' ? 'Hauteur(m)' : 'Height (m)'
                      "
                      :persistent-placeholder="true"
                      type="number"
                      :min="0"
                      dense
                      outlined
                      hide-details
                      color="#704ad1"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-file-input
                      v-model="comble.file"
                      dense
                      accept="image/*"
                      prepend-icon="mdi-plus"
                      :label="
                        $i18n.locale === 'fr'
                          ? 'Ajouter photo du comble'
                          : 'Add attic photo'
                      "
                      underlined
                      clearable
                      color="#704ad1"
                    ></v-file-input>
                  </v-col>
                </v-row>
              </template>
            </Card>
          </v-col>
        </v-row>
        <!-- Bouton "Suivant" pour chaque étape -->
        <v-col cols="12" class="bottom">
          <v-btn color="#5C2DD3" outlined @click="prevStep">
            {{ $t('precedant') }}
          </v-btn>
          <v-btn color="#5C2DD3" dark type="submit">
            {{ $t('suivant') }}
          </v-btn>
        </v-col>
      </v-form>
    </template>
  </Card>
</template>

<script>
import Card from '@/components/CrmIla26Component/Projets/Card.vue'

export default {
  components: {
    Card
  },
  data() {
    return {
      form13: {
        combles: []
      },
      number: null,
      numbers: [
        {
          label:
            this.$i18n.locale === 'fr' ? '1 type de Comble' : '1 type of Attic',
          id: 1
        },
        {
          label:
            this.$i18n.locale === 'fr'
              ? '2 types de Comble'
              : '2 types of Attic',
          id: 2
        },
        {
          label:
            this.$i18n.locale === 'fr'
              ? '3 types de Comble'
              : '3 types of Attic',
          id: 3
        }
      ],
      Types: [
        {
          label: this.$i18n.locale === 'fr' ? 'Perdus' : 'Lost',
          value: 'perdus'
        },
        {
          label: this.$i18n.locale === 'fr' ? 'Aménageable' : 'Convertible',
          value: 'amenageable'
        }
      ],
      Isolations: [
        {
          label: this.$i18n.locale === 'fr' ? 'Aucune' : 'None',
          value: null
        },
        {
          label:
            this.$i18n.locale === 'fr'
              ? 'Plus de 10 ans'
              : 'More than 10 years',
          value: 'Plus de 10 ans'
        },
        {
          label:
            this.$i18n.locale === 'fr'
              ? 'Entre 5 et 10 ans'
              : 'Between 5 and 10 years',
          value: 'Entre 5 et 10 ans'
        },
        {
          label:
            this.$i18n.locale === 'fr' ? 'Moins de 5 ans' : 'Less than 5 years',
          value: 'Moins de 5 ans'
        }
      ],
      Epaisseurs: [
        {
          label: '0cm',
          value: '0cm'
        },
        {
          label: '5cm',
          value: '5cm'
        },
        {
          label: '10cm',
          value: '10cm'
        },
        {
          label: '15cm',
          value: '15cm'
        },
        {
          label: '20cm',
          value: '20cm'
        }
      ],
      Repartitions: [
        {
          label: '10%',
          value: '10%'
        },
        {
          label: '20%',
          value: '20%'
        },
        {
          label: '30%',
          value: '30%'
        },
        {
          label: '40%',
          value: '40%'
        },
        {
          label: '50%',
          value: '50%'
        },
        {
          label: '60%',
          value: '60%'
        },
        {
          label: '70%',
          value: '70%'
        },
        {
          label: '80%',
          value: '80%'
        },
        {
          label: '90%',
          value: '90%'
        },
        {
          label: '100%',
          value: '100%'
        }
      ]
    }
  },
  methods: {
    resetComponent() {
      // Réinitialiser les données spécifiques à ce composant (par exemple, formulaire)
      this.form13 = {
        combles: []
      }
      this.number = 0
    },
    submitForm() {
      // Utilisez $refs pour accéder au formulaire
      const valid = this.$refs.myForm.validate()
      if (!valid) {
        return
      }

      this.$emit('nextStep', {
        surface_total: this.surface_total,
        hauteur_total: this.hauteur_total,
        ...this.form13
      })
    },
    prevStep() {
      this.$emit('prevStep')
    },
    updateComble(event) {
      let boucle = 0
      if (event > this.form13.combles.length) {
        boucle = event - this.form13.combles.length
        for (let i = 0; i < boucle; i++) {
          this.form13.combles.push({
            type: null,
            isolation: null,
            epaisseur: null,
            repartition: null,
            surface: 0,
            hauteur: 0,
            file: null
          })
        }
      } else if (event < this.form13.combles.length) {
        boucle = this.form13.combles.length - event
        this.form13.combles.splice(this.form13.combles.length - boucle, boucle)
      }
    }
  },
  computed: {
    surface_total() {
      let total = 0
      if (this.form13.combles && this.form13.combles.length) {
        this.form13.combles.forEach(comble => {
          total += +comble.surface
        })
      }

      return total
    },
    hauteur_total() {
      let total = 0
      if (this.form13.combles && this.form13.combles.length) {
        this.form13.combles.forEach(comble => {
          total += +comble.hauteur
        })
      }

      return total
    }
  }
}
</script>

<style lang="css" scoped>
.bottom {
  display: flex !important;
  align-items: center;
  justify-content: center !important;
  gap: 16px;
}
</style>
